import { Grid } from '@mui/material';
import LeadCostChart from 'components/analytics/lead-charts/lead-cost';
import LeadVolumeChart from 'components/analytics/lead-charts/lead-volume';
import MDBox from 'components/themed/MDBox';

import './style.scss';
import UserStats from 'components/user-stats';
import { useApplicationUserContext } from 'context/ApplicationUser';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const UserDashboard: React.FC = () => {
    const { banners } = useApplicationUserContext();

    useEffect(() => {
        banners.remove('onboarding-left');
    }, []);

    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <MDBox className="layout-middle">
                <UserStats />
                <Grid container className="chart-container">
                    <Grid item xs={12} md={6} className="chart-wrapper">
                        <LeadCostChart />
                    </Grid>
                    <Grid item xs={12} md={6} className="chart-wrapper">
                        <LeadVolumeChart />
                    </Grid>
                </Grid>

            </MDBox>
            
        </>
    );
};

export default UserDashboard;
