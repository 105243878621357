import { IClientsFinancialDetailsResponse } from 'contracts/requests/IClientsFinancialDetailsResponse';
import { IDashboardCommissionQuery, IDashboardCommissionResponse } from 'contracts/requests/IDashboardCommission';
import { IDashboardStatsResponseData } from 'contracts/requests/IDashboardStatsResponse';
import { TAG_ACCOUNT_MANAGER_STATS } from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';

const accountManagerStatsApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getDashboardCommission: builder.query<IDashboardCommissionResponse, IDashboardCommissionQuery>({
            query: (body) => {
                return {
                    url: `/dashboard/commissions`,
                    method: 'POST',
                    body,
                };
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        spotdifApi.util.invalidateTags([
                            {
                                type: TAG_ACCOUNT_MANAGER_STATS,
                            },
                        ]),
                    );
                } catch (error) {
                    console.error('Something went wrong with dashboard commissions request.', error.message);
                }
            },
        }),

        getDashboardStats: builder.query<IDashboardStatsResponseData, IDashboardCommissionQuery>({
            providesTags: [TAG_ACCOUNT_MANAGER_STATS],
            query: (body) => {
                return {
                    url: `/dashboard/stats`,
                    method: 'POST',
                    body: body,
                };
            },
        }),

        getClientsFinancialDetails: builder.query<IClientsFinancialDetailsResponse, IDashboardCommissionQuery>({
            providesTags: [TAG_ACCOUNT_MANAGER_STATS],
            query: (body) => {
                return {
                    url: `dashboard/client-financials`,
                    method: 'POST',
                    body,
                };
            },
        }),

        exportAccountManagersStats: builder.mutation<any, IDashboardCommissionQuery>({
            query: (body) => {
                return {
                    url: `dashboard/commissions/export`,
                    method: 'POST',
                    body,
                };
            },
            transformResponse: (response: any) => {
                return response;
            },
        }),

        exportClientFinancials: builder.mutation<any, IDashboardCommissionQuery>({
            query: (body) => {
                return {
                    url: `dashboard/client-financials/export`,
                    method: 'POST',
                    body,
                };
            },
            transformResponse: (response: any) => {
                return response;
            },
        }),
    }),
});

export const {
    useGetDashboardCommissionQuery,
    useGetDashboardStatsQuery,
    useGetClientsFinancialDetailsQuery,
    useExportAccountManagersStatsMutation,
    useExportClientFinancialsMutation,
} = accountManagerStatsApi;
