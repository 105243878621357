import classNames from 'classnames';
import PrimarySwitch from 'components/shared/PrimarySwitch';
import MDButton from 'components/themed/MDButton';
import MDInput from 'components/themed/MDInput';
import { ContactOptionsObject } from 'contracts/spotdif/ContactOptionsObject';
import { FieldArray, Form, FormikProvider, useFormik, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useUpdateContactPricesMutation } from 'redux/services/spotdif/advanceManagement';
import * as Yup from 'yup';

interface IContactPrice {
    contactOptions: ContactOptionsObject[];
    businessId: string;
}

const ContactPrice: React.FC<IContactPrice> = ({ contactOptions, businessId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [updateContactPrice] = useUpdateContactPricesMutation();

    const validationSchema = Yup.object().shape({
        contactOptions: Yup.array().of(
            Yup.object().shape({
                optionPrice: Yup.number().required('Option price is required').positive('Must be positive.'),
            }),
        ),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { contactOptions },
        validationSchema,
        onSubmit: async (values) => {
            updateContactPrice({ ...values, businessId })
                .unwrap()
                .then((res) => {
                    enqueueSnackbar(res.message ?? 'Price updated successfully.', {
                        variant: 'success',
                        key: 'contact-price',
                    });
                })
                .catch((error) => {
                    enqueueSnackbar(error?.message ?? 'Something went wrong.', {
                        variant: 'error',
                        key: 'contact-price',
                    });
                });
        },
    });

    const { values, handleBlur, setFieldValue, errors, touched } = formik;

    return (
        <>
            <div className="form-groups">
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="form-input mb-0 contact-price">
                            <FieldArray name="contactOptions">
                                {() =>
                                    values.contactOptions.map((contact, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="price-input">
                                                    <div className="left">
                                                        <MDInput
                                                            type="text"
                                                            label={contact.optionName}
                                                            placeholder="Enter contact option price"
                                                            name={`contactOptions[${index}].optionPrice`}
                                                            value={values.contactOptions[index].optionPrice}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    `contactOptions[${index}].optionPrice`,
                                                                    +e.target.value,
                                                                );
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={
                                                                touched.contactOptions?.[index]?.optionPrice &&
                                                                Boolean(errors.contactOptions?.[index])
                                                            }
                                                            helperText={
                                                                touched.contactOptions?.[index]?.optionPrice &&
                                                                // @ts-ignore
                                                                errors.contactOptions?.[index]?.optionPrice
                                                            }
                                                        />
                                                    </div>

                                                    <div className="right">
                                                        <PrimarySwitch
                                                            defaultChecked
                                                            name={`contactOptions[${index}].isActive`}
                                                            value={values.contactOptions[index].isActive}
                                                            onChange={formik.handleChange}
                                                            checked={values.contactOptions[index].isActive}
                                                        />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </FieldArray>
                        </div>
                        <MDButton
                            className={classNames('button button-primary', {})}
                            type="button"
                            onClick={() => formik.handleSubmit()}
                        >
                            Update
                        </MDButton>
                    </Form>
                </FormikProvider>
            </div>
        </>
    );
};

export default ContactPrice;
