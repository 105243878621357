import React, { createContext, useState } from 'react';

interface ICountyContext {
    currentCountyCode: string;
    updateCurrentCounty: (postalCode: string) => void;
}

export const CountyContext = createContext<ICountyContext>({
    currentCountyCode: '',
    updateCurrentCounty: null,
});

interface IPostalCodeProviderProps {
    children: React.ReactNode;
}

export const usePostalCodeContext = () => {
    const context = React.useContext(CountyContext);

    if (!context) {
        throw new Error('The component is not within a PostalCodeProvider');
    }

    return context;
};

const PostalCodeProvider: React.FC<IPostalCodeProviderProps> = ({ children }) => {
    const [currentCountyCode, setCurrentCountyCode] = useState<string>('');

    const updateCurrentCounty = (postalCode: string) => {
        setCurrentCountyCode(postalCode);
    };

    return (
        <CountyContext.Provider value={{ currentCountyCode, updateCurrentCounty }}>{children}</CountyContext.Provider>
    );
};

export default PostalCodeProvider;
