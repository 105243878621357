import { ISetSupplierUrlRequest } from 'contracts/requests/ISetSupplierUrlRequest';
import { ISetSupplierUrlResponse } from 'contracts/requests/ISetSupplierUrlResponse';
import { IChangePartnerAdsQuery, IChangePartnerAdsResponse } from 'contracts/spotdif/IChangePartnerAdsQuery';
import { IGeneratePartnersAdsResponse } from 'contracts/spotdif/IGeneratePartnersAdsQuery';
import { IGetPartnersAdsResponse, IPartnersAdsQuery } from 'contracts/spotdif/IGetPartnersAdsResponse';
import { TAG_GET_BADGE_STATUS, TAG_GET_PARTNERS_ADS } from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';

const partnersApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getPartnersAds: builder.query<IGetPartnersAdsResponse, IPartnersAdsQuery>({
            providesTags: [TAG_GET_PARTNERS_ADS],
            query: (body) => {
                return {
                    url: `/ads/get-ads`,
                    method: 'POST',
                    body,
                };
            },
        }),

        updatePartnersAds: builder.mutation<any, FormData>({
            invalidatesTags: [TAG_GET_PARTNERS_ADS],
            query: (body) => {
                return {
                    url: `/ads/update/${body.get('_id')}`,
                    method: 'POST',
                    body,
                };
            },
        }),

        changePartnersAdsStatus: builder.mutation<IChangePartnerAdsResponse, IChangePartnerAdsQuery>({
            invalidatesTags: [TAG_GET_PARTNERS_ADS],
            query: (body) => {
                return {
                    url: `ads/update/${body.id}`,
                    method: 'POST',
                    body,
                };
            },
        }),

        generatePartnersAds: builder.mutation<IGeneratePartnersAdsResponse, any>({
            invalidatesTags: [TAG_GET_PARTNERS_ADS],
            query: (body) => {
                return {
                    url: `ads/create`,
                    method: 'POST',
                    body,
                };
            },
        }),

        deletePartnersAds: builder.mutation<void, string>({
            invalidatesTags: [TAG_GET_PARTNERS_ADS],
            query: (id) => ({
                url: `ads/${id}`,
                method: 'DELETE',
            }),
        }),

        getSupplierBadges: builder.query<any, void>({
            query: () => {
                return {
                    url: `/supplier-badges`,
                    method: 'GET',
                };
            },
        }),

        setPartnerUrl: builder.mutation<ISetSupplierUrlResponse, ISetSupplierUrlRequest>({
            invalidatesTags: [
                // {
                //     type: TAG_USER_PROFILE,
                //     id: TAG_GET_USER_PROFILE,
                // },
                TAG_GET_BADGE_STATUS,
            ],
            query: (body) => {
                return {
                    url: `supplier-badges/check-badge`,
                    method: 'POST',
                    body,
                };
            },
        }),
    }),
});

export const {
    useGetPartnersAdsQuery,
    useChangePartnersAdsStatusMutation,
    useGeneratePartnersAdsMutation,
    useDeletePartnersAdsMutation,
    useUpdatePartnersAdsMutation,
    useGetSupplierBadgesQuery,
    useSetPartnerUrlMutation,
} = partnersApi;
