import { IPostCodeCollection } from 'contracts/spotdif/PostCodeTargetingList';
import { useMemo } from 'react';
import { useGetIrelandPostCodeQuery, useGetUKPostCodeQuery } from 'redux/services/spotdif/onBoarding';

export const useCountyData = () => {
    const { isLoading: isLoadingUKCounties, data: ukCounties } = useGetUKPostCodeQuery();
    const { isLoading: isLoadingIrelandCounties, data: irelandCounties } = useGetIrelandPostCodeQuery();

    const availableCounties = useMemo<IPostCodeCollection>(() => {
        let areaGeometries = [];

        if (ukCounties?.objects?.Areas?.geometries && Array.isArray(ukCounties?.objects?.Areas?.geometries)) {
            areaGeometries.push(...ukCounties?.objects?.Areas?.geometries);
        }
        if (
            irelandCounties?.objects?.IRL_adm1?.geometries &&
            Array.isArray(irelandCounties?.objects?.IRL_adm1?.geometries)
        ) {
            areaGeometries.push(...irelandCounties?.objects?.IRL_adm1?.geometries);
        }
        // if (irelandCounties?.objects?.Areas?.geometries && Array.isArray(irelandCounties?.objects?.Areas?.geometries)) {
        //     areaGeometries.push(...irelandCounties?.objects?.Areas?.geometries);
        // }
        return areaGeometries.reduce((postalCodes, { properties }) => {
            postalCodes[properties.NAME_0 + '.' + properties.name] = properties.POSTAL_CODES;
            return postalCodes;
        }, {});
    }, [ukCounties, irelandCounties]);

    return {
        availableCountyPostalCodes: availableCounties,
        ukCounties: ukCounties,
        irelandCounties: irelandCounties,
        isLoading: isLoadingUKCounties || isLoadingIrelandCounties,
    };
};
