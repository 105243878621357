import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import { ExpandedState, flexRender, useReactTable } from '@tanstack/react-table';
import { createColumnHelper, getCoreRowModel } from '@tanstack/table-core';
import reportAccepted from 'assets/images/icons/report-accepted.png';

import validLeads from 'assets/images/icons/valid-leads.png';
import classNames from 'classnames';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import BasicModal from 'components/core/Modal';
import AlertPopUp from 'components/shared/AlertPopUp';
import CurrencySymbol from 'components/shared/CurrencyFormat';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import { GetBusinessIndustryResponseDataObject } from 'contracts/spotdif/GetBusinessIndustryResponseDataObject';

import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { useDeleteBusinessIndustryMutation } from 'redux/services/spotdif/industriesLeads';
import ExpandedRow from './ExpandedRow';

interface TableProps {
    industriesData: GetBusinessIndustryResponseDataObject[];
}

const columnHelper = createColumnHelper<GetBusinessIndustryResponseDataObject>();

const Table: React.FC<TableProps> = ({ industriesData }) => {
    const [deleteBusinessIndustry] = useDeleteBusinessIndustryMutation();
    const [showDeletePopup, setShowDeletePopUp] = useState('');

    const [expanded, setExpanded] = useState<ExpandedState>({});

    const getIcon = useCallback((status: boolean) => {
        if (status) {
            return (
                <Tooltip title="Live" placement="top">
                    <img className="status-img" alt="active" src={validLeads} />
                </Tooltip>
            );
        }
        return (
            <Tooltip title="Paused" placement="top">
                <img className="status-img" alt="paused" src={reportAccepted} />
            </Tooltip>
        );
    }, []);

    const columns = useMemo(
        () => [
            columnHelper.accessor('isActive', {
                header: 'Status',
                cell: (info) => getIcon(info.getValue()),
            }),
            columnHelper.accessor('_id', {
                header: () => (
                    <AuthorisedUsage module={MODULE.BUSINESS_INDUSTRIES} permission={PERMISSIONS.DELETE}>
                        <span>Action</span>
                    </AuthorisedUsage>
                ),
                cell: (info) => (
                    <AuthorisedUsage module={MODULE.BUSINESS_INDUSTRIES} permission={PERMISSIONS.DELETE}>
                        <button
                            type="button"
                            className="delete-icon"
                            onClick={(e) => {
                                info.row.toggleExpanded();
                                setShowDeletePopUp(info.getValue());
                            }}
                        >
                            <DeleteIcon />
                        </button>
                    </AuthorisedUsage>
                ),
            }),
            columnHelper.accessor('industry', {
                header: 'Business Industry',
                cell: (info) => <>{info.getValue()}</>,
            }),
            columnHelper.accessor('leadCost', {
                header: 'Default Lead CPL',
                cell: (info) => (
                    <CurrencySymbol currencyCode={info.row.original.associatedCurrency} value={info.getValue()} />
                ),
            }),
            columnHelper.accessor('avgConversionRate', {
                header: 'Avg. conversion rate',
                cell: (info) => <>{info.getValue() ? `${info.getValue()}%` : '-'}</>,
            }),

            columnHelper.accessor('updatedAt', {
                header: '',
                cell: (info) => (
                    <>
                        {info.row.getIsExpanded() ? (
                            <ArrowUpwardIcon
                                onClick={() => {
                                    info.row.toggleExpanded();
                                }}
                                className="expand-icon active-expand-icon"
                            />
                        ) : (
                            <ArrowDownwardIcon
                                onClick={() => {
                                    info.row.toggleExpanded();
                                }}
                                className="expand-icon"
                            />
                        )}
                    </>
                ),
            }),
        ],
        [getIcon],
    );
    const table = useReactTable({
        data: industriesData ?? [],
        state: { expanded },
        columns,
        onExpandedChange: setExpanded,
        getCoreRowModel: getCoreRowModel(),
    });

    const deleteObject = async (id) => {
        try {
            deleteBusinessIndustry(id)
                .unwrap()
                .then(() => {
                    enqueueSnackbar('Deleted Successfully', { variant: 'success', key: 'deleted-promo' });
                    handleClosePopup();
                })
                .catch((err) => {
                    console.error('ERROR deleting industry', err);
                    enqueueSnackbar(err?.data?.error?.message ? err?.data?.error?.message : 'Something went wrong', {
                        variant: 'error',
                        key: 'deleted-promo',
                    });
                });
        } catch (err) {
            console.error('ERROR deleting industry', err);

        }
    };

    const handleClosePopup = useCallback(() => {
        setShowDeletePopUp('');
    }, []);

    const numberOfHeads = useMemo(() => columns.length, [columns]);

    return (
        <>
            <div className="table-v2-block">
                <div className="table-list-scroller">
                    <table>
                        <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(header.column.columnDef.header, header.getContext())}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <>
                                <tr
                                    key={row.id}
                                    className={classNames({ 'active-row': row.getIsExpanded() })}
                                    {...{
                                        onClick: () => row.toggleExpanded(),
                                        style: { cursor: 'pointer' },
                                    }}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                                <ErrorBoundary identifier="industries-expanded-row"
                                               message="Something went wrong #sd-bi-re">
                                    {row.getIsExpanded() ? <ExpandedRow industryData={row.original} /> : null}
                                </ErrorBoundary>
                            </>
                        ))}

                        {table.getRowModel().rows.length === 0 && (
                            <tr style={{ width: '100%' }}>
                                <td colSpan={numberOfHeads}>
                                    <div className="no-data">No Data Found</div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
            <BasicModal open={!!showDeletePopup} handleClose={handleClosePopup}>
                <AlertPopUp
                    fn={(businessIndustryID) => deleteObject(businessIndustryID)}
                    handleCloseModal={handleClosePopup}
                    heading="Are you sure?"
                    subheading="Do you want to delete this business industry? It cannot be undone."
                    buttonText="Yes"
                    value={showDeletePopup}
                />
            </BasicModal>
        </>
    );
};

export default Table;
