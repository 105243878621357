import usePagination, { DOTS } from 'hooks/usePagination';
import React from 'react';

const Pagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        // className,
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    if (currentPage === 0 || paginationRange?.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange?.[paginationRange?.length - 1];

    return (
        <div className="pagination">
            <button
                onClick={onPrevious}
                disabled={currentPage === 1}
                style={{ pointerEvents: currentPage === 1 ? 'none' : 'fill' }}
            >
                {'<'}
            </button>
            <span>
                {paginationRange?.map((pageNumber, index) => {
                    if (pageNumber === DOTS) {
                        return <span key={index}>&#8230;</span>;
                    }

                    return pageNumber === currentPage ? (
                        <strong key={index} style={{ color: 'black', fontWeight: 600 }}>
                            {pageNumber}
                        </strong>
                    ) : (
                        <span key={index} onClick={() => onPageChange(pageNumber)}>
                            {pageNumber}
                        </span>
                    );
                })}
            </span>
            <button
                onClick={onNext}
                disabled={currentPage === lastPage}
                style={{ pointerEvents: currentPage === lastPage ? 'none' : 'fill' }}
            >
                {'>'}
            </button>
        </div>
    );
};

export default Pagination;
