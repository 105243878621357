import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import { IUserAccountTabs } from 'contracts/requests/IHeaderStepperProps';
import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import CreditInfo from '../shared/LoggedInUserInfo/CreditInfo';
import MDButton from '../themed/MDButton';
import { getStepsForPath } from './getStepsForPath';

export default function AccountTabHeader({ steps }: IUserAccountTabs) {
    const [open, setOpen] = React.useState(false);
    const { pathname } = useLocation();

    const { currentStep } = useMemo(() => {
        return getStepsForPath(steps, pathname);
    }, [pathname, steps]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const AddCredit = () => {
        return (
            <>
                <MDButton onClick={() => handleOpen()} className="button">
                    Add Credit
                </MDButton>
            </>
        );
    };
    return (
        <>
            <Grid sx={{ width: '100%' }} className="header-tabs--container" container>
                <Box className="account-tabs">
                    {steps.map((step) => (
                        <NavLink
                            key={step.id}
                            to={step.path}
                            className={({ isActive, isPending }) =>
                                classNames('tab', {
                                    'tab--active': currentStep?.id === step?.id,
                                    'tab--pending': isPending,
                                    'tab--default': true,
                                })
                            }
                        >
                            <span className="tab__title">{step.title}</span>
                        </NavLink>
                    ))}
                </Box>
                <Box className="header-tabs--container__right">
                    <CreditInfo slot={<AddCredit />} />
                </Box>
            </Grid>
        </>
    );
}
