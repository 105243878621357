import React from 'react';
import { Form, useFormikContext } from 'formik';
import { Autocomplete } from '@mui/material';
import { NOTIFICATION_STATUS } from './notificationStatus';
import MDInput from 'components/themed/MDInput';
import { INotificationRequest } from 'contracts/requests/INotificationRequest';

const EmailNotificationsForm: React.FC = () => {
    const { values, handleBlur, setFieldValue, handleSubmit } = useFormikContext<INotificationRequest>();
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <div className="tab-user-content-card payment-card">
                    <div className="full-width-field">
                        <Autocomplete
                            id="finance"
                            data-name="Business Industry"
                            className="spotdif-field w-100 dropdown-input"
                            value={values.leadAlertsFrequency}
                            onChange={(e, value) => setFieldValue(`leadAlertsFrequency`, value ?? '')}
                            onBlur={handleBlur}
                            options={Object.keys(NOTIFICATION_STATUS)}
                            renderInput={(params) => (
                                <MDInput
                                    {...params}
                                    isBordered={true}
                                    name="leadAlertsFrequency"
                                    value={values.leadAlertsFrequency}
                                    sx={{ padding: 0 }}
                                    label={'How often would you like to receive new lead notifications via email?'}
                                />
                            )}
                            getOptionLabel={(option) => {
                                const valueLabelMap = {
                                    instant: 'Instantly',
                                    daily: 'Daily',
                                    disabled: 'Never',
                                };
                                return valueLabelMap[option] || option;
                            }}
                        />
                    </div>
                </div>
                <button type="submit" className="button">
                    Save changes
                </button>
            </Form>
        </>
    );
};

export default EmailNotificationsForm;
