import CloseIcon from '@mui/icons-material/Close';
import { InputAdornment, MenuItem, Select } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDInput from 'components/themed/MDInput';
import { currencyData } from 'contracts/requests/ICurrencyCodesResponse';
import { useFormik } from 'formik';
import useAuthentication from 'hooks/useAuthentication';
import useGlobalMinimumTopupLeads from 'hooks/useGlobalMinimumTopupLeads';
import { enqueueSnackbar } from 'notistack';

import './style.scss';
import React, { useCallback } from 'react';
import { useAddIndustryLeadMutation } from 'redux/services/spotdif/industriesLeads';
import { avgConversionRateRegex, checkForNegativeValue } from 'utils/text-helpers';
import * as Yup from 'yup';

interface ICreateIndustryProps {
    handleCloseModal: () => void;
    currenciesData?: currencyData[];
}

const CreateIndustry: React.FC<ICreateIndustryProps> = ({ handleCloseModal, currenciesData }) => {
    const [addIndustryLead, { isLoading: loading }] = useAddIndustryLeadMutation();
    const { user } = useAuthentication();
    const { minimumLeadConfigAsPerAdmin, globalSettingEnabled } = useGlobalMinimumTopupLeads();

    const initState = {
        industry: '',
        industryUrl: '',
        centralIndustryId: '',
        leadCost: '',
        currencyCode: currenciesData[0]?.value,
        avgConversionRate: '100',
        minimumTopupLeads: '',
    };

    const validationSchema = Yup.object().shape({
        industry: Yup.string()
            .required('Industry is required.')
            .min(3, 'Minimum 3 characters')
            .max(80, 'Maximum 80 characters')
            .trim(),
        industryUrl: Yup.string()
            .required('Industry URL is required.')
            .url('Valid URL is required. Eg. https://spotdif.com/industry')
            .max(300, 'URLs are typically 300 characters long.')
            .trim(),
        centralIndustryId: Yup.string().nullable().trim(),
        leadCost: Yup.number().required('Lead cost is required.'),
        currencyCode: Yup.string().required('Currency is required.'),
        avgConversionRate: Yup.string()
            .matches(avgConversionRateRegex, 'Conversion rate should be between 0 and 100')
            .required('Conversion rate is required'),
        minimumTopupLeads: Yup.string().matches(checkForNegativeValue, 'Negative value not allowed'),
    });

    const formik = useFormik({
        initialValues: initState,
        validationSchema,
        onSubmit: async (values) => {
            const { avgConversionRate, minimumTopupLeads, ...rest } = values;
            await addIndustryLead({
                ...rest,
                avgConversionRate: Number(avgConversionRate),
                minimumTopupLeads: Number(minimumTopupLeads),
            })
                .unwrap()
                .then((res) => {
                    enqueueSnackbar('Industry created successfully', { variant: 'success', key: 'create-industry' });
                    handleCloseModal();
                })
                .catch(({ data }) => {
                    enqueueSnackbar(data?.error?.message, { variant: 'error', key: 'create-industry' });
                });
        },
    });

    const handleInputChange = useCallback(
        (event) => {
            const inputValue = event.target.value;
            const regex = /^$|^[0-9]{0,5}(\.[0-9]{0,2})?$/; // Allow an empty string or up to 5 digits before the decimal point and up to 2 digits after

            if (!regex.test(inputValue)) {
                return;
            }
            formik.handleChange(event);
        },
        [formik],
    );

    const handleCurrencyChange = useCallback(
        (event) => {
            formik.setFieldValue('currencyCode', event.target.value);
        },
        [formik],
    );

    return (
        <MDBox className="modalFormMainWrapper">
            <MDBox className="modalMainWrapper">
                <div className="modal-heading-2">Add industry</div>
                <div className="form-groups">
                    <div className="form-input">
                        <div className="form-input-groups w-100">
                            <MDInput
                                type="text"
                                label="Industry"
                                className="spotdif-field w-100"
                                name="industry"
                                data-name="industry"
                                placeholder="Eg. 'Boilers"
                                inputProps={{ maxLength: 80 }}
                                value={formik.values.industry}
                                onChange={formik.handleChange}
                                error={formik.touched.industry && Boolean(formik.errors.industry)}
                                helperText={formik.touched.industry && formik.errors.industry}
                            />
                        </div>
                        <div className="form-input-groups w-100">
                            <MDInput
                                type="text"
                                label="Industry URL"
                                className="spotdif-field w-100"
                                name="industryUrl"
                                data-name="industryUrl"
                                placeholder="https://spotdif.com/industry"
                                inputProps={{ maxLength: 80 }}
                                value={formik.values.industryUrl}
                                onChange={formik.handleChange}
                                error={formik.touched.industryUrl && Boolean(formik.errors.industryUrl)}
                                helperText={formik.touched.industryUrl && formik.errors.industryUrl}
                            />
                        </div>
                        <div className="form-input-groups w-100">
                            <MDInput
                                type="text"
                                label="Central Industry ID"
                                className="spotdif-field w-100"
                                name="centralIndustryId"
                                data-name="centralIndustryId"
                                placeholder="6539fc6e8728d1dee6ec597a"
                                inputProps={{ maxLength: 80 }}
                                value={formik.values.centralIndustryId}
                                onChange={formik.handleChange}
                                error={formik.touched.centralIndustryId && Boolean(formik.errors.centralIndustryId)}
                                helperText={formik.touched.centralIndustryId && formik.errors.centralIndustryId}
                            />
                        </div>
                        <div className="business-industry-values-wrapper">
                            <div className="w-100">
                                <MDInput
                                    type="text"
                                    label="Lead Cost"
                                    name="leadCost"
                                    data-name="Lead Cost"
                                    placeholder=""
                                    value={formik.values.leadCost}
                                    onChange={(event) => handleInputChange(event)}
                                    pr={2}
                                    inputProps={{ maxLength: 6 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                position="start"
                                                className="input-adornment currency-input-adornment"
                                            >
                                                <Select
                                                    value={formik.values.currencyCode}
                                                    onChange={handleCurrencyChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Select Currency' }}
                                                    className="input-adornment-select-currency"
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        },
                                                    }}
                                                >
                                                    {currenciesData.map((currencyCode) => (
                                                        <MenuItem
                                                            className="input-adornment-select-currency-menu-item"
                                                            key={currencyCode.value}
                                                            value={currencyCode.value}
                                                        >
                                                            <ListItemIcon
                                                                className="input-adornment-select-currency-menu-item-icon">
                                                                {currencyCode.label.charAt(0)}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                className="input-adornment-select-currency-menu-item-text">
                                                                {currencyCode.value}
                                                            </ListItemText>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={formik.touched.leadCost && Boolean(formik.errors.leadCost)}
                                    helperText={formik.touched.leadCost && formik.errors.leadCost}
                                />
                            </div>
                        </div>
                        <div className="business-industry-values-wrapper mt-1">
                            <div className="w-50">
                                <MDInput
                                    type="text"
                                    label="Average Conversion Rate %"
                                    name="avgConversionRate"
                                    data-name="Average Conversion Rate"
                                    placeholder="1-100"
                                    value={formik.values.avgConversionRate}
                                    onChange={formik.handleChange}
                                    pr={2}
                                    error={formik.touched.avgConversionRate && Boolean(formik.errors.avgConversionRate)}
                                    helperText={formik.touched.avgConversionRate && formik.errors.avgConversionRate}
                                />
                            </div>
                            <div className="w-50 ml-3">
                                <MDInput
                                    type="text"
                                    label="Minimum topup leads"
                                    name="minimumTopupLeads"
                                    data-name="Minimum topup leads"
                                    placeholder={
                                        globalSettingEnabled
                                            ? `Global setting is ${minimumLeadConfigAsPerAdmin}`
                                            : `Default value is ${user?.normalizedTopupAmount}`
                                    }
                                    value={formik.values.minimumTopupLeads}
                                    onChange={formik.handleChange}
                                    pr={2}
                                    error={formik.touched.minimumTopupLeads && Boolean(formik.errors.minimumTopupLeads)}
                                    helperText={formik.touched.minimumTopupLeads && formik.errors.minimumTopupLeads}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </MDBox>
            <button type="submit" onClick={() => formik.handleSubmit()} className="button modal-button">
                {loading ? 'Loading...' : 'Add industry'}
            </button>

            <MDBox class="modalCloseWrapper">
                <MDButton class="modalCloseBtn" onClick={handleCloseModal}>
                    <CloseIcon fontSize="large" />
                </MDButton>
            </MDBox>
        </MDBox>
    );
};

export default CreateIndustry;
