import classNames from 'classnames';
import MDTypography from 'components/themed/MDTypography';
import { ECurrency } from 'contracts/spotdif/currencyAndCountryCodes';
import React from 'react';
import CurrencySymbol from '../CurrencyFormat';
import './styles.scss';

interface LeadCardProps {
    theme: 'dark' | 'light';
    title: string;
    value: number;
    valueType: 'currency' | 'percentage' | 'simple';
    userCurrency?: string;
}

const renderValue = (value: number, type: LeadCardProps['valueType'], userCurrency?: string) => {
    switch (type) {
        case 'percentage':
            return <MDTypography className="percentage_box">{value}%</MDTypography>;
        case 'currency':
            return (
                <div className="price_box_main">
                    <MDTypography className="price_box">
                        <CurrencySymbol currencyCode={userCurrency} />
                        {value.toLocaleString()}
                    </MDTypography>
                    {userCurrency !== ECurrency.EUR && <MDTypography className="price_box_vat">+VAT</MDTypography>}
                </div>
            );
        case 'simple':
        default:
            return <MDTypography className="number_box">{value || '-'}</MDTypography>;
    }
};

const LeadCard: React.FC<LeadCardProps> = ({ title, theme, value, valueType, userCurrency }) => {
    return (
        <div className={classNames({ 'dark-card': theme === 'dark', 'light-card': theme === 'light' })}>
            <MDTypography className="" dangerouslySetInnerHTML={{ __html: title }}></MDTypography>
            {renderValue(value, valueType, userCurrency)}
        </div>
    );
};

export default LeadCard;
