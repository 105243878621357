import './style.scss';
import ServiceDetails from 'components/business-details/ServiceDetails';
import MDButton from 'components/themed/MDButton';
import { useApplicationUserContext } from 'context/ApplicationUser';
import { useCountryCodeContext } from 'context/CountryCodeContext/CountryCodeContext';
import { EVENT_ACTION, EVENT_CATEGORY, useUserAnalytics } from 'context/UserAnalytics';
import { IBusinessDetailsRequest } from 'contracts/requests/IBusinessDetailsRequest';
import { ECountry, ECurrency } from 'contracts/spotdif/currencyAndCountryCodes';
import { DaySchedule, getDefaultScheduleData } from 'contracts/spotdif/DaySchedule';
import { BuyerQuestionsConfig } from 'contracts/spotdif/GetBusinessIndustryResponseDataObject';
import { IOnboardingStepComponent } from 'contracts/view-models/IOnboardingStepComponent';
import { Form, FormikProvider, useFormik } from 'formik';
import useAuthentication from 'hooks/useAuthentication';
import { useRegexPatterns } from 'hooks/useRegexPatterns';
import { useSnackbar } from 'notistack';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    usePostBussinessProfileDetailsMutation,
    useUpdateBussinessProfileDetailsMutation,
} from 'redux/services/spotdif/onBoarding';
import * as Yup from 'yup';
import BusinessInfo from './BusinessInfo';

export interface IBusinessDetailsProps extends IOnboardingStepComponent {
}

// JSON to FormData
export const mapBusinessDetailsRequestToFormData = (userData: IBusinessDetailsRequest): FormData => {
    let formData = new FormData();
    formData.append('businessAddress', userData.businessAddress);
    formData.append('businessCity', userData.city);
    formData.append('businessIndustry', userData.businessIndustry);
    formData.append('businessLogo', userData.businessLogo);
    formData.append('businessName', userData.businessName);
    formData.append('businessUrl', userData.businessUrl);
    formData.append('address1', userData.addressLine1);
    formData.append('address2', userData.addressLine2);
    formData.append('trustpilotReviews', userData.trustpilotReviews);
    formData.append('accreditations', JSON.stringify(userData.accreditations));
    formData.append('prices', userData.prices);

    [
        'welcomeMessage',
        'financeOffers',
        'financeOffersText',
        'avgInstallTime',
        'avgInstallTimeText',
        'warranty',
        'warrantyText',
        'startingPriceCurrencyCode',
        'startingPrice',
        'startingPriceText',
    ].forEach((item) => {
        formData.append(item, userData[item] ? userData[item] : '');
    });


    const formattedBusinessHours = userData.businessOpeningHours
        .filter(({ isActive }) => isActive)
        .map((item: DaySchedule) => {
            return {
                day: item.day,
                openTime: item.openTime.format('HH:mm'),
                closeTime: item.closeTime.format('HH:mm'),
            };
        });

    formData.append('businessOpeningHours', JSON.stringify(formattedBusinessHours));
    formData.append('businessPostCode', userData.postcode);
    formData.append('businessSalesNumber', userData.businessPhoneNumber);
    formData.append('businessDescription', userData.businessDescription);
    formData.append('userId', userData.userId);
    formData.append('businessId', userData?.businessId);
    formData.append('businessMobilePrefixCode', userData?.businessMobilePrefixCode);

    const formattedBuyerQuestions = userData?.buyerQuestions?.map((question: BuyerQuestionsConfig) => {
        return {
            answer: question.answer,
            questionSlug: question.questionSlug,
            title: question.title,
            columnName: question.columnName,
            _id: question._id,
        };
    });

    if (userData?.buyerQuestions?.length > 0) {
        formattedBuyerQuestions.forEach(({ answer, questionSlug, title, columnName, _id }, index) => {
            formData.append(`buyerQuestions[${index}][answer]`, answer);
            formData.append(`buyerQuestions[${index}][questionSlug]`, questionSlug);
            formData.append(`buyerQuestions[${index}][title]`, title);
            formData.append(`buyerQuestions[${index}][columnName]`, columnName);
            formData.append(`buyerQuestions[${index}][_id]`, _id);
        });
    } else {
        formData.append('buyerQuestions', null);
    }

    return formData;
};

const BusinessDetails: React.ForwardRefExoticComponent<React.RefAttributes<IBusinessDetailsProps>> = forwardRef(
    (props, ref) => {
        const { enqueueSnackbar } = useSnackbar();
        const { user } = useAuthentication();
        const { canSubmitDetailsFor } = useApplicationUserContext();

        const { businessDetailsId, userServiceId } = user;

        const [isManualLocation, toggleManualLocation] = useState(false);

        const [createBusinessProfileTrigger, { isLoading: isCreatingBusinessProfile }] =
            usePostBussinessProfileDetailsMutation();
        const [updateBusinessProfileTrigger, { isLoading: isUpdatingBusinessProfile }] =
            useUpdateBussinessProfileDetailsMutation();

        const { trackEvent } = useUserAnalytics();

        const { currentCountryCode } = useCountryCodeContext();

        const { phoneNumberRegex } = useRegexPatterns();

        useEffect(() => {
            if (!user.hasOnboarded) {
                trackEvent(user.isStepCompleted('businessDetails') ? EVENT_ACTION.COMPLETED : EVENT_ACTION.INITIATED, {
                    category: EVENT_CATEGORY.ONBOARDING_BUSINESS,
                    label: EVENT_CATEGORY.ONBOARDING_BUSINESS,
                });
            }
        }, [trackEvent, user]);

        const initialValues: IBusinessDetailsRequest = {
            businessLogo: businessDetailsId?.businessLogo ?? '',
            businessIndustry: businessDetailsId.businessIndustry,
            businessName: businessDetailsId?.businessName,
            businessAddress: businessDetailsId?.businessAddress ?? '',
            addressLine1: businessDetailsId?.address1 ?? '',
            addressLine2: businessDetailsId?.address2 ?? '',
            businessDescription: businessDetailsId?.businessDescription ?? '',
            businessUrl: businessDetailsId?.businessUrl ?? '',
            city: businessDetailsId?.businessCity,
            postcode: businessDetailsId?.businessPostCode,
            businessPhoneNumber: businessDetailsId?.businessSalesNumber,
            businessOpeningHours: businessDetailsId?.businessOpeningHours || getDefaultScheduleData(),
            financeOffers: userServiceId?.financeOffers ?? '',
            financeOffersText: userServiceId?.financeOffersText ?? '',
            prices: userServiceId?.prices ?? '',
            startingPrice: userServiceId?.startingPrice ?? '',
            startingPriceCurrencyCode: userServiceId?.startingPriceCurrencyCode ?? '',
            startingPriceText: userServiceId?.startingPriceText ?? '',
            warranty: userServiceId?.warranty ?? '',
            warrantyText: userServiceId?.warrantyText ?? '',
            welcomeMessage: userServiceId?.welcomeMessage ?? '',
            accreditations: userServiceId?.accreditations ?? [],
            avgInstallTime: userServiceId?.avgInstallTime ?? '',
            avgInstallTimeText: userServiceId?.avgInstallTimeText ?? '',
            trustpilotReviews: userServiceId?.trustpilotReviews ?? '',
            businessMobilePrefixCode: businessDetailsId?.businessMobilePrefixCode || '44',
            buyerQuestions: user?.buyerQuestions || [],
        };

        const validationSchema = Yup.object().shape({
            businessIndustry: Yup.string().required('Business Industry is required'),
            businessName: Yup.string().required('Business Name is required *').trim(),
            businessUrl: Yup.string()
                .nullable()
                .url('A valid web address is required (starting with https://) *')
                .trim(),
            businessAddress: Yup.string(),
            businessPhoneNumber: Yup.string()
                .required('Phone Number is required *')
                .matches(phoneNumberRegex, 'Phone Number is not valid'),
            addressLine1: Yup.string().required('Address Line 1 is required *').trim(),
            businessDescription: Yup.string()
                .max(250, 'Business Description should be less than 250 characters for' + ' best results.')
                .trim(),
            city: Yup.string().required('City is required *').trim(),
            postcode: Yup.string()
                .required(
                    currentCountryCode === ECountry.US || user?.currency === ECurrency.USD
                        ? 'Zipcode is required'
                        : 'Postcode is required',
                )
                .trim(),
            businessOpeningHours: Yup.array().of(
                Yup.object()
                    .shape({
                        day: Yup.string(),
                    })
                    .test({
                        name: 'one-valid',
                        message: 'At Least one opening hour is required',
                        test: (...val) => {
                            return val[1].parent.some((obj) => !!obj.isActive);
                        },
                    }),
            ),
            buyerQuestions: Yup.array().of(
                Yup.object().shape({
                    answer: Yup.string().required('Answer is required').trim(),
                }),
            ),
        });

        const handleBussinessDetails = (data): Promise<boolean> => {
            return new Promise((resolve, reject) => {
                createBusinessProfileTrigger(data)
                    .unwrap()
                    .then((res) => {
                        enqueueSnackbar('Business Details Added Successfully', { variant: 'success' });
                        resolve(true);
                    })
                    .catch((err) => {
                        enqueueSnackbar(err?.data?.error?.message, { variant: 'error' });
                        reject(err);
                    });
            });
        };

        const updateBussinessDetails = (data): Promise<boolean> => {
            return new Promise((resolve, reject) => {
                updateBusinessProfileTrigger(data)
                    .unwrap()
                    .then((res) => {
                        enqueueSnackbar('Business Details Updated Successfully', { variant: 'success' });
                        resolve(true);
                    })
                    .catch((err) => {
                        enqueueSnackbar(err?.data?.error?.message, { variant: 'error' });
                        reject(err);
                    });
            });
        };

        const formik = useFormik<IBusinessDetailsRequest>({
            initialValues,
            validationSchema: validationSchema,
            onSubmit: (values: IBusinessDetailsRequest) => {
            },
        });
        useImperativeHandle(ref, () => ({
            handleValidation,
        }));

        const handleValidation = (): Promise<boolean> => {
            return new Promise(async (resolve, reject) => {
                const validationResponse = await formik.validateForm();

                const isFormValid = Object.keys(validationResponse).length === 0;

                const newVal: IBusinessDetailsRequest = {
                    userId: user?._id,
                    businessLogo: formik.values.businessLogo,
                    businessId: businessDetailsId?._id,
                    ...formik.values,
                };

                const formDataVal = mapBusinessDetailsRequestToFormData(newVal);
                formik.handleSubmit();

                if (!isFormValid && Object.keys(validationResponse).length > 0) {
                    toggleManualLocation(true);
                    window.scrollTo({ top: 0, left: 0 });
                    return;
                }

                const apiResponse = await Promise.allSettled([
                    Boolean(businessDetailsId?._id)
                        ? updateBussinessDetails(formDataVal)
                        : handleBussinessDetails(formDataVal),
                ]);

                if (apiResponse[0]?.status === 'fulfilled') {
                    resolve(true);
                } else {
                    // reject()
                    console.log('FALSE');
                }
            });
        };

        useEffect(() => {
            if (currentCountryCode === ECountry.US) {
                formik.setFieldValue('businessMobilePrefixCode', businessDetailsId?.businessMobilePrefixCode || '1');
            } else {
                formik.setFieldValue('businessMobilePrefixCode', businessDetailsId?.businessMobilePrefixCode || '44');
            }
        }, [currentCountryCode, initialValues.businessIndustry, businessDetailsId?.businessMobilePrefixCode]);

        useEffect(() => {
            formik.setFieldValue('buyerQuestions', user?.buyerQuestions);
        }, [user?.buyerQuestions]);

        return (
            <div className="tab-user-content">
                <Helmet>
                    <title>Business</title>
                </Helmet>
                <FormikProvider value={formik}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleValidation();
                        }}
                    >
                        <h6>Business details</h6>
                        <BusinessInfo />

                        {canSubmitDetailsFor('user_services.any') && <ServiceDetails />}

                        {user?.hasOnboarded && (
                            <div className="form-footer d-flex m-3">
                                <MDButton
                                    className="spotdif-signup-Button"
                                    type="submit"
                                    disabled={isCreatingBusinessProfile || isUpdatingBusinessProfile}
                                    isLoading={isCreatingBusinessProfile || isUpdatingBusinessProfile}
                                >
                                    Save changes
                                </MDButton>
                            </div>
                        )}
                    </Form>
                </FormikProvider>
            </div>
        );
    },
);

export default BusinessDetails;
