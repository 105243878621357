import { Autocomplete } from '@mui/material';
import MDInput from 'components/themed/MDInput';
import { useCountryCodeContext } from 'context/CountryCodeContext/CountryCodeContext';
import { useFormikContext } from 'formik';
import useAuthentication from 'hooks/useAuthentication';
import { useEffect, useState } from 'react';
import { useGetAllIndustiesLeadsQuery } from 'redux/services/spotdif/industriesLeads';
import { IBusinessDetailsRequest } from 'contracts/requests/IBusinessDetailsRequest';
import './style.scss';

interface IIndustryPickerProps {
    fieldName?: string;
}

interface Option {
    currencyCode: string | undefined;
    industry: string;
    value: string;
}

const BusinessIndustryPicker: React.FC<IIndustryPickerProps> = ({ fieldName = 'businessIndustry' }) => {
    const { user } = useAuthentication();
    const { setFieldValue, values, errors, touched } = useFormikContext<IBusinessDetailsRequest>();
    const { data: businessIndustries } = useGetAllIndustiesLeadsQuery();
    const { updateCurrentCode } = useCountryCodeContext();

    const handleOnChange = (event, value) => {
        if (value && value.industry) {
            setFieldValue('businessIndustry', value.industry, true);
            updateCurrentCode(value.country);
        } else {
            // Handle the case when the value is null or undefined (cleared text)
            setFieldValue('businessIndustry', null, true);
        }
    };

    const [options, setOptions] = useState<Option[]>([]);

    useEffect(() => {
        if (user?.hasOnboarded) {
            // Show industries from options and filter by none
            setOptions(
                businessIndustries?.data
                    .map((industry) => ({
                        currencyCode: industry.associatedCurrency,
                        industry: industry.industry,
                        value: industry.industry,
                        country: industry.country,
                    }))
                    .sort((a, b) => a.currencyCode?.localeCompare(b.currencyCode)),
            );
        } else {
            // Show industries from options and filter by isActive
            setOptions(
                businessIndustries?.data
                    .filter((industry) => industry.isActive)
                    .map((industry) => ({
                        currencyCode: industry.associatedCurrency,
                        industry: industry.industry,
                        value: industry.industry,
                        country: industry.country,
                    }))
                    .sort((a, b) => a.currencyCode?.localeCompare(b.currencyCode)),
            );
        }
    }, [user?.hasOnboarded, businessIndustries?.data]);

    useEffect(() => {
        if (!values[fieldName] && options?.length > 0) {
            setFieldValue(fieldName, options[0].industry);
        }
    }, [businessIndustries?.data, fieldName, setFieldValue, options]);

    return (
        <>
            <Autocomplete
                id="businessIndustry"
                data-name="Business Industry"
                className="dropdown-input"
                onChange={handleOnChange}
                groupBy={(option) => option?.currencyCode}
                getOptionLabel={(option) => option?.industry || ''}
                options={options || []}
                disabled={user?.hasOnboarded ? true : false}
                value={options?.find((option) => option.industry === values[fieldName]) || null}
                renderInput={(params) => (
                    <MDInput
                        {...params}
                        name={fieldName}
                        sx={{ padding: 0 }}
                        label="Business Industry"
                        disabled={user?.hasOnboarded ? true : false}
                        error={touched.businessIndustry && Boolean(errors.businessIndustry)}
                        helperText={touched.businessIndustry && errors.businessIndustry}
                    />
                )}
                renderOption={(props, option) => <li {...props}>{option?.industry}</li>}
            />
        </>
    );
};

export default BusinessIndustryPicker;
