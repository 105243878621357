import AddIcon from '@mui/icons-material/Add';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CancelIcon from '@mui/icons-material/Cancel';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Grid, Stack, TextField, Tooltip } from '@mui/material';

import Button from '@mui/material/Button';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import BasicModal from 'components/core/Modal';
import ThreadItem from 'components/Inbox/ThreadItem';
import AlertPopUp from 'components/shared/AlertPopUp';
import { ConfirmActionPopover } from 'components/shared/Popover/ConfirmActionPopover';
import { ChatContext } from 'context/ChatContext';
import { ChatFileAttachment } from 'contracts/chat/ChatFileAttachment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import { useDeleteImageMutation, useGetMessagesQuery, useUploadImageMutation } from 'redux/services/chat/messageThread';
import { useGetChatRoomDetailsQuery } from 'redux/services/spotdif/chatToken';
import { selectMessagesForRoom } from 'redux/slices/chat';
import { useTypedSelector } from 'redux/store';

const Threads: React.FC = () => {
    const { roomId } = useParams();
    const messageThreads = useTypedSelector((state) => selectMessagesForRoom(state, roomId));
    const { enqueueSnackbar } = useSnackbar();
    const [page, setPage] = useState(1);
    const rowHeights = useRef({});
    const listRef = useRef(null);
    const messageFormRef = useRef(null);
    const { sendMessage, readRoomThreads } = useContext(ChatContext);

    const { leadId, isHydrated, isChatAllowed, isLoading, isError } = useGetChatRoomDetailsQuery(roomId, {
        selectFromResult: ({ data, isLoading, isError }) => {
            const hydrationStatus = data?.data?.isHydrated ?? false;
            return {
                leadId: data?.data?._id,
                isHydrated: hydrationStatus,
                isError: isError,
                isLoading: isLoading,
                isChatAllowed: (isLoading || !isError) && hydrationStatus,
            };
        },
    });


    const hasSeenBottomOfList = useRef<Boolean>(false);
    const [showDeletePopup, setShowDeletePopUp] = useState(false);

    const [threadInputHeight, setThreadInputHeight] = useState<number>(0);


    const scrollToBottom = useCallback(() => {
        window.setTimeout(() => {
            if (listRef.current) {
                listRef.current.scrollToItem(messageThreads.length - 1);
                hasSeenBottomOfList.current = true;
            }
        }, 1000);
    }, [messageThreads.length]);

    const { isLoading: isLoadingMessages, data: messagesData } = useGetMessagesQuery({
        roomId,
        page,
    });

    // MEDIA CONTENT LOGIC HERE.
    const [uploadImageToChatServer, { isLoading: isUploadingImage }] = useUploadImageMutation();
    const [deleteImageFromChatServer, { isLoading: isDeletingImage }] = useDeleteImageMutation();

    const [uploadedAttachments, setUploadedAttachments] = useState<Array<ChatFileAttachment>>([]);

    const handleImageUpload = async (image) => {
        const formData = new FormData();
        formData.append('file', image);
        try {
            return await uploadImageToChatServer(formData).unwrap();
        } catch (error) {
            const errorMessage = error?.data?.error?.message ?? 'Something went wrong.';
            enqueueSnackbar(errorMessage, { variant: 'error', key: 'upload' });
            throw error; // Re-throw the error to be handled in the Promise.all
        }
    };

    const openImageDeleteConfirmation = (id: string) => {
        setShowDeletePopUp(true);

    };

    const handleImageDeletion = (imageId: string) => {
        try {
            deleteImageFromChatServer(imageId).unwrap().then(() => {
                setUploadedAttachments((prev) => prev.filter((attachment) => attachment._id !== imageId));
                handleClosePopup();
            });
        } catch (error) {
            const errorMessage = error?.data?.error?.message ?? 'Something went wrong.';
            enqueueSnackbar(errorMessage, { variant: 'error', key: 'delete' });
            throw error; // Re-throw the error to be handled in the Promise.all
        }
    };

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/*': [],
        },
        maxSize: 5242880,
        onDrop: async (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                const promisifyFileUploads = acceptedFiles.map((file) => {
                    return handleImageUpload(file);
                });
                const result = await Promise.all(promisifyFileUploads)
                    .then((res) => {
                        setUploadedAttachments((prev) => {
                            return [...prev, ...res];
                        });
                    })
                    .catch((err) => {
                        console.log(err, 'error uploading file');
                    });

                console.log(result, 'file upload result');
            }
        },
    });

    const loadThreadsFromPast = useCallback(
        ({ scrollOffset, scrollDirection }) => {
            if (scrollDirection === 'backward' && scrollOffset <= 0 && !isLoadingMessages) {
                if (messagesData?.meta.total === messageThreads.length) {
                    return;
                } else {
                    setPage((prevPage) => prevPage + 1);
                }
            }
        },
        [isLoadingMessages, messagesData?.meta.total, messageThreads.length],
    );

    const handleClosePopup = useCallback(() => {
        setShowDeletePopUp(false);
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messageThreads.length, scrollToBottom]);

    useEffect(() => {
        //set page name to 1 when the inbox/room changes
        setPage(1);
    }, [roomId, scrollToBottom]);

    const [threadText, setThreadText] = useState<string>('');

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setThreadText(e.target.value);
    };

    const sendTypedMessage = useCallback(() => {
        if (isChatAllowed && (threadText.trim() || uploadedAttachments.length > 0)) {
            sendMessage(roomId, threadText.trim(), uploadedAttachments);
            setThreadText('');
            setUploadedAttachments([]);
        }
    }, [roomId, sendMessage, threadText, uploadedAttachments]);

    const handleKeyPress = useCallback(
        (event) => {
            // This is perfectly safe in react, it correctly detect the keys
            if (event.key === 'Enter') {
                sendTypedMessage();
            }
        },
        [sendTypedMessage],
    );

    useEffect(() => {
        setThreadInputHeight(
            messageFormRef.current?.clientHeight ??
            messageFormRef.current?.offsetHeight ??
            messageFormRef.current?.scrollHeight ??
            0,
        );
    }, [threadText]);

    useEffect(() => {
        readRoomThreads(roomId);
    }, [roomId, readRoomThreads]);

    const getRowHeight = useCallback(index => rowHeights.current[index] + 8 || 82, []);

    const setRowHeight = useCallback((index, size) => {
        if (listRef.current) {
            listRef.current?.resetAfterIndex(0);
            rowHeights.current = { ...rowHeights.current, [index]: size };
        }
    }, []);

    const textProps = useMemo(() => {
        const shouldBeMultiline = threadText.length > 100;
        return ({
            multiline: shouldBeMultiline,
            rows: shouldBeMultiline ? 3 : 1,
        });
    }, [threadText]);

    return (
        <>
            <Grid
                container
                className="threads-outer-cnt inbox-body"
                item
                sx={{
                    height: `calc(100vh - ${191 + threadInputHeight}px )`,
                }}
            >
                <AutoSizer disableWidth={true}>
                    {({ height }) => (
                        <List
                            height={height}
                            itemCount={messageThreads.length}
                            // itemSize={(index) => messageThreads[index].itemHeight}
                            itemSize={getRowHeight}
                            width={'975px'}
                            onScroll={loadThreadsFromPast}
                            ref={listRef}
                            onItemsRendered={() => {
                                if (!hasSeenBottomOfList.current) {
                                    scrollToBottom();
                                }
                            }}
                        >
                            {/* <div></div> */}
                            {({ index, style }) => {
                                const message = messageThreads[index];
                                return (
                                    <ThreadItem
                                        style={style}
                                        message={message}
                                        index={index}
                                        setRowHeight={setRowHeight}
                                    />
                                );
                            }}
                        </List>
                    )}
                </AutoSizer>

                <AuthorisedUsage module={MODULE.CHAT_INBOX} permission={PERMISSIONS.MANAGE}>
                    {!!uploadedAttachments?.length && (
                        <div className="upload-photos">
                            {uploadedAttachments?.map((attachment) => {
                                // const imageUrl = URL.createObjectURL(blob);
                                const imageUrl = `${process.env.REACT_APP_CHAT_SERVER_URL}/${attachment.path}`;
                                return (
                                    <>
                                        <div className="remove-img">
                                            <ConfirmActionPopover
                                                toolTipTitle={'Delete'}
                                                title={'Are you sure?'}
                                                onConfirm={() => openImageDeleteConfirmation(attachment._id)}
                                            >
                                                <CancelIcon />
                                            </ConfirmActionPopover>
                                            <img className="uploaded-pics" src={imageUrl} />
                                        </div>

                                        <BasicModal open={showDeletePopup} handleClose={handleClosePopup}>
                                            <AlertPopUp
                                                fn={() => handleImageDeletion(attachment._id)}
                                                handleCloseModal={handleClosePopup}
                                                heading="Are you sure?"
                                                subheading="Do you want to delete this image? It cannot be undone."
                                                buttonText="Yes"
                                                value={showDeletePopup}
                                            />
                                        </BasicModal>
                                    </>
                                );
                            })}
                            <div className="uploaded-pics add-img">
                                <AddIcon />
                            </div>

                        </div>
                    )}

                </AuthorisedUsage>
            </Grid>

            <Stack
                direction="row"
                className="message-form"
                ref={messageFormRef}
            >
                <TextField

                    disabled={!isChatAllowed}

                    value={threadText}
                    onChange={onInputChange}
                    placeholder="Type a message..."
                    className="message-textfield"
                    fullWidth
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    variant="filled"
                    onKeyDown={handleKeyPress}
                    {...textProps}
                />
                <Button
                    disabled={!isChatAllowed}
                    onClick={sendTypedMessage}
                >
                    Send
                </Button>

                <input type="file" hidden />
                {
                    isChatAllowed && (
                        <AuthorisedUsage module={MODULE.CHAT_INBOX} permission={PERMISSIONS.MANAGE}>
                            <div {...getRootProps({
                                className: 'dropzone',
                                onClick: (event) => event.stopPropagation(),
                            })}>
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <UploadFileIcon />
                                ) : (
                                    <Tooltip
                                        title="Only Drag and Drop for images supported."
                                    >
                                        <AttachmentIcon className="gallery-icon" />
                                    </Tooltip>
                                )}
                            </div>
                        </AuthorisedUsage>
                    )
                }
            </Stack>
        </>
    );
};

export default Threads;
