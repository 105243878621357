import { IApiErrorResponse } from 'contracts/requests/IApiErrorResponse';
import { IApiSuccessResponse } from 'contracts/requests/IApiSuccessResponse';
import { ICreateIndustriesRequest } from 'contracts/requests/ICreateIndustriesRequest';
import { ICurrencyCodesResponse } from 'contracts/requests/ICurrencyCodesResponse';

import {
    IBusinessIndustryStats,
    IGetBusinessIndustryRequest,
    IGetBusinessIndustryResponse,
} from 'contracts/requests/IGetBusinessIndustryResponse';
import {
    IUpdateIndustryRequest,
    IUpdateIndustryRequestV2,
    IUpdateIndustryResponse,
} from 'contracts/requests/IUpdateIndustryRequest';
import { TAG_INDUSTRIES_LEAD } from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';

const industryLeadApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllIndustiesLeads: builder.query<IGetBusinessIndustryResponse, IGetBusinessIndustryRequest | void>({
            providesTags: [TAG_INDUSTRIES_LEAD],
            query: (filters) => {
                const queryParams = new URLSearchParams();
                if (filters) {
                    filters.sortingOrder && queryParams.append('order', filters.sortingOrder);

                    filters.search && queryParams.append('search', filters.search);

                    filters.perPage && queryParams.append('perPage', filters.perPage.toString());

                    filters.page && queryParams.append('page', filters.page.toString());
                }
                const queryString = queryParams.toString();
                return {
                    url: `/businessIndustry${queryString ? `?${queryString}` : ''}`,
                    method: 'get',
                };

                // const sortingOrder = filters ? filters.sortingOrder  : "";
                // const search = filters ? filters.search : "";
                // const perPageRecord = filters ? filters.perPage : "";
                // return {
                //     url: `/businessIndustry?order=${sortingOrder}&perPage=${perPageRecord}&search=${search}`,
                //     method: "get"
                // }
            },
        }),
        businessIndustryUpdate: builder.mutation<IUpdateIndustryResponse | IApiErrorResponse, IUpdateIndustryRequest>({
            invalidatesTags: [TAG_INDUSTRIES_LEAD],

            query: (body) => {
                return {
                    url: `businessIndustry/${body.id}`,
                    method: 'post',
                    body: body.data,
                };
            },
        }),
        businessIndustryUpdateV2: builder.mutation<
            IUpdateIndustryResponse | IApiErrorResponse,
            IUpdateIndustryRequestV2
        >({
            invalidatesTags: [TAG_INDUSTRIES_LEAD],

            query: (body) => {
                return {
                    url: `businessIndustry/${body.id}`,
                    method: 'post',
                    body: body.data,
                };
            },
        }),
        addIndustryLead: builder.mutation<IApiSuccessResponse | IApiErrorResponse, ICreateIndustriesRequest>({
            invalidatesTags: [TAG_INDUSTRIES_LEAD],

            query: (body) => {
                return {
                    url: `businessIndustry`,
                    method: 'post',
                    body: body,
                };
            },
        }),
        deleteBusinessIndustry: builder.mutation<void, string>({
            invalidatesTags: [TAG_INDUSTRIES_LEAD],

            query: (id) => {
                return {
                    url: `businessIndustry/${id}`,
                    method: 'DELETE',
                };
            },
        }),
        getBusinessIndustryStats: builder.query<IBusinessIndustryStats, void>({
            providesTags: [TAG_INDUSTRIES_LEAD],
            query: () => {
                return {
                    url: 'businessIndustry/stats',
                    method: 'get',
                };
            },
        }),
        getCurrencyCodes: builder.query<ICurrencyCodesResponse, void>({
            // * check if we need tags
            query: () => {
                return {
                    url: 'businessIndustry/currency',
                    method: 'get',
                };
            },
        }),
    }),
});

export const {
    useGetAllIndustiesLeadsQuery,
    useGetBusinessIndustryStatsQuery,
    useDeleteBusinessIndustryMutation,
    useBusinessIndustryUpdateMutation,
    useBusinessIndustryUpdateV2Mutation,
    useAddIndustryLeadMutation,
    useGetCurrencyCodesQuery,
} = industryLeadApi;
