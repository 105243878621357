import MDTypography from 'components/themed/MDTypography';
import React from 'react';
import './style.scss';

interface IChildOfferBannerProps {
    title: string;
}

const BannerWithoutButton: React.FC<IChildOfferBannerProps> = ({ title }) => {
    return (
        <div className="offer-wrapper justify-content-center ">
            <div className="offer-left-wrapper ">
                <MDTypography
                    className="offer-text text-align-center"
                    dangerouslySetInnerHTML={{ __html: title }}
                ></MDTypography>
            </div>
        </div>
    );
};

export default BannerWithoutButton;
