import dayjs from 'dayjs';

export function timeToDayJS(value: any) {
    return value
        ? dayjs()
              .hour(Number(value.split(':')[0]))
              .minute(Number(value.split(':')[1]))
        : dayjs();
}

export function dayToTime(day: dayjs.Dayjs) {
    return day.format('HH:mm');
}
