import classNames from 'classnames';
import MDInput from 'components/themed/MDInput';
import './style.scss';
import React from 'react';

interface IColorInputFieldProps {
    onBlur: (value: string) => void;
    onChange: (value: string) => void;
    error: boolean;
    helperText: string | any;
    value: string;
}

const ColorInputField: React.FC<IColorInputFieldProps> = ({ onBlur, onChange, error, helperText, value }) => {
    return (
        <>
            <div className={classNames('form-input mb-0 d-flex', { 'color-input-align-end': error })}>
                <MDInput
                    value={value}
                    type="color"
                    label="Picker"
                    name="badgeColor"
                    onBlur={onBlur}
                    onChange={onChange}
                    error={error}
                    helperText={helperText}
                />

                <MDInput
                    value={value}
                    type="text"
                    label="Badge Color"
                    placeholder="Badge Color"
                    name="badgeColor"
                    onBlur={onBlur}
                    onChange={onChange}
                    error={error}
                    helperText={helperText}
                />
            </div>
        </>
    );
};

export default ColorInputField;
