import MDInput from 'components/themed/MDInput';
import { Form, FormikProvider, useFormik } from 'formik';
import { useRegexPatterns } from 'hooks/useRegexPatterns';
import { useSnackbar } from 'notistack';
import { useResetPasswordMutation } from 'redux/services/spotdif/onBoarding';
import * as Yup from 'yup';

const UpdatePassword: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [resetPassword] = useResetPasswordMutation();

    const { passwordRegex } = useRegexPatterns();

    const handleResetPassword = async (data) => {
        resetPassword(data)
            .unwrap()
            .then((res) => {
                enqueueSnackbar(res.data.message, { variant: 'success' });
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(err.data.error.message, { variant: 'error' });
            });
    };

    const resetPasswordForm = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object().shape({
            currentPassword: Yup.string().required('Current Password is required'),
            newPassword: Yup.string()
                .required('New Password is required')
                .matches(
                    passwordRegex,
                    'Password must be of 8 Characters, must contain one uppercase, one lowercase, one numeric character',
                ),
            confirmPassword: Yup.string()
                .trim()
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
                .required('Confirm Password is required')
                .min(3)
                .matches(/^\S*$/, 'Password cannot be all whitespace'),
        }),
        onSubmit: async (values) => {
            handleResetPassword(values);
            resetPasswordForm.resetForm();
        },
    });
    return (
        <div className="app_tab-layout">
            <div className="app_card">
                <div className="w-form">
                    <FormikProvider value={resetPasswordForm}>
                        <Form onSubmit={resetPasswordForm.handleSubmit}>
                            <div className="modal_wrap">
                                <div className="modal_title">
                                    <h3 className="heading-style-card">Reset Password</h3>
                                </div>
                                <div className="modal_col-3">
                                    <div className="modal_input-wrap">
                                        <MDInput
                                            type="password"
                                            label="Current Password"
                                            placeholder="Current Password"
                                            className="spotdif-field"
                                            value={resetPasswordForm.values.currentPassword}
                                            onBlur={resetPasswordForm.handleBlur}
                                            name="currentPassword"
                                            onChange={resetPasswordForm.handleChange}
                                            error={
                                                resetPasswordForm.touched.currentPassword &&
                                                Boolean(resetPasswordForm.errors.currentPassword)
                                            }
                                            helperText={
                                                resetPasswordForm.touched.currentPassword &&
                                                resetPasswordForm.errors.currentPassword
                                            }
                                        />
                                    </div>
                                    <MDInput
                                        type="password"
                                        label="New Password"
                                        placeholder="New Password"
                                        className="spotdif-field"
                                        value={resetPasswordForm.values.newPassword}
                                        onBlur={resetPasswordForm.handleBlur}
                                        name="newPassword"
                                        onChange={resetPasswordForm.handleChange}
                                        error={
                                            resetPasswordForm.touched.newPassword &&
                                            Boolean(resetPasswordForm.errors.newPassword)
                                        }
                                        helperText={
                                            resetPasswordForm.touched.newPassword &&
                                            resetPasswordForm.errors.newPassword
                                        }
                                    />
                                    <MDInput
                                        type="password"
                                        label="Confirm New Password"
                                        className="spotdif-field"
                                        placeholder="Confirm New Password"
                                        value={resetPasswordForm.values.confirmPassword}
                                        onBlur={resetPasswordForm.handleBlur}
                                        name="confirmPassword"
                                        onChange={resetPasswordForm.handleChange}
                                        error={
                                            resetPasswordForm.touched.confirmPassword &&
                                            Boolean(resetPasswordForm.errors.confirmPassword)
                                        }
                                        helperText={
                                            resetPasswordForm.touched.confirmPassword &&
                                            resetPasswordForm.errors.confirmPassword
                                        }
                                    />
                                </div>
                                <div className="buttons_component">
                                    <div className="button-group">
                                        <button type="submit" className="button w-button">
                                            Save changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </FormikProvider>
                </div>
            </div>
        </div>
    );
};

export default UpdatePassword;
