import classNames from 'classnames';
import LimitedText from 'components/shared/LimitedText/LimitedText';
import React, { useCallback } from 'react';

export enum ItemNature {
    badge = 'badge',
    text = 'text',
}

export type InfoItemObj = {
    // nature: ItemNature;
    label: React.ReactNode;
    value: React.ReactNode;
    className?: string;
};

interface IInfoItemProps {
    item: InfoItemObj;
    onClick?: () => void;
}

const InfoItem: React.FC<IInfoItemProps> = ({ item, onClick }) => {
    // const itemType = item.nature;
    const onClickHandler = useCallback(() => {
        if (typeof onClick === 'function') {
            onClick();
        }
    }, [onClick]);

    return (
        <>
            {
                <div className="info-items" onClick={onClickHandler}>
                    <h6 className="sub-heading">{item.label}</h6>
                    <h5 className={classNames('sub-value', { 'client-note-width': item.label === 'Client Notes' })}>
                        <LimitedText maxLength={150} item={item} />
                    </h5>
                </div>
            }
        </>
    );
};

export default InfoItem;
