import { IAdminStatusRequest } from 'contracts/requests/IAdminStatusRequest';
import { UserBase } from 'contracts/spotdif/UserBase';
import { TAG_NON_BILLABLE_USER } from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';

const nonBilliableUserApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getNonBilliableUser: builder.query({
            providesTags: [TAG_NON_BILLABLE_USER],
            query: (args) => {
                return {
                    url: `/non-billable-users?search=${args?.search}&page=${args?.page}&perPage=${args?.perPage}`,
                    method: 'GET',
                };
            },
        }),

        addNonBillableUser: builder.mutation({
            invalidatesTags: [TAG_NON_BILLABLE_USER],
            query: (body) => {
                return {
                    url: '/non-billable-users',
                    method: 'POST',
                    body,
                };
            },
        }),

        updateNonBillableUserStatus: builder.mutation<UserBase, IAdminStatusRequest>({
            invalidatesTags: [TAG_NON_BILLABLE_USER],
            query: (body) => {
                return {
                    url: `/non-billable-users/${body.id}`,
                    method: 'POST',
                    body,
                };
            },
        }),

        deleteNonBillableUser: builder.mutation<void, string>({
            invalidatesTags: [TAG_NON_BILLABLE_USER],
            query: (args) => {
                return {
                    url: `/non-billable-users/${args}`,
                    method: 'DELETE',
                };
            },
        }),
    }),
});

export const {
    useGetNonBilliableUserQuery,
    useAddNonBillableUserMutation,
    useUpdateNonBillableUserStatusMutation,
    useDeleteNonBillableUserMutation,
} = nonBilliableUserApi;

export default nonBilliableUserApi;
