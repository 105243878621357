import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';

import {
    ColumnDef,
    createColumnHelper,
    ExpandedState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import ActiveClients from 'assets/images/icons/active.svg';
import lostClients from 'assets/images/icons/lost.svg';
import NonActiveClients from 'assets/images/icons/nonactive.svg';
import pendingClients from 'assets/images/icons/pending.svg';

// IMPORT ASSETS
import classNames from 'classnames';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import CheckPermission from 'components/app-permission/CheckPermission';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import ExpandedRow from 'components/client-table/ExpandedRow';
import Configurator from 'components/core/Configurator';
import BasicModal from 'components/core/Modal';
import AlertPopUp from 'components/shared/AlertPopUp';

import HeaderCard from 'components/shared/Card/HeaderCard';
import Pagination from 'components/shared/Pagination/Pagination';
import SpinningComponent from 'components/shared/SpinningComponent';
import TableHeader from 'components/shared/TableHeader';
import InviteUser from 'components/user-profile/AddUserModal';
import userDetailRenderer from 'components/user-profile/UserDetailRenderer';
import { EUserRole } from 'contracts/app-utils/EUserRole';

// IMPORT INTERFACE
import { ColumnsDataObject } from 'contracts/spotdif/ColumnsDataObject';
import { ClientStatus } from 'contracts/spotdif/GetTableResponseDataObject';
import { UserBase, UserBaseSerializer } from 'contracts/spotdif/UserBase';
import ITableHeaderFilters from 'contracts/view-models/ITableHeaderFilters';
import useAuthentication from 'hooks/useAuthentication';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import './style.scss';

import {
    useClientPreferenceUpdateMutation,
    useDeleteClientMutation,
    useExportClientsMutation,
    useGetAllClientsDeatilsQuery,
    useGetAllClientsQuery,
    useGetClientColumnsQuery,
    useGetUserStatsQuery,
} from 'redux/services/spotdif/clients';
import { useGetAllIndustiesLeadsQuery } from 'redux/services/spotdif/industriesLeads';
import { useAddNonBillableUserMutation } from 'redux/services/spotdif/non-billiable-user';
import { useGetAllUsersQuery, useLazyImpersonateLoginQuery } from 'redux/services/spotdif/user';
import toTitleCase, { capitalizeFirstLetter } from 'utils/text-helpers';

const columnHelper = createColumnHelper<ColumnsDataObject>();

const ClientTableV2: React.FC = () => {
    const [filters, setFilters] = useState<ITableHeaderFilters>({
        search: '',
        perPage: 100,
        sortingOrder: 'desc',
        status: '',
        page: 1,
        total: 0,
        pageCount: 0,
        clientId: '',
        leadsStatus: '',
        industry: '',
        business: '',
        showOnlyAccountManagers: false,
        accountManagerId: '',
    });
    const { user } = useAuthentication();

    const navigate = useNavigate();
    const [sorting, setSorting] = useState<SortingState>([]);
    const [clientID, setClientID] = useState('');
    const [showPopUp, setShowPopUp] = useState(false);
    const [expanded, setExpanded] = useState<ExpandedState>({});
    const [openConfig, setOpenConfig] = useState<boolean>(false);
    const csvInstanceRef: any = useRef();

    const { availableAccessManagers } = useGetAllClientsQuery(
        { showOnlyAccountManagers: true, accountManagerId: '' },
        {
            selectFromResult: ({ data, isLoading }) => {
                return {
                    availableAccessManagers: data?.data,
                    isLoadingAccessManager: isLoading,
                };
            },
        },
    );

    const { data: clients, isLoading: isLoadingUsers } = useGetAllClientsDeatilsQuery({ ...filters, sortBy: sorting });
    const { columnPreference, isLoadingColumnNames } = useGetClientColumnsQuery(undefined, {
        selectFromResult: ({ data, isLoading }) => {
            return {
                // columnPreference: columnPreferenceSerializer.parse(data),
                columnPreference: data,
                isLoadingColumnNames: isLoading,
            };
        },
    });

    const { data: businessIndustries } = useGetAllIndustiesLeadsQuery();

    const { data: allClients } = useGetAllUsersQuery();
    const [exportClientsData, { data: csvData }] = useExportClientsMutation();

    const { data: userStats, isLoading: isLoadingStats } = useGetUserStatsQuery();
    const [deleteClientTriger] = useDeleteClientMutation();

    const [impersonateLogin] = useLazyImpersonateLoginQuery();

    const [clientPreferenceUpdate] = useClientPreferenceUpdateMutation();

    const handleClosePopup = useCallback(() => {
        setShowPopUp(false);
        setClientID('');
    }, [setShowPopUp, setClientID]);

    useEffect(() => {
        if (csvData && csvInstanceRef.current && csvInstanceRef.current?.link) {
            setTimeout(() => {
                csvInstanceRef.current.link.click();
            });
        }
    }, [csvData]);

    const availableStatuses = useMemo(
        () => [
            {
                name: 'All Clients',
                value: ClientStatus.AllClients,
            },
            // {
            //     name: 'Archived Clients',
            //     value: 'archived',
            // },
            {
                name: 'Active Clients',
                value: ClientStatus.isActive,
            },
            {
                name: 'Non-Active  Clients',
                value: ClientStatus.isNotActive,
            },
            {
                name: 'Pending Clients',
                value: ClientStatus.isPending,
            },
            {
                name: 'Lost Clients',
                value: ClientStatus.isLost,
            },
        ],
        [],
    );

    const getIcon = useCallback((status: ClientStatus) => {
        switch (status) {
            case ClientStatus.isActive:
                return (
                    <Tooltip title="Active Client" placement="top">
                        <img className="status-img" alt="Active" src={ActiveClients} />
                    </Tooltip>
                );

            case ClientStatus.isNotActive:
                return (
                    <Tooltip title="Non-Active Client" placement="top">
                        <img className="status-img" alt="Non-Active" src={NonActiveClients} />
                    </Tooltip>
                );

            case ClientStatus.isPending:
                return (
                    <Tooltip title="Pending Client" placement="top">
                        <img className="status-img" alt="Pending" src={pendingClients} />
                    </Tooltip>
                );

            case ClientStatus.isLost:
                return (
                    <Tooltip title="Lost Client" placement="top">
                        <img className="status-img" alt="Lost" src={lostClients} />
                    </Tooltip>
                );

            default:
                return null;
        }
    }, []);

    const impersonateLoginHandler = useCallback(async (id) => {
        impersonateLogin(id)
            .unwrap()
            .then((payload) => {
                window.open(payload.url, '_blank');
            })
            .catch((err) => {
                console.log(err, '>>>>>>');
            });
    }, []);

    const columns = useMemo<ColumnDef<any, React.ReactNode>[]>(() => {
        return isLoadingColumnNames || isLoadingUsers
            ? []
            : [
                  columnHelper.accessor('clientStatus', {
                      header: () => <span>Status</span>,
                      cell: (info) => <>{getIcon(info.getValue())}</>,
                  }),
                  columnHelper.display({
                      id: 'expander',
                      header: () => (
                          <AuthorisedUsage module={MODULE.CLIENTS} permission={PERMISSIONS.DELETE}>
                              {' '}
                              <span>Action</span>{' '}
                          </AuthorisedUsage>
                      ),
                      enableSorting: false,
                      cell: ({ row }) => (
                          <AuthorisedUsage module={MODULE.CLIENTS} permission={PERMISSIONS.DELETE}>
                              <div className="d-flex gap client-button">
                                  <button
                                      className={classNames('delete-icon', 'client-deletButton', {
                                          'button-disabled': row.original.isDeleted,
                                      })}
                                      onClick={(e) => {
                                          row.toggleExpanded();
                                          setShowPopUp(true);
                                          setClientID(row.original._id);
                                      }}
                                      disabled={row.original.isDeleted}
                                  >
                                      <DeleteIcon />
                                  </button>
                              </div>
                          </AuthorisedUsage>
                      ),
                  }),

                  ...(columnPreference?.columns || [])
                      .filter((c: ColumnsDataObject) => c.originalName && c.isVisible)
                      .map((item: ColumnsDataObject) => {
                          return {
                              id: item.originalName,
                              accessorFn: (row) => {
                                  return row[item.originalName];
                              },
                              header: () => {
                                  if (item.originalName === 'createdAt') {
                                      return <span>Signup Date</span>;
                                  } else if (item.originalName === 'daily') {
                                      return <span>Daily Lead Cap</span>;
                                  } else {
                                      return <span>{toTitleCase(item?.displayName ?? item.originalName)}</span>;
                                  }
                              },
                              // enableSorting: true,
                              enableMultiSort: true,
                              // sortingFn: sortingFns.textCaseSensitive,
                              cell: (info) => {
                                  const user = UserBaseSerializer.parse(info.row.original);

                                  const detailComponent = userDetailRenderer({ user, detailPath: item.originalName });
                                  if (detailComponent) {
                                      return detailComponent;
                                  } else {
                                      if (
                                          info.cell.renderValue()?.toString() === 'weekly payment' ||
                                          info.cell.renderValue()?.toString() === 'auto charge' ||
                                          info.cell.renderValue()?.toString() === 'add credits manually'
                                      ) {
                                          return (
                                              <span>
                                                  {capitalizeFirstLetter(info.cell.renderValue()?.toString()) || '-'}
                                              </span>
                                          );
                                      } else return <span>{info.cell.renderValue()?.toString() || '-'}</span>;
                                  }
                              },
                              footer: (info) => info.column.id,
                          };
                      }),
                  columnHelper.accessor('_id', {
                      header: '',
                      cell: (info) => (
                          <>
                              <div className="d-flex gap">
                                  <button className="common-table-btn">More Details</button>
                                  {user.showImpersonate && (
                                      <button
                                          className="common-table-btn"
                                          onClick={() => impersonateLoginHandler(info.getValue())}
                                      >
                                          Impersonate
                                      </button>
                                  )}
                                  <AuthorisedUsage module={MODULE.INVOICE_MANAGEMENT} permission={PERMISSIONS.UPDATE}>
                                      {info.row.original?.onBoardingPercentage === 100 && (
                                          <button
                                              className="common-table-btn"
                                              onClick={() => handleXeroManagement(info.row.original._id)}
                                          >
                                              Advance Management
                                          </button>
                                      )}
                                  </AuthorisedUsage>
                              </div>
                          </>
                      ),
                  }),
              ];
    }, [isLoadingColumnNames, isLoadingUsers, columnPreference]);

    const table = useReactTable({
        data: clients?.data || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
        // enableMultiSort: true,
        manualSorting: true,

        state: { expanded, sorting },
        onExpandedChange: setExpanded,
        getExpandedRowModel: getExpandedRowModel(),

        // onSortingChange: setSorting,
        onSortingChange: (getSorting) => {
            let sortedValue = [...(typeof getSorting === 'function' ? getSorting(sorting) : [])];

            setSorting(sortedValue);
        },
        getSortedRowModel: getSortedRowModel(),

        // getPaginationRowModel: getPaginationRowModel(),
    });

    const numberOfHeads = columns.length;

    const columnPreferenceUpdate = (result) => {
        return clientPreferenceUpdate({ columns: result })
            .unwrap()
            .then((payload) => {
                enqueueSnackbar('Column preference updated.', { variant: 'success', key: 'user-auth' });
                setExpanded({});
                setOpenConfig(false);
            })
            .catch(({ data }) => {
                enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', {
                    variant: 'error',
                    key: 'user-auth',
                });
            });
    };

    useEffect(() => {
        if (clients?.meta) {
            setFilters({
                ...filters,
                total: clients?.meta?.total,
                perPage: clients?.meta?.perPage,
                pageCount: clients?.meta?.pages,
                page: typeof clients?.meta?.page === 'string' ? parseInt(clients?.meta?.page) : clients?.meta?.page,
            });
        }
    }, [clients?.meta]);

    const exportClients = () => {
        let filterUrl = `?`;

        if (filters.clientId) {
            filterUrl = `${filterUrl}&businessDetailId=${filters.clientId}`;
        }
        if (filters.search) {
            filterUrl = `${filterUrl}&search=${filters.search}`;
        }
        if (filters.sortingOrder) {
            filterUrl = `${filterUrl}&sortingOrder=${filters.sortingOrder}`;
        }

        if (filters.industry) {
            filterUrl = `${filterUrl}&industryId=${filters.industry}`;
        }

        if (filters.leadsStatus) {
            filterUrl = `${filterUrl}&clientStatus=${filters.leadsStatus}`;
        }

        if (filters.business) {
            filterUrl = `${filterUrl}&business=${filters.business}`;
        }

        if (filters.clientType) {
            filterUrl = `${filterUrl}&clientType=${filters.clientType}`;
        }

        if (filters.accountManagerId) {
            filterUrl = `${filterUrl}&accountManagerId=${filters.accountManagerId}`;
        }

        if (filters.onBoarding) {
            filterUrl = `${filterUrl}&onBoardingPercentage=${filters.onBoarding}`;
        }

        exportClientsData(filterUrl)
            .then((res) => {
                // console.log(res);
            })
            .catch((err) => {
                // console.log(err);
            });
    };
    const [isAddingClientModalOpen, setIsAddingClientModalOpen] = useState<boolean>(false);

    const openModal = (): void => setIsAddingClientModalOpen(true);
    const closeModal = (): void => setIsAddingClientModalOpen(false);

    const handleXeroManagement = useCallback((userId) => {
        navigate(`/admin/advance-management?clientID=${userId}&fromClientTable=true`);
    }, []);

    const renderStatusHeader = () => (
        <>
            <AuthorisedUsage module={MODULE.CLIENTS} permission={PERMISSIONS.CREATE}>
                <CheckPermission authorizedRole={[EUserRole.SUPER_ADMIN]}>
                    <div className={classNames('table_export_leads', { table_export_leads_disabled: isLoadingUsers })}>
                        <button onClick={() => openModal()} className="button is-small add-shadow w-button export-csv">
                            Add non billable client
                        </button>
                    </div>
                </CheckPermission>
            </AuthorisedUsage>

            <AuthorisedUsage module={MODULE.CLIENT_CSV_FILE} permission={PERMISSIONS.READ}>
                <div className={classNames('table_export_leads', { table_export_leads_disabled: isLoadingUsers })}>
                    <button onClick={exportClients} className="button is-small add-shadow w-button export-csv">
                        Export Clients
                    </button>
                    {csvData ? <CSVLink data={csvData} filename={'Clients.CSV'} ref={csvInstanceRef} /> : undefined}
                </div>
            </AuthorisedUsage>
        </>
    );

    const updateLeadFilters = (key: string, value: string | number) => {
        setFilters({ ...filters, [key]: value, page: 1 });
        setExpanded({});
    };

    const deleteClient = (id) => {
        try {
            table.getRowModel().rows.map((row) => row.toggleExpanded(false));
            deleteClientTriger(id)
                .unwrap()
                .then((res) => {
                    enqueueSnackbar(res?.message ?? 'Deleted Successfully', {
                        variant: 'success',
                        key: 'delete-client',
                    });
                    handleClosePopup();
                })
                .catch((error) => {
                    enqueueSnackbar(error?.data?.error?.message ?? 'Something went wrong', {
                        variant: 'error',
                        key: 'delete-client',
                    });
                });
        } catch (err) {
            console.log('err', err);
        }
    };

    const onboardingPerc = useMemo(
        () => [
            {
                label: 'On Boarding',
                value: '',
            },
            {
                label: 'Onboarding-25%',
                value: '25',
            },
            {
                label: 'Onboarding-50%',
                value: '50',
            },
            {
                label: 'Onboarding-75%',
                value: '75',
            },
            {
                label: 'Onboarding-100%',
                value: '100',
            },
        ],
        [],
    );

    return (
        <>
            <SpinningComponent loading={isLoadingUsers}>
                <div className="layout-middle table-v2 client-table-v2">
                    {!(isLoadingColumnNames && isLoadingUsers) ? (
                        <>
                            <AuthorisedUsage module={MODULE.CLIENTS} permission={PERMISSIONS.READ}>
                                {clients && (
                                    <TableHeader
                                        filters={filters}
                                        updateFilters={updateLeadFilters}
                                        entriesPerPage={[100, 50, 25, 10]}
                                        allStatus={availableStatuses}
                                        businessIndustries={businessIndustries?.data}
                                        clients={allClients}
                                        accountsManagers={availableAccessManagers}
                                        clientType={[
                                            { label: 'Billable', value: 'billable' },
                                            { label: 'Non-billable', value: 'nonBillable' },
                                        ]}
                                        onboardingPerc={onboardingPerc}
                                    />
                                )}
                            </AuthorisedUsage>

                            <AuthorisedUsage module={MODULE.CLIENTS} permission={PERMISSIONS.READ}>
                                <div className="table-card-block">
                                    <HeaderCard
                                        icon={ActiveClients}
                                        custClass="valid-leads"
                                        value={!isLoadingStats && userStats?.data?.activeClients}
                                        heading="Active Clients"
                                    />
                                    <HeaderCard
                                        icon={NonActiveClients}
                                        value={!isLoadingStats && userStats?.data?.pausedClients}
                                        custClass="reported-leads"
                                        heading="Non-Active Clients"
                                    />
                                    <HeaderCard
                                        icon={pendingClients}
                                        custClass="valid-leads"
                                        value={!isLoadingStats && userStats?.data?.pendingClients}
                                        heading="Pending Clients"
                                    />
                                    <HeaderCard
                                        icon={lostClients}
                                        value={!isLoadingStats && userStats?.data?.lostClients}
                                        custClass="reported-leads"
                                        heading="Lost Clients"
                                    />
                                </div>
                            </AuthorisedUsage>

                            <div className="table-card-block tableConfig justify-flex-end">
                                {renderStatusHeader()}
                                <AuthorisedUsage module={MODULE.CLIENTS} permission={PERMISSIONS.UPDATE}>
                                    <button
                                        className="border-0 pointer edit-dashboard-button"
                                        onClick={() => setOpenConfig(true)}
                                    >
                                        {' '}
                                        <DashboardCustomizeIcon className="view-list-icon" />{' '}
                                    </button>
                                </AuthorisedUsage>
                            </div>

                            <AuthorisedUsage module={MODULE.CLIENTS} permission={PERMISSIONS.READ}>
                                <div className="listing-table">
                                    <div className="listing-table-scroll bg-white  border_table_all">
                                        <table>
                                            <thead className="previous-table-header">
                                                {table.getHeaderGroups().map((headerGroup) => (
                                                    <tr key={headerGroup.id}>
                                                        {headerGroup.headers.map((header) => (
                                                            <th key={header.id}>
                                                                {header.isPlaceholder
                                                                    ? null
                                                                    : flexRender(
                                                                          header.column.columnDef.header,
                                                                          header.getContext(),
                                                                      )}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody>
                                                {table.getRowModel().rows.map((row, index) => {
                                                    const userDetail: UserBase = UserBaseSerializer.parse(row.original);
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr
                                                                key={row.id}
                                                                {...{
                                                                    onClick: () => row.toggleExpanded(),
                                                                    style: { cursor: 'pointer' },
                                                                }}
                                                            >
                                                                {row.getVisibleCells().map((cell) => (
                                                                    <td
                                                                        key={cell.id}
                                                                        className={classNames({
                                                                            // "Archived": userDetail?.isActive && userDetail?.isArchived || !userDetail?.isActive && userDetail?.isArchived,
                                                                            // "Valid": userDetail?.isActive && !userDetail?.isArchived,
                                                                            // "paused": !userDetail?.isActive && !userDetail?.isArchived,
                                                                        })}
                                                                    >
                                                                        {flexRender(
                                                                            cell.column.columnDef.cell,
                                                                            cell.getContext(),
                                                                        )}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            {row.getIsExpanded() ? (
                                                                <tr>
                                                                    <ExpandedRow
                                                                        availableAccessManagers={
                                                                            availableAccessManagers
                                                                        }
                                                                        userDetail={userDetail}
                                                                        setExpanded={setExpanded}
                                                                        spanlength={
                                                                            columnPreference?.visibleColLength ?? 0
                                                                        }
                                                                    />
                                                                </tr>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}

                                                {isLoadingUsers && (
                                                    <tr>
                                                        <td colSpan={7} className="text-align-center">
                                                            Loading...
                                                        </td>{' '}
                                                    </tr>
                                                )}

                                                {table.getRowModel().rows.length === 0 && !isLoadingUsers && (
                                                    <tr>
                                                        {columnPreference && (
                                                            <td colSpan={numberOfHeads}>
                                                                <div className="no-data">No Data Found</div>
                                                            </td>
                                                        )}
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {clients?.meta && (
                                    <Pagination
                                        totalCount={clients?.meta?.total}
                                        currentPage={
                                            typeof clients?.meta?.page === 'string'
                                                ? parseInt(clients?.meta?.page)
                                                : clients?.meta?.page
                                        }
                                        pageSize={clients?.meta?.perPage}
                                        onPageChange={(page) => setFilters({ ...filters, page })}
                                    />
                                )}
                            </AuthorisedUsage>
                            {openConfig && (
                                <Configurator
                                    visibleColumn={columns}
                                    columnPreference={columnPreference}
                                    currentVisible={columnPreference?.columns?.filter((column) => column.isVisible)}
                                    currentInvisible={columnPreference?.columns?.filter((column) => !column.isVisible)}
                                    columnPreferenceUpdate={columnPreferenceUpdate} // update api hitting
                                    openConfigurator={openConfig}
                                    handleCloseConfigurator={() => setOpenConfig(false)}
                                />
                            )}
                        </>
                    ) : (
                        <span>Loading...</span>
                    )}

                    <BasicModal open={showPopUp} handleClose={handleClosePopup}>
                        <AlertPopUp
                            fn={(clientID) => deleteClient(clientID)}
                            handleCloseModal={handleClosePopup}
                            heading={`Are you sure?`}
                            subheading={`Do you want to delete this client? It cannot be undone`}
                            buttonText="Yes"
                            value={clientID}
                        />
                    </BasicModal>

                    <BasicModal open={isAddingClientModalOpen} handleClose={closeModal}>
                        <InviteUser
                            onModalClose={closeModal}
                            useSaveMutation={useAddNonBillableUserMutation}
                            modalHeader="Add a Non Billable Client"
                            modalButton="Add Client"
                        />
                    </BasicModal>
                </div>
            </SpinningComponent>
        </>
    );
};

export default ClientTableV2;
