/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 PRO React TS Base Styles
import typography from 'assets/theme/base/typography';
import { generateXAxisLabel, generateYAxisLabel } from 'utils/text-helpers';

function configs(labels: any, datasets: any, chartTitle: string = '') {
    return {
        data: {
            labels,
            datasets: [...datasets],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
            },
            interaction: {
                intersect: false,
                mode: 'index',
            },
            scales: {
                y: {
                    grid: {
                        drawBorder: false,
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                        borderDash: [5, 5],
                    },
                    ticks: {
                        display: true,
                        padding: 10,
                        color: '#b2b9bf',
                        font: {
                            size: 11,
                            family: typography.fontFamily,
                            style: 'normal',
                            lineHeight: 2,
                        },
                    },
                    title: {
                        display: false,
                        text: generateYAxisLabel(chartTitle), // Y-axis label
                        color: '#9ca2b7',
                        font: {
                            size: 14,
                            family: typography.fontFamily,
                            style: 'normal',
                            lineHeight: 2,
                        },
                    },
                },
                x: {
                    grid: {
                        drawBorder: false,
                        display: false,
                        drawOnChartArea: false,
                        drawTicks: false,
                        borderDash: [5, 5],
                    },
                    ticks: {
                        display: true,
                        color: '#b2b9bf',
                        padding: 20,
                        font: {
                            size: 11,
                            family: typography.fontFamily,
                            style: 'normal',
                            lineHeight: 2,
                        },
                    },
                    title: {
                        display: false,
                        text: generateXAxisLabel(chartTitle), // X-axis label
                        color: '#9ca2b7',
                        font: {
                            size: 14,
                            family: typography.fontFamily,
                            style: 'normal',
                            lineHeight: 2,
                        },
                    },
                },
            },
        },
    };
}

export default configs;
