/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Theme } from '@mui/material/styles';

export default function sidenavLogoLabel(theme: Theme, ownerState: any) {
    const { functions, transitions, typography, breakpoints } = theme;
    const { miniSidenav } = ownerState;

    const { pxToRem } = functions;
    const { fontWeightMedium } = typography;

    return {
        ml: 0.5,
        fontWeight: fontWeightMedium,
        wordSpacing: pxToRem(-1),
        transition: transitions.create('opacity', {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
        }),

        [breakpoints.up('xl')]: {
            opacity: miniSidenav ? 0 : 1,
        },
    };
}
