import { Autocomplete } from '@mui/material';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDInput from 'components/themed/MDInput';

import XeroDetails from 'components/xeroDetails';

import './styles.scss';
import useDebounce from 'hooks/useDebounce';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyGetXeroDetailsQuery } from 'redux/services/spotdif/advanceManagement';
import { useLazyGetAllUsersDetailsQuery } from 'redux/services/spotdif/clients';

const AdvanceManagement = () => {
    const [searchText, setSearchText] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const debouncedSearchText = useDebounce(searchText, 300); // Debounce the search text

    const [getAllUsersDetailsTrigger, { data: usersData }] = useLazyGetAllUsersDetailsQuery();
    const [getXeroDetails, { data: xeroDetailsData }] = useLazyGetXeroDetailsQuery();

    const userId = useMemo(() => searchParams.get('clientID'), [searchParams.get('clientID')]);

    const fromClientTable = useMemo(() => searchParams.get('fromClientTable'), [searchParams.get('fromClientTable')]);

    const handleOnChange = (event, newValue) => {
        setSearchText(newValue); // Update the search text
    };

    useEffect(() => {
        if (debouncedSearchText) {
            getAllUsersDetailsTrigger({ search: debouncedSearchText, onBoarding: '100' });
        }
    }, [debouncedSearchText, getAllUsersDetailsTrigger]);

    useEffect(() => {
        getAllUsersDetailsTrigger({ onBoarding: '100' });
        userId && getXeroDetails(userId);
        return () => {
            setSearchParams({});
        };
    }, []);

    const handleSelectOption = (event, value) => {
        console.log('selected value', value?._id);
        if (value) {
            setSearchParams({
                clientID: value?._id,
            });
            getXeroDetails(value?._id)
                .unwrap()
                .catch((err) => {
                    console.log(err);
                    enqueueSnackbar(err?.data?.message || 'Something went wrong', {
                        variant: 'error',
                        key: 'xeroDetails',
                    });
                });
        }
    };

    return (
        <div className="advance-management-wrapper">
            {fromClientTable ? (
                <MDBox className="xero-input-wrapper">
                    <MDInput
                        type="text"
                        label="User Id"
                        placeholder="User Id"
                        className="xero-input"
                        disabled
                        id="userId"
                        name="userId"
                        value={userId}
                    />
                    <MDButton
                        onClick={() => {
                            navigate('/admin/advance-management');
                        }}
                        type="button"
                        className="button generate-xero-button"
                    >
                        use search
                    </MDButton>
                </MDBox>
            ) : null}

            {!fromClientTable && (
                <Autocomplete
                    id="users"
                    data-name="Users"
                    className="dropdown-input"
                    onChange={(event, value) => {
                        handleSelectOption(event, value);
                    }}
                    value={usersData?.data?.find((option) => option._id === userId) || null}
                    options={usersData?.data || []}
                    getOptionLabel={(option) => `${option?.firstName} ${option?.lastName}` || ''}
                    renderInput={(params) => (
                        <MDInput
                            {...params}
                            name="users"
                            sx={{ padding: 0 }}
                            label="Users"
                            onChange={(event) => handleOnChange(event, event.target.value)}
                        />
                    )}
                    renderOption={(props, option) => <li {...props}>{`${option?.firstName} ${option?.lastName}`}</li>}
                />
            )}
            <XeroDetails xeroDetailsData={xeroDetailsData} userId={userId} />
        </div>
    );
};

export default AdvanceManagement;
