import { Switch } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const PrimarySwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#ff4e00 !important',
        borderColor: '#ff4e00 !important',
        '&:hover': {
            backgroundColor: alpha('#ff4e00 !important', theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#ff4e00 !important',
        borderColor: '#ff4e00 !important',
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
        // color: '#33cf4d',
        border: '0px solid #fff !important',
    },
    '& .MuiSwitch-thumb': {
        // width: 15,
        borderColor: '#fff !important',
        border: '0px solid #fff !important',
    },
    '& .MuiSwitch-switchBase.Mui-checked.MuiSwitch-thumb': {
        borderColor: '#fff !important',
    },
}));

export default PrimarySwitch;
