import { useApplicationUserContext } from 'context/ApplicationUser';
import { EUserRole } from 'contracts/app-utils/EUserRole';
import { useCallback } from 'react';
import { logout } from 'redux/slices/auth';
import { useAppDispatch } from 'redux/store';
import useUserRole from './useUserRole';

export default function useAuthentication() {
    const isAdmin = useUserRole(EUserRole.ADMIN);
    const isSuperAdmin = useUserRole(EUserRole.SUPER_ADMIN);
    const isUser = useUserRole(EUserRole.USER, EUserRole.INVITED);
    const isNonBillableUser = useUserRole(EUserRole.NON_BILLABLE);
    const isAccountManger = useUserRole(EUserRole.ACCOUNT_MANAGER);
    const isAccountAdmin = useUserRole(EUserRole.ACCOUNT_ADMIN);
    const appDispatch = useAppDispatch();

    const { user, isLoading: isLoadingUserDetails } = useApplicationUserContext();

    const logoutUser = useCallback(() => {
        appDispatch(logout());
    }, [appDispatch]);

    return {
        user,
        isLoadingUserDetails,
        isLoggedIn: !!user,
        isAdmin,
        isSuperAdmin,
        isUser,
        isNonBillableUser,
        isAccountManger,
        logoutUser,
        isAccountAdmin,
    };
}
