// ICONS
import SeoBusiness from 'assets/images/icons/packages/seo-business.png';
import SeoEnterprisePlus from 'assets/images/icons/packages/seo-enterprise-plus.png';
import SeoEnterprise from 'assets/images/icons/packages/seo-enterprise.png';
import SeoPremimum from 'assets/images/icons/packages/seo-premium.png';
import ListCard from 'components/shared/ListCard';
import MDBox from 'components/themed/MDBox';
import MDTypography from 'components/themed/MDTypography';
import React from 'react';

// API HOOKS
import { useGetPlanPackagesQuery } from 'redux/services/spotdif/packages';

// import constant
import { SEO_BUSINESS, SEO_ENTERPRISE, SEO_ENTERPRISE_PLUS, SEO_PREMIUM } from './CONSTANT';

const PlanPackages: React.FC = () => {
    const { data: allPackages, isLoading } = useGetPlanPackagesQuery();
    return (
        <>
            <MDBox className="layout-middle">
                <MDTypography variant="h5">Are you looking for more?</MDTypography>
                <MDTypography variant="p">
                    Supercharge your leads with our Google-boosting SEO packages. Rely on your expert lead-generating
                    agency to skyrocket your Google rankings and supercharge your business growth.
                </MDTypography>

                <MDBox className="list-card-parent" sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    {allPackages &&
                        allPackages.data.map((val, index) => {
                            let icon = '';
                            if (val.title === SEO_BUSINESS) {
                                icon = SeoBusiness;
                            } else if (val.title === SEO_PREMIUM) {
                                icon = SeoPremimum;
                            } else if (val.title === SEO_ENTERPRISE_PLUS) {
                                icon = SeoEnterprisePlus;
                            } else if (val.title === SEO_ENTERPRISE) {
                                icon = SeoEnterprise;
                            }
                            return (
                                <>
                                    <ListCard
                                        key={index}
                                        icon={icon}
                                        title={val.title}
                                        min={val.amountInPound}
                                        max={val.amountInEuro}
                                        desc={val.description}
                                        source={val.features}
                                        tagline={val.popular}
                                        selected={val.selected}
                                    />
                                </>
                            );
                        })}
                </MDBox>
            </MDBox>
        </>
    );
};

export default PlanPackages;
