import dayjs from 'dayjs';
import React, { createContext, useContext, useState } from 'react';

export type AccountMangerOptionType = {
    label: string;
    value: string;
};

export type StatsQuery = {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
    accountManagerIds: any; // @todo define here proper types
    // accountManagerIds: AccountMangerOptionType[],  // @todo define here proper types ---- this is giving error on auto complete which custom defined
};

interface IDashboardStatsContext {
    query: StatsQuery;
    setQuery: (val: StatsQuery) => void;
    data: any;
    setData: (val: any) => void;
}

export const DashboardStatsContext = createContext<IDashboardStatsContext>({
    query: {
        startDate: dayjs().startOf('month'),
        endDate: dayjs().endOf('month'),
        accountManagerIds: [],
    },
    setQuery: () => null,
    data: {},
    setData: () => null,
});

export const useDashboardStatsContext = () => {
    const context = useContext(DashboardStatsContext);

    if (!context) {
        console.error('User Onboarding Context can only be used within User Onboarding Context Provider');
        return null;
    }

    return context;
};

export const DashboardStatsContextProvider = ({ children }) => {
    const [query, setQuery] = useState<StatsQuery>({
        startDate: dayjs().startOf('month'),
        endDate: dayjs().endOf('month'),
        // startDate: new Date(),
        // endDate: new Date(),
        accountManagerIds: [],
    });

    const [data, setData] = useState();

    return (
        <DashboardStatsContext.Provider value={{ query, setQuery, data, setData }}>
            {children}
        </DashboardStatsContext.Provider>
    );
};
