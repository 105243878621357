import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export class OnboardingStepValidationInfo {
    @jsonMember(String)
    key: string;

    @jsonArrayMember(String)
    pendingFields: string[];

    @jsonArrayMember(String)
    dependencies: string[];
}
