import { UserBase } from 'contracts/spotdif/UserBase';
import dayjs from 'dayjs';
import React from 'react';

interface IUserDetailRendererProps {
    user: UserBase;
    detailPath: string;
}

const userDetailRenderer = ({ user, detailPath }: IUserDetailRendererProps): React.ReactNode | null => {
    const handledProps = ['business', 'leadA', 'userSignup', 'daily', 'phoneN', 'createdAt'];

    // TODO: Create a method to match businessDetailsId...
    if (!handledProps.some((prop) => detailPath.startsWith(prop))) return null;

    if (`${detailPath}`.startsWith('business')) {
        return detailPath === 'businessLogo' ? (
            user.getBusinessDetail(detailPath) ? (
                <img src={`${process.env.REACT_APP_API_SERVER}/${user.getBusinessDetail(detailPath)}`} alt="business" />
            ) : (
                '-'
            )
        ) : (
            <span>{user?.getBusinessDetail(detailPath) || '-'}</span>
        );
    }

    if (`${detailPath}`.startsWith('leadA')) {
        return <span>{user?.getUserLeadDetail(detailPath) || '-'}</span>;
    }

    if (`${detailPath}`.startsWith('userSignup')) {
        /* now onboarding coming from backend, as onBoardingPercentage, no need to userBase class function */

        // user?.onBoardingPercentage is from backend
        // user?.onboardingPercentage is from frontend

        // if (!user?.onBoardingPercentage) {
        //     return 0;
        // }

        /* When onBoarding Array is coming from backend  */
        if (user?.onBoarding?.length > 0) {
            return <span>{user?.onboardingPercentage ? `Onboarding ${user?.onboardingPercentage}%` : '-'}</span>;
        } else {
            /*  When onBoarding Array is coming EMPTY from backend   */
            return <span>{user?.onBoardingPercentage ? `Onboarding ${user?.onBoardingPercentage}%` : '-'}</span>;
        }
    }

    if (`${detailPath}`.startsWith('daily')) {
        return <span>{user?.getUserLeadDetail(detailPath) || '-'}</span>;
    }
    if (`${detailPath}`.startsWith('phoneN')) {
        const phoneNumber = user?.[detailPath];
        const formattedPhoneNumber = phoneNumber
            ? phoneNumber.startsWith('0')
                ? phoneNumber
                : `0${phoneNumber}`
            : '-';
        return <span>{formattedPhoneNumber}</span>;
    }

    if (`${detailPath}`.startsWith('createdAt')) {
        return <span>{dayjs(user?.createdAt).format('DD/MM/YYYY')} </span>;
    }
    return null;
};

export default userDetailRenderer;
