import ExpiredLinks from 'assets/images/icons/report-accepted.png';
import validLinks from 'assets/images/icons/valid-leads.png';
import classNames from 'classnames';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import HeaderCard from 'components/shared/Card/HeaderCard';
import SpinningComponent from 'components/shared/SpinningComponent';
import TableHeaderV2 from 'components/shared/TableHeaderV2';
import useToggle from 'hooks/useToggle';
import React from 'react';
import { useGetAllIndustiesLeadsQuery } from 'redux/services/spotdif/industriesLeads';
import {
    useGeneratePromoLinkMutation,
    useGetAccountManagerQuery,
    useGetPromoLinksQuery,
    useGetPromoLinksStatsQuery,
} from 'redux/services/spotdif/promoLink';
import PromoTable from './PromoTable';
import GeneratePromoLinkContentModal from './GeneratePromoLinkContentModal';

import './style.scss';

const PromoLink: React.FC = () => {
    const [status, setStatus] = React.useState('all');
    const [accountManager, setAccountManager] = React.useState(null);
    const [isGenerateLinkPopupOpen, toggleGenerateLinkPopup] = useToggle(false);
    const { data: accountManagerList } = useGetAccountManagerQuery();
    const [generateLink] = useGeneratePromoLinkMutation();
    const { data: promoLinks, isLoading: isLoadingPromoLinks } = useGetPromoLinksQuery({ status, accountManager });
    const { statsData } = useGetPromoLinksStatsQuery(null, {
        selectFromResult: ({ data }) => {
            return {
                statsData: data?.data,
            };
        },
    });
    const { data: businessIndustries } = useGetAllIndustiesLeadsQuery();

    const handleFilter = (e) => {
        setAccountManager(e.target.value);
    };
    const handleStatus = (event) => {
        setStatus(event.target.value);
    };

    return (
        <>
            <SpinningComponent loading={isLoadingPromoLinks}>
                <div className="layout-middle table-v2 promolink-v2">
                    <div className="table_header">
                        <div className="table_header-col d-flex">
                            <AuthorisedUsage module={MODULE.PROMO_LINKS} permission={PERMISSIONS.READ}>
                                <TableHeaderV2
                                    isPromoLink={true}
                                    hideSearchAndPerPage={true}
                                    handleStatus={handleStatus}
                                    accountManagerList={accountManagerList}
                                    handleFilter={handleFilter}
                                />
                            </AuthorisedUsage>
                        </div>
                    </div>

                    <div className="table-card-block">
                        <AuthorisedUsage module={MODULE.PROMO_LINKS} permission={PERMISSIONS.READ}>
                            <div className="promo w-50">
                                <HeaderCard
                                    icon={validLinks}
                                    value={statsData?.liveLinks}
                                    custClass="valid-leads"
                                    heading="Live"
                                />

                                <HeaderCard
                                    icon={ExpiredLinks}
                                    value={statsData?.expiredLinks}
                                    custClass="report-accepted"
                                    heading="Expired"
                                />
                            </div>
                        </AuthorisedUsage>

                        <AuthorisedUsage module={MODULE.PROMO_LINKS} permission={PERMISSIONS.CREATE}>
                            <button
                                onClick={toggleGenerateLinkPopup}
                                className={classNames('button', { table_export_leads_disabled: isLoadingPromoLinks })}
                            >
                                Generate link
                            </button>
                        </AuthorisedUsage>
                    </div>

                    <AuthorisedUsage module={MODULE.PROMO_LINKS} permission={PERMISSIONS.READ}>
                        <PromoTable
                            promoLinksData={promoLinks}
                            loading={isLoadingPromoLinks}
                            accountManagerList={accountManagerList?.data}
                            businessIndustries={businessIndustries?.data}
                        />
                    </AuthorisedUsage>
                </div>
            </SpinningComponent>

            {isGenerateLinkPopupOpen && (
                <GeneratePromoLinkContentModal
                    closePopup={toggleGenerateLinkPopup}
                    onSave={generateLink}
                    accountManagerList={accountManagerList?.data}
                    businessIndustries={businessIndustries?.data}
                />
            )}
        </>
    );
};

export default PromoLink;
