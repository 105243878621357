import { useMemo } from 'react';
import { ECurrency } from 'contracts/spotdif/currencyAndCountryCodes';

const usePostalCodeValidation = (currency) => {
    // ? UK please refer to this URL https://stackoverflow.com/questions/164979/regex-for-matching-uk-postcodes/17024047#17024047

    // ? older regex https://regex101.com/library/yN8eM1?orderBy=RELEVANCE&search=postal

    // ? US please refer to this URL https://regex101.com/library/yK0oW1?orderBy=RELEVANCE&search=postal

    const postCodeRegex = useMemo(() => {
        if (currency === ECurrency.USD) {
            return /(^[0-9]{4}?[0-9]$|^[0-9]{4}?[0-9]-[0-9]{4}$)/gm;
        } else return /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/gm;
    }, [currency]);

    return postCodeRegex;
};

export default usePostalCodeValidation;
