import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete } from '@mui/material';
import './style.scss';
import classNames from 'classnames';
import MDInput from 'components/themed/MDInput';
import { ECurrency } from 'contracts/spotdif/currencyAndCountryCodes';
import { isPostCodePair, LeadDetails } from 'contracts/spotdif/LeadDetails';
import { FieldArray, useFormikContext } from 'formik';
import { useCallback } from 'react';
import addIcon from '../../assets/images/basic-icons/addIcon.svg';

interface IRadiusBasedPostPickerValueProps {
    currencyCode: string;
}

const RadiusBasedPostCode: React.FC<IRadiusBasedPostPickerValueProps> = ({ currencyCode }) => {
    const { values, setFieldValue, handleBlur, touched, errors } = useFormikContext<Partial<LeadDetails>>();

    const postalError = useCallback(
        (ind) => {
            if (touched.postCodeList?.[ind]?.postcode || true) {
                const pair = errors.postCodeList?.[ind];
                if (isPostCodePair(pair)) {
                    return pair.postcode;
                } else {
                    return null; // TODO check the case where pair is not used.
                }
            } else {
                return null;
            }
        },
        [errors, touched],
    );

    // TODO: Create a type for available props in PostCodePair so that single function can be used.
    const milesError = useCallback(
        (ind) => {
            if (touched.postCodeList?.[ind]?.miles || true) {
                const pair = errors.postCodeList?.[ind];
                if (isPostCodePair(pair)) {
                    return pair.miles;
                } else {
                    return null; // TODO check the case where pair is not used.
                }
            } else {
                return null;
            }
        },
        [errors, touched],
    );
    const handleMilesChange = (value, index) => {
        const newPostCodeList = [...values.postCodeList];
        newPostCodeList[index].miles = value === null ? '' : value;
        setFieldValue('postCodeList', newPostCodeList);
    };

    return (
        <FieldArray
            name="postCodeList"
            render={(arrayHelpers) => (
                <>
                    {values?.postCodeList?.map((postCode, ind) => (
                        <div key={ind}>
                            <div className="lead-quantity-wrap">
                                <div className="full-width-field">
                                    <Autocomplete
                                        id={`miles-${ind}`}
                                        data-name="miles"
                                        className="spotdif-field w-50 dropdown-input"
                                        value={postCode?.miles}
                                        onChange={(e, value) => handleMilesChange(value, ind)}
                                        onBlur={handleBlur}
                                        options={['5', '10', '20', '40', '60', '80', '100']}
                                        renderInput={(params) => (
                                            <MDInput
                                                {...params}
                                                isBordered={true}
                                                name={`miles-${ind}`}
                                                sx={{ padding: 0 }}
                                                label={'I serve customers within (miles)'}
                                                placeholder="Select Miles"
                                                error={touched.postCodeList?.[ind]?.miles && Boolean(milesError(ind))}
                                                helperText={touched.postCodeList?.[ind]?.miles && milesError(ind)}
                                            />
                                        )}
                                    />
                                </div>

                                <span className="align-self">of</span>

                                <div className="full-width-field">
                                    <MDInput
                                        id={`postcode-${ind}`}
                                        data-name="postcode"
                                        isBordered={true}
                                        name={`postcode-${ind}`}
                                        value={postCode?.postcode}
                                        sx={{ padding: 0 }}
                                        onBlur={handleBlur}
                                        label={currencyCode === ECurrency.USD ? 'Enter a Zipcode' : 'Enter a Postcode'}
                                        placeholder={currencyCode === ECurrency.USD ? 'e.g 98104' : 'e.g SK139JX'}
                                        error={touched.postCodeList?.[ind]?.postcode && Boolean(postalError(ind))}
                                        helperText={touched.postCodeList?.[ind]?.postcode && postalError(ind)}
                                        onChange={(e) => {
                                            const newPostCodeList = [...values.postCodeList];
                                            newPostCodeList[ind].postcode = e.target.value;
                                            setFieldValue('postCodeList', newPostCodeList);
                                        }}
                                    />
                                </div>
                                <span
                                    className={classNames('delete-icon', {
                                        'delete-icon-disabled': values.postCodeList.length <= 1,
                                    })}
                                    onClick={() => values.postCodeList.length > 1 && arrayHelpers.remove(ind)}
                                >
                                    <DeleteIcon />
                                </span>
                            </div>
                        </div>
                    ))}

                    <div className="spotdif-field w-100">
                        <div
                            className="addTeamMembersBtn"
                            onClick={() => arrayHelpers.push({ postcode: '', miles: '' })}
                        >
                            {currencyCode === ECurrency.USD ? 'Add a Zipcode' : 'Add a Postcode'}
                            <img src={addIcon} className="addTeamMemberIcon" alt="add icon" />
                        </div>
                    </div>
                </>
            )}
        />
    );
};

export default RadiusBasedPostCode;
