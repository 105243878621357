import DeleteIcon from '@mui/icons-material/Delete';
import StarRateIcon from '@mui/icons-material/StarRate';
import { Autocomplete, Icon, InputAdornment, MenuItem, Select } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MDInput from 'components/themed/MDInput';
import { useApplicationUserContext } from 'context/ApplicationUser';
import { IBusinessDetailsRequest } from 'contracts/requests/IBusinessDetailsRequest';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { useGetCurrencyCodesQuery } from 'redux/services/spotdif/industriesLeads';
import addIcon from '../../assets/images/basic-icons/addIcon.svg';

const newAccredationObj = [
    'APHC',
    'ASSURE',
    'CERTAS',
    'CHAS Elite',
    'CIPHE',
    'EHPA',
    'Energy Saving Trust',
    'FENSA',
    'Gas Safe Register',
    'HIES',
    'MCS Cert',
    'NAPIT',
    'NICEIC',
    'REA',
    'RECC',
    'RIF',
    'SEUK',
    'TrustMark',
    'Which? Trusted Trader',
];

const ServiceDetails: React.FC = () => {
    const {
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
    } = useFormikContext<IBusinessDetailsRequest>();

    const { canSubmitDetailsFor } = useApplicationUserContext();
    const { data: currencies } = useGetCurrencyCodesQuery();

    return (
        <div className="leadSettingsHrsWrapper">
            <h6>Service Details</h6>
            <p className="lead-area-text p-1">
                All details provided below will be displayed to your leads upon submission of the form to provide more
                information on your business and the service you offer.
            </p>

            <div className="tab-user-content-card">
                <div className="form-middle notification-setting-wrapper">
                    <div className="full-width-field">
                        {canSubmitDetailsFor('user_services.welcome_message') && (
                            <MDInput
                                type="text"

                                isBordered={true}
                                multiline
                                rows={2}

                                id="welcomeMessage"
                                name="welcomeMessage"
                                onChange={handleChange}
                                onBlur={handleBlur}

                                placeholder="Please wait, You will be connected to our representative shortly."
                                label="Welcome Text"


                                value={values.welcomeMessage}
                                error={touched.welcomeMessage && Boolean(errors.welcomeMessage)}
                                helperText={touched.welcomeMessage && errors.welcomeMessage}

                            />
                        )}
                    </div>

                    <div className="form-col-2">
                        {canSubmitDetailsFor('user_services.average_install_time') && (
                            <div className="d-flex w-50 serviceDetailsWrapper">
                                <Autocomplete
                                    id="avgInstallTime"
                                    className="spotdif-field w-50 dropdown-input"
                                    value={values.avgInstallTime}
                                    onChange={(e, value) => setFieldValue('avgInstallTime', value)}
                                    onBlur={handleBlur}
                                    options={['1-2 weeks', '2-4 weeks', '6-8 weeks', '10 weeks+']}
                                    isOptionEqualToValue={(option, val) => option === val}

                                    renderInput={(params) => (
                                        <MDInput
                                            {...params}
                                            isBordered={true}
                                            name="avgInstallTime"
                                            value={values.avgInstallTime}
                                            sx={{ padding: 0 }}
                                            label={'Average install time'}
                                            placeholder="Average install time"
                                        />
                                    )}
                                />
                                <MDInput
                                    id="avgInstallTimeText"
                                    className="spotdif-field serviceDetailsTextInput"
                                    isBordered={true}
                                    name="avgInstallTimeText"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.avgInstallTimeText}
                                    sx={{ padding: 0 }}
                                />
                            </div>
                        )}
                        {canSubmitDetailsFor('user_services.finance_offers') && (
                            <div className="d-flex w-50 serviceDetailsWrapper">
                                <Autocomplete
                                    id="finance"
                                    data-name="Offer Finance"
                                    className="spotdif-field w-50 dropdown-input"
                                    value={values.financeOffers}
                                    onChange={(e, value) => setFieldValue('financeOffers', value)}
                                    isOptionEqualToValue={(option, val) => option === val}
                                    onBlur={handleBlur}
                                    options={['Yes', 'No']}
                                    renderInput={(params) => (
                                        <MDInput
                                            {...params}
                                            isBordered={true}
                                            name="financeOffers"
                                            value={values.financeOffers}
                                            sx={{ padding: 0 }}
                                            label={'Do you offer finance?'}
                                            placeholder="Do you offer finance"
                                        />
                                    )}
                                />
                                <MDInput
                                    id="financeOffers.text"
                                    className="spotdif-field serviceDetailsTextInput"
                                    isBordered={true}
                                    name="financeOffersText"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.financeOffersText}
                                    sx={{ padding: 0 }}
                                />
                            </div>
                        )}

                        {canSubmitDetailsFor('user_services.warranty') && (
                            <div className="d-flex w-50 serviceDetailsWrapper">
                                <MDInput
                                    id="warranty"
                                    className="spotdif-field"
                                    isBordered={true}
                                    name="warranty"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.warranty}
                                    sx={{ padding: 0 }}
                                    label={'Warranty Length'}
                                    placeholder="25 years"
                                />
                                <MDInput
                                    id="warrantyText"
                                    className="spotdif-field serviceDetailsTextInput"
                                    isBordered={true}
                                    name="warrantyText"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.warrantyText}
                                    sx={{ padding: 0 }}
                                />
                            </div>
                        )}

                        {canSubmitDetailsFor('user_services.starting_price') && (
                            <div className="d-flex w-50 serviceDetailsWrapper">
                                <MDInput
                                    type="text"
                                    name="startingPrice"
                                    id="startingPrice"
                                    value={values.startingPrice}
                                    sx={{ padding: 0 }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={'Prices From'}
                                    placeholder="£6995"
                                    data-name="Prices From"
                                    pr={2}
                                    inputProps={{ maxLength: 6 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                position="start"
                                                className="input-adornment currency-input-adornment"
                                            >
                                                <Select
                                                    value={values.startingPriceCurrencyCode}
                                                    onChange={(e) =>
                                                        setFieldValue('startingPriceCurrencyCode', e.target.value)
                                                    }
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Select Currency' }}
                                                    className="input-adornment-select-currency"
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        },
                                                    }}
                                                >
                                                    {currencies?.data?.map((currencyCode) => (
                                                        <MenuItem
                                                            className="input-adornment-select-currency-menu-item"
                                                            key={currencyCode.value}
                                                            value={currencyCode.value}
                                                        >
                                                            <ListItemIcon
                                                                className="input-adornment-select-currency-menu-item-icon">
                                                                {currencyCode.label.charAt(0)}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                className="input-adornment-select-currency-menu-item-text">
                                                                {currencyCode.value}
                                                            </ListItemText>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <MDInput
                                    id="startingPriceText"
                                    className="spotdif-field serviceDetailsTextInput"
                                    isBordered={true}
                                    name="startingPriceText"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.startingPriceText}
                                    sx={{ padding: 0 }}
                                />
                            </div>
                        )}

                        {canSubmitDetailsFor('user_services.prices') && (
                            <Autocomplete
                                id="pricing-range"
                                data-name="Pricing Range"
                                className="spotdif-field w-50 dropdown-input"
                                value={values.prices}
                                onChange={(e, value) => setFieldValue('prices', value)}
                                onBlur={handleBlur}
                                options={['£', '££', '£££']}
                                renderInput={(params) => (
                                    <MDInput
                                        {...params}
                                        isBordered={true}
                                        name="prices"
                                        value={values.prices}
                                        sx={{ padding: 0 }}
                                        label={'Where do your prices sit within the market?'}
                                        placeholder="Where do your prices sit within the market"
                                    />
                                )}
                            />
                        )}

                        {canSubmitDetailsFor('user_services.accreditations') && (
                            <FieldArray
                                name="accreditations"
                                render={(arrayHelpers) => (
                                    <>
                                        {values.accreditations.map((accredation, ind) => (
                                            <div key={ind} className="w-50">
                                                <div className="field-with-delete">
                                                    <Autocomplete
                                                        id="accredtitations"
                                                        data-name="Business Accredtitations"
                                                        className="dropdown-input"
                                                        value={
                                                            accredation === '' ? 'Select an accredation' : accredation
                                                        }
                                                        onChange={(e, value) =>
                                                            setFieldValue(`accreditations.${ind}`, value)
                                                        }
                                                        getOptionDisabled={(option) => {
                                                            return (
                                                                values.accreditations.includes(option) &&
                                                                accredation !== option
                                                            );
                                                        }}
                                                        onBlur={handleBlur}
                                                        options={newAccredationObj}
                                                        renderInput={(params) => (
                                                            <MDInput
                                                                {...params}
                                                                isBordered={true}
                                                                name={`accreditations.${ind}`}
                                                                value={
                                                                    accredation === ''
                                                                        ? 'Select an accreditation'
                                                                        : accredation
                                                                }
                                                                sx={{ padding: 0 }}
                                                                label={'Accreditations'}
                                                            />
                                                        )}
                                                    />

                                                    <span
                                                        className="delete-icon field-with-delete-icon"
                                                        onClick={() => arrayHelpers.remove(ind)}
                                                    >
                                                        <DeleteIcon />
                                                    </span>
                                                </div>
                                            </div>
                                        ))}

                                        <div className="spotdif-field w-100 d-flex-end">
                                            <div
                                                className="addTeamMembersBtn"
                                                onClick={() =>
                                                    values.accreditations.length < newAccredationObj.length &&
                                                    arrayHelpers.push('')
                                                }
                                            >
                                                Add an accreditation
                                                <img src={addIcon} className="addTeamMemberIcon" alt="add icon" />
                                            </div>
                                        </div>
                                    </>
                                )}
                            />
                        )}

                        {canSubmitDetailsFor('user_services.trust_pilot_reviews') && (
                            <MDInput
                                type="text"
                                placeholder="www."
                                label="Trustpilot reviews"
                                onBlur={handleBlur}
                                id="trustpilotReviews"
                                name="trustpilotReviews"
                                value={values.trustpilotReviews}
                                autoComplete="trustpilotReviews"
                                isBordered="true"
                                onChange={handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" className="">
                                            <span>
                                                <Icon fontSize="medium">
                                                    <StarRateIcon style={{ color: '#00B67A' }} />
                                                </Icon>
                                            </span>
                                            <span className="input-adornment-text">Trustpilot</span>{' '}
                                            <span className="input-adornment-symbol">|</span>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceDetails;
