import React from 'react';
import { Grid } from '@mui/material';
import CreditCard from 'components/creditCard';
import PaymentMethod from 'components/paymentMethod';
import MDButton from 'components/themed/MDButton';
import { IMakeDefaultCardResponse } from 'contracts/requests/IMakeDefaultCardResponse';
import { UserCard } from 'contracts/spotdif/BaseCardData';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';

interface IPaymentCardSectionProps {
    userCards: UserCard[];
    isLoadingUserCardDetails: boolean;
    userCardDetails: IMakeDefaultCardResponse;
    handleopenModal: () => void;
}

const PaymentCardSection: React.FC<IPaymentCardSectionProps> = ({
    userCards,
    isLoadingUserCardDetails,
    userCardDetails,
    handleopenModal,
}) => {
    return (
        <div className="user-card-section">
            <h6>Your payment cards</h6>

            {userCards
                ?.sort(() => {
                    return 0;
                })
                .map((card) => {
                    if (card.isDefault && card.status === 'success') {
                        return <CreditCard key={card._id} card={card} isLoading={isLoadingUserCardDetails} />;
                    } else {
                        return (
                            <Grid item xs={12} key={card._id}>
                                <PaymentMethod
                                    creditCardData={card}
                                    isLoading={isLoadingUserCardDetails}
                                    cardData={userCardDetails?.data}
                                />
                            </Grid>
                        );
                    }
                })}

            <MDButton onClick={() => handleopenModal()} className="button payment-ghost-button">
                Add a new payment method <AddCircleIcon />
            </MDButton>
        </div>
    );
};

export default PaymentCardSection;
