import classNames from 'classnames';
import React from 'react';

export enum BadgeNature {
    inverted = 'inverted',
    normal = 'normal',
}

interface IBadgeProps {
    className?: string;
    badgeType: BadgeNature;
    value: React.ReactNode;
}

const BadgeV2: React.FC<IBadgeProps> = ({ className, badgeType, value }) => {
    return (
        <>
            {badgeType === BadgeNature.normal ? (
                <span className={classNames('badge-v2', `${className}`)}>{value}</span>
            ) : badgeType === BadgeNature.inverted ? (
                <span className={classNames('badge', `${className}`)}>{value}</span>
            ) : (
                value
            )}
        </>
    );
};

export default BadgeV2;
