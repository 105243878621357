import React, { useEffect } from 'react';
import './style.scss';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'react-router-dom';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import { Switch, Tooltip } from '@mui/material';
import MDInput from 'components/themed/MDInput';
import { ISiteConfigsRequest, PaymentConfigCardObject } from 'contracts/requests/ISiteConfigsRequest';
import { useSnackbar } from 'notistack';
import { useUpdateSiteConfigsMutation } from 'redux/services/spotdif/siteConfigs';
import * as Yup from 'yup';
import { onlyNumbersRegExp } from 'utils/text-helpers';
import { useUserHasPermission } from 'components/app-permission/useUserHasPermission';

export interface paymentConfigCardProps {
    title: string;
    tag: 'free-credits' | 'first-card-bonus';
    data: PaymentConfigCardObject;
}

const PaymentConfigCard: React.FC<paymentConfigCardProps> = ({ title, tag, data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [updateSiteConfigsDetailsTrigger] = useUpdateSiteConfigsMutation();
    const hasConfigPermission = useUserHasPermission(MODULE.FREE_CREDITS_CONFIGS, PERMISSIONS.UPDATE);

    const updateSiteConfigsDetails = async (data: ISiteConfigsRequest) => {
        try {
            const res = await updateSiteConfigsDetailsTrigger(data).unwrap();
            enqueueSnackbar(
                data.tags === 'firstCardBonus'
                    ? 'First card bonus updated successfully.'
                    : data.tags === 'promoLinks'
                    ? 'Promo links bonus updated successfully.'
                    : 'Site configs updated successfully.',
                { variant: 'success', key: 'site-config-update' },
            );
        } catch (err) {
            enqueueSnackbar(err?.message || 'Something Went Wrong', { variant: 'error', key: 'site-config-update' });
        }
    };

    const formik = useFormik({
        initialValues: {
            enabled: data?.enabled || false,
            amount: data?.amount || 0,
        },
        validationSchema: Yup.object().shape({
            amount: Yup.string().when('enabled', {
                is: true,
                then: (schema) =>
                    schema
                        .matches(onlyNumbersRegExp, 'amount not valid')
                        .max(4, 'amount should not be more than 9999')
                        .required('amount is required'),
            }),
        }),
        onSubmit: async (values) => {
            updateSiteConfigsDetails({
                ...formik.values,
                tags: tag === 'first-card-bonus' ? 'firstCardBonus' : 'promoLinks',
            });
        },
    });

    useEffect(() => {
        if (data) {
            formik.setValues({
                enabled: data?.enabled || false,
                amount: data?.amount || 0,
            });
        }
    }, [data]);

    return (
        <AuthorisedUsage module={MODULE.FREE_CREDITS_CONFIGS} permission={PERMISSIONS.READ}>
            <div className="site-configs-settings-content">
                <h6 className="site-config-content-card-header">{title}</h6>
                {formik && (
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <div className="site-configs-settings-card payment-card">
                                <div className="full-width-field">
                                    <div className="d-flex">
                                        <Tooltip title={formik?.values?.enabled ? 'Active' : 'InActive'}>
                                            <Switch
                                                disabled={!hasConfigPermission}
                                                checked={formik?.values?.enabled}
                                                onChange={() => {
                                                    formik.setFieldValue('enabled', !formik?.values?.enabled);
                                                }}
                                            />
                                        </Tooltip>

                                        <p className="para-graph-color">Enabled.</p>
                                    </div>

                                    <MDInput
                                        type="text"
                                        id="amount"
                                        name="amount"
                                        placeholder="Enter amount"
                                        label={tag === 'first-card-bonus' ? 'Free Leads' : 'Free Credits'}
                                        value={formik.values?.amount}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={formik.touched?.amount && Boolean(formik.errors?.amount)}
                                        helperText={formik.touched?.amount && formik.errors?.amount}
                                        disabled={!hasConfigPermission || !formik?.values?.enabled}
                                    />
                                </div>
                                {tag === 'first-card-bonus' && (
                                    <div className="free-leads-text">
                                        {`Total of ${
                                            formik.values?.amount || '0'
                                        } x CPL credit will be added into user account when they add their first card.`}
                                    </div>
                                )}
                                <AuthorisedUsage module={MODULE.FREE_CREDITS_CONFIGS} permission={PERMISSIONS.UPDATE}>
                                    <div className="d-flex save-button-justify-end">
                                        <button type="submit" className="button">
                                            Save
                                        </button>
                                    </div>
                                </AuthorisedUsage>
                            </div>
                        </Form>
                    </FormikProvider>
                )}
            </div>
        </AuthorisedUsage>
    );
};

export default PaymentConfigCard;
