import { ILeadStat } from 'contracts/view-models/ILeadStat';
import React, { useMemo } from 'react';
import { useGetTopDashboardCharDataQuery } from 'redux/services/spotdif/stats';
import MDBox from '../themed/MDBox';
import LeadStat from './LeadStat';

const UserStats: React.FC = () => {
    const { data: dashboardData } = useGetTopDashboardCharDataQuery();

    const stats = useMemo<ILeadStat[]>(() => {
        return [
            {
                title: "Today's Leads",
                stat: dashboardData?.data?.todayData ?? 0,
                description: ` ${dashboardData?.data?.todayPercentage >= 0 ? 'Up' : 'Down'} ${
                    dashboardData?.data?.todayPercentage ?? 0
                }% from Yesterday`,
            },
            {
                title: 'Weekly Leads',
                stat: dashboardData?.data?.lastWeekData ?? 0,
                description: `${dashboardData?.data?.pastWeekPercentage >= 0 ? 'Up' : 'Down'} ${
                    dashboardData?.data?.pastWeekPercentage ?? 0
                }% from Last Week`,
            },
            {
                title: 'Monthly Leads',
                stat: dashboardData?.data?.monthlyData ?? 0,
                description: `${dashboardData?.data?.monthlyPercentage >= 0 ? 'Up' : 'Down'} ${
                    dashboardData?.data?.monthlyPercentage ?? 0
                }% from Last Month`,
            },
            {
                title: 'Quarterly Leads',
                stat: dashboardData?.data?.quarterlyData ?? 0,
                description: `${dashboardData?.data?.quarterlyPercentage >= 0 ? 'Up' : 'Down'} ${
                    dashboardData?.data?.quarterlyPercentage ?? 0
                }% from Last Quarter`,
            },
        ];
    }, [dashboardData]);

    return (
        <MDBox className="user-lead-stats--conatiner">
            {stats.map((stat, index) => (
                <LeadStat lead={stat} key={index} />
            ))}
        </MDBox>
    );
};

export default UserStats;
