import { IAddCardRequest } from 'contracts/requests/IAddCardRequest';
import { IApiErrorResponse } from 'contracts/requests/IApiErrorResponse';
import { IBusinessDetailsRequest } from 'contracts/requests/IBusinessDetailsRequest';
import { ICreateBusinessDetailsResponse } from 'contracts/requests/ICreateBusinessDetailsRequest';
import { ICreateSessionRequest, ICreateSessionResponse } from 'contracts/requests/ICreateSessionRequest';

import { IGetPostCodesResponse } from 'contracts/requests/IGetPostCodes';
import { IInviteUserRequest } from 'contracts/requests/IInviteUserRequest';
import { ILeadSettingsRequest } from 'contracts/requests/ILeadSettingsRequest';
import { ILeadSettingsResponse } from 'contracts/requests/ILeadSettingsResponse';
import { ILoggedInUserResponse, IUserIdQuery } from 'contracts/requests/ILoggedInUserResponse';
import { IMessageOnlyResponse } from 'contracts/requests/IMessageOnlyResponse';
import {
    INotificationRequest,
    INotificationResponse,
    INotificationTestLeadResponse,
} from 'contracts/requests/INotificationRequest';
import { IPostCodesMapIrelandResponse } from 'contracts/requests/IPostCodesMapIrelandResponse';
import { IPostCodesMapUKResponse } from 'contracts/requests/IPostCodesMapUKResponse';
import { IRadiusBasedCodesRequest } from 'contracts/requests/IRadiusBasedCodesRequest';
import { IRadiusBasedCodesResponse } from 'contracts/requests/IRadiusBasedCodesResponse';
import { IResetPasswordRequest } from 'contracts/requests/IResetPasswordRequest';
import { IRetrievePaymentDetailsResponse } from 'contracts/requests/IRetrievePaymentDetailsResponse';
import { GeometryDataMain } from 'contracts/spotdif/GeometryDataMain';
import {
    TAG_GET_USER_CARD,
    TAG_GET_USER_PROFILE,
    TAG_INVITE_USER,
    TAG_POST_CODE_MAP,
    TAG_POST_CODE_MAP_ALL,
    TAG_POST_CODE_MAP_IR,
    TAG_POST_CODE_MAP_UK,
    TAG_SUBSCRIBER_USER,
    TAG_USER_CARD,
    TAG_USER_PROFILE,
} from 'contracts/spotDiffAPITags';
import { dayToTime } from 'utils/dateHelpers';
import { spotdifApi } from '.';
import { IBusinessIndustryResponse } from '../../../contracts/requests/IBusinessIndustryResponse';

const onBoardingApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getUKPostCode: builder.query<GeometryDataMain, void>({
            providesTags: (result, error) => {
                return [
                    { type: TAG_POST_CODE_MAP, id: TAG_POST_CODE_MAP_UK },
                    { type: TAG_POST_CODE_MAP, id: TAG_POST_CODE_MAP_ALL },
                ];
            },
            query: () => {
                return {
                    url: `/auth/map`,
                    method: 'get',
                };
            },
            transformResponse: (response: IPostCodesMapUKResponse) => {
                return response.data;
            },
        }),

        getIrelandPostCode: builder.query<GeometryDataMain, void>({
            providesTags: (result, error) => {
                return [
                    { type: TAG_POST_CODE_MAP, id: TAG_POST_CODE_MAP_IR },
                    { type: TAG_POST_CODE_MAP, id: TAG_POST_CODE_MAP_ALL },
                ];
            },
            query: () => {
                return {
                    url: `/auth/map/ireland`,
                    method: 'get',
                };
            },
            transformResponse: (response: IPostCodesMapIrelandResponse) => {
                return response.data;
            },
        }),

        resetPassword: builder.mutation<IResetPasswordRequest, IMessageOnlyResponse>({
            query: (body) => {
                return {
                    url: `/profile/change-password`,
                    method: 'post',
                    body,
                };
            },
        }),

        // bussinessProfile
        postBussinessProfileDetails: builder.mutation<ICreateBusinessDetailsResponse, IBusinessDetailsRequest>({
            invalidatesTags: [
                {
                    type: TAG_USER_PROFILE,
                    id: TAG_GET_USER_PROFILE,
                },
            ],
            query: (body) => {
                return {
                    url: `/auth/business`,
                    method: 'post',
                    body,
                };
            },
        }),

        getBusinessIndustry: builder.query<IBusinessIndustryResponse, void>({
            query: () => {
                return {
                    url: `businessIndustry/industry`,
                    method: 'get',
                };
            },
        }),

        updateBussinessProfileDetails: builder.mutation<ICreateBusinessDetailsResponse, FormData>({
            invalidatesTags: [
                {
                    type: TAG_USER_PROFILE,
                    id: TAG_GET_USER_PROFILE,
                },
            ],
            query: (body) => {
                return {
                    url: `/auth/business/${body.get('businessId')}`,
                    method: 'post',
                    body,
                };
            },
        }),

        // updateBussinessProfileDetails: builder.mutation<ICreateBusinessDetailsResponse, IBussinessDetailsRequest>({
        //     invalidatesTags: [
        //         {
        //             type: TAG_USER_PROFILE,
        //             id: TAG_GET_USER_PROFILE,
        //         },
        //     ],
        //     query: (body) => {
        //         return {
        //             url: `/businessIndustry/${body.userId}`,
        //             method: "post", //TODO: change to patch
        //             body,
        //         };
        //     },
        // }),

        // leadSettings
        postLeadSettingDetails: builder.mutation<ILeadSettingsResponse, ILeadSettingsRequest>({
            invalidatesTags: [
                {
                    type: TAG_USER_PROFILE,
                    id: TAG_GET_USER_PROFILE,
                },
            ],
            query: (body) => {
                return {
                    url: `/userLeadsDetails`,
                    method: 'post',
                    body: {
                        ...body,
                        leadSchedule: body.leadSchedule.map((schedule) => {
                            return {
                                day: schedule.day,
                                isActive: true,
                                openTime: dayToTime(schedule.openTime),
                                closeTime: dayToTime(schedule.closeTime),
                            };
                        }),
                    },
                };
            },
        }),

        updateLeadSettingDetails: builder.mutation<ILeadSettingsResponse, ILeadSettingsRequest>({
            invalidatesTags: [
                {
                    type: TAG_USER_PROFILE,
                    id: TAG_GET_USER_PROFILE,
                },
            ],
            query: (body) => {
                return {
                    url: `/userLeadsDetails/${body._id}`,
                    method: 'post', // TODO: change to patch
                    body: {
                        ...body,
                        leadSchedule: body.leadSchedule.map((schedule) => {
                            return {
                                day: schedule.day,
                                isActive: true,
                                openTime: dayToTime(schedule.openTime),
                                closeTime: dayToTime(schedule.closeTime),
                            };
                        }),
                    },
                };
            },
        }),

        // Payment
        createSesion: builder.mutation<ICreateSessionResponse, ICreateSessionRequest>({
            query: (body) => {
                return {
                    url: `cardDetails/create-session`,
                    method: 'post',
                    body,
                };
            },
        }),

        saveCreditCard: builder.mutation<any | IApiErrorResponse, IAddCardRequest>({
            invalidatesTags: [
                {
                    type: TAG_USER_PROFILE,
                    id: TAG_GET_USER_PROFILE,
                },

                {
                    type: TAG_USER_CARD,
                    id: TAG_GET_USER_CARD,
                },
            ],
            query: (body) => {
                return {
                    url: `cardDetails/addCard`,
                    method: 'post',
                    body,
                };
            },
        }),

        getRetriveSession: builder.query<IRetrievePaymentDetailsResponse, string>({
            query: (queryArgs) => {
                return {
                    url: `cardDetails/session?ps=${queryArgs}&requestJson=true`,
                    method: 'get',
                };
            },

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedData } = await queryFulfilled;
                    dispatch(
                        spotdifApi.util.invalidateTags([
                            {
                                type: TAG_USER_PROFILE,
                                id: TAG_GET_USER_PROFILE,
                            },

                            {
                                type: TAG_USER_CARD,
                                id: TAG_GET_USER_CARD,
                            },
                        ]),
                    );
                } catch {
                }
            },
        }),

        getLoggedUser: builder.query<ILoggedInUserResponse, IUserIdQuery>({
            query: (args) => {
                return {
                    url: `user/${args.id}`,
                    method: 'get',
                };
            },
        }),

        // FIXME : Can Be Reused
        postNotificationData: builder.mutation<INotificationResponse, INotificationRequest>({
            invalidatesTags: [
                {
                    type: TAG_USER_PROFILE,
                    id: TAG_GET_USER_PROFILE,
                },
            ],
            query: (body: INotificationRequest) => {
                const { _id, ...rest } = body;
                return {
                    url: `/userLeadsDetails/${_id}`,
                    method: 'post', // TODO: change to patch
                    body: rest,
                };
            },
        }),

        sendTestLead: builder.mutation<INotificationTestLeadResponse, INotificationRequest>({
            query: (body: INotificationRequest) => {
                const { _id, ...rest } = body;
                return {
                    url: `user/test-lead/${_id}`,
                    method: 'post',
                    body: rest,
                };
            },
        }),

        // FIXME : Can Be Reused
        createSmsAlert: builder.mutation<INotificationResponse, INotificationRequest>({
            invalidatesTags: [
                {
                    type: TAG_USER_PROFILE,
                    id: TAG_GET_USER_PROFILE,
                },
            ],
            query: (body: INotificationRequest) => {
                const { _id, ...rest } = body;
                return {
                    url: `/user/${_id}`,
                    method: 'post',
                    body: rest,
                };
            },
        }),

        inviteUser: builder.mutation<IMessageOnlyResponse, IInviteUserRequest>({
            invalidatesTags: [TAG_INVITE_USER],
            query: (body) => {
                return {
                    url: `/invitedUsers`,
                    method: 'post',
                    body,
                };
            },
        }),

        getInvitedUser: builder.query<any, void>({
            // have to make interface for this any not making due to  .....
            providesTags: [TAG_INVITE_USER],

            query: () => {
                return {
                    url: `/invitedUsers`,
                    method: 'get',
                };
            },
            transformResponse: (response: any) => {
                return response.data;
            },
        }),

        deleteInvitedUser: builder.mutation<void, string>({
            invalidatesTags: [TAG_INVITE_USER],

            query: (id) => ({
                url: `invitedUsers/${id}`,
                method: 'DELETE',
            }),
        }),

        invitedSubscriber: builder.mutation<IMessageOnlyResponse, IInviteUserRequest>({
            invalidatesTags: [TAG_SUBSCRIBER_USER],
            query: (body) => {
                return {
                    url: `/invitedUsers/subscriber`,
                    method: 'post',
                    body,
                };
            },
        }),

        getInvitedSubscriber: builder.query<any, void>({
            // have to make interface for this any not making due to  .....
            providesTags: [TAG_SUBSCRIBER_USER],

            query: () => {
                return {
                    url: `/invitedUsers/subscriber`,
                    method: 'get',
                };
            },
            transformResponse: (response: any) => {
                return response.data;
            },
        }),

        deleteInvitedSubscriber: builder.mutation<void, string>({
            invalidatesTags: [TAG_SUBSCRIBER_USER],

            query: (id) => ({
                url: `invitedUsers/subscriber/${id}`,
                method: 'DELETE',
            }),
        }),

        getPostCodes: builder.query<IGetPostCodesResponse, void>({
            query: () => {
                return {
                    url: `get-postCodes/all`,
                    method: 'get',
                };
            },
        }),

        getRadiusBasedCodes: builder.query<IRadiusBasedCodesResponse, IRadiusBasedCodesRequest>({
            query: (args) => {
                return {
                    url: `get-postCodes?postCode=${args.postcode}&radius=${args.miles}`,
                    method: 'get',
                };
            },
        }),
    }),
});

export const {
    useGetUKPostCodeQuery,
    useGetIrelandPostCodeQuery,
    useCreateSesionMutation,
    useResetPasswordMutation,
    usePostBussinessProfileDetailsMutation,
    useGetBusinessIndustryQuery,
    usePostLeadSettingDetailsMutation,
    useUpdateLeadSettingDetailsMutation,
    useUpdateBussinessProfileDetailsMutation,
    useGetLoggedUserQuery,
    usePostNotificationDataMutation,
    useCreateSmsAlertMutation,
    useSaveCreditCardMutation,
    useGetRetriveSessionQuery,
    useLazyGetRetriveSessionQuery,

    useInviteUserMutation,
    useInvitedSubscriberMutation,
    useGetInvitedUserQuery,
    useGetInvitedSubscriberQuery,
    useDeleteInvitedUserMutation,
    useDeleteInvitedSubscriberMutation,
    useSendTestLeadMutation,
    useLazyGetRadiusBasedCodesQuery,
    useGetPostCodesQuery,
} = onBoardingApi;

export default onBoardingApi;
