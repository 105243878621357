import { createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { ChatRoom, ChatRoomSerializer } from 'contracts/chat/ChatRoom';
import { MessageThread, MessageThreadSerializer } from 'contracts/chat/MessageThread';
import { isChatTokenResponse } from 'contracts/requests/IChatTokenResponse';

import { RootState } from 'redux/reducers';
import { chatRoomsApi } from 'redux/services/chat/chatRooms';
import { messageThreadApi } from 'redux/services/chat/messageThread';
import { chatTokenApi } from 'redux/services/spotdif/chatToken';

export const CHAT_AUTH_TOKEN = 'auth_token_chat';

const persistedChatToken = sessionStorage.getItem(CHAT_AUTH_TOKEN) || localStorage.getItem(CHAT_AUTH_TOKEN);

export const roomsAdapter = createEntityAdapter<ChatRoom>({
    selectId: (room) => room._id,
    sortComparer: (a, b) => {
        return ChatRoomSerializer.parse(b).lastUpdatedAt.diff(ChatRoomSerializer.parse(a).lastUpdatedAt);
    },
});

export const messageThreadAdapter = createEntityAdapter<MessageThread>({
    selectId: (thread) => thread?._id,
    sortComparer: (a, b) => {
        return MessageThreadSerializer.parse(a).createdAt.diff(MessageThreadSerializer.parse(b).createdAt);
    },
});

interface IChatRoomFilterConfig {
    query: string;
    // MAYBE: add options for status and unread later on.
}

interface ChatSlice {
    chatToken: string;
    chatRoomFilters: IChatRoomFilterConfig;
    rooms: EntityState<ChatRoom>;
    messages: EntityState<MessageThread>;
}

let initialState: ChatSlice = {
    chatToken: persistedChatToken ? JSON.parse(persistedChatToken).token : '',
    chatRoomFilters: {
        query: '',
    },
    rooms: roomsAdapter.getInitialState(),
    messages: messageThreadAdapter.getInitialState(),
};

export const chatCoreSlice = createSlice({
    name: 'chatCore',
    initialState,
    reducers: {
        setChatRoomQuery: (state, { payload }) => {
            state.chatRoomFilters.query = payload;
        },
        addThreadMessage: (state, { payload }: { payload: MessageThread }) => {
            messageThreadAdapter.addOne(state.messages, payload);

            roomsAdapter.upsertOne(state.rooms, {
                _id: payload.roomId,
                latestMessage: payload,
            } as ChatRoom);
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(chatTokenApi.endpoints.getChatToken.matchFulfilled, (state, { payload }) => {
                if (isChatTokenResponse(payload)) {
                    state.chatToken = payload.data.accessToken;
                    sessionStorage.setItem(CHAT_AUTH_TOKEN, JSON.stringify({ token: payload.data.accessToken }));
                    localStorage.setItem(CHAT_AUTH_TOKEN, JSON.stringify({ token: payload.data.accessToken }));
                } else {
                    state.chatToken = null;
                    localStorage.removeItem(CHAT_AUTH_TOKEN);
                    sessionStorage.removeItem(CHAT_AUTH_TOKEN);
                }
            })
            .addMatcher(chatRoomsApi.endpoints.getRooms.matchFulfilled, (state, { payload }) => {
                roomsAdapter.upsertMany(state.rooms, payload.data);
            })
            .addMatcher(chatRoomsApi.endpoints.getRoomDetails.matchFulfilled, (state, { payload }) => {
                roomsAdapter.upsertOne(state.rooms, payload);
                if (typeof payload.latestMessage !== 'string') {
                    messageThreadAdapter.addOne(state.messages, payload.latestMessage);
                }
            })
            .addMatcher(messageThreadApi.endpoints.getMessages.matchFulfilled, (state, { payload }) => {
                messageThreadAdapter.upsertMany(state.messages, payload.data);
            });
        // .addMatcher(messageThreadApi.endpoints.getMessages.matchFulfilled, (state, { payload }) => {
        //     messageThreadAdapter.addOne(state.messages, payload);
        // });
    },
});

export default chatCoreSlice.reducer;
export const { setChatRoomQuery, addThreadMessage } = chatCoreSlice.actions;

export const selectChatRoomFilters = (state: RootState) => state.chatCore.chatRoomFilters;

export const { selectAll: getAllChatRoomsFromAdapter, selectById: getChatRoomById } = roomsAdapter.getSelectors(
    (state: RootState) => state.chatCore.rooms,
);

export const selectAllRooms = createSelector(getAllChatRoomsFromAdapter, (rooms) => {
    return rooms.map((room) => room) || [];
});

export const selectChatRoomById = createSelector(getChatRoomById, (room) => ChatRoomSerializer.parse(room ?? {}));

export const selectFilteredRooms = createSelector(selectAllRooms, selectChatRoomFilters, (rooms, filters) => {
    return rooms
        .filter((r) => !!r._id && r.buyerName)
        .filter((r) => r.buyerName?.toLowerCase().includes(filters.query?.toLowerCase()))
        .map((r) => ChatRoomSerializer.parse(r))
        .sort((r1, r2) => {
            return r2.lastUpdatedAt.diff(r1.lastUpdatedAt);
        });
});

export const { selectAll: getAllMessagesFromAdapter } = messageThreadAdapter.getSelectors(
    (state: RootState) => state.chatCore.messages,
);

export const selectMessagesForRoom = createSelector(
    [getAllMessagesFromAdapter, (_, roomId) => roomId],
    (messages, roomId) => {
        return messages.filter((m) => m && m.roomId === roomId).map((m) => MessageThreadSerializer.parse(m));
    },
);
