import React, { createContext, useContext, useState } from 'react';

interface InAppNotification {
    id: string;
    text: string;
    variant: EInAppNotificationVariant;
    cta?: {
        label: string;
        onClick: () => void;
    };
}

enum EInAppNotificationVariant {
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
}

type AppNotificationContextType = {
    notification: InAppNotification[];
    notify: (InAppNotification) => void;
    clearNotification: (id: string) => void;
    removeAllNotifications: () => void;
};

export const AppNotificationContext = createContext<AppNotificationContextType | null>(null);

const NotificationProvider: React.FC<any> = ({ children }) => {
    const [notification, setNotification] = useState<InAppNotification[]>();

    const removeAllNotifications = () => {
        setNotification([]);
    };

    const notify = (notification: InAppNotification) => {
        setNotification((prev) => [...prev, notification]);
    };

    const clearNotification = (id: string) => {
        setNotification((prev) => prev.filter((notification) => notification.id !== id));
    };

    return (
        <AppNotificationContext.Provider value={{ notification, removeAllNotifications, notify, clearNotification }}>
            {children}
        </AppNotificationContext.Provider>
    );
};

export const useAppNotification = () => {
    return useContext(AppNotificationContext);
};

export default NotificationProvider;
