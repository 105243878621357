import { ChatFileAttachment } from 'contracts/chat/ChatFileAttachment';
import { IGetMessageResponse } from 'contracts/requests/IGetMessageResponse';
import { chatSystemsApi } from '.';

export const messageThreadApi = chatSystemsApi.injectEndpoints({
    endpoints: (builder) => ({
        getMessages: builder.query<IGetMessageResponse, { roomId: string; page: number }>({
            query: ({ roomId, page }) => ({
                url: `/rooms/${roomId}/messages?page=${page}`,
                method: 'GET',
            }),
        }),
        uploadImage: builder.mutation<ChatFileAttachment, any>({
            invalidatesTags: [],
            query: (body) => {
                return {
                    url: `messages/upload`,
                    method: 'post',
                    body,
                };
            },
        }),

        deleteImage: builder.mutation<void, string>({
            invalidatesTags: [],
            query: (id) => {
                return {
                    url: `messages/image/${id}`,
                    method: 'delete',
                };
            },

        }),

    }),
});

export const {
    useGetMessagesQuery,
    useUploadImageMutation,
    useDeleteImageMutation,
} = messageThreadApi;
