/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import List from '@mui/material/List';
import { ReactNode } from 'react';

function SidenavList({ children }: { children: ReactNode }): JSX.Element {
    return (
        <List
            sx={{
                px: 2,
                my: 0.3,
            }}
        >
            {children}
        </List>
    );
}

export default SidenavList;
