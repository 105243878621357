import { flexRender, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import { ColumnHelper, createColumnHelper, getCoreRowModel } from '@tanstack/table-core';

import { RowData } from '@tanstack/table-core/src/types';
import classNames from 'classnames';
import SharedDataTableResponsive from 'components/shared/SharedTable/SharedDataTableResponsive';
import SpinningComponent from 'components/shared/SpinningComponent';

import downArrow from 'assets/svg/down-arrow.svg';
import upArrow from 'assets/svg/up-arrow.svg';

import { IDashboardCommissionQuery } from 'contracts/requests/IDashboardCommission';
import React from 'react';
import './style.scss';

interface TableProps {
    tableData: RowData[];
    loading?: boolean;
    query?: IDashboardCommissionQuery;
    setQuery?: (arg: IDashboardCommissionQuery) => void;
    columns: any /* TODO: */;
    columnHelper: ReturnType<typeof createColumnHelper>;
    responsiveTableHeader?: Record<string, string>;
    hasCurvedHeader?: boolean;
    hasLeftAligned?: boolean;
    toSortHeader?: string;
}

const SharedDataTable: React.FC<TableProps> = ({
    loading = false,
    tableData = [],
    query,
    setQuery,
    columns,
    columnHelper,
    responsiveTableHeader,
    hasCurvedHeader = false,
    hasLeftAligned = false,
    toSortHeader = '',
}) => {
    const tableColumns = React.useMemo(
        () =>
            columns.map((column) =>
                columnHelper.accessor(column.accessor, {
                    header: column.header,
                    cell: column.cell,
                }),
            ),
        [columnHelper, columns],
    );

    const numberOfHeads = columns.length;
    const [sorting, setSorting] = React.useState<SortingState>([]);

    const table = useReactTable({
        data: tableData ?? [],
        columns: tableColumns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
    });

    const tableRows = table.getRowModel().rows;

    const handleSort = (columnId: string) => {
        if (columnId === 'credits') {
            const isDesc = query?.sortBy === columnId && query?.sortingOrder === 'desc';
            // Always set sorting order to "desc" for "remainingCredit"
            setQuery({
                ...query,
                sortingOrder: isDesc ? 'asc' : 'desc',
                sortBy: columnId,
            });
        }
    };

    const getSortingIndicator = (columnId: string) => {
        if (query?.sortBy === columnId) {
            // If sortBy exists for the current column, display an indicator based on the sorting order
            return query?.sortingOrder === 'asc' ? '▲' : '▼';
        }
        // For other columns or when sortBy doesn't exist, no indicator
        return null;
    };

    return (
        <>
            <div className="table-v2-block">
                <div className="table-list-scroller">
                    <table>
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header, index) => (
                                        <th
                                            key={header.id}
                                            className={classNames('text-align-center', {
                                                'bg-header': hasCurvedHeader && index === 0,
                                                'curved-th': hasCurvedHeader && index === 1,
                                            })}
                                            onClick={() => handleSort(header.id as string)}
                                        >
                                            {/* {header.isPlaceholder
                                                ? null
                                                : flexRender(header.column.columnDef.header, header.getContext())} */}

                                            {header.isPlaceholder ? null : (
                                                <>
                                                    {header.id === toSortHeader ? (
                                                        <div
                                                            {...{
                                                                className: header.column.getCanSort()
                                                                    ? 'cursor-pointer select-none sort-arrow'
                                                                    : '',
                                                                onClick: header.column.getToggleSortingHandler(),
                                                            }}
                                                        >
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext(),
                                                            )}
                                                            {{
                                                                asc: <img src={upArrow} alt="up-arrow" />,
                                                                desc: <img src={downArrow} alt="down-arrow" />,
                                                            }[header.column.getIsSorted() as 'asc' | 'desc'] ?? null}
                                                        </div>
                                                    ) : (
                                                        flexRender(header.column.columnDef.header, header.getContext())
                                                    )}
                                                </>
                                            )}

                                            {getSortingIndicator(header.id as string)}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {tableRows.map((row) => (
                                <tr
                                    key={row.id}
                                    {...{
                                        onClick: () => {
                                            row.toggleExpanded();
                                        },
                                        style: { cursor: 'pointer' },
                                    }}
                                >
                                    {row.getVisibleCells().map((cell, columnIndex) => (
                                        <td
                                            key={cell.id}
                                            // className={classNames('text-align-center', {
                                            //     'curved-td': hasCurvedHeader,
                                            // })}
                                            className={classNames({
                                                'text-align-left left-padding': hasLeftAligned && columnIndex === 0,
                                                'text-align-center': !hasLeftAligned || columnIndex !== 0,
                                                'curved-td': hasCurvedHeader,
                                            })}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            {loading && (
                                <tr className="">
                                    <td colSpan={numberOfHeads} className="text-align-center">
                                        Loading...
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {tableRows.length === 0 && !loading && (
                        <div className="no-data no-data-centered">No Data Found</div>
                    )}
                </div>
            </div>
            <SpinningComponent loading={loading}>
                <div className="table-v2-block table-v2-responsive">
                    {tableRows.map((row) => (
                        <SharedDataTableResponsive key={row.id} row={row} IDToHeaderMap={responsiveTableHeader} />
                    ))}
                    {loading && <h4 className="table-loading">Loading...</h4>}
                    {tableData?.length <= 0 && <h4 className="table-loading">No data found</h4>}
                </div>
            </SpinningComponent>
        </>
    );
};

export default SharedDataTable;
