import GradeIcon from '@mui/icons-material/Grade';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useGetChatRoomDetailsQuery, useRateLeadMutation } from 'redux/services/spotdif/chatToken';

interface IRateLeadProps {
    chatRoomId?: string;
}

const RateLead: React.FC<IRateLeadProps> = ({ chatRoomId }) => {
    const isMobile = useMediaQuery('(max-width:768px)');

    const { leadRating, chatRoomDetails } = useGetChatRoomDetailsQuery(chatRoomId, {
        selectFromResult: ({ data, isLoading }) => {
            return {
                data: data,
                leadRating: isLoading ? 0 : data?.data?.selfRating?.value ?? 0,
                chatRoomDetails: data?.data,
                isLoading: isLoading,
            };
        },
    });

    const [updateLeadRating] = useRateLeadMutation();

    const generateRatings = (rating: number) => {
        return Array.from({ length: 5 }, (_, index) => index < rating);
    };

    const [ratings, setRatings] = useState(generateRatings(leadRating));
    const [originalRatings, setOriginalRatings] = useState([...ratings]);

    const handleStarHover = (index) => {
        const newRatings = ratings.map((_, i) => i <= index);
        setRatings(newRatings);
    };

    const handleMouseLeave = () => {
        setRatings([...originalRatings]);
    };

    const updateRating = async (index) => {
        const newRatings = [...ratings];
        newRatings[index] = !newRatings[index];
        const ratingValue = index + 1;
        let ratingsObj = {
            value: ratingValue,
            userId: chatRoomDetails?.selfRating?.userId,
            leadId: chatRoomDetails?._id,
        };
        await updateLeadRating(ratingsObj)
            .unwrap()
            .then((res) => {
                enqueueSnackbar('Rating Updated Successfully', { variant: 'success', key: 'rating' });
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('There was some error in updating the rating', { variant: 'error', key: 'rating' });
            });

        setRatings(generateRatings(ratingValue));
        setOriginalRatings(generateRatings(ratingValue)); // Update originalRatings when ratings change
    };

    // INFO: ensures that api response-based rating is reflected in the widget.
    useEffect(() => {
        setRatings(generateRatings(leadRating));
        setOriginalRatings(generateRatings(leadRating)); // Update originalRatings when ratingFromApi changes
    }, [leadRating]);

    // Just for layout rerendering purposes, we may be can ignore this.
    useEffect(() => {
        setOriginalRatings([...ratings]);
    }, [isMobile]);

    return (
        <>
            {isMobile ? (
                <Grid xs={6} md={6} className="rating-cnt-mobile" item>
                    <Typography className="rate-text">Rate the lead</Typography>
                    <Grid className="rating" item>
                        {ratings.map((filled, index) => (
                            <GradeIcon
                                className="rating-icon-mobile"
                                key={index.toString()}
                                onMouseEnter={() => handleStarHover(index)}
                                onMouseLeave={handleMouseLeave}
                                style={{ color: filled ? 'gold' : 'grey' }}
                                onClick={() => updateRating(index)}
                            />
                        ))}
                    </Grid>
                </Grid>
            ) : (
                <Grid item xs={2} md={2} className="rating-cnt">
                    <Typography className="rate-text">Rate the lead</Typography>
                    <Grid className="rating">
                        {ratings.map((filled, index) => (
                            <GradeIcon
                                className="rating-icon"
                                key={index.toString()}
                                onMouseEnter={() => handleStarHover(index)}
                                onMouseLeave={handleMouseLeave}
                                style={{ color: filled ? 'gold' : 'grey' }}
                                onClick={() => updateRating(index)}
                            />
                        ))}
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default RateLead;
