import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Icon } from '@mui/material';
import discoverImageAccess from 'assets/images/discover-img-2.png';
import discoverImage from 'assets/images/discover-img1.png';
import featureCardOne from 'assets/images/icons/feature-1.svg';
import featureCardTwo from 'assets/images/icons/feature-2.svg';
import featureCardThree from 'assets/images/icons/feature-3.svg';
import logoWhiteOrange from 'assets/images/logos/spotdiff-logo-white-orange.svg';
import logo from 'assets/images/logos/spotdiff-logo.svg';
import trustWorthy from 'assets/images/trustpilot-rating-top.svg';
import trustWorthyWhite from 'assets/svg/trustworthy-white.svg';

import { BrandNav } from 'components/shared/BrandNav';
import './brandingLayout.scss';

import { BrandPanel } from 'components/shared/BrandPanel';
import { Features } from 'components/shared/Features';
import { HowItWorks } from 'components/shared/HowItWork';
import { ReplacementContent } from 'components/shared/ReplacementContent';
import MDTypography from 'components/themed/MDTypography';
import { useUserAnalytics } from 'context/UserAnalytics';
import { usePromoLinkContent } from 'hooks/usePromoLinkContent';
import React, { useEffect, useRef } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { SIGNINV2, SIGNUPV2, WELCOME } from './CONST';

//!-- extra css files below
import 'components/layout/fullPageLayout/fullPageLayout.scss';
import 'components/core/SignUpCard/SignUpCard.scss';
import 'pages/Welcome/welcome.scss';

import 'legacyPages/Signup/Signup.scss';
import 'legacyPages/Login/Login.scss';
import 'legacyPages/ForgetPassword/forget-password.scss';

interface IBrandingLayoutProps {}

export const BrandingLayout: React.FC<IBrandingLayoutProps> = () => {
    const { pathname } = useLocation();
    const { sendPageView } = useUserAnalytics();

    const [searchParams] = useSearchParams();

    const { leadShowCase, qualityLeads, replacementPolicyText, replacementPolicyHeader } = usePromoLinkContent(
        searchParams.get('code'),
    );

    const topRef = useRef(null);

    useEffect(() => {
        sendPageView(pathname);
    }, [pathname, sendPageView]);

    const scrollToTop = () => {
        if (topRef.current) {
            topRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    const qualityLeadsRes = qualityLeads.split('<br/>');

    return (
        <div ref={topRef} className="branding-wrapper">
            <BrandNav variant="header" imageUrl={logo} trustworthy={trustWorthy} pathName={pathname} />
            <Outlet />
            {pathname === SIGNUPV2 || pathname === WELCOME ? (
                <>
                    <div className="features-wrapper">
                        <Features imageUrl={featureCardOne} title="High-quality leads" points={qualityLeadsRes} />
                        <Features
                            imageUrl={featureCardTwo}
                            title="Pay-as-you-go"
                            points={[
                                '<b>Free account</b> - only pay for the leads you receive',
                                '<b>No membership fees</b> or hidden charges',
                                'Minimum order of just <b>5 leads</b>',
                            ]}
                        />
                        <Features
                            imageUrl={featureCardThree}
                            title="Easy to use"
                            points={[
                                'Easily receive leads to your dashboard in <b>real time</b>',
                                '<b>No call</b> with our sales team needed',
                                '<b>Live support</b> available',
                            ]}
                        />
                    </div>
                    <BrandPanel
                        title="<b>Discover</b> your leads dashboard"
                        details={[
                            'Access and manage your leads <b>wherever, whenever</b>',
                            'Top up your credit and <b>order leads with ease</b>',
                            '<b>Tailor your lead preferences</b> to your businesses’ specific offering',
                            'Monitor lead statuses, performance and report with precise reporting tools',
                            'API integration to different CRM platforms',
                        ]}
                        imageUrl={discoverImage}
                        variant="white"
                        scrollFunc={scrollToTop}
                    />
                    <HowItWorks />
                    <BrandPanel
                        title={leadShowCase}
                        paragraph="We are experts in lead generation. We have worked with with over 200 companies in 30 industries and have delivered 100,000+ leads. We run marketing activities on 12+ channels, ensuring a large reach, brand familiarity and a steady flow of high quality leads."
                        imageUrl={discoverImageAccess}
                        variant="blue"
                        scrollFunc={scrollToTop}
                    />
                    {/* <Testimonials /> */}
                    {replacementPolicyHeader && replacementPolicyText && (
                        <ReplacementContent
                            heading={replacementPolicyHeader}
                            content={replacementPolicyText}
                            scrollFunc={scrollToTop}
                        />
                    )}
                    <BrandNav variant="footer" imageUrl={logoWhiteOrange} trustworthy={trustWorthyWhite} />
                    <button className="sticky-get-free-leads" type="submit" onClick={scrollToTop}>
                        <p>Get free leads</p>
                        <Icon className="spotdif-arrow">
                            <ArrowForwardIcon />
                        </Icon>
                    </button>
                </>
            ) : null}
            {pathname === SIGNINV2 && (
                <div className="login-footer-v2">
                    <MDTypography
                        className="login-footer-terms-box"
                        component="a"
                        href="https://leads.spotdif.com/terms&conditions/Narwhal-Ts&Cs.pdf"
                        target="_blank"
                    >
                        Terms and Conditions
                    </MDTypography>
                    <MDTypography
                        className="login-footer-terms-box"
                        component="a"
                        href="https://leads.spotdif.com/terms&conditions/Narwhal-Ts&Cs.pdf"
                        target="_blank"
                    >
                        Privacy Policy
                    </MDTypography>
                </div>
            )}
        </div>
    );
};
