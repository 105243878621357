import { IGetUserAdsResponse } from 'contracts/requests/IGetUserAdsResponse';
import { IPlanPackagesResponse } from 'contracts/requests/IPlanPackagesResponse';
import { TAG_GET_USER_ADS } from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';

const packagesApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getPlanPackages: builder.query<IPlanPackagesResponse, void>({
            query: () => {
                return {
                    url: '/adminSettings/plan-packages',
                    method: 'get',
                };
            },
            transformResponse: (response: IPlanPackagesResponse) => {
                return response;
            },
        }),

        getUserAds: builder.query<IGetUserAdsResponse, void>({
            providesTags: [TAG_GET_USER_ADS],
            query: () => {
                return {
                    url: '/ads/users-add',
                    method: 'GET',
                };
            },
        }),

        advertismentClick: builder.mutation({
            invalidatesTags: [TAG_GET_USER_ADS],
            query: (args) => {
                return {
                    url: `/ads/click/${args}`,
                    method: 'POST',
                };
            },
        }),
    }),
});
export const { useGetPlanPackagesQuery, useGetUserAdsQuery, useAdvertismentClickMutation } = packagesApi;
