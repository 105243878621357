import dayjs from 'dayjs';
import React from 'react';

interface DateRendererProps {
    date: dayjs.Dayjs;
    format?: string;
    fallback?: string;
}

const DateRenderer: React.FC<DateRendererProps> = ({ date, format = 'DD/MM/YYYY, hh:mm A', fallback = '-' }) => {
    const dateValue = format ? date.format(format) : date.diff();

    return <>{date?.isValid() ? dateValue : fallback}</>;
};

export default DateRenderer;
