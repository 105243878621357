import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import DateRenderer from 'components/shared/DateTimeRenderer/DateRenderer';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import { ChatContext } from 'context/ChatContext';
import React, { useContext } from 'react';

const ChatAttachmentLightBox: React.FC = () => {
    const { attachmentInFocus, setAttachmentInFocus } = useContext(ChatContext);
    const isModalOpen = !!attachmentInFocus?.path;

    const chatAttachmentUrl = (path: string) => {
        return `${process.env.REACT_APP_CHAT_SERVER_URL}/${path}`;
    };

    const handleCloseModal = () => {
        setAttachmentInFocus(null);
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={() => setAttachmentInFocus(null)}
        >

            <MDBox className="lightbox-wrapper">
                <div className="lightbox-header">
                    <Typography variant="h4">
                        <DateRenderer date={attachmentInFocus?.createdAt} />
                    </Typography>
                    <MDBox className="modalCloseWrapper">
                        <MDButton className="modalCloseBtn" onClick={handleCloseModal}>
                            <CloseIcon fontSize="large" />
                        </MDButton>
                    </MDBox>
                </div>
                <div className="image-wrapper">
                    <img src={chatAttachmentUrl(attachmentInFocus?.path)} alt="Attachment" />
                </div>
            </MDBox>
        </Modal>
    );
};

export default ChatAttachmentLightBox;
