import dayjs from 'dayjs';
import { AnyT, jsonMember, jsonObject, TypedJSON } from 'typedjson';
import { LeadsDataObject } from './LeadsDataObject';

export enum LeadStatuses {
    valid = 'Valid',
    reportAccepted = 'Report Accepted',
    reportRejected = 'Report Rejected',
    reported = 'Reported',
    reprocess = 'Reprocess',
    archived = 'Archived',
    tentative = 'Tentative',
}

@jsonObject()
export class SelfRatingObject {
    @jsonMember(String)
    ratedAt: string;

    @jsonMember(String)
    userId: string;

    @jsonMember(Number)
    value: number;

    @jsonMember(String)
    _id: string;

}

@jsonObject()
export class LeadsBidData {
    @jsonMember(String)
    _id: string;

    @jsonMember(String)
    bid: string;

    @jsonMember(String)
    chatRoomId: string;

    @jsonMember(Number)
    leadsCost: number;

    @jsonMember(AnyT)
    clientName: any;

    @jsonMember(() => LeadsDataObject)
    leads: LeadsDataObject;

    @jsonMember(String)
    contactStatus: string;

    @jsonMember(Number)
    __v: number;

    @jsonMember(Number)
    rowIndex?: number;

    @jsonMember(String)
    clientNotes?: string;

    @jsonMember(String)
    clientNotes2?: string;

    @jsonMember(String)
    clientNotes1?: string;

    @jsonMember(String)
    clientNotes3?: string;

    @jsonMember(String)
    feedbackForNMG?: string;

    @jsonMember(String)
    invalidLeadReason?: string;

    @jsonMember(String)
    leadRemarks?: string;

    @jsonMember(String)
    status: LeadStatuses;

    @jsonMember(Boolean)
    isArchived: boolean;

    @jsonMember(Boolean, {
        deserializer: (value: unknown) => {
            return value !== 'false' && value !== false;
        },
    })
    isHydrated: boolean;

    @jsonMember(Number)
    soldAt: number;

    @jsonMember(AnyT)
    columns: any;

    @jsonMember(SelfRatingObject)
    selfRating?: SelfRatingObject;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value ? dayjs(value) : null;
        },
    })
    reportAcceptedAt?: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value ? dayjs(value) : null;
        },
    })
    reportedAt?: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value ? dayjs(value) : null;
        },
    })
    createdAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value ? dayjs(value) : null;
        },
    })
    updatedAt: dayjs.Dayjs;

    get email(): string {
        return this.isHydrated ? (this.leads.email ?? '-') : '-';
    }

    get businessName(): string {
        return this.isHydrated ? (this.leads.businessName ?? '-') : '-';
    }

    get businessIndustry(): string {
        return this.isHydrated ? (this.leads.businessIndustry ?? '-') : '-';
    }

    get phone(): string {
        return this.isHydrated ? (this.leads.phone1 ?? '-') : '-';
    }

    get firstName(): string {
        return this.isHydrated ? (this.leads.firstName ?? this.leads.firstname ?? '-') : '-';
    }

    get lastName(): string {
        return this.isHydrated ? (this.leads.lastName ?? this.leads.lastname ?? '-') : '-';
    }

    get postCode(): string {
        return this.isHydrated ? (this.leads.postcode ?? '-') : '-';
    }

    get street1(): string {
        return this.isHydrated ? (this.leads.street1 ?? '-') : '-';
    }

    get client(): string {
        return this.isHydrated ? (this.leads.clientName ?? '-') : '-';
    }
}

export const LeadsBidDataSerializer = new TypedJSON(LeadsBidData);
