import RolePermissionTable from 'components/role-permissions';
import React from 'react';

const RolePermissionPage: React.FC = () => {
    return (
        <>
            <RolePermissionTable />
        </>
    );
};

export default RolePermissionPage;
