import ErrorBoundary from 'components/shared/ErrorBoundary';
import React from 'react';
import { useUserHasPermission } from './useUserHasPermission';

interface IAuthorisationProps {
    module: string;
    permission: string;
    failure?: React.ReactNode;
    children: React.ReactNode;
}

const AuthorisedUsage: React.FC<IAuthorisationProps> = ({
                                                            module, permission, failure = () => {
    }, children,
                                                        }) => {
    const hasPermission = useUserHasPermission(module, permission);

    return <ErrorBoundary>{hasPermission ? <>{children}</> : null}</ErrorBoundary>;
};

export default AuthorisedUsage;
