import { jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export class ContactCreditsObject {
    @jsonMember(Number)
    liveChatLeads: number;

    @jsonMember(Number)
    inboundCallLeads: number;

    @jsonMember(Number)
    hotkeys: number;
}
