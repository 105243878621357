import MDBox from 'components/themed/MDBox';
import MDTypography from 'components/themed/MDTypography';
import React from 'react';
import { useGetUserAdsQuery } from 'redux/services/spotdif/packages';

import AdvertisementCard from './AdvertisementCard';

const PartnershipOffers = () => {
    const { data: userAdsData } = useGetUserAdsQuery(undefined, {
        pollingInterval: 12000,
    });

    return (
        <>
            <MDBox className="layout-middle">
                <MDTypography variant="h5">Are you looking for more?</MDTypography>
                <MDTypography variant="p">
                    Our partners offer your business everything from calculator tools to distributor level pricing. If
                    you have a tool or service you would like to list, in touch with us.
                </MDTypography>

                <MDBox className="list-card-parent grid-responsive">
                    {userAdsData?.ads?.map((card) => {
                        return (
                            <AdvertisementCard
                                key={card?._id}
                                icon={card?.image}
                                title={card?.title}
                                promotionText={card?.promotionText}
                                description={card?.description}
                                buttonText={card?.buttonText}
                                advertisementId={card?._id}
                                callToAction={card?.callToAction}
                            />
                        );
                    }) || null}
                </MDBox>
                {userAdsData?.ads.length === 0 && (
                    <div className="no_data_box">
                        <h6>There are no Partnership offers to display</h6>
                    </div>
                )}
            </MDBox>
        </>
    );
};

export default PartnershipOffers;
