import React, { Component, ErrorInfo, ReactElement, ReactNode } from 'react';
import './index.scss';

interface ErrorBoundaryProps {
    children: ReactNode;
    identifier?: string;
    message?: string;
}

interface ErrorBoundaryState {
    hasError: boolean;
    retryAttempted: boolean;
    key: number;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    private timeoutId: NodeJS.Timeout | null = null;

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            retryAttempted: false,
            key: 0,
        };
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        return { hasError: true, retryAttempted: false, key: 0 };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.error('Error caught by error boundary', error, errorInfo);
        // Clear any pending timeouts when an error occurs
        if (this.timeoutId !== null) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }

    componentWillUnmount() {
        // Clear any pending timeouts when the component unmounts
        if (this.timeoutId !== null) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }

    handleRetry = () => {
        if (!this.state.retryAttempted) {
            this.setState((prevState) => ({
                retryAttempted: true,
                key: prevState.key + 1,
            }));
        }
    };

    render() {
        const { hasError, key, retryAttempted } = this.state;
        const { message, identifier, children } = this.props;

        if (hasError) {
            return (
                <div className={`error-boundary-container ${identifier}`}>
                    <p className="error-message">
                        {message ? message : 'Something went wrong'}
                    </p>
                    {/* {!retryAttempted && (
                        <button onClick={this.handleRetry}>Retry</button>
                    )} */}
                    {React.Children.map(children, (child: ReactElement) => {
                        const { className } = child.props;
                        return (
                            <div className={`faded ${className}`} key={key}>
                                {child}
                            </div>
                        );
                    })}
                </div>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
