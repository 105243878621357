import classNames from 'classnames';
import { LeadStatuses } from 'contracts/spotdif/LeadsBidData';
import React, { useCallback, useState } from 'react';
import { stringFormatter } from 'utils/text-helpers';
import LeadsExpandedResponsiveRow from './LeadsExpandedResponsiveRow';

interface LeadsResponsiveTableProps {
    row: any; // Row<LeadsBidData> @fixme this is not proper type find and replace it
    getIcon: (status: LeadStatuses) => React.ReactNode;
    adminLeadsData: any; // @fixme this is not proper type find and replace it
}

const idToHeaderMap = {
    status: 'Status',
    businessName: 'Business Name',
    businessIndustry: 'Business Industry',
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    // _id: ""
};

export enum TableCardView {
    MoreDetails = 'MoreDetails',
    ReportLead = 'ReportLead',
    NONE = null,
}

const LeadsResponsiveTable: React.FC<LeadsResponsiveTableProps> = ({ row, getIcon, adminLeadsData }) => {
    const [view, setView] = useState<TableCardView>();

    const handleView = useCallback(
        (toggleView: TableCardView) => {
            if (toggleView === view) {
                setView(TableCardView.NONE);
            } else {
                setView(toggleView);
            }
        },
        [view],
    );

    return (
        <>
            <table className="table-v2-responsive-row">
                {row.getAllCells().map((tableCol) => {
                    return (
                        <>
                            {idToHeaderMap?.[tableCol.column.id] ? (
                                <tr>
                                    <td>{idToHeaderMap?.[tableCol.column.id]}</td>
                                    <td>
                                        {tableCol?.renderValue() ? (
                                            <>
                                                {tableCol.column.id === 'status' ? (
                                                    <span className="status_icon">
                                                        {getIcon(tableCol?.renderValue() as LeadStatuses)}
                                                    </span>
                                                ) : (
                                                    stringFormatter(20, tableCol?.renderValue())
                                                )}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                </tr>
                            ) : (
                                <></>
                            )}
                        </>
                    );
                })}
            </table>
            <div className="table-v2-responsive-buttons leads-more-details-btn">
                <button
                    className={classNames('more-info-table-btn', {
                        'table-btn-actve': view === TableCardView.MoreDetails,
                    })}
                    onClick={() => handleView(TableCardView.MoreDetails)}
                >
                    More Details
                </button>
                <button
                    className={classNames('report-table-btn', { 'table-btn-actve': view === TableCardView.ReportLead })}
                    onClick={() => handleView(TableCardView.ReportLead)}
                >
                    Report lead
                </button>
            </div>
            {view !== TableCardView.NONE ? (
                <LeadsExpandedResponsiveRow view={view} leadData={row.original} adminLeadsData={adminLeadsData} />
            ) : (
                <></>
            )}
        </>
    );
};

export default LeadsResponsiveTable;
