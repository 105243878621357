import { IGetBadgeStatusResponse } from 'contracts/requests/IGetBadgeStatusResponse';
import {
    IAllUsersResponse,
    ImpersonateUserResponse,
    IUserProfileRequest,
    IUserProfileResponse,
} from 'contracts/requests/IUserProfileResponse';
import { UserBase } from 'contracts/spotdif/UserBase';
import {
    TAG_CLIENT_UPDATE,
    TAG_GET_ACCOUNT_MANAGER,
    TAG_GET_BADGE_STATUS,
    TAG_GET_USER_PROFILE,
    TAG_SAVE_CLIENT_DETAIL,
    TAG_SUB_ADMIN,
    TAG_USER_PROFILE,
} from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';

const userProfileApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        currentUserData: builder.query<IUserProfileResponse, void>({
            providesTags: [
                {
                    type: TAG_USER_PROFILE,
                    id: TAG_GET_USER_PROFILE,
                },
            ],

            query: () => {
                return {
                    url: `/auth/me`,
                    method: 'get',
                };
            },
        }),

        userProfileData: builder.query<IUserProfileResponse, string>({
            providesTags: [
                {
                    type: TAG_USER_PROFILE,
                    id: TAG_GET_USER_PROFILE,
                },
            ],
            query: (userId) => {
                return {
                    url: `/user/${userId}`,
                    method: 'get',
                };
            },
        }),

        impersonateLogin: builder.query<ImpersonateUserResponse, string>({
            providesTags: [],
            query: (userId) => {
                return {
                    url: `/auth/impersonate/${userId}`,
                    method: 'get',
                };
            },
        }),

        // TODO: convert to PATCH
        updateUserProfile: builder.mutation<IUserProfileResponse, IUserProfileRequest>({
            invalidatesTags: [
                TAG_GET_ACCOUNT_MANAGER,
                {
                    type: TAG_USER_PROFILE,
                    id: TAG_GET_USER_PROFILE,
                },
                TAG_SUB_ADMIN,
            ],
            query: (body: IUserProfileRequest) => {
                return {
                    url: `/user/${body._id}`,
                    method: 'post',
                    body: body,
                };
            },
        }),
        getAllUsers: builder.query<UserBase[], void>({
            providesTags: [TAG_CLIENT_UPDATE, TAG_SAVE_CLIENT_DETAIL],
            query: () => {
                return {
                    url: `/user/show?sortingOrder=desc`,
                    method: 'get',
                };
            },
            transformResponse: (response: IAllUsersResponse) => {
                return response.data;
            },
        }),

        getBadgeStatus: builder.query<IGetBadgeStatusResponse, void>({
            providesTags: [TAG_GET_BADGE_STATUS],
            query: () => {
                return {
                    url: `/supplier-badges/my-badges`,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const {
    useCurrentUserDataQuery,
    useUserProfileDataQuery,
    useUpdateUserProfileMutation,
    useGetAllUsersQuery,
    useLazyImpersonateLoginQuery,
    useGetBadgeStatusQuery,
} = userProfileApi;

export default userProfileApi;
