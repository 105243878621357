import { ILeadStat } from 'contracts/view-models/ILeadStat';
import React from 'react';
import MDBox from '../themed/MDBox';
import MDTypography from '../themed/MDTypography';

interface ILeadStatProps {
    lead: ILeadStat;
}

const LeadStat: React.FC<ILeadStatProps> = ({ lead }) => {
    const { title, stat, description } = lead;

    return (
        <MDBox className="user-lead-stats--item">
            <MDTypography variant="h2">{stat}</MDTypography>
            <MDTypography variant="h3">{title}</MDTypography>
            <MDTypography>{description}</MDTypography>
        </MDBox>
    );
};

export default LeadStat;
