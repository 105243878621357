import React, { useState, useMemo, useEffect, useCallback } from 'react';
import SharedDataTable from '../SharedTable/SharedDataTable';
import { createColumnHelper } from '@tanstack/table-core';
import { Autocomplete, Checkbox } from '@mui/material';
import MDTypography from 'components/themed/MDTypography';
import { useGetRolePermissionsQuery, useUpdateUserModulePermissionsMutation } from 'redux/services/spotdif/sub-admin';
import { enqueueSnackbar } from 'notistack';

import { useGetAllModulesQuery } from 'redux/services/spotdif/rolePermissions';
import { handleCheckboxChange } from 'utils/handleCheckboxChange';
import SpinningComponent from '../SpinningComponent';
import MDInput from 'components/themed/MDInput';
import toTitleCase, { capitalizeFirstLetter } from 'utils/text-helpers';
import { userPermissionsArray } from 'contracts/spotdif/userPermissionsArray';
import './style.scss';

// import './styles.scss';

interface IUserModulePermission {
    _id?: string;
    permissions: userPermissionsArray[];
}

interface Props {
    userModulePermission: IUserModulePermission;
}
interface AllModulesListType {
    data: userPermissionsArray[];
    message: string;
}

const PermissionTable: React.FC<Props> = ({ userModulePermission }) => {
    const { data: allModulesList, isLoading: isAllModulesListLoading } = useGetAllModulesQuery();
    const [selectedRole, setSelectedRole] = useState('');
    const [data, setData] = useState<{ permissions: userPermissionsArray[] }>({ permissions: [] });

    const mergePermissionsForModule = useCallback(
        (rowData: IUserModulePermission, allModulesList: AllModulesListType) => {
            const existingPermissions = rowData?.permissions?.map((perm) => perm?.module);
            const modulesToAdd = allModulesList?.data?.filter(
                (module) => !existingPermissions.includes(module?.module),
            );
            const newPermissions = modulesToAdd.map((module) => ({
                module: module.module,
                permission: module.permission,
            }));
            const updatedPermissions = [...rowData.permissions, ...newPermissions];
            const updatedRowData = {
                ...rowData,
                permissions: updatedPermissions,
            };
            return updatedRowData;
        },
        [allModulesList],
    );

    useEffect(() => {
        if (userModulePermission && allModulesList) {
            const updatedData = mergePermissionsForModule(userModulePermission, allModulesList);
            setData(updatedData);
        }
    }, [userModulePermission, allModulesList]);

    const handleCheckboxChangeMemoized = useCallback(handleCheckboxChange(setData), [setData]);

    const columnHelper = createColumnHelper();
    const { data: rolePermissionsData } = useGetRolePermissionsQuery();

    const [updateModulePermissionsTrigger] = useUpdateUserModulePermissionsMutation();
    const updateModulePermissions = async (id: string, body: IUserModulePermission) => {
        try {
            const response = await updateModulePermissionsTrigger({ userId: id, body });
            if ('data' in response) {
                enqueueSnackbar(response.data.message, { variant: 'success', key: 'change-permission' });
            } else {
                enqueueSnackbar('Failed to update permissions.', { variant: 'error', key: 'change-permission' });
            }
            return response;
        } catch (error) {
            enqueueSnackbar('Failed to update permissions.', { variant: 'error', key: 'change-permission' });
            throw error;
        }
    };
    const tableColumns = [
        {
            accessor: 'module',
            header: () => <span>Module Name</span>,
            cell: (info) => <span>{capitalizeFirstLetter(info.getValue() ?? 'Unable to retrieve')}</span>,
        },
        {
            accessor: 'create',
            header: () => <span>Create</span>,
            cell: ({ row }) => (
                <Checkbox
                    checked={row.original.create}
                    onChange={() => handleCheckboxChangeMemoized(row.index, 'create')}
                />
            ),
        },
        {
            accessor: 'read',
            header: () => <span>Read</span>,
            cell: ({ row }) => (
                <Checkbox
                    checked={row.original.read}
                    onChange={() => handleCheckboxChangeMemoized(row.index, 'read')}
                />
            ),
        },
        {
            accessor: 'update',
            header: () => <span>Update</span>,
            cell: ({ row }) => (
                <Checkbox
                    checked={row.original.update}
                    onChange={() => handleCheckboxChangeMemoized(row.index, 'update')}
                />
            ),
        },
        {
            accessor: 'delete',
            header: () => <span>Delete</span>,
            cell: ({ row }) => (
                <Checkbox
                    checked={row.original.delete}
                    onChange={() => handleCheckboxChangeMemoized(row.index, 'delete')}
                />
            ),
        },
    ];

    const handleRoleChange = useCallback(
        (event, value) => {
            if (value) {
                const selectedRolePermissions = rolePermissionsData?.data?.roles.find(
                    (role) => role.role === value.role,
                );
                if (selectedRolePermissions?.permissions) {
                    const updatedPermissionsObject = {
                        permissions: selectedRolePermissions?.permissions,
                    };
                    setSelectedRole(value.role);
                    setData(updatedPermissionsObject);
                }
            }
        },
        [rolePermissionsData, setData, setSelectedRole],
    );

    return (
        <>
            <SpinningComponent loading={isAllModulesListLoading}>
                <div className="permission-table-container">
                    <div className="header">
                        <MDTypography variant="h4">Update Permissions</MDTypography>
                        <Autocomplete
                            className="dropdownMenu"
                            onChange={handleRoleChange}
                            options={rolePermissionsData?.data?.roles}
                            getOptionLabel={(option) => option.role}
                            renderOption={(props, option) => (
                                <li {...props} key={option._id}>
                                    {toTitleCase(option.role ?? 'Unable to retrieve')}
                                </li>
                            )}
                            renderInput={(params) => (
                                <MDInput {...params} fullWidth placeholder="Select a role preset" />
                            )}
                        />
                    </div>
                    <div className="table-v2 client-leads-v2 update-permission-modal">
                        <SharedDataTable
                            tableData={data?.permissions?.map((permission) => ({
                                module: permission.module,
                                create: permission.permission.includes('create'),
                                read: permission.permission.includes('read'),
                                update: permission.permission.includes('update'),
                                delete: permission.permission.includes('delete'),
                            }))}
                            loading={false}
                            columns={tableColumns}
                            columnHelper={columnHelper}
                            hasCurvedHeader={false}
                            hasLeftAligned={true}
                            toSortHeader={'module'}
                        />
                    </div>
                </div>
                <button
                    className="button update-table w-100"
                    onClick={() =>
                        updateModulePermissions(userModulePermission._id, {
                            permissions: data.permissions,
                        })
                    }
                >
                    Update table
                </button>
            </SpinningComponent>
        </>
    );
};

export default PermissionTable;
