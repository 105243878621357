import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import MDButton from 'components/themed/MDButton';
import BasicModal from 'components/core/Modal';
import { createColumnHelper } from '@tanstack/react-table';
import toTitleCase from 'utils/text-helpers';
import './style.scss';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import SpinningComponent from 'components/shared/SpinningComponent';
import CloseIcon from '@mui/icons-material/Close';
import MDBox from 'components/themed/MDBox';
import {
    useRolePermissionsListQuery,
    useAddNewRoleMutation,
    useGetAllModulesQuery,
} from 'redux/services/spotdif/rolePermissions';
import SharedDataTable from 'components/shared/SharedTable/SharedDataTable';
import MDTypography from 'components/themed/MDTypography';
import { enqueueSnackbar } from 'notistack';
import MDInput from 'components/themed/MDInput';
import UpdatePermissionsTable from './UpdatePermissionsTable';
import { Grid } from '@mui/material';
import validLeads from 'assets/images/icons/valid-leads.png';
import dayjs from 'dayjs';
import TableViewIcon from '@mui/icons-material/TableView';
import GridViewIcon from '@mui/icons-material/GridView';
import { userPermissionsArray } from 'contracts/spotdif/userPermissionsArray';

const ActionHeader = () => (
    <AuthorisedUsage module={MODULE.ADMINS} permission={PERMISSIONS.UPDATE}>
        <span>Action</span>
    </AuthorisedUsage>
);

interface IRolePermission {
    _id?: string;
    role?: string;
    permissions: userPermissionsArray[];
    createdAt?: string;
    updatedAt?: string;
}

interface IAllModulesListType {
    data: userPermissionsArray[];
    message: string;
}

const RolePermissionTable: React.FC = () => {
    const columnHelper = createColumnHelper();
    const { data: allModulesList, isLoading: isAllModulesListLoading } = useGetAllModulesQuery();
    const { data: rolePermissionsList, isLoading: isRolePermissionsListLoading } = useRolePermissionsListQuery();

    const mergePermissionsForModule = useCallback(
        (rowData: IRolePermission[], allModulesList: IAllModulesListType) => {
            const updatedRowData = rowData.map((role) => {
                const existingPermissions = role.permissions.map((perm) => perm.module);
                const modulesToAdd = allModulesList?.data?.filter(
                    (module) => !existingPermissions.includes(module.module),
                );
                const newPermissions = modulesToAdd?.map((module) => ({
                    module: module.module,
                    permission: module.permission,
                }));
                const updatedPermissions = [...role.permissions, ...newPermissions];
                const updatedRole = {
                    ...role,
                    permissions: updatedPermissions,
                };
                return updatedRole;
            });
            return updatedRowData;
        },
        [allModulesList],
    );

    const [data, setData] = useState(() => {
        if (rolePermissionsList && allModulesList) {
            return mergePermissionsForModule(rolePermissionsList.data?.roles, allModulesList);
        } else {
            return [];
        }
    });

    const [isTableVisible, setIsTableVisible] = useState(true);
    const toggleTableVisibility = () => {
        setIsTableVisible(!isTableVisible);
    };

    const [newRoleInput, setNewRoleInput] = useState('');
    const [addRolePopup, setAddRolePopup] = useState(false);
    const handleaddRolePopup = useCallback(() => {
        setAddRolePopup(false);
    }, [setAddRolePopup]);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewRoleInput(e.target.value);
    };

    const [addNewRoleTrigger] = useAddNewRoleMutation();
    const addNewRole = () => {
        const newRoleData = {
            role: newRoleInput,
            permissions: allModulesList?.data,
        };
        addNewRoleTrigger(newRoleData)
            .unwrap()
            .then((res) => {
                enqueueSnackbar(res?.message, { variant: 'success', key: 'add-new-role' });
            })
            .catch((error) => {
                enqueueSnackbar(error?.data?.data ?? error?.data?.error?.message ?? 'Something went wrong', {
                    variant: 'error',
                    key: 'add-new-role',
                });
            });
    };

    useLayoutEffect(() => {
        if (rolePermissionsList && allModulesList) {
            setData(mergePermissionsForModule(rolePermissionsList.data?.roles, allModulesList));
        }
    }, [rolePermissionsList, allModulesList, setData]);

    const roleTableColumns = useMemo(
        () => [
            {
                accessor: 'role',
                header: <span>Role</span>,
                cell: (info) => <span>{toTitleCase(info.getValue() || '-')}</span>,
            },
            {
                accessor: '_id',
                header: ActionHeader,
                cell: (info) => {
                    const rolePermissionInfo: IRolePermission = {
                        _id: info.row.original._id,
                        role: info.row.original.role,
                        permissions: info.row.original.permissions,
                    };
                    return <UpdatePermissionsTable info={rolePermissionInfo} />;
                },
            },
        ],
        [],
    );

    return (
        <>
            <SpinningComponent loading={isRolePermissionsListLoading}>
                <div className="buttonWrapper">
                    <button
                        type="button"
                        className="button mr-4"
                        onClick={() => {
                            setAddRolePopup(true);
                        }}
                    >
                        Add Role
                    </button>
                    <button type="button" className="toggleButton mr-1" onClick={toggleTableVisibility}>
                        {isTableVisible ? (
                            <GridViewIcon className="toggle-view-icon" />
                        ) : (
                            <TableViewIcon className="toggle-view-icon" />
                        )}
                    </button>
                </div>

                <BasicModal open={addRolePopup} handleClose={handleaddRolePopup}>
                    <div>
                        <MDBox className="modalCloseWrapper">
                            <div className="popup-container">
                                <MDTypography variant="h3">Role Name</MDTypography>
                                <div className="role-permission-field w-100">
                                    <MDInput
                                        type="text"
                                        value={newRoleInput}
                                        id={'newRole'}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <button type="button" onClick={addNewRole} className="button update-table w-100">
                                Add Role
                            </button>
                            <div>
                                <div className="roleModalCloseWrapper">
                                    <MDButton className="roleModalCloseBtn" onClick={handleaddRolePopup}>
                                        <CloseIcon fontSize="large" />
                                    </MDButton>
                                </div>
                            </div>
                        </MDBox>
                    </div>
                </BasicModal>
                {isTableVisible && (
                    <div className="layout-middle-admin table-v2 industries-leads sub-admin-table">
                        <div className="table-list-scroller">
                            <SharedDataTable
                                tableData={data}
                                loading={isRolePermissionsListLoading}
                                columns={roleTableColumns}
                                columnHelper={columnHelper}
                                hasCurvedHeader={false}
                                hasLeftAligned={true}
                                toSortHeader={'role'}
                            />
                        </div>
                    </div>
                )}
                {!isTableVisible && (
                    <div className="gridWrapper">
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {data.map((role, index) => {
                                const rolePermissionInfo = {
                                    _id: role._id,
                                    role: role.role,
                                    permissions: role.permissions,
                                };
                                return (
                                    <Grid item xs={2} sm={4} md={4} key={index}>
                                        <div className="gridItem">
                                            <div className="girdChildItems">
                                                <img className="status-img" alt="" src={validLeads} />
                                                <div>
                                                    <h6>{toTitleCase(role.role)}</h6>
                                                    <p>
                                                        Created At:{' '}
                                                        {dayjs(role.createdAt).format('DD/MM/YYYY').toString() || '-'}
                                                    </p>
                                                    <p>
                                                        Updated At:{' '}
                                                        {dayjs(role.updatedAt).format('DD/MM/YYYY').toString() || '-'}
                                                    </p>
                                                </div>
                                            </div>
                                            <UpdatePermissionsTable info={rolePermissionInfo} />
                                        </div>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                )}
            </SpinningComponent>
        </>
    );
};

export default RolePermissionTable;
