import MDButton from 'components/themed/MDButton';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useCreateXeroIdMutation } from 'redux/services/spotdif/advanceManagement';
import './styles.scss';
import { IXeroDetailsProps } from '.';

const GenerateXero: React.FC<IXeroDetailsProps> = ({ xeroDetailsData, userId }) => {
    const [createXeroId, { isLoading: xeroLoading }] = useCreateXeroIdMutation();

    const handleGenerateXero = useCallback((userId) => {
        createXeroId(userId)
            .unwrap()
            .then((res) => {
                enqueueSnackbar(res?.message ?? 'Xero Id Generated Successfully', {
                    variant: 'success',
                    key: 'xeroDetails',
                });
            })
            .catch((err) => {
                enqueueSnackbar(err?.data?.message || 'Something went wrong', {
                    variant: 'error',
                    key: 'xeroDetails',
                });
            });
    }, []);

    return (
        <>
            <MDButton
                onClick={() => {
                    handleGenerateXero(userId);
                }}
                type="button"
                className="button generate-xero-button"
                disabled={xeroDetailsData?.isXeroCustomer || userId === null || xeroLoading}
            >
                Generate Xero
            </MDButton>
        </>
    );
};

export default GenerateXero;
