import dayjs from 'dayjs';
import { jsonMember, jsonObject, TypedJSON } from 'typedjson';


@jsonObject()
export class ChatFileAttachment {
    @jsonMember(String)
    _id: string; // Document ID from MongoDB

    @jsonMember(String)
    path: string;

    @jsonMember(Boolean)
    inUse: boolean;

    @jsonMember(String)
    userId: string;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return dayjs(value);
        },
    })
    createdAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return dayjs(value);
        },
    })
    updatedAt: dayjs.Dayjs;
}

export const ChatFileAttachmentSerializer = new TypedJSON(ChatFileAttachment);
