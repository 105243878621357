import React, { useCallback } from 'react';
import BadgeV2, { BadgeNature } from './BadgeV2';

export enum ItemNature {
    badge = 'badge',
    text = 'text',
}

type BadgeItemType = {
    nature: BadgeNature;
    label: React.ReactNode;
    value: React.ReactNode;
    className?: string;
};

interface IBadgeItemProps {
    item: BadgeItemType;
    onClick?: () => void;
}

const BadgeItem: React.FC<IBadgeItemProps> = ({ item, onClick }) => {
    const onClickHandler = useCallback(() => {
        if (typeof onClick === 'function') {
            onClick();
        }
    }, [onClick]);

    return (
        <>
            <div className="info-items" onClick={onClickHandler}>
                <h6 className="sub-heading">{item.label}</h6>
                <BadgeV2 className={item.className} badgeType={item.nature} value={item.value} />
            </div>
        </>
    );
};

export default BadgeItem;
