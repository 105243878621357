import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { EUserRole } from 'contracts/app-utils/EUserRole';
import { IUserProfileResponse } from 'contracts/requests/IUserProfileResponse';
import { UserBase, UserBaseSerializer } from 'contracts/spotdif/UserBase';
import { enqueueSnackbar } from 'notistack';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { render } from 'react-dom';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGetSiteConfigsQuery } from 'redux/services/spotdif/siteConfigs';
import { useCurrentUserDataQuery } from 'redux/services/spotdif/user';
import { selectIsLoggedIn } from 'redux/slices/auth';
import { useTypedSelector } from 'redux/store';
import MDButton from '../../components/themed/MDButton';

export interface IApplicationUserContext {
    user: UserBase | null;
    isLoading: boolean;

    canSubmitDetailsFor: (field: string) => boolean;

    banners: {
        content: IAppBanner[];
        add: (banner: IAppBanner) => void;
        remove: (bannerId: string) => void;
    };
}

export interface IAppBanner {
    id: string;
    message: string;
    cta?: React.ReactNode;
    type: 'success' | 'error' | 'info' | 'warning';
}

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHING_KEY}`);

export const ApplicationUserContext = createContext<IApplicationUserContext>({} as IApplicationUserContext);

export const useApplicationUserContext = () => {
    const context = useContext(ApplicationUserContext);

    if (!context) {
        throw new Error('useApplicationUserContext must be used within a ApplicationUserContextProvider');
    }

    return context;
};

export const PendingPayment = ({ user, dispatch, cta }) => {
    const stripe = useStripe();
    console.log('Stripe', stripe);
    if (user?.pendingTransaction && user?.pendingTransaction !== '') {
        stripe
            ?.handleNextAction({
                clientSecret: user?.pendingTransaction,
            })
            .then((res) => {
                console.log('STRIPE RESPONSE', res);
                if (res.error) {
                    enqueueSnackbar(res?.error?.message ?? 'Something went wrong', { variant: 'error' });
                }
                if (res?.paymentIntent?.status === 'succeeded') {
                    enqueueSnackbar('Payment Success', { variant: 'success' });
                }
                cta();
            })
            .catch((error) => {
                console.log('`STRIPE ERROR', stripe);
                console.log('STRIPE ERROR', error);
                /* As Discussed with the Team */
                enqueueSnackbar('Your transaction is in progress. Please check back in 15 minutes.', {
                    variant: 'error',
                });
                window.location.reload();
            });
    }
    return <></>;
};

export const ApplicationUserContextProvider = (props: any) => {
    const isLoggedIn = useTypedSelector(selectIsLoggedIn);
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [banners, setBanners] = React.useState<IAppBanner[]>([]);
    const [showZenDesk, setShowZenDesk] = React.useState(false);

    const handleClose = () => setOpen(false);

    const { siteConfigsData } = useGetSiteConfigsQuery(undefined, {
        skip: !isLoggedIn,
        selectFromResult: ({ data, isLoading }) => {
            return {
                siteConfigsData: data?.data,
                siteConfigsDataLoading: isLoading,
            };
        },
    });

    const { data: userData, isLoading: isUserDataLoading } = useCurrentUserDataQuery(undefined, {
        skip: !isLoggedIn,
    });

    const user = useMemo<UserBase | null>(() => {
        if (isUserDataLoading || !userData) {
            return null;
        }

        return UserBaseSerializer.parse((userData as IUserProfileResponse).data);
    }, [isUserDataLoading, userData]);

    const addCreditsButton = useMemo(() => {
        return (
            <>
                <Link
                    to="/account/credit-and-billing"
                    style={{
                        padding: 0,
                    }}
                >
                    <MDButton className="button banner-add-credit-button">Add Credit</MDButton>
                </Link>
            </>
        );
    }, []);

    const handlePendingTransaction = (user) => {
        const options = {
            appearance: {
                /*...*/
            },
        };
        render(
            /* TODO: need to use Ref and CreatePortal */
            <>
                <Elements stripe={stripePromise} options={options}>
                    <PendingPayment user={user} dispatch={dispatch} cta={() => removeBanner('pending-transactions')} />
                </Elements>
            </>,
            document.getElementById('react-portal'),
        );
    };

    const completeTransactionButton = useMemo(() => {
        return (
            <>
                <MDButton onClick={() => handlePendingTransaction(user)}>Complete Transaction</MDButton>
            </>
        );
    }, [handlePendingTransaction, user]);

    const checkFieldAccessForUserService = useCallback((field: string) => {
        if (!user) {
            return false;
        }

        if (user.isAdmin || user.isSuperAdmin) {
            return true;
        }

        const solarPanelIndustries = [
            '64882d6931f282eade3a8086',
            '65e16aa4765003faf8023bab',
            '65c9bc56c0556441e230c225',
        ];
        switch (field) {
            case 'any':
            case 'welcome_message':
                return true;

            case 'finance_offers':
            case 'starting_price':
            case 'average_install_time':
            case 'warranty':
                return solarPanelIndustries.includes(user.businessIndustryId);

            default:
                return false;
        }
    }, [user]);

    const checkUserInputAccess = useCallback((field: string) => {
        if (!user) {
            return false;
        }

        if (user.isAdmin || user.isSuperAdmin) {
            return true;
        }

        const [module, fieldName] = field.split('.');

        switch (module.toLowerCase()) {
            case 'user_services':
                return checkFieldAccessForUserService(fieldName);

            default:
                return true;
        }

    }, [checkFieldAccessForUserService, user]);

    const addBanner = useCallback((banner: IAppBanner) => {
        // add or update banner based on id
        setBanners((bannersInState) => {
            const bannerIndex = bannersInState.findIndex((b) => b.id === banner.id);
            if (bannerIndex !== -1) {
                const newBanners = [...bannersInState];
                newBanners[bannerIndex] = banner;

                return newBanners;
            } else {
                // add new banner
                return [...bannersInState, banner];
            }
        });
    }, []);

    const removeBanner = useCallback((bannerId: string) => {
        setBanners((banners) => banners.filter((banner) => banner.id !== bannerId));
    }, []);

    useEffect(() => {
        showZenDesk && window.zE('messenger', 'show');
        !showZenDesk && window.zE('messenger', 'hide');
        if (!user || user.isAdmin || user.isSuperAdmin || user.isAccountManager || user.isAccountAdmin) return;
        const creditBannerId = 'insufficient-credits';
        const disableAutoCharge = 'disable-auto-charge';
        const pendingTransaction = 'pending-transactions';
        const isAutoChargeEnabled = 'auto-charge-enabled';
        if (
            (user.hasOnboarded && user?.credits < 1 && user.role !== EUserRole.NON_BILLABLE) ||
            (user.role === EUserRole.NON_BILLABLE && user?.isCreditsAndBillingEnabled === true && user.hasOnboarded)
        ) {
            // -- is auto charge is false then show else don't show
            if (!user?.isAutoChargeEnabled) {
                addBanner({
                    id: creditBannerId,
                    message:
                        'You have insufficient credit. Leads will be paused until you confirmed and set up autocharge',
                    cta: addCreditsButton,
                    type: 'error',
                });
            } else {
                removeBanner(creditBannerId);
            }
            if (user?.hasEverTopped && user?.credits === 0 && user?.isAutoChargeEnabled) {
                addBanner({
                    id: disableAutoCharge,
                    message:
                        'You don\'t have enough credits. If you want to add more credits, please wait for up to 24 hours or disable the auto top-up option on the credit and billing page.',
                    cta: addCreditsButton,
                    type: 'error',
                });
            } else {
                removeBanner(disableAutoCharge);
            }

            // user?.hasEverTopped && user?.credits === 0 && user?.isAutoChargeEnabled
        } else {
            removeBanner(disableAutoCharge);
            removeBanner(creditBannerId);
        }

        if (!user.isLeadReceived && user?.credits > 1) {
            addBanner({
                id: 'leads-pending',
                message:
                    'Please allow up to 24 to 48 hours to start receiving leads. You will be notified via email when the first lead arrives.\n',
                type: 'success',
            });
        }

        if (
            user?.isAutoChargeEnabled &&
            user.hasOnboarded &&
            user?.credits / user?.leadCost < user?.triggerAmount / user?.leadCost &&
            !siteConfigsData?.firstCardBonus?.enabled
        ) {
            addBanner({
                id: isAutoChargeEnabled,
                message:
                    'Please allow up to 24 hours for credit to be added, you will start to receive leads in 24-48 hours after top up.',
                type: 'success',
            });
        } else {
            removeBanner(isAutoChargeEnabled);
        }

        if (!user.hasOnboarded) {
            addBanner({
                id: 'onboarding-left',
                message: `Your account is ${user.onboardingPercentage}% complete. Please complete all steps to begin receiving leads.`,
                type: 'error',
            });
        }

        if (user?.pendingTransaction) {
            addBanner({
                id: pendingTransaction,
                message: 'Please complete the pending transaction to proceed with your top-up.',
                cta: completeTransactionButton,
                type: 'error',
            });
        } else {
            removeBanner(pendingTransaction);
        }

        if (user) {
            setShowZenDesk(true);

            if (user.hasOnboarded || user.role === 'admin' || user.role === 'superAdmin') {
                setShowZenDesk(false);
            }
        }
    }, [
        user,
        user?.credits,
        addCreditsButton,
        removeBanner,
        addBanner,
        showZenDesk,
        user?.ryftClientId,
        user?.isRyftCustomer,
    ]);

    return (
        <ApplicationUserContext.Provider
            value={{
                canSubmitDetailsFor: checkUserInputAccess,
                user: user,
                isLoading: isUserDataLoading,
                banners: {
                    content: banners,
                    add: addBanner,
                    remove: removeBanner,
                },
            }}
        >
            {props.children}
        </ApplicationUserContext.Provider>
    );
};

export default ApplicationUserContext;
