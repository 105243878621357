import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';

interface IPageEvent {
    title: string;
}

export enum EVENT_CATEGORY {
    USER_SIGNUP = 'user-signup',
    ONBOARDING_BUSINESS = 'onboarding.business',
    ONBOARDING_LEAD_DETAILS = 'onboarding.leadDetails',
    ONBOARDING_PAYMENT = 'onboarding.paymentDetails',
    CREDITS_TOPUP = 'credits.topup',
}

// const options = {
//     autoConfig: true, //  Automatically configures Pixel based on Facebook Pixel ID
//     debug: true, // Enable debug mode for development
// };

export enum EVENT_ACTION {
    INITIATED = 'initiated',
    COMPLETED = 'completed',
}

interface IUserEvent {
    category: EVENT_CATEGORY;
    label?: string;
    value?: number;
}

interface IUserAnalyticsContext {
    trackEvent: (action: EVENT_ACTION, params?: IUserEvent) => void;
    sendPageView: (pagePath: string, params?: IPageEvent) => void;
}

interface IUserAnalyticsProviderProps {
    children: React.ReactNode;
}

const UserAnalyticsContext = React.createContext<IUserAnalyticsContext>({
    trackEvent: () => {},
    sendPageView: () => {},
});

export const useUserAnalytics = () => {
    const context = React.useContext(UserAnalyticsContext);
    if (context === undefined) {
        throw new Error('useUserAnalytics must be used within a UserAnalyticsProvider');
    }
    return context;
};

const UserAnalyticsProvider: React.FC<IUserAnalyticsProviderProps> = ({ children }) => {
    const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
    const shouldTrack = process.env.REACT_APP_GA_TRACKING_ID !== '';
    const facebookPixelID = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
    // const advancedMatching ={ email : ""};

    React.useEffect(() => {
        if (trackingId) {
            ReactGA.initialize(trackingId);
        }
    }, [trackingId]);

    React.useEffect(() => {
        if (facebookPixelID) {
            ReactPixel.init(facebookPixelID);
        }
    }, [facebookPixelID]);

    const trackEvent = React.useCallback(
        (action: EVENT_ACTION, params?: IUserEvent) => {
            if (shouldTrack) {
                ReactGA.event({
                    action: action,
                    ...params,
                });
            } else {
                console.log(`Analytics hasn't been initialized GA event: ${action}`, params);
            }
        },
        [shouldTrack],
    );

    const sendPageView = React.useCallback(
        (page: string, pageOptions?: IPageEvent) => {
            if (shouldTrack) {
                ReactGA.send({ hitType: 'pageview', page: page, ...pageOptions });
            } else {
                console.log(`Analytics hasn't been initialized GA Page View: ${page}`, pageOptions);
            }

            if (facebookPixelID) {
                ReactPixel.pageView();
            } else {
                console.log(`Analytics hasn't been initialized FACEBOOK Page View: ${page}`, pageOptions);
            }
        },
        [shouldTrack, facebookPixelID],
    );

    return (
        <UserAnalyticsContext.Provider value={{ trackEvent, sendPageView }}>{children}</UserAnalyticsContext.Provider>
    );
};

export default UserAnalyticsProvider;
