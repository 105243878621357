const handleKeyPress = (event) => {
    const charCode = event.which || event.keyCode;
    const char = String.fromCharCode(charCode);
    const regex = /^[0-9\b]+$/; // Only allow numbers and backspace

    if (!regex.test(char)) {
        event.preventDefault(); // Prevent the keypress event
    }
};

export default handleKeyPress;
