import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Link } from 'react-router-dom';
import './style.scss';

const NotFound = () => {
    return (
        <>
            <div className="page-error-wrapper">
                <div className="page-error-box">
                    <div className="error-text">
                        <div className="err">4</div>
                        <HelpOutlineIcon />
                        <div className="err">4</div>
                    </div>
                    <p>The page you are looking for might have been removed... Or it's temporarily unavailable.</p>
                    <Link className="button" to="/">
                        Go To Home
                    </Link>
                </div>
            </div>
        </>
    );
};

export default NotFound;
