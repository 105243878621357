import { ISiteConfigsRequest, ISiteConfigsResponse } from 'contracts/requests/ISiteConfigsRequest';
import { IValidationConfigRequest, IValidationConfigResponse } from 'contracts/requests/IValidationConfigRequest';
import { TAG_GET_SITE_CONFIGS, TAG_GET_VALIDATION_CONFIGS } from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';

const siteConfigsApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getSiteConfigs: builder.query<ISiteConfigsResponse, void>({
            providesTags: [TAG_GET_SITE_CONFIGS],
            query: (body) => {
                return {
                    url: `/adminSettings/site-configs`,
                    method: 'GET',
                };
            },
        }),

        getValidationConfigs: builder.query<IValidationConfigResponse, void>({
            providesTags: [TAG_GET_VALIDATION_CONFIGS],
            query: (body) => {
                return {
                    url: `/validationConfigs/minimum_topUp_leadCount`,
                    method: 'GET',
                };
            },
        }),

        updateSiteConfigs: builder.mutation<ISiteConfigsResponse, ISiteConfigsRequest>({
            invalidatesTags: [TAG_GET_SITE_CONFIGS],
            query: (body) => {
                return {
                    url: `/adminSettings/site-configs`,
                    method: 'POST',
                    body: body,
                };
            },
        }),

        updateValidationConfigs: builder.mutation<IValidationConfigResponse, IValidationConfigRequest>({
            invalidatesTags: [TAG_GET_VALIDATION_CONFIGS],
            query: (body) => {
                return {
                    url: `/validationConfigs/minimum_topUp_leadCount`,
                    method: 'PATCH',
                    body: body,
                };
            },
        }),
    }),
});

export const {
    useGetSiteConfigsQuery,
    useUpdateSiteConfigsMutation,
    useGetValidationConfigsQuery,
    useUpdateValidationConfigsMutation,
} = siteConfigsApi;
