import { Grid, Paper, Typography } from '@mui/material';
import { ChatFileAttachmentsRenderer } from 'components/Inbox/ChatFileAttachmentsRenderer';
import HumanDateRenderer from 'components/shared/DateTimeRenderer/HumanDateRenderer';
import { MessageThread } from 'contracts/chat/MessageThread';
import React, { useEffect, useRef } from 'react';

interface IThreadItem {
    style: React.CSSProperties;
    message: MessageThread;
    index: number;
    setRowHeight: (arg1: number, arg2: number) => void;
}

const ThreadItem: React.FC<IThreadItem> = ({ style, message, index, setRowHeight }) => {
    const rowRef = useRef({});

    const debounceRef = useRef<number>();

    useEffect(() => {
        const lk = () => {
            if (rowRef.current) {
                // @ts-ignore
                setRowHeight(index, rowRef.current.clientHeight + 15);
            }
        };

        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = window.setTimeout(lk, 200);

        return () => {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }
        };
    }, [index, setRowHeight]);

    return (
        <div style={style}>
            {/* @ts-ignore */}
            <Grid
                ref={rowRef}
                item
                key={index}
                xs={12}
                className={`message-${message.direction.toLowerCase()}`}
            >
                <Paper
                    className={`chat-thread-item ${message.media ? 'media' : 'paper-message'}`}
                    elevation={0}
                >
                    {message.text && (
                        <Typography
                            dangerouslySetInnerHTML={{ __html: parseUrls(message.text) }}
                            className={`message-text message-bg-${message.direction.toLowerCase()}`}
                            variant="body2"
                        >
                            {/* {message.text} */}
                        </Typography>
                    )}
                    <ChatFileAttachmentsRenderer attachments={message.attachments} />
                </Paper>
            </Grid>

            <Typography className={`message-time-${message.direction.toLowerCase()}`}>
                <HumanDateRenderer date={message.createdAt} />
            </Typography>
        </div>
    );
};

export default ThreadItem;

function parseUrls(message) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return message.replace(urlRegex, ' <a href="$1" target="_blank">$1</a> ');
}
