import { EUserRole } from 'contracts/app-utils/EUserRole';
import useAuthentication from 'hooks/useAuthentication';
import React from 'react';

interface ICheckPermission {
    authorizedRole: EUserRole[];
    children: React.ReactNode;
}

const CheckPermission: React.FC<ICheckPermission> = ({ children, authorizedRole }) => {
    const { user } = useAuthentication();

    return <>{authorizedRole.includes(user.role) ? children : <></>}</>;
};

export default CheckPermission;
