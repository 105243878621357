import { useCallback, useState } from 'react';

export default function useToggle(defaultValue: boolean): [boolean, () => void] {
    const [value, setValue] = useState<boolean>(defaultValue);

    const toggle = useCallback(() => {
        setValue((value) => !value);
    }, []);

    return [value, toggle];
}
