import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import useAuthentication from '../hooks/useAuthentication';

export const ADMIN_ONLY = {
    module: MODULE.DASHBOARD,
    permission: PERMISSIONS.UPDATE,
};

export const SPECIAL_ADMINS_ONLY = {
    module: MODULE.DASHBOARD,
    permission: PERMISSIONS.CREATE,
};

export const CLIENTS_AS_WELL = {
    module: MODULE.DASHBOARD,
    permission: PERMISSIONS.READ,
};
export const CLIENTS_ONLY = {
    module: MODULE.CLIENT_DASHBOARD,
    permission: PERMISSIONS.READ,
};

interface IRoleProtectedRouteProps {
    element: React.JSX.Element;
    permissions: Array<{
        module: string;
        permission: string;
    }>;
    mustOnboard?: boolean;
}

export const ModuleProtectedRoute = ({ element, permissions, mustOnboard = false }: IRoleProtectedRouteProps) => {
    const { user, isLoadingUserDetails, isAdmin, isSuperAdmin } = useAuthentication();

    const hasUserDetailsBeenLoaded = useMemo<boolean>(() => !isLoadingUserDetails, [isLoadingUserDetails]);

    useEffect(() => {
        if (hasUserDetailsBeenLoaded && !user) {
            // MAYBE: We don't require this snack bar message
            enqueueSnackbar('logout successfully', {
                key: 'authentication_error',
                variant: 'success',
            });
        }
    }, [hasUserDetailsBeenLoaded, user]);

    if (!hasUserDetailsBeenLoaded) {
        return null; // Return null or a loading spinner while user details are loading
    }

    if (!user) {
        return <Navigate to="/login" />;
    }

    if (mustOnboard && !isSuperAdmin && !isAdmin && !user.hasOnboarded) {
        enqueueSnackbar(`Please complete your account setups before continuing further.`, {
            key: 'authentication_error',
            variant: 'error',
        });

        return <Navigate to={`${user.onboardingRoute}`} />;
    }

    if (permissions.every(({ module, permission }) => user?.canAccess(module, permission) ?? false)) {
        return element;
    }

    // if (user.role === role) {
    //     return element;
    // }

    // enqueueSnackbar(`You need to be logged in with ${role} permissions`, {
    //     key: "authentication_error",
    //     variant: "error",
    // });

    if (user.role === 'admin' || user.role === 'superAdmin' || user.role === 'accountAdmin') {
        return <Navigate to="/admin/dashboard" />;
    } else if (user.role === 'user') {
        return <Navigate to="/" />;
    } else if (user.role === 'accountManager') {
        return <Navigate to="/admin/clients-v2" />;
    }

    return null; // Handle other roles or return an appropriate fallback
};
