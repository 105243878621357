import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { removeTrailingSlash } from 'utils/text-helpers';

export default function usePageName() {
    const { pathname } = useLocation();

    return useMemo<string>(() => {
        const pathRoot = pathname.split('/')[1] ?? 'nothing';

        // MAYBE: use pathroot for rest of the routes to group as well. (Could help naming pages even better.)
        if (pathRoot === 'chat-inbox') return 'Inbox Alpha';

        switch (removeTrailingSlash(pathname)) {
            case '/dashboard':
                return 'Dashboard';

            case '/chat-inbox':
                return 'Inbox Alpha';

            case '/leads':
                return 'Leads';

            case '/leads-v2':
                return 'Leads';

            case '/on-boarding':
                return 'Basic Info';

            case '/on-boarding/business':
                return 'Business Details';

            case '/on-boarding/leads':
                return 'Leads Settings';

            case '/on-boarding/payment':
                return 'Payments Information';

            case '/account':
            case '/account/info':
            case '/account/business':
            case '/account/leads':
            case '/account/credit-and-billing':
            case '/account/payment':
            case '/account/notifications':
                return 'Your Account';

            case '/account/reset-password':
                return 'Reset Password';

            case '/reset/password':
                return 'Reset Password';

            case '/admin/dashboard':
                return 'Dashboard';

            case '/admin/clients':
                return 'Clients';

            case '/admin/clients-v2':
                return 'Clients';

            case '/plans/packages':
                return 'Growth Plan Packages';

            case '/partners/offers':
                return 'Partnership offers';

            case '/admin/leads':
                return 'Leads';

            case '/admin/leads-v2':
                return 'Leads';

            case '/admin/reported-leads':
                return 'Reported Leads';
            case '/admin/reported-leads-v2':
                return 'Reported Leads';

            case '/admin/industries-leads':
                return 'Business Industries';

            case '/admin/industries-leads-v2':
                return 'Business Industries';

            case '/admin/account':
                return 'Account';

            case '/admin/promo-links':
                return 'Promo Links';

            case '/admin/sub-admin':
                return 'Admins';
            case '/admin/sub-admin-v2':
                return 'Admins';

            case '/admin/non_billable_users':
                return 'Non-billable Clients';

            case '/admin/promo-links-v2':
                return 'Promo Links';

            case '/admin/partners':
                return 'Partnership';

            case '/admin/postcodes':
                return 'Post Codes';

            case '/admin/site-configs':
                return 'Site Configs';

            case `/admin/advance-management`:
                return 'Advance Management';

            case '/certified/supplier':
                return 'Become a SpotDif certified supplier';

            case '/admin/certified':
                return 'Certified Supplier';

            case '/admin/role-permissions':
                return 'Role Permissions';

            case '':
            default:
                return `Spotdif`;
        }
    }, [pathname]);
}
