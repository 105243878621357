import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Grid } from '@mui/material';
import Icon from '@mui/material/Icon';
import MDBox from 'components/themed/MDBox';
import { IDateRangeQuery } from 'contracts/requests/IDateRangeQuery';
import { IDateRangedChartProps } from 'contracts/view-models/IDateRangedChartProps';
import dayjs from 'dayjs';
import useToggle from 'hooks/useToggle';
import { DateRange, DateRangePicker } from 'mui-daterange-picker';
import React, { ComponentType, useCallback } from 'react';
import './style.scss';

export default function WithDateRangePicker<T extends IDateRangedChartProps>(WrappedComponent: ComponentType<T>) {
    // Return a new component with the input field and date prop
    return function WithDateComponent(props: Omit<T, 'range'>) {
        const [isDatePickerOpen, toggleDatePicker] = useToggle(false);
        const [range, setDateRange] = React.useState<IDateRangeQuery>({
            startDate: dayjs().startOf('month'),
            endDate: dayjs().endOf('month'),
        });

        const onDateRangeChange = useCallback((dateRange: DateRange) => {
            setDateRange({
                startDate: dayjs(dateRange.startDate),
                endDate: dayjs(dateRange.endDate),
            });
        }, []);

        return (
            <>
                <MDBox mb={2} className="chart-date-filter">
                    <Grid item xs={12} sm={12}>
                        <button onClick={toggleDatePicker}>
                            By Date
                            <Icon fontSize="small">
                                <KeyboardArrowDownIcon />
                            </Icon>
                        </button>
                        <div className="chart-date-picker-dropdown">
                            <DateRangePicker
                                open={isDatePickerOpen}
                                wrapperClassName="spotdif-calendar-wrapper"
                                toggle={toggleDatePicker}
                                onChange={onDateRangeChange}
                                initialDateRange={{
                                    startDate: range.startDate.toDate(),
                                    endDate: range.endDate.toDate(),
                                }}
                            />
                        </div>
                    </Grid>
                </MDBox>
                <WrappedComponent {...(props as T)} range={range} />
            </>
        );
    };
}
