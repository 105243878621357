import { RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

const CodeToolbar = ({ editorState, onChange }) => {
    const handleButtonClick = () => {
        onChange(RichUtils.toggleInlineStyle(editorState, 'CODE'));
    };

    return (
        <div className="rdw-inline-wrapper" onClick={handleButtonClick}>
            <div className="rdw-option-wrapper">
                <span>
                    <FormatColorFillIcon />
                </span>
            </div>
        </div>
    );
};

export default CodeToolbar;
