import { DashboardCommissionMetaObject } from 'contracts/spotdif/DashboardCommissionMetaObject';
import { TypedJSON, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

@jsonObject()
export class Postcode {
    @jsonArrayMember(String)
    activeClients: string[];

    @jsonMember(String)
    postalCode: string;

    @jsonMember(String)
    nationalPostcode?: string;

    get activeCount(): number {
        return this.activeClients.length ?? 0;
    }
}

export const postcodeSerializer = new TypedJSON(Postcode);

@jsonObject()
export class PostCodeResponse {
    @jsonMember(() => Postcode)
    data: Postcode[];
    meta: DashboardCommissionMetaObject;
}

export interface IPostCodeRequest {
    search?: string;
    perPage?: number;
    total?: number;
    pageCount?: number;
    page?: number;
    pageClient?: number;
    industry?: Array<string>;
    countyId?: string;
}
