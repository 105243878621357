import LogoutIcon from '@mui/icons-material/Logout';
import StarIcon from '@mui/icons-material/Star';
import { Tooltip } from '@mui/material';
import MDTypography from 'components/themed/MDTypography';
import { useMaterialUIController } from 'context/ThemeContext';
import useAuthentication from 'hooks/useAuthentication';

import React from 'react';

const LoggedInUserInfo: React.FC = () => {
    const [controller] = useMaterialUIController();
    const { miniSidenav } = controller;
    const { user, isAdmin, logoutUser, isSuperAdmin } = useAuthentication();

    return (
        <>
            <div className="logged-user-wrapper">
                <div className="logged-user-info">
                    <Tooltip title={user.fullName} placement="right-start">
                        <MDTypography
                            fontSize="16px"
                            color="white"
                            noWrap
                            display="flex"
                            gap={2.4}
                            alignItems="center"
                            sx={{
                                width: '196px',
                                mb: 5,
                                mt: 1,
                                pl: miniSidenav ? '39px' : '48px',
                                display: 'block',
                                alignItems: 'center',
                            }}
                        >
                            {isAdmin
                                ? miniSidenav
                                    ? null
                                    : 'Admin'
                                : isSuperAdmin
                                    ? miniSidenav
                                        ? null
                                        : 'Super Admin'
                                    : miniSidenav
                                        ? null
                                        : user.fullName}
                        </MDTypography>
                    </Tooltip>
                    <MDTypography
                        fontSize="16px"
                        color="white"
                        display="flex"
                        gap={2.4}
                        alignItems="center"
                        sx={{
                            width: '100%',
                            mb: 5,
                            mt: 1,
                            pl: miniSidenav ? '39px' : '48px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {/* {miniSidenav
                            ? <StarIcon/>
                            : isSuperAdmin || isAdmin
                                ? user.fullName
                                :
                                // TODO: removed by Client
                                //  <CreditInfo/>
                                `Remaining Leads - ${Math.round(user?.credits / user?.leadCost) }`
                        } */}
                        {miniSidenav ? (
                            <StarIcon />
                        ) : isSuperAdmin || isAdmin ? (
                            user.fullName
                        ) : (
                            // TODO: removed by Client
                            //  <CreditInfo/>
                            `Remaining Leads - ${user?.normalizedUserCredits}`
                        )}
                    </MDTypography>
                </div>
                <MDTypography
                    fontSize="16px"
                    color="white"
                    display="flex"
                    gap={2.4}
                    alignItems="center"
                    className="logout-button"
                    sx={{
                        width: '100%',
                        mb: 5,
                        mt: 2,
                        cursor: 'pointer',

                        pl: miniSidenav ? '39px' : '48px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={logoutUser}
                >
                    <LogoutIcon />
                    {miniSidenav ? null : 'Logout '}
                </MDTypography>
            </div>
        </>
    );
};

export default LoggedInUserInfo;
