import { jsonMember, jsonObject } from 'typedjson';
import dayjs from 'dayjs';

@jsonObject()
export class BaseDataModel {
    @jsonMember(String)
    _id: string;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        },
    })
    updatedAt?: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        },
    })
    createdAt?: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        },
    })
    deletedAt?: dayjs.Dayjs;

    @jsonMember(Boolean)
    isDeleted?: boolean;
}
