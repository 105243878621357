import { flexRender, getSortedRowModel, Row, SortingState, useReactTable } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { createColumnHelper, getCoreRowModel } from '@tanstack/table-core';

import { RowData } from '@tanstack/table-core/src/types';
import downArrow from 'assets/svg/down-arrow.svg';
import upArrow from 'assets/svg/up-arrow.svg';
import classNames from 'classnames';
import SpinningComponent from 'components/shared/SpinningComponent';

import { IDashboardCommissionQuery } from 'contracts/requests/IDashboardCommission';
import React, { useCallback } from 'react';
import VirtualizedDataTableResponsive from './VirtualizedDataTableResponsive';

import './style.scss';

interface TableProps {
    tableData: RowData[];
    loading?: boolean;
    query?: IDashboardCommissionQuery;
    setQuery?: (arg: IDashboardCommissionQuery) => void;
    columns: any[] /* TODO: */;
    columnHelper: ReturnType<typeof createColumnHelper>;
    responsiveTableHeader?: Record<string, string>;
    hasCurvedHeader?: boolean;
    isFetching?: boolean;
    isLoading?: boolean;
    fetchNextPage?: () => void;
}

const VirtualizedDataTable: React.FC<TableProps> = ({
    loading = false,
    tableData = [],
    query,
    setQuery,
    columns,
    columnHelper,
    responsiveTableHeader,
    hasCurvedHeader = false,
    isFetching,
    fetchNextPage,
    isLoading,
}) => {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const tableColumns = React.useMemo(
        () =>
            columns.map((column) =>
                columnHelper.accessor(column.accessor, {
                    header: column.header,
                    cell: column.cell,
                }),
            ),
        [columnHelper, columns],
    );

    const numberOfHeads = columns.length;

    const table = useReactTable({
        data: tableData ?? [],
        columns: tableColumns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
    });

    const tableRows = table.getRowModel().rows;

    //The virtualizer needs to know the scrollable container element
    const tableContainerRef = React.useRef<HTMLDivElement>(null);

    const rowVirtualizer = useVirtualizer({
        count: tableRows.length,
        estimateSize: () => 46, //estimate row height for accurate scrollbar dragging
        getScrollElement: () => tableContainerRef.current,
        //measure dynamic row height, except in firefox because it measures table border height incorrectly
        measureElement:
            typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
                ? (element) => element?.getBoundingClientRect().height
                : undefined,
        overscan: 5,
    });

    const fetchMoreOnBottomReached = useCallback(async () => {
        if (
            tableContainerRef.current &&
            tableContainerRef.current.scrollTop + tableContainerRef.current.clientHeight >=
                tableContainerRef.current.scrollHeight &&
            !isFetching
        ) {
            fetchNextPage();
        }
    }, [fetchNextPage, isFetching, tableData]);

    return (
        <>
            <div className="table-v2-block Virtualized-table">
                <div className="table-list-scroller" ref={tableContainerRef} onScroll={fetchMoreOnBottomReached}>
                    <table>
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header, index) => (
                                        <th
                                            key={header.id}
                                            className={classNames('text-align-center', {
                                                'bg-header': hasCurvedHeader && index === 0,
                                            })}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <>
                                                    {header.id === 'activeCount' ? (
                                                        <div
                                                            {...{
                                                                className: header.column.getCanSort()
                                                                    ? 'cursor-pointer select-none'
                                                                    : '',
                                                                onClick: header.column.getToggleSortingHandler(),
                                                            }}
                                                        >
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext(),
                                                            )}

                                                            {{
                                                                asc: <img src={upArrow} alt="up-arrow" />,
                                                                desc: <img src={downArrow} alt="down-arrow" />,
                                                            }[header.column.getIsSorted() as 'asc' | 'desc'] ?? null}
                                                        </div>
                                                    ) : (
                                                        flexRender(header.column.columnDef.header, header.getContext())
                                                    )}
                                                </>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody
                            style={{
                                height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                                position: 'relative', //needed for absolute positioning of rows
                            }}
                        >
                            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                                const row = tableRows[virtualRow.index] as Row<any>;
                                return (
                                    <tr
                                        data-index={virtualRow.index} //needed for dynamic row height measurement
                                        ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                                        key={row.id}
                                        style={{
                                            display: 'flex',
                                            position: 'absolute',
                                            transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                                            width: '100%',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <td key={cell.id} className="text-align-center">
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            {loading && (
                                <tr className="">
                                    <td colSpan={numberOfHeads} className="text-align-center">
                                        Loading...
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {tableRows.length === 0 && !loading && (
                        <div className="no-data no-data-centered">No Data Found</div>
                    )}
                </div>
            </div>
            <SpinningComponent loading={loading}>
                <div className="table-v2-block table-v2-responsive">
                    {tableRows.map((row) => (
                        <VirtualizedDataTableResponsive key={row.id} row={row} IDToHeaderMap={responsiveTableHeader} />
                    ))}
                    {loading && <h4 className="table-loading">Loading...</h4>}
                    {tableData?.length <= 0 && <h4 className="table-loading">No data found</h4>}
                </div>
            </SpinningComponent>
        </>
    );
};

export default VirtualizedDataTable;
