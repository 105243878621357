import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import classNames from 'classnames';
import SupplierBadgeDetail from 'contracts/spotdif/SupplierBadgeDetail';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { capitalizeFirstLetter, copyUrl } from 'utils/text-helpers';
import './style.scss';

const SpotDifSupplier: React.FC<SupplierBadgeDetail> = ({ title, imageUrl, type, codeSnippet, contentTitle }) => {
    return (
        <div className="bg-white p-0 m-tb br-2">
            <div className="text-align-center bg-purple">{type}</div>
            <div className="p-2 pt-0">
                <h1 className="supplier-heading-text">{capitalizeFirstLetter(title)}</h1>
                <div className="d-flex gap-1 responsive-flex">
                    <div
                        className={classNames(
                            'bg-gray w-50 br-2 d-flex justify-content-center align-items-center p-2',
                            { 'h-200': !contentTitle },
                        )}
                    >
                        {/* Add Image Here */}
                        <img className="h-150" src={imageUrl} alt="supplier-badges" />
                    </div>
                    <div className="w-50">
                        {contentTitle && (
                            <div className="border-dark br-1 overflow-wrap scroll mb-3">
                                <div className="d-flex justify-space-between align-items-center sticky-header">
                                    <p className="font-bold">{contentTitle}</p>
                                    <p className="text-color-orange" onClick={() => copyUrl(contentTitle, ' Copied')}>
                                        Copy Title
                                        <ContentCopyOutlinedIcon
                                            className="copy-content-icon ml-1"
                                            onClick={() => copyUrl(contentTitle, 'Copied')}
                                        />
                                    </p>
                                </div>
                            </div>
                        )}

                        <div className="border-dark br-1 h-200 overflow-wrap scroll">
                            <div className="d-flex justify-space-between align-items-center mb-1 sticky-header">
                                <p className="font-bold">HTML</p>
                                <p className="text-color-orange" onClick={() => copyUrl(codeSnippet, ' Copied')}>
                                    Copy code
                                    <ContentCopyOutlinedIcon
                                        className="copy-content-icon ml-1"
                                        onClick={() => copyUrl(codeSnippet, 'Copied')}
                                    />
                                </p>
                            </div>
                            <p className="pt-0_5">{codeSnippet}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpotDifSupplier;
