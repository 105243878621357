import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Collapse from '@mui/material/Collapse';

import Icon from '@mui/material/Icon';

import MDBox from 'components/themed/MDBox';
import MDTypography from 'components/themed/MDTypography';
import { MouseEventHandler } from 'react';

interface CollapseCardProps {
    title: string;
    open: boolean;
    children: React.ReactNode;
    removeCountyFromList: Function;
    uncheckPostalCodesFromCounty: Function;
    checkAllPostalCodesForCountry?: Function;
    onClick: MouseEventHandler<HTMLDivElement>;
}

const CollapseCard: React.FC<CollapseCardProps> = ({
    title,
    open,
    children,
    removeCountyFromList,
    uncheckPostalCodesFromCounty,
    checkAllPostalCodesForCountry,
    onClick,
    ...rest
}) => {
    return (
        <MDBox mb={0.5}>
            <MDBox {...rest} onClick={onClick} className="map-collapse-data">
                <MDBox className="map-collapse-dropdown">
                    <MDTypography
                        variant="caption"
                        fontWeight="medium"
                        sx={{ fontSize: '17px', pl: 1, userSelect: 'none' }}
                        color={open ? 'dark' : 'text'}
                    >
                        {title}
                    </MDTypography>
                    <Icon sx={{ fontWeight: 'bold', pt: 0.6 }} fontSize="small">
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </Icon>
                </MDBox>
                <MDBox className="map-collapse-controls" color={open ? 'dark' : 'text'}>
                    <MDTypography
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{
                            fontSize: '17px',
                            cursor: 'pointer',
                            '&:hover': {
                                color: '#ff4e02',
                            },
                            '&:focus:not(:hover)': {
                                color: '#fff',
                            },
                            pr: 2,
                        }}
                        onClick={uncheckPostalCodesFromCounty}
                    >
                        Uncheck All
                    </MDTypography>

                    <MDTypography
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{
                            fontSize: '17px',
                            cursor: 'pointer',
                            '&:hover': {
                                color: '#ff4e02',
                            },
                            '&:focus:not(:hover)': {
                                color: '#fff',
                            },
                            pr: 2,
                        }}
                        onClick={checkAllPostalCodesForCountry}
                    >
                        Check All
                    </MDTypography>

                    <MDTypography
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{
                            fontSize: '17px',
                            cursor: 'pointer',
                            '&:hover': {
                                color: '#ff4e02',
                            },
                            '&:focus:not(:hover)': {
                                color: '#fff',
                            },
                            pr: 2,
                        }}
                        onClick={removeCountyFromList}
                    >
                        Remove
                    </MDTypography>
                </MDBox>
            </MDBox>
            <Collapse timeout={400} in={open}>
                <MDBox p={1} lineHeight={1}>
                    <MDTypography variant="button" color="text" opacity={0.8} fontWeight="regular">
                        {children}
                    </MDTypography>
                </MDBox>
            </Collapse>
        </MDBox>
    );
};

export default CollapseCard;
