import reportedLeads from 'assets/images/icons/reported-leads.png';

import validLeads from 'assets/images/icons/valid-leads.png';
import React from 'react';
import { stringFormatter } from 'utils/text-helpers';

export interface AccountManagerStatsTableItem {
    getAllCells: Function;
}

interface AccountManagerStatsTableProps {
    row: AccountManagerStatsTableItem;
    IDToHeaderMap: Record<string, string>;
}

const VirtualizedDataTableResponsive: React.FC<AccountManagerStatsTableProps> = ({ row, IDToHeaderMap }) => {
    return (
        <>
            <table className="table-v2-responsive-row admin-table-responsive">
                {row.getAllCells().map((tableCol) => {
                    if (tableCol.column.id === 'isCommissionedUser') {
                        return (
                            <tr key={tableCol.column.id}>
                                <td>{IDToHeaderMap?.[tableCol.column.id]}</td>
                                <td>
                                    {tableCol?.renderValue() ? (
                                        <img className="status-img" alt="Valid" src={validLeads} />
                                    ) : (
                                        <img className="status-img" alt="Reported" src={reportedLeads} />
                                    )}
                                </td>
                            </tr>
                        );
                    }
                    if (tableCol.column.id === 'activeClients') {
                        return (
                            <tr key={tableCol.column.id}>
                                <td>{IDToHeaderMap?.[tableCol.column.id]}</td>
                                <td>{tableCol?.renderValue() ? tableCol?.renderValue().length : '-'}</td>
                            </tr>
                        );
                    }
                    return (
                        <>
                            {IDToHeaderMap?.[tableCol.column.id] ? (
                                <tr>
                                    <td>{IDToHeaderMap?.[tableCol.column.id]}</td>
                                    <td>{tableCol?.renderValue() ? stringFormatter(6, tableCol.renderValue()) : ''}</td>
                                </tr>
                            ) : null}
                        </>
                    );
                })}
            </table>
        </>
    );
};

export default VirtualizedDataTableResponsive;
