import { Tooltip } from '@mui/material';
import { ExpandedState, flexRender, useReactTable } from '@tanstack/react-table';
import { createColumnHelper, getCoreRowModel } from '@tanstack/table-core';
import reportRejected from 'assets/images/icons/decline.png';
import reportAccepted from 'assets/images/icons/report-accepted.png';
import reportedLeads from 'assets/images/icons/reported-leads.png';

import validLeads from 'assets/images/icons/valid-leads.png';
import classNames from 'classnames';
import SpinningComponent from 'components/shared/SpinningComponent';
import { ILeadsLIstQuery, ILeadsListResponse } from 'contracts/requests/ILeadsListResponse';

import { LeadsBidData, LeadStatuses } from 'contracts/spotdif/LeadsBidData';
import React, { useCallback, useEffect, useMemo } from 'react';
import ExpandedRow from './ExpandedRow';
import LeadsResponsiveTable from './LeadsResponsiveTable';

interface TableProps {
    adminSerializedData: LeadsBidData[];
    query: ILeadsLIstQuery;
    setQuery: (arg: ILeadsLIstQuery) => void;
    adminLeadsData: ILeadsListResponse;
    loading?: boolean;
}

const columnHelper = createColumnHelper<LeadsBidData>();

const Table: React.FC<TableProps> = ({ adminSerializedData, query, setQuery, loading = false, adminLeadsData }) => {
    const [expanded, setExpanded] = React.useState<ExpandedState>({});
    const getIcon = useCallback((status: LeadStatuses) => {
        switch (status) {
            case LeadStatuses.valid:
                return (
                    <Tooltip title="Valid Lead" placement="top">
                        <img src={validLeads} alt="Valid" />
                    </Tooltip>
                );

            case LeadStatuses.reportAccepted:
                return (
                    <Tooltip title="Report Accepted" placement="top">
                        <img src={reportAccepted} alt="Accepted" />
                    </Tooltip>
                );

            case LeadStatuses.reportRejected:
                return (
                    <Tooltip title="Report Rejected" placement="top">
                        <img src={reportRejected} alt="Rejected" />
                    </Tooltip>
                );

            case LeadStatuses.reported:
                return (
                    <Tooltip title="Reported Lead" placement="top">
                        <img src={reportedLeads} alt="Reported" />
                    </Tooltip>
                );

            case LeadStatuses.archived:
                return (
                    <Tooltip title="Archived Lead" placement="top">
                        <img src={validLeads} alt="archived" className="archived-leads" />
                    </Tooltip>
                );

            default:
                return status;
        }
    }, []);

    const columns = useMemo(
        () => [
            columnHelper.accessor('status', {
                header: () => <span className="ml-3">Status</span>,
                cell: (info) => <span className="status_icon ml-3">{getIcon(info.getValue())}</span>,
            }),
            columnHelper.accessor('businessName', {
                header: 'Business Name',
            }),
            columnHelper.accessor('businessIndustry', {
                header: 'Business Industry',
            }),
            columnHelper.accessor('firstName', {
                header: 'First Name',
            }),
            columnHelper.accessor('lastName', {
                header: 'Last Name',
            }),
            columnHelper.accessor('phone', {
                header: 'Phone',
            }),
            columnHelper.accessor('_id', {
                header: '',
                cell: (info) => (
                    <>
                        <button
                            className={classNames('common-table-btn', { 'active-expand': info.row.getIsExpanded() })}
                        >
                            More Details
                        </button>
                    </>
                ),
            }),
        ],
        [],
    );

    const numberOfHeads = columns.length;

    const table = useReactTable({
        data: adminSerializedData ?? [],
        state: { expanded },
        columns,
        onExpandedChange: setExpanded,
        getCoreRowModel: getCoreRowModel(),
    });

    useEffect(() => {
        window.onscroll = function (ev) {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight && window.innerWidth < 769) {
                // you're at the bottom of the page, load more content here.
                query?.page <= query?.pageCount && setQuery({ ...query, perPage: query?.perPage * 1 + 10 });
            }
        };
    }, [query]);

    return (
        <>
            <div className="table-v2-block">
                <div className="table-list-scroller">
                    <table>
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <>
                                    <tr
                                        key={row.id}
                                        {...{
                                            onClick: () => {
                                                row.toggleExpanded();
                                            },
                                            style: { cursor: 'pointer' },
                                        }}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                    {row.getIsExpanded() ? (
                                        <>
                                            <ExpandedRow
                                                leadData={row.original}
                                                setExpanded={setExpanded}
                                                adminLeadsData={adminLeadsData}
                                            />
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ))}
                            {loading && (
                                <tr className="">
                                    <td colSpan={7} className="text-align-center">
                                        Loading...
                                    </td>{' '}
                                </tr>
                            )}

                            {table.getRowModel().rows.length === 0 && !loading && (
                                <tr style={{ width: '100%' }}>
                                    <td colSpan={numberOfHeads}>
                                        <div className="no-data">No Data Found</div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <SpinningComponent loading={loading}>
                <div className="table-v2-block table-v2-responsive">
                    {table.getRowModel().rows.map((row) => {
                        return <LeadsResponsiveTable row={row} getIcon={getIcon} adminLeadsData={adminLeadsData} />;
                    })}
                    {loading && <h4 className="table-loading">Loading...</h4>}
                    {adminSerializedData?.length <= 0 && <h4 className="table-loading">No data found</h4>}
                </div>
            </SpinningComponent>
        </>
    );
};

export default Table;
