import { ChatRoom } from 'contracts/chat/ChatRoom';
import { IGetRoomsResponse } from 'contracts/requests/IGetRoomsResponse';
import { TAG_CHAT_ROOMS } from 'contracts/spotDiffAPITags';
import { chatSystemsApi } from '.';

export const chatRoomsApi = chatSystemsApi.injectEndpoints({
    endpoints: (builder) => ({
        getRooms: builder.query<IGetRoomsResponse, { page?: number; searchTerm?: string }>({
            query: ({ page, searchTerm }) => ({
                url: `/rooms?page=${page}&search=${searchTerm}`,
                method: 'GET',
            }),
            providesTags: (result) => {
                return result
                    ? [
                          { type: TAG_CHAT_ROOMS, id: 'INDEX' },
                          ...result.data.map(({ _id }) => ({ type: TAG_CHAT_ROOMS, _id })),
                      ]
                    : [];
            },
        }),
        getRoomDetails: builder.query<ChatRoom, string>({
            query: (roomId) => ({
                url: `/rooms/${roomId}`,
                method: 'GET',
            }),
            providesTags: (result, error, roomId) => {
                return result ? [{ type: TAG_CHAT_ROOMS, id: roomId }] : [];
            },
        }),
    }),
});

export const { useGetRoomsQuery, useLazyGetRoomDetailsQuery } = chatRoomsApi;
