import useAuthentication from 'hooks/useAuthentication';
import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

export const GuestRoute = ({ element }: { element: React.JSX.Element }) => {
    const { isLoggedIn, isAdmin, isAccountManger, isLoadingUserDetails, isAccountAdmin } = useAuthentication();

    return useMemo(() => {
        if (isLoadingUserDetails) {
            // The user details are still loading, return null or a loading spinner
            return null;
        }

        return isLoggedIn ? (
            isAdmin || isAccountManger || isAccountAdmin ? (
                <Navigate to="/admin" />
            ) : (
                <Navigate to="/dashboard" />
            )
        ) : (
            element
        );
    }, [isLoadingUserDetails, isLoggedIn, isAdmin, element]);
};
