/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS Base Styles
import colors from 'assets/theme/base/colors';

// Material Dashboard 2 PRO React TS Helper Functions
import gradientChartLine from 'assets/theme/functions/gradientChartLine';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/themed/MDBox';
import { IChartRendererProps } from 'contracts/view-models/IChartRendererProps';
import React, { useEffect, useMemo, useRef, useState } from 'react';

// react-chartjs-2 components
import { Line } from 'react-chartjs-2';
import { ChartTitleBox } from '../chartTitleBox';

// GradientLineChart configurations
import configs from './configs';

const GradientLineChart: React.FC<IChartRendererProps> = ({ icon, title, description, height, chart }) => {
    const chartRef = useRef(null);
    const [chartData, setChartData] = useState({});
    const { data, options }: any = chartData;

    useEffect(() => {
        const chartDatasets = chart.datasets
            ? chart.datasets.map((dataset) => ({
                ...dataset,
                tension: 0,
                pointRadius: 0,
                borderWidth: 4,
                borderColor: colors[dataset.color] ? colors[dataset.color || 'dark'].main : colors.dark.main,
                fill: true,
                maxBarThickness: 6,
                backgroundColor: gradientChartLine(
                    chartRef.current.children[0],
                    colors[dataset.color] ? colors[dataset.color || 'dark'].main : colors.dark.main,
                ),
            }))
            : [];

        setChartData(configs(chart.labels || [], chartDatasets, title));
    }, [chart]);

    const renderChart = (
        <MDBox py={0} pr={0} pl={0}>
            {title || description ? (
                <ChartTitleBox description={description} component={icon.component} color={icon.color} title={title} />
            ) : null}

            {useMemo(
                () => (
                    <MDBox ref={chartRef}>
                        <Line data={data} options={options} />
                    </MDBox>
                ),
                [data, height, options],
            )}
        </MDBox>
    );

    return title || description ? <Card>{renderChart}</Card> : renderChart;
};

// Declaring default props for GradientLineChart
GradientLineChart.defaultProps = {
    icon: { color: 'info', component: '' },
    title: '',
    description: '',
    height: '19.125rem',
};

export default GradientLineChart;
