/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import { styled } from '@mui/material';

export default styled('span')(({ theme }) => {
    const { palette, typography, functions } = theme;

    const { white } = palette;
    const { size, fontWeightMedium } = typography;
    const { pxToRem } = functions;

    return {
        color: white.main,
        fontSize: size.xl,
        padding: `${pxToRem(9)} ${pxToRem(6)} ${pxToRem(8)}`,
        marginLeft: pxToRem(40),
        fontWeight: fontWeightMedium,
        cursor: 'pointer',
        lineHeight: 0,
    };
});
