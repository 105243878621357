import { Autocomplete, Tooltip } from '@mui/material';
import Switch from '@mui/material/Switch';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDInput from 'components/themed/MDInput';
import { EUserRole } from 'contracts/app-utils/EUserRole';
import { GetAccountManagerDropdownDataObject } from 'contracts/spotdif/AccountManager';
import { GetBusinessIndustryDropdownDataObject } from 'contracts/spotdif/BusinessIndustry';
import { GetFreeCreditsLinkResponseData } from 'contracts/spotdif/GetFreeCreditsLinkResponseData';
import { UserBase } from 'contracts/spotdif/UserBase';
import { Form, FormikProvider, useFormik } from 'formik';

import useAuthentication from 'hooks/useAuthentication';
import { useSnackbar } from 'notistack';
import React from 'react';
import * as Yup from 'yup';
import './style.scss';

interface IGenerateLinkProps {
    onSave: Function;
    accountManagerList: Array<GetAccountManagerDropdownDataObject>;
    businessIndustries?: Array<GetBusinessIndustryDropdownDataObject>;
    promoLinkData?: GetFreeCreditsLinkResponseData;
    isEditMode?: boolean;
    setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
    setPromoLinkContentId?: React.Dispatch<React.SetStateAction<string>>;
    promoLinkContentId?: string;
}

type DropdownOption = { label: string; value: string };

const GenerateLink: React.FC<IGenerateLinkProps> = ({
                                                        onSave,
                                                        accountManagerList,
                                                        businessIndustries,
                                                        promoLinkData,
                                                        isEditMode = false,
                                                        setActiveTab,
                                                        setPromoLinkContentId,
                                                        promoLinkContentId,
                                                    }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useAuthentication();

    const generateLinkForm = useFormik({
        initialValues: {
            freeCredits: promoLinkData?.freeCredits ?? 0,
            topUpAmount: promoLinkData?.topUpAmount ?? 0,
            discount: promoLinkData?.discount ?? 0,
            maxUseCounts: promoLinkData?.maxUseCounts ?? '',
            isUnlimitedAllowance: true,
            name: promoLinkData?.name ?? '',
            code: promoLinkData?.code ?? '',
            accountManager: promoLinkData?.accountManagerId[0]
                ? {
                    label: `${promoLinkData?.accountManagerId[0]?.firstName} ${promoLinkData?.accountManagerId[0]?.lastName}`,
                    value: promoLinkData?.accountManagerId[0]?._id,
                }
                : null,
            isAccountManager: user?.role === EUserRole.ACCOUNT_MANAGER ? true : false,
            businessIndustry: promoLinkData?.businessIndustry[0]
                ? {
                    label: `${promoLinkData?.businessIndustry[0]?.industry}`,
                    value: promoLinkData?.businessIndustry[0]?._id,
                }
                : null,
            isCommission: promoLinkData?.isCommission ?? false,
            giveCreditOnAddCard: promoLinkData?.giveCreditOnAddCard ?? false,
            firstCardBonusCredit: promoLinkData?.firstCardBonusCredit ?? 0,
            isFreeCreditEntered: false,
        },
        validationSchema: Yup.object().shape({
            freeCredits: Yup.number().when('discount', {
                is: (discount) => !discount,
                then: (schema) =>
                    schema
                        .min(1, 'Minimum amount should be 1')
                        .max(100, 'Maximum amount is 100')
                        .integer('Decimal Value not allowed')
                        .required('Free Leads is required'),
            }),

            topUpAmount: Yup.number().when('discount', {
                is: (discount) => !discount,
                then: (schema) =>
                    schema
                        .min(1, 'Minimum amount should be 1')
                        .max(100, 'Maximum amount is 100')
                        .integer('Decimal Value not allowed')
                        .required('Topup Leads is required'),
            }),
            discount: Yup.number().when('isFreeCreditEntered', {
                is: false,
                then: (schema) =>
                    schema
                        .min(1, 'Minimum value should be 1')
                        .max(100, 'Maximum limit is 100')
                        .required('Discount is required')
                        .integer('Decimal Value not allowed'),
            }),
            isUnlimitedAllowance: Yup.boolean(),
            isCommission: Yup.boolean().required('Commission Status is required'),

            maxUseCounts: Yup.number().when('isUnlimitedAllowance', {
                is: false,
                then: (schema) => schema.min(1, 'Minimum value should be 1').required('Max use counts is required'),
            }),
            name: Yup.string()
                .required('Promo Link Name is required')
                .min(3, 'Minimum 3 characters length')
                .max(100, 'Maximum limit is 100')
                .trim(),
            code: Yup.string()
                .min(3, 'Minimum 3 characters length')
                .max(100, 'Maximum limit is 100')
                .matches(/^\S*$/, 'Promo Code cannot contain spaces')
                .required('Promo Code is required'),
            accountManager: Yup.object()
                .nullable()
                .when('isAccountManager', (isAccountManager, schema) => {
                    if ((isAccountManager || [])[0] ?? []) {
                        return schema.required('Select an Account Manager');
                    }
                    return schema;
                }),
            giveCreditOnAddCard: Yup.boolean(),
            firstCardBonusCredit: Yup.number().when('giveCreditOnAddCard', {
                is: true,
                then: (schema) =>
                    schema.min(1, 'Minimum value should be 1').required('First card bonus credit is required'),
            }),
        }),
        onSubmit: async (values, formik) => {
            const { businessIndustry, discount, ...restValues } = values;

            const payload = {
                ...(values.accountManager
                    ? { ...restValues, accountManager: (values.accountManager as DropdownOption).value }
                    : restValues),
                businessIndustryId: (businessIndustry as DropdownOption)?.value ?? null,
                discount: discount || 0,
            };
            onSave(payload)
                .unwrap()
                .then((res) => {
                    console.log();
                    enqueueSnackbar('Promo Link generated successfully.', { variant: 'success', key: 'user-auth' });
                    setPromoLinkContentId(res?._id);
                    setActiveTab(1);
                })
                .catch((err) => {
                    enqueueSnackbar(err?.data?.error?.message, { variant: 'error', key: 'user-auth' });
                });
        },
    });

    return (
        <FormikProvider value={generateLinkForm}>
            <Form onSubmit={generateLinkForm.handleSubmit}>
                <MDBox>
                    <MDBox>
                        <div className="modal-heading-2">{isEditMode ? 'Update Link' : 'Generate Link'}</div>
                        <div className="form-groups">
                            <div className="form-input mb-0">
                                <MDInput
                                    type="text"
                                    label="Promo Link Name"
                                    placeholder="Promo Link Name"
                                    name="name"
                                    onBlur={generateLinkForm.handleBlur}
                                    onChange={generateLinkForm.handleChange}
                                    error={generateLinkForm.touched.name && Boolean(generateLinkForm.errors.name)}
                                    helperText={generateLinkForm.touched.name && generateLinkForm.errors.name}
                                    value={generateLinkForm?.values?.name}
                                    disabled={(!isEditMode && Boolean(promoLinkContentId)) || isEditMode}
                                />
                            </div>
                            <div className="form-input mb-0">
                                <MDInput
                                    type="text"
                                    label="Promo Code"
                                    placeholder="Promo Code"
                                    name="code"
                                    onBlur={generateLinkForm.handleBlur}
                                    onChange={generateLinkForm.handleChange}
                                    error={generateLinkForm.touched.code && Boolean(generateLinkForm.errors.code)}
                                    helperText={generateLinkForm.touched.code && generateLinkForm.errors.code}
                                    value={generateLinkForm?.values?.code}
                                    disabled={(!isEditMode && Boolean(promoLinkContentId)) || isEditMode}
                                />
                            </div>
                        </div>

                        <div className="form-groups">
                            <div className="form-input mb-0">
                                <MDInput
                                    type="number"
                                    label="Free Leads"
                                    placeholder="Free Leads"
                                    onBlur={(e) => {
                                        generateLinkForm.handleBlur(e);
                                        if (e.target.value !== '' && !isNaN(e.target.value)) {
                                            generateLinkForm.setFieldValue('isFreeCreditEntered', true);
                                        }
                                    }}
                                    name="freeCredits"
                                    onChange={generateLinkForm.handleChange}
                                    error={
                                        generateLinkForm.touched.freeCredits &&
                                        Boolean(generateLinkForm.errors.freeCredits)
                                    }
                                    helperText={
                                        generateLinkForm.touched.freeCredits && generateLinkForm.errors.freeCredits
                                    }
                                    value={generateLinkForm?.values?.freeCredits}
                                    disabled={(!isEditMode && Boolean(promoLinkContentId)) || isEditMode}
                                />
                            </div>

                            <div className="form-input mb-0">
                                <MDInput
                                    type="number"
                                    label="Topup Leads"
                                    placeholder="Topup Leads"
                                    onBlur={generateLinkForm.handleBlur}
                                    name="topUpAmount"
                                    onChange={generateLinkForm.handleChange}
                                    error={
                                        generateLinkForm.touched.topUpAmount &&
                                        Boolean(generateLinkForm.errors.topUpAmount)
                                    }
                                    helperText={
                                        generateLinkForm.touched.topUpAmount && generateLinkForm.errors.topUpAmount
                                    }
                                    value={generateLinkForm?.values?.topUpAmount}
                                    disabled={(!isEditMode && Boolean(promoLinkContentId)) || isEditMode}
                                />
                            </div>
                        </div>

                        <div className="form-groups">
                            <div className="form-input mb-0">
                                <Autocomplete
                                    id="accountManager"
                                    data-name="accountManager"
                                    className="spotdif-field w-50 dropdown-input"
                                    value={generateLinkForm.values.accountManager}
                                    onChange={(e, value: unknown) => {
                                        if (!value) {
                                            generateLinkForm.setFieldValue('isCommission', false);
                                        }
                                        generateLinkForm.setFieldValue('accountManager', value);
                                    }}
                                    onBlur={generateLinkForm.handleBlur}
                                    options={accountManagerList?.map((item: UserBase) => {
                                        return {
                                            label: `${item.firstName} ${item.lastName || ''}`,
                                            value: item._id,
                                        } as DropdownOption;
                                    })}
                                    renderInput={(params) => (
                                        <MDInput
                                            {...params}
                                            isBordered={true}
                                            name="accountManager"
                                            value={generateLinkForm.values.accountManager}
                                            sx={{ padding: 0 }}
                                            label={'Account Manager'}
                                            placeholder="Account Manager"
                                            error={
                                                generateLinkForm.touched.accountManager &&
                                                Boolean(generateLinkForm.errors.accountManager)
                                            }
                                            helperText={
                                                generateLinkForm.touched.accountManager &&
                                                generateLinkForm.errors.accountManager
                                            }
                                        />
                                    )}
                                    disabled={(!isEditMode && Boolean(promoLinkContentId)) || isEditMode}
                                />
                            </div>
                        </div>
                        <div className="form-groups">
                            <div className="form-input mb-0">
                                <Autocomplete
                                    id="businessIndustry"
                                    data-name="businessIndustry"
                                    className="spotdif-field w-50 dropdown-input"
                                    value={generateLinkForm.values.businessIndustry}
                                    onChange={(e, value) => {
                                        generateLinkForm.setFieldValue('businessIndustry', value);
                                    }}
                                    onBlur={generateLinkForm.handleBlur}
                                    options={businessIndustries?.map((item) => {
                                        return {
                                            label: item.industry,
                                            value: item._id,
                                        } as DropdownOption;
                                    })}
                                    renderInput={(params) => (
                                        <MDInput
                                            {...params}
                                            isBordered={true}
                                            name="businessIndustry"
                                            value={generateLinkForm.values.businessIndustry}
                                            sx={{ padding: 0 }}
                                            label={'Business Industry'}
                                            placeholder="Business Industry"
                                            error={
                                                generateLinkForm.touched.businessIndustry &&
                                                Boolean(generateLinkForm.errors.businessIndustry)
                                            }
                                            helperText={
                                                generateLinkForm.touched.businessIndustry &&
                                                generateLinkForm.errors.businessIndustry
                                            }
                                        />
                                    )}
                                    disabled={(!isEditMode && Boolean(promoLinkContentId)) || isEditMode}
                                />
                            </div>
                        </div>
                        <div className="form-groups">
                            <div className="form-input mb-0">
                                <MDInput
                                    type="number"
                                    label="Discount on CPL (in %)"
                                    placeholder="Enter discount"
                                    onBlur={generateLinkForm.handleBlur}
                                    name="discount"
                                    onChange={generateLinkForm.handleChange}
                                    error={
                                        generateLinkForm.touched.discount && Boolean(generateLinkForm.errors.discount)
                                    }
                                    helperText={generateLinkForm.touched.discount && generateLinkForm.errors.discount}
                                    value={generateLinkForm?.values?.discount}
                                    disabled={(!isEditMode && Boolean(promoLinkContentId)) || isEditMode}
                                />
                            </div>
                        </div>

                        {generateLinkForm.values.accountManager?.label && (
                            <div className="form-input mb-15 ">
                                <label> Commission Status:</label>
                                <Tooltip
                                    title={generateLinkForm.values.isCommission ? 'Commissioned' : 'Non- Commissioned'}
                                    placement="top"
                                >
                                    <Switch
                                        name="isCommission"
                                        onChange={generateLinkForm.handleChange}
                                        onBlur={generateLinkForm.handleBlur}
                                        defaultChecked={generateLinkForm.values.isCommission}
                                        disabled={(!isEditMode && Boolean(promoLinkContentId)) || isEditMode}
                                    />
                                </Tooltip>
                            </div>
                        )}
                        <div className="form-input mb-15">
                            <label>First Card Bonus</label>
                            <Tooltip
                                title={generateLinkForm.values.giveCreditOnAddCard ? 'Active' : 'Inactive'}
                                placement="top"
                            >
                                <Switch
                                    name="giveCreditOnAddCard"
                                    onChange={generateLinkForm.handleChange}
                                    onBlur={generateLinkForm.handleBlur}
                                    defaultChecked={generateLinkForm.values.giveCreditOnAddCard}
                                    disabled={(!isEditMode && Boolean(promoLinkContentId)) || isEditMode}
                                />
                            </Tooltip>
                        </div>
                        <div className="full-width-field">
                            {generateLinkForm.values.giveCreditOnAddCard && (
                                <MDInput
                                    type="number"
                                    label="First card bonus credit"
                                    placeholder="First card bonus credit"
                                    onBlur={generateLinkForm.handleBlur}
                                    name="firstCardBonusCredit"
                                    onChange={generateLinkForm.handleChange}
                                    error={
                                        generateLinkForm.touched.firstCardBonusCredit &&
                                        Boolean(generateLinkForm.errors.firstCardBonusCredit)
                                    }
                                    helperText={
                                        generateLinkForm.touched.firstCardBonusCredit &&
                                        generateLinkForm.errors.firstCardBonusCredit
                                    }
                                    value={generateLinkForm?.values?.firstCardBonusCredit}
                                    disabled={(!isEditMode && Boolean(promoLinkContentId)) || isEditMode}
                                />
                            )}
                        </div>

                        <div className="form-input mb-15">
                            <label>
                                Max Allowance: <span className="badge">Unlimited</span>
                            </label>
                            <Tooltip
                                title={generateLinkForm.values.isUnlimitedAllowance ? 'Active' : 'Inactive'}
                                placement="top"
                            >
                                <Switch
                                    name="isUnlimitedAllowance"
                                    onChange={generateLinkForm.handleChange}
                                    onBlur={generateLinkForm.handleBlur}
                                    defaultChecked={generateLinkForm.values.isUnlimitedAllowance}
                                    disabled={(!isEditMode && Boolean(promoLinkContentId)) || isEditMode}
                                />
                            </Tooltip>
                        </div>
                        <div className="full-width-field">
                            {!generateLinkForm.values.isUnlimitedAllowance && (
                                <MDInput
                                    type="number"
                                    label="Max use counts"
                                    placeholder="Max use counts"
                                    onBlur={generateLinkForm.handleBlur}
                                    name="maxUseCounts"
                                    onChange={generateLinkForm.handleChange}
                                    error={
                                        generateLinkForm.touched.maxUseCounts &&
                                        Boolean(generateLinkForm.errors.maxUseCounts)
                                    }
                                    helperText={
                                        generateLinkForm.touched.maxUseCounts && generateLinkForm.errors.maxUseCounts
                                    }
                                    value={generateLinkForm?.values?.maxUseCounts}
                                    disabled={(!isEditMode && Boolean(promoLinkContentId)) || isEditMode}
                                />
                            )}
                        </div>
                    </MDBox>
                </MDBox>
                <MDButton
                    className="button modal-button w-100 mt-2"
                    type="submit"
                    disabled={(!isEditMode && Boolean(promoLinkContentId)) || isEditMode}
                >
                    {isEditMode ? 'Update Link' : 'Generate Link'}
                </MDButton>
            </Form>
        </FormikProvider>
    );
};
export default GenerateLink;
