import { Checkbox } from '@mui/material';
import { TimeField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { BusinessDetailsDataObject } from 'contracts/spotdif/BusinessDetailsDataObject';
import { DaySchedule } from 'contracts/spotdif/DaySchedule';
import { LeadDetails } from 'contracts/spotdif/LeadDetails';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { timeToDayJS } from 'utils/dateHelpers';
import './style.scss';

interface BusinessOpeningHoursProps {
    fieldName?: string;
    isHoursVisible?: boolean;
}

const BusinessOpeningHours: React.FC<BusinessOpeningHoursProps> = ({
    fieldName = 'businessOpeningHours',
    isHoursVisible = false,
}) => {
    const { values, setFieldValue, setFieldTouched } = useFormikContext();

    const formikValues = useMemo<LeadDetails | BusinessDetailsDataObject>(() => {
        if (Object.keys(values).includes('leadSchedule')) {
            return values as LeadDetails;
        } else {
            return values as BusinessDetailsDataObject;
        }
    }, [values]);

    return (
        <div className="buisness-oepning-hours-wrapper">
            {(formikValues[fieldName] as DaySchedule[])
                .sort((a, b) => {
                    const dayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                    return dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day);
                })
                .map((item, index) => {
                    return (
                        <div key={index.toString()} className="buisness-oepning-hours-row">
                            <label>
                                <Checkbox
                                    checked={!!formikValues[fieldName][index]?.isActive}
                                    name={`${fieldName}[${index}].isActive`}
                                    value={formikValues[fieldName][index]?.isActive}
                                    onChange={(e) => {
                                        setFieldTouched(`${fieldName}[${index}].isActive`);
                                        setFieldValue(`${fieldName}[${index}].isActive`, !!e.target.checked);
                                    }}
                                />
                                <span className="check-label">{formikValues[fieldName][index]?.day}</span>
                            </label>
                            {isHoursVisible && (
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                    <div className="input-cols">
                                        <TimeField
                                            name={`${fieldName}.${index}.openTime`}
                                            className="spotdif-field"
                                            value={formikValues[fieldName][index]?.openTime}
                                            onBlur={(e) => {
                                                setFieldTouched(`${fieldName}[${index}].openTime`);
                                                setFieldValue(
                                                    `${fieldName}[${index}].openTime`,
                                                    timeToDayJS(e.target.value),
                                                );
                                            }}
                                            format="HH:mm"
                                        />

                                        <span className="hyphen">-</span>

                                        <TimeField
                                            name={`${fieldName}.${index}.closeTime`}
                                            value={formikValues[fieldName][index]?.closeTime}
                                            className="spotdif-field"
                                            onBlur={(e) => {
                                                setFieldTouched(`${fieldName}[${index}].closeTime`);
                                                setFieldValue(
                                                    `${fieldName}[${index}].closeTime`,
                                                    timeToDayJS(e.target.value),
                                                );
                                            }}
                                            format="HH:mm"
                                        />
                                    </div>
                                </LocalizationProvider>
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

export default BusinessOpeningHours;
