import './style.scss';
import MDInput from 'components/themed/MDInput';
import { GetBusinessIndustryResponseDataObject } from 'contracts/spotdif/GetBusinessIndustryResponseDataObject';
import { FieldArray, useFormikContext } from 'formik';
import React, { useCallback } from 'react';

const BuyerQuestions: React.FC = () => {
    const { values, handleBlur, touched, errors, setFieldValue } =
        useFormikContext<Partial<GetBusinessIndustryResponseDataObject>>();

    const handleQuestionTitleChange = useCallback((value, questionIndex) => {
        const updatedBuyerQuestions = values.buyerQuestions.map((question, index) => {
            if (index === questionIndex) {
                return { ...question, title: value };
            }
            return question;
        });
        setFieldValue('buyerQuestions', updatedBuyerQuestions);
    }, [setFieldValue, values.buyerQuestions]);

    const handleQuestionColumnNamwChange = useCallback((value, questionIndex) => {
        const updatedBuyerQuestions = values.buyerQuestions.map((question, index) => {
            if (index === questionIndex) {
                return { ...question, columnName: value };
            }
            return question;
        });
        setFieldValue('buyerQuestions', updatedBuyerQuestions);
    }, [setFieldValue, values.buyerQuestions]);

    // TODO: Will be required later.
    // const handleAddQuestion = () => {
    //     const newQuestion = { title: '' };
    //     const newBuyerQuestions = [...values.buyerQuestions, newQuestion];
    //     setFieldValue('buyerQuestions', newBuyerQuestions);
    // };

    return (
        <FieldArray
            name="columns"
            render={(arrayHelpers) => (
                <>
                    {values?.buyerQuestions?.map((question, index) => {
                        return (
                            <div className="form-groups">
                                <div className="form-input mb-0 buyer-question-wrapper" key={index}>
                                    <MDInput
                                        type="text"
                                        label={`Column Name ${index + 1}`}
                                        placeholder="Enter Column Name"
                                        name={`question-column-${index}`}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleQuestionColumnNamwChange(e.target.value, index);
                                        }}
                                        value={question?.columnName}
                                        error={
                                            touched.buyerQuestions?.[index]?.columnName &&
                                            Boolean(errors.buyerQuestions?.[index])
                                        }
                                        helperText={
                                            touched.buyerQuestions?.[index]?.columnName &&
                                            // @ts-ignore
                                            errors.buyerQuestions?.[index]?.columnName
                                        }
                                    />
                                    <MDInput
                                        type="text"
                                        label={`Buyer question ${index + 1}`}
                                        placeholder="Enter title"
                                        name={`question-${index}`}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleQuestionTitleChange(e.target.value, index);
                                        }}
                                        value={question?.title}
                                        error={
                                            touched.buyerQuestions?.[index]?.title &&
                                            Boolean(errors.buyerQuestions?.[index])
                                        }
                                        helperText={
                                            touched.buyerQuestions?.[index]?.title &&
                                            // @ts-ignore
                                            errors.buyerQuestions?.[index]?.title
                                        }
                                    />

                                    {/* TODO: Will be required later.
                                     <div
                                        className={classNames('buyer-question-delete-icon delete-icon', {
                                            'delete-icon-disabled': values.buyerQuestions.length <= 1,
                                        })}
                                        onClick={() => values.buyerQuestions.length > 1 && arrayHelpers.remove(index)}
                                    >
                                        <DeleteIcon />
                                    </div> */}
                                </div>
                            </div>
                        );
                    })}
                    {/*
                    TODO: Will be required later.
                    <div className="spotdif-field w-100">
                        <div className="addTeamMembersBtn" onClick={() => handleAddQuestion()}>
                            <span> Add </span>
                            <img src={addIcon} className="addTeamMemberIcon" alt="add icon" />
                        </div>
                    </div> */}
                </>
            )}
        />
    );
};

export default BuyerQuestions;
