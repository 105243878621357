import { ICertifiedSupplierRequest, ICertifiedSupplierResponse } from 'contracts/requests/ICertifiedSupplierRequest';
import { TAG_GET_CERTIFIED_SUPPLIER } from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';

const certifiedSupplierApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getCertifiedSupplier: builder.query<ICertifiedSupplierResponse, ICertifiedSupplierRequest>({
            providesTags: [TAG_GET_CERTIFIED_SUPPLIER],
            query: (body) => {
                return {
                    url: `/supplier-badges/badges-credits`,
                    method: 'POST',
                    body,
                };
            },
        }),
    }),
});

export const { useGetCertifiedSupplierQuery } = certifiedSupplierApi;
