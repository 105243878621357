import { Tooltip } from '@mui/material';
import React from 'react';
import { stringFormatter } from 'utils/text-helpers';

interface StringFormatterProps {
    length?: number;
    text: string;
    suffix?: string;
}

export const StringFormatter: React.FC<StringFormatterProps> = ({
                                                                    length = 20, text, suffix,
                                                                }) => {
    const trimmed = stringFormatter(length, text, suffix);

    return (
        <Tooltip
            title={text}
            placement="top"
        >
            <span>{trimmed ? trimmed : '-'}</span>
        </Tooltip>
    );
};


export default StringFormatter;
