import BasicModal from 'components/core/Modal';
import AutoChargeSettingSection from 'components/creditAndBilling/AutoChargeSettingSection';
import BuyLeadManuallySection from 'components/creditAndBilling/BuyLeadManuallySection';
import PaymentCardSection from 'components/creditAndBilling/PaymentCardSection';
import PaymentMethodSection from 'components/creditAndBilling/PaymentMethodSection';
import ProcessingPaymentSection from 'components/creditAndBilling/ProcessingPaymentSection';
import RemainingLeadSection from 'components/creditAndBilling/RemainingLeadSection';
import StripePayment from 'components/stripe-payment';
import { SessionHandlerContext } from 'context/RfytSessionContext';
import { EVENT_ACTION, EVENT_CATEGORY, useUserAnalytics } from 'context/UserAnalytics';
import './style.scss';
import { EUserRole } from 'contracts/app-utils/EUserRole';
import { IAddCreditsRequest, IAddCreditsResponse } from 'contracts/requests/IAddCreditsRequest';
import { UserCard, UserCardSerializer } from 'contracts/spotdif/BaseCardData';
import { RetrievePaymentDetailsDataSerializer } from 'contracts/spotdif/RetrievePaymentDetailsData';
import { FormikProvider, useFormik } from 'formik';
import useAuthentication from 'hooks/useAuthentication';
import useGlobalMinimumTopupLeads from 'hooks/useGlobalMinimumTopupLeads';
import useIndustryMinimumTopupLeads from 'hooks/useIndustryMinimumTopupLeads';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
    useAddCreditsManuallyMutation,
    useGetUserCardDetailsQuery,
    useUpdatePaymentMethodMutation,
} from 'redux/services/spotdif/creditAndBilling';
import { useCurrentUserDataQuery } from 'redux/services/spotdif/user';
import { ErrorCode } from 'utils/stripeErrorCodes';
import * as Yup from 'yup';
import BuyContactCredits from './BuyContactCredits';

const CreditAndBilling: React.FC = () => {
    const { user } = useAuthentication();
    const { minimumLeadConfigAsPerAdmin } = useGlobalMinimumTopupLeads();
    const { industryMinTopupLeads } = useIndustryMinimumTopupLeads();
    const routerLocation = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(routerLocation.search), [routerLocation.search]);
    const [searchParams, setSearchParams] = useSearchParams();

    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [openCreditModal, setOpenCreditModal] = useState(false);

    const handleClose = () => setOpen(false);

    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const handleopenModal = () => setOpenPaymentModal(true);
    const handleCloseModal = () => setOpenPaymentModal(false);

    const handleOpenCreditModal = () => setOpenCreditModal(true);
    const { sessionId, setSessionId, isChecking, waitingSince, setSession, setPaymentMethod } =
        useContext(SessionHandlerContext);

    const [updatePaymentMethodTrigger] = useUpdatePaymentMethodMutation();
    const [addCreditsManuallyTrigger, { isLoading: isAddingCredits, data: creditDataResponse }] =
        useAddCreditsManuallyMutation();

    const {
        isLoading: isLoadingUserCardDetails,
        data: userCardDetails,
        userCards,
    } = useGetUserCardDetailsQuery(user?._id, {
        selectFromResult: ({ isLoading, data }) => {
            let userCards: UserCard[] = [];
            if (!isLoading && data?.data) {
                userCards = data.data.map((c) => UserCardSerializer.parse(c)).sort((cA, cB) => cB.order - cA.order);
            }

            return {
                isLoading,
                data,
                userCards,
            };
        },
    });

    // Formik for Add Credit Manually
    const addCredits = useFormik({
        initialValues: {
            amount: '',
        },

        validationSchema: Yup.object().shape({
            amount: Yup.number()
                .required('leads are required')
                .positive('Negative values are not allowed')
                .integer('Decimal value not allowed')
                .min(
                    industryMinTopupLeads ? industryMinTopupLeads : minimumLeadConfigAsPerAdmin,
                    `Minimum ${industryMinTopupLeads ? industryMinTopupLeads : minimumLeadConfigAsPerAdmin} leads`,
                ),
            // .test(
            //     "minMultipleOfLeadCost",
            //     `Minimum amount should be 5 times lead cost i.e ${user?.leadCost * 5}`,
            //     function (value) {
            //         const leadCost = user?.leadCost;
            //         return value >= leadCost * 5;
            //     }
            // )
            // .test("multipleOfLeadCost", `Value must be a multiple of ${user?.leadCost}`, function (value) {
            //     const leadCost = user?.leadCost;
            //     return value % leadCost === 0;
            // }),
        }),

        onSubmit: async (values) => {
            const amountAsNumber = Number(values.amount) * user?.leadCost;
            if (isNaN(amountAsNumber)) {
                console.error('Invalid amount entered');
            } else {
                if (userCards.length <= 0) {
                    enqueueSnackbar('No Card Found', { variant: 'error', key: 'pending-card' });
                    return;
                }
                userCards
                    .filter((card) => card.isDefault)
                    .map((card) => {
                        if (card.isDefault && card.status === 'success') {
                            return handleAddCreditsManually({ ...values, amount: Number(amountAsNumber.toFixed(2)) });
                        } else {
                            enqueueSnackbar('Card is not yet Verified', {
                                variant: 'error',
                                key: 'pending-card',
                            });

                            return Promise.resolve();
                        }
                    });
            }
        },
    });

    const handlePaymentMethod = async (request) => {
        updatePaymentMethodTrigger(request)
            .unwrap()
            .then((res) => {
                enqueueSnackbar(res?.message, { variant: 'success', key: 'auto-charge' });
            })
            .catch((err) => {
                enqueueSnackbar(err.data?.error?.message, { variant: 'error' });
            });
    };
    const { trackEvent } = useUserAnalytics();

    const handleAddCreditsManually = async (request: IAddCreditsRequest) => {
        // intialized
        trackEvent(EVENT_ACTION.INITIATED, {
            category: EVENT_CATEGORY.CREDITS_TOPUP,
            label: EVENT_CATEGORY.CREDITS_TOPUP,
            value: request.amount as number,
        });

        addCreditsManuallyTrigger(request)
            .unwrap()
            .then((res: IAddCreditsResponse) => {
                const response = RetrievePaymentDetailsDataSerializer.parse(res.data);
                if (response.needsManualPayment) {
                    handleOpenCreditModal();
                }

                if (+res?.data?.status === 302 && res?.data?.url) {
                    window.open(res?.data?.url);
                    return;
                }

                // completd
                // enqueueSnackbar(res?.data?.message, { variant: "success" });
                if (res?.data?.sessionID === undefined) {
                    setSessionId(null);
                } else {
                    setSessionId(res.data.sessionID);
                    setPaymentMethod(res.data.paymentMethods);
                }

                addCredits.resetForm();
                trackEvent(EVENT_ACTION.COMPLETED, {
                    category: EVENT_CATEGORY.CREDITS_TOPUP,
                    label: EVENT_CATEGORY.CREDITS_TOPUP,
                    value: request.amount as number,
                });
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar(
                    err?.data?.data?.errors[0]?.message ?? err?.data?.error?.message ?? 'Something went Wrong',
                    { variant: 'error' },
                );
            });
    };

    // Formik for Autocharge
    const autoCharge = useFormik({
        initialValues: {
            triggerAmount: user?.normalizedTriggerAmount,
            topupAmount: user?.normalizedTopupAmount,
            // triggerAmount: user?.credits && user?.leadCost > 0 ? Math.round(user?.triggerAmount / user?.leadCost) : 5,
            // topupAmount: user?.credits && user?.leadCost > 0 ? Math.round(user?.autoChargeAmount / user?.leadCost) : 50,
        },

        validationSchema: Yup.object().shape({
            triggerAmount: Yup.number()
                .required('Remaining leads is required')
                .positive('Negative values are not allowed')
                .integer('Decimal Value not allowed ')
                .min(5, 'Minimum 5 leads'),
            // .max(1000000, "Maximium amount is 100000")
            // .test("multipleOfLeadCost", `minimum amount is ${user?.leadCost}`, function (value) {
            //     const leadCost = user?.leadCost;
            //     return value >= leadCost;
            // }),
            topupAmount: Yup.number()
                .required('Leads is required')
                .positive('Negative values are not allowed')
                .integer('Decimal Value not allowed ')
                .min(
                    industryMinTopupLeads ? industryMinTopupLeads : minimumLeadConfigAsPerAdmin,
                    `Minimum ${industryMinTopupLeads ? industryMinTopupLeads : minimumLeadConfigAsPerAdmin} leads`,
                ),
            // .test(
            //     "minMultipleOfLeadCost",
            //     `Minimum amount should be 5 times lead cost i.e ${user?.leadCost * 5}`,
            //     function (value) {
            //         const leadCost = user?.leadCost;
            //         return value >= leadCost * 5;
            //     }
            // )
            // .test("multipleOfLeadCost", `Value must be a multiple of ${user?.leadCost}`, function (value) {
            //     const leadCost = user?.leadCost;
            //     return value % leadCost === 0;
            // }),
        }),

        onSubmit: async (values) => {
            const triggerAmount = values.triggerAmount * user?.leadCost;
            const topupAmount = values.topupAmount * user?.leadCost;
            if (!isNaN(triggerAmount) && !isNaN(topupAmount)) {
                if (userCards.length <= 0) {
                    return enqueueSnackbar('No Card Found', { variant: 'error', key: 'pending-card' });
                }
                // userCards.map((card, index) => {
                //     if (card.isDefault && card.status === "success") {
                //         handlePaymentMethod({ triggerAmount, autoChargeAmount: topupAmount, _id: user?._id });
                //     } else {
                //         enqueueSnackbar("Card is not yet Verified", { variant: "error", key: "pending-card" });
                //     }
                // });

                userCards
                    .filter((card) => card.isDefault)
                    .map((card) => {
                        if (card.isDefault && card.status === 'success') {
                            handleAutoCharge(true);
                            return handlePaymentMethod({
                                triggerAmount: Number(triggerAmount.toFixed(2)),
                                autoChargeAmount: Number(topupAmount.toFixed(2)),
                                _id: user?._id,
                            });
                        } else {
                            return enqueueSnackbar('Card is not yet Verified', {
                                variant: 'error',
                                key: 'pending-card',
                            });
                        }
                    });
            } else {
                console.error('Invalid amount entered');
            }
        },
    });

    const handleAutoCharge = (isAutoChargeEnabled: boolean) => {
        const modifiedRequest = {
            isAutoChargeEnabled: isAutoChargeEnabled,
            _id: user?._id,
        };

        updatePaymentMethodTrigger(modifiedRequest)
            .unwrap()
            .then((res) => {
                return enqueueSnackbar(res?.message, { variant: 'success', key: 'auto-charge' });
            })
            .catch((error) => {
                return enqueueSnackbar(error?.message || 'Something went wrong', { variant: 'success' });
            });
    };

    useEffect(() => {
        const messageFromQueryParam = queryParams.get('status_code');
        messageFromQueryParam &&
            enqueueSnackbar(ErrorCode[messageFromQueryParam] ?? 'something went wrong', {
                variant: 'error',
                key: 'stripe-error',
            });
        if (searchParams.has('status_code')) {
            searchParams.delete('status_code');
            setSearchParams(searchParams);
        }
    }, [enqueueSnackbar, queryParams, searchParams, setSearchParams]);

    const { data: userData } = useCurrentUserDataQuery();

    const isUserInvited = useMemo(() => {
        return userData?.data?.role === EUserRole.INVITED;
    }, [userData]);
    return (
        <>
            <Helmet>
                <title>Credit</title>
            </Helmet>
            {isUserInvited && (
                <div className="contact-primary-user-message-container">
                    <div>{'To make changes contact primary user.'}</div>
                </div>
            )}
            <div className="credit-billing-div">
                <PaymentCardSection
                    userCards={userCards}
                    isLoadingUserCardDetails={isLoadingUserCardDetails}
                    userCardDetails={userCardDetails}
                    handleopenModal={handleopenModal}
                />

                <div className="user-card-deatils-section">
                    <h6>Buy contact credits</h6>
                    <BuyContactCredits />
                </div>

                {/* <div className="user-card-deatils-section">
                    <h6>Lead billing</h6>

                    <RemainingLeadSection />

                    <PaymentMethodSection />

                    {user?.paymentMethod === 'add credits manually' && (
                        <div className="add-credit-details add-credit-details-custom">
                            <h6>Buy leads</h6>
                            <div className="credit-form">
                                <FormikProvider value={addCredits}>
                                    <BuyLeadManuallySection isAddingCredits={isAddingCredits} />
                                </FormikProvider>
                            </div>
                        </div>
                    )}

                    {user?.paymentMethod === 'auto charge' && (
                        <div className="add-credit-details add-credit-details-custom">
                            <h6>Autocharge settings</h6>
                            <div className="credit-form">
                                <FormikProvider value={autoCharge}>
                                    <AutoChargeSettingSection isAddingCredits={isAddingCredits} />
                                </FormikProvider>
                            </div>
                        </div>
                    )}
                </div> */}
            </div>
            <div>
                <ProcessingPaymentSection />
            </div>
            <BasicModal open={openPaymentModal} handleClose={handleCloseModal}>
                <StripePayment closeModal={handleCloseModal} modalOpen={openPaymentModal} />
            </BasicModal>
        </>
    );
};

export default CreditAndBilling;
