import WithDateRangePicker from 'components/analytics/date-ranged-data';

import VerticalBarChart from 'components/shared/charts/VerticalBarChart';
import { useDashboardStatsContext } from 'context/DashboadStatsContext';
import { IChartData } from 'contracts/view-models/IChartData';
import { IDateRangedChartProps } from 'contracts/view-models/IDateRangedChartProps';
import React, { useEffect, useMemo } from 'react';
import { chartColor } from 'utils/bg-color';

interface NoOfTopupChartProps extends IDateRangedChartProps {
    // setQuery: any,
}

const NoOfTopupChart: React.FC<NoOfTopupChartProps> = ({ range }) => {
    const { query, setQuery, data } = useDashboardStatsContext();
    useEffect(() => {
        setQuery({
            ...query,
            startDate: range.startDate,
            endDate: range.endDate,
        });
    }, [range]);

    const topUpNoData = useMemo<IChartData>(() => {
        return {
            labels: ['Account Managers'],
            datasets: [
                ...(data?.map((res, index) => ({
                    label: res.name,
                    color:
                        chartColor.length <= index
                            ? chartColor[Math.ceil(index / chartColor.length) - 1]
                            : chartColor[index],
                    data: [res.creditsCount],
                })) ?? []),
            ],
        };
    }, [data]);

    return (
        <>
            <VerticalBarChart title="No. of top-up" description="" chart={topUpNoData} />
        </>
    );
};

export default WithDateRangePicker(NoOfTopupChart);
