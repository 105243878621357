import WithDateRangePicker from 'components/analytics/date-ranged-data';
import GradientLineChart from 'components/shared/charts/GradientLineChart';
import { IChartData } from 'contracts/view-models/IChartData';
import { IDateRangedChartProps } from 'contracts/view-models/IDateRangedChartProps';
import React, { useMemo } from 'react';
import { useGetRightDashboardChartDataQuery } from 'redux/services/spotdif/stats';

interface ILeadVolumeChartProps extends IDateRangedChartProps {}

const LeadVolumeChart: React.FC<ILeadVolumeChartProps> = ({ range }) => {
    const { data: dataFromApi } = useGetRightDashboardChartDataQuery({
        start: range.startDate.toISOString(),
        end: range.endDate.toISOString(),
    });

    const gradientLineChartData = useMemo<IChartData>(() => {
        let dates = [];
        let data = [];

        dataFromApi?.data?.forEach((item) => {
            dates.push(item?.date);
            data.push(item?.count);
        });

        return {
            labels: dates,
            datasets: [
                {
                    label: 'Count',
                    color: 'dark',
                    data: data,
                },
            ],
        };
    }, [dataFromApi]);

    return (
        <>
            <GradientLineChart title="Lead Volume" description="" chart={gradientLineChartData} />
        </>
    );
};

export default WithDateRangePicker(LeadVolumeChart);
