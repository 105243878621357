import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import { Icon } from '@mui/material';

import { CellContext, createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import classNames from 'classnames';
import CurrencySymbol from 'components/shared/CurrencyFormat';
import MDButton from 'components/themed/MDButton';
import { TransactionData } from 'contracts/spotdif/TransactionData';
import dayjs from 'dayjs';
import useAuthentication from 'hooks/useAuthentication';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import {
    useGenerateXeroInvoiceMutation,
    useGetTransactionsTableDataQuery,
    useLazyGetDownloadInvoicePdfQuery,
} from 'redux/services/spotdif/creditAndBilling';

const columnHelper = createColumnHelper<TransactionData>();

interface ITableProps {
    userId?: string;
}

const TransactionTable: React.FC<ITableProps> = ({ userId }) => {
    const { isLoading, data: transactionData } = useGetTransactionsTableDataQuery(userId ? { id: userId } : {});
    const { user } = useAuthentication();

    const { enqueueSnackbar } = useSnackbar();
    const [transactionId, setTransactionId] = useState<string>('');
    const [generateXeroInvoiceMutation, { isLoading: xeroLoading, data: xeroData }] = useGenerateXeroInvoiceMutation();

    const [getInvoiceLink] = useLazyGetDownloadInvoicePdfQuery();

    const [data, setData] = useState(() => (transactionData ? [...transactionData] : []));
    const invoicesButtonClick = async (value) => {
        getInvoiceLink(value)
            .unwrap()
            .then((pdfURLResponse) => {
                setTimeout(() => {
                    let alink = document.createElement('a');
                    const pdfWindow = window.open();
                    pdfWindow.location.href = `${process.env.REACT_APP_API_SERVER}${pdfURLResponse.path}`;
                    alink.setAttribute('download', 'file.pdf');
                    alink.click();
                }, 1000);
            });
    };

    const columns = useMemo(
        () => [
            columnHelper.accessor('title', {
                header: () => <span>Payment Status</span>,
                cell: (info) => info.getValue() || '-',
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('createdAt', {
                header: () => <span>Time</span>,
                cell: (info) => dayjs(info.getValue()).format('HH:mm:ss').toString() || '-',
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('createdAt', {
                id: 'createdAtDate',
                // TODO: find a way to set unique key
                header: () => <span>Date</span>,
                cell: (info) => {
                    return dayjs(info.getValue()).format('DD/MM/YYYY').toString() || '-';
                },
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('creditsLeft', {
                header: () => <span>Total</span>,
                cell: (info) => {
                    const value = info.getValue();

                    if (info.row.original.isV3Transaction) {
                        const contactCredits = info.row.original.leftContactCredit;
                        return (
                            <>
                                <div className="available-contact">
                                    <div>
                                        <span>Chat Leads</span>
                                        <span>{contactCredits?.liveChatLeads ?? '-'}</span>
                                    </div>
                                    <div>
                                        <span>Call Leads</span>
                                        <span>{contactCredits?.inboundCallLeads ?? '-'}</span>
                                    </div>
                                    <div>
                                        <span>Hotkeys</span>
                                        <span>{contactCredits?.hotkeys ?? '-'}</span>
                                    </div>
                                </div>
                            </>
                        );
                    }
                    if (value !== undefined) {
                        const formattedValue = (Math.round(value * 100) / 100).toFixed(2);
                        return <CurrencySymbol currencyCode={user?.currency} value={formattedValue} />;
                    } else {
                        return '-';
                    }
                },

                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('invoiceId', {
                header: () => <span>View Invoice</span>,
                // cell: (info) => info.getValue() || "-", // Use is byDefault
                cell: (info) => (
                    <>
                        {!info.getValue() && userId ? (
                            <>
                                <MDButton
                                    variant="gradient"
                                    color="info"
                                    className={classNames({
                                        // table_export_leads_disabled: !info.getValue(),
                                    })}
                                    // disabled={true}
                                    disabled={xeroLoading && transactionId === info.row.original._id}
                                    sx={{
                                        cursor: `${
                                            xeroLoading && transactionId === info.row.original._id
                                                ? 'not-allowed'
                                                : 'pointer'
                                        }`,
                                    }}
                                    onClick={() => {
                                        // if (info.getValue()) {
                                        generateInvoice(info.row.original._id);
                                        // }
                                    }}
                                >
                                    <Icon>
                                        <PictureAsPdf />
                                    </Icon>
                                    &nbsp; Generate Invoice
                                </MDButton>{' '}
                            </>
                        ) : (
                            <></>
                        )}
                        <MDButton
                            variant="gradient"
                            color="info"
                            className={classNames({
                                table_export_leads_disabled: !info.getValue(),
                            })}
                            disabled={!info.getValue()}
                            sx={{ cursor: `${info.getValue() !== null ? 'pointer' : 'not-allowed'}` }}
                            onClick={() => {
                                if (info.getValue()) {
                                    invoicesButtonClick(info.getValue());
                                }
                            }}
                        >
                            <Icon>
                                <PictureAsPdf />
                            </Icon>
                            &nbsp; View Invoice
                        </MDButton>
                    </>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('amount', {
                header: () => <span>Amount</span>,
                cell: (props: CellContext<TransactionData, number>) => {
                    const tData = props.row.original;
                    return (
                        (
                            <span style={{ color: `${tData.isCredited ? 'green' : 'red'}` }}>
                                {`${tData.isCredited ? '+' : '-'}`}
                                <CurrencySymbol
                                    currencyCode={user?.currency}
                                    value={(Math.round(props.getValue() * 100) / 100).toFixed(2)}
                                />
                            </span>
                        ) || '-'
                    );
                },
                footer: (info) => info.column.id,
            }),
        ],
        [xeroLoading],
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    useEffect(() => {
        if (transactionData) {
            setData([...transactionData]);
        }
    }, [transactionData]);

    const generateInvoice = async (transactionId) => {
        setTransactionId(transactionId);
        generateXeroInvoiceMutation({ transactionId: transactionId })
            .unwrap()
            .then((res) => {})
            .catch((err) => {
                console.error(err);
                enqueueSnackbar(
                    err?.data?.data?.errors[0]?.message ?? err?.data?.error?.message ?? 'Something went Wrong',
                    { variant: 'error' },
                );
            });
    };

    return (
        <>
            <div className="table-heading mb-15">Transaction Data</div>
            <div className="table-responsive">
                {!isLoading && table.getRowModel().rows.length > 0 ? (
                    <table>
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                        {table.getRowModel().rows.length === 0 && <div className="no-data">No Data Found</div>}
                    </table>
                ) : (
                    <div className="no-data">No Data Found</div>
                )}
            </div>
        </>
    );
};

export default TransactionTable;
