import ClearIcon from '@mui/icons-material/Clear';

import DoneIcon from '@mui/icons-material/Done';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { Card, Divider } from '@mui/material';
import Chip from '@mui/material/Chip';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDTypography from 'components/themed/MDTypography';
import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';

interface SourceObject {
    content: string;
    available: boolean;
}

interface IListCard {
    icon?: string;
    title?: string;
    min?: string;
    max?: string;
    tagline?: boolean;
    selected?: boolean;
    hidden?: boolean;
    desc?: string;
    btnText?: string;
    source: SourceObject[];
}

const ListCard: React.FC<IListCard> = ({ icon, title, min, max, tagline, selected, hidden, desc, btnText, source }) => {
    const [readMore, setReadMore] = useState(false);

    const getCurrentDimension = useCallback(() => {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }, []);

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension());
        };
        window.addEventListener('resize', updateDimension);

        return () => {
            window.removeEventListener('resize', updateDimension);
        };
    }, [screenSize]);

    const toggleReadMore = useCallback(() => {
        setReadMore((prev) => !prev);
    }, [readMore]);

    if (hidden) return;
    return (
        <MDBox className="list-card" sx={{ width: '24%' }}>
            <Card>
                <MDBox className="main-container card-container">
                    <MDBox className="inner-container">
                        {/* CARD HEADER */}
                        <MDBox class="card-header">
                            <MDBox className="top d-flex flex-space-between">
                                <MDBox className="icon-container">
                                    <img src={icon} alt="package0icon" />
                                </MDBox>
                                <MDBox className="chip-container">
                                    {tagline && <Chip label={tagline && 'Popular'} className="card-btn" />}
                                </MDBox>
                            </MDBox>

                            <MDBox className="title-container">
                                <MDTypography className="title" variant="h4">
                                    {title}
                                </MDTypography>
                            </MDBox>
                            <MDBox className="price-container d-flex">
                                <MDTypography variant="h4" className="min-container d-flex">
                                    <MDBox className="currency">£</MDBox>
                                    <MDTypography variant="h3" className="currency-value">
                                        {min}
                                    </MDTypography>
                                </MDTypography>
                                <MDBox className="separator">
                                    <div className="separator-icon"></div>
                                </MDBox>
                                <MDTypography variant="h4" className="max-container d-flex">
                                    <MDBox className="currency">€</MDBox>
                                    <MDTypography variant="h3" className="currency-value">
                                        {max}
                                    </MDTypography>
                                </MDTypography>
                            </MDBox>
                            <MDBox className="tagline">
                                <MDTypography variant="p" className="tagline-text">
                                    {desc}
                                </MDTypography>
                            </MDBox>
                            <Divider className="card-divider" />
                            <MDButton
                                className={`get-started-btn ${selected ? 'selected' : 'not-selected'}`}
                                type="submit"
                                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=${title}`}
                            >
                                {btnText ?? 'Get Started'}
                            </MDButton>
                        </MDBox>
                        {/* CARD BODY */}
                        <MDBox className="card-body">
                            <MDBox className="inner-container">
                                {readMore || screenSize.width > 1400 ? (
                                    source.map((val, index) => {
                                        return (
                                            <MDBox key={index} className="text-wrapper" marginTop={2} marginBottom={2}>
                                                <span
                                                    className={`content-icon ${
                                                        val.available === true ? 'content-success' : 'content-error'
                                                    }`}
                                                >
                                                    {val.available === true ? <DoneIcon /> : <ClearIcon />}
                                                </span>
                                                <MDTypography variant="p" className="content">
                                                    {val.content}
                                                </MDTypography>
                                            </MDBox>
                                        );
                                    })
                                ) : (
                                    <>
                                        {' '}
                                        {source.slice(0, 4).map((val, index) => {
                                            return (
                                                <MDBox
                                                    key={index}
                                                    className="text-wrapper"
                                                    marginTop={2}
                                                    marginBottom={2}
                                                >
                                                    <span
                                                        className={`content-icon ${
                                                            val.available === true ? 'content-success' : 'content-error'
                                                        }`}
                                                    >
                                                        {val.available === true ? <DoneIcon /> : <ClearIcon />}
                                                    </span>
                                                    <MDTypography variant="p" className="content">
                                                        {val.content}
                                                    </MDTypography>
                                                </MDBox>
                                            );
                                        })}
                                    </>
                                )}
                            </MDBox>
                            {!(screenSize.width > 1400) && (
                                <>
                                    {' '}
                                    {!readMore ? (
                                        <span className="expand-details-card" onClick={toggleReadMore}>
                                            Read More <KeyboardArrowDownRoundedIcon />
                                        </span>
                                    ) : (
                                        <span className="expand-details-card" onClick={toggleReadMore}>
                                            Collapse <KeyboardArrowUpRoundedIcon />
                                        </span>
                                    )}
                                </>
                            )}
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        </MDBox>
    );
};

export default ListCard;
