import { InfoItemObj } from 'components/leads-table/InfoItem';
import { useCallback, useState } from 'react';
import './styles.scss';


interface ILimitedText {
    maxLength?: number;
    item?: InfoItemObj;
}

const LimitedText: React.FC<ILimitedText> = ({ maxLength = 350, item }) => {
    const [showMore, setShowMore] = useState(false);

    const renderValue = useCallback(() => {
        if (item.label === 'Client Notes' && showMore) {
            return item.value;
        } else if (typeof item.value === 'string') {
            return item.value?.substring(0, maxLength) || item.value;
        } else {
            return item.value;
        }
    }, [item.label, item.value, maxLength, showMore]);

    const renderShowMoreButton = useCallback(() => {
        if (item.label === 'Client Notes' && typeof item.value === 'string' && item.value?.length > maxLength) {
            return (
                <button
                    className="show-more-less-button"
                    onClick={() => {
                        setShowMore(!showMore);
                    }}
                >
                    {showMore ? 'Show less' : 'Show more'}
                </button>
            );
        } else {
            return null;
        }
    }, [item.label, item.value, maxLength, showMore]);

    return (
        <>
            {renderValue()} {renderShowMoreButton()}
        </>
    );
};

export default LimitedText;
