import React from 'react';
import './style.scss';

interface ICreditInfoProps {
    slot?: React.ReactNode;
}

const CreditInfo: React.FC<ICreditInfoProps> = ({ slot }) => {

    return (
        <>
            {/* TODO: Removed by Client */}
            {/* <span className={"user-credits"}>
                <FormattedCurrency value={Math.round((user?.credits * 100) / 100).toFixed(2) ?? "0"} /> credit{" "}
                <StarIcon />
            </span>
            {!!slot ? slot : null} */}
        </>
    );
};

export default CreditInfo;
