import { IRolePermissionsResponse } from 'contracts/requests/IRolePermissionsResponse';
import { IUserModulePermissionsResponse } from 'contracts/requests/IUserModulePermissionResponse';
import { IAdminStatusRequest } from 'contracts/requests/IAdminStatusRequest';
import { IInviteUserRequest } from 'contracts/requests/IInviteUserRequest';
import { IMessageOnlyResponse } from 'contracts/requests/IMessageOnlyResponse';
import { TAG_GET_ACCOUNT_MANAGER, TAG_SUB_ADMIN, TAG_USER_PROFILE } from 'contracts/spotDiffAPITags';
import { IUpdateUserModulePermissionsRequest } from 'contracts/requests/IUpdateUserModulePermissionsRequest';
import { spotdifApi } from '.';

const subAdminApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getSubAdminList: builder.query({
            providesTags: [TAG_SUB_ADMIN, TAG_USER_PROFILE],
            query: (args) => {
                return {
                    url: `/invitedUsers/admin?search=${args?.search}&page=${args?.page}&perPage=${args?.perPage}`,
                    method: 'get',
                };
            },
        }),

        addSubAdmin: builder.mutation<IInviteUserRequest, IMessageOnlyResponse>({
            invalidatesTags: [TAG_SUB_ADMIN, TAG_GET_ACCOUNT_MANAGER],
            query: (body) => {
                return {
                    url: '/invitedUsers/admin',
                    method: 'post',
                    body,
                };
            },
        }),

        deleteSubAdmin: builder.mutation<void, string>({
            invalidatesTags: [TAG_SUB_ADMIN],
            query: (args) => {
                return {
                    url: `/invitedUsers/admin/${args}`,
                    method: 'delete',
                };
            },
        }),

        changeAdminStatus: builder.mutation<void, IAdminStatusRequest>({
            invalidatesTags: [TAG_SUB_ADMIN],
            query: (body) => {
                return {
                    url: `/invitedUsers/admin/${body.id}`,
                    method: 'post',
                    body,
                };
            },
        }),

        getUserModulePermissions: builder.query<IUserModulePermissionsResponse, string>({
            providesTags: [TAG_SUB_ADMIN, TAG_USER_PROFILE],
            query: (args) => {
                return {
                    url: `/permission/user/${args}`,
                    method: 'get',
                };
            },
        }),
        updateUserModulePermissions: builder.mutation<
            IUserModulePermissionsResponse,
            IUpdateUserModulePermissionsRequest
        >({
            invalidatesTags: [TAG_SUB_ADMIN],
            query: ({ userId, body }) => {
                return {
                    url: `/permission/user/${userId}`,
                    method: 'post',
                    body,
                };
            },
        }),
        getRolePermissions: builder.query<IRolePermissionsResponse, void>({
            providesTags: [TAG_SUB_ADMIN, TAG_USER_PROFILE],
            query: () => {
                return {
                    url: `/roles?page=1&perPage=100`, //fetched data in dropdown menu can't have pagination
                    method: 'get',
                };
            },
        }),
    }),
});

export const {
    useGetSubAdminListQuery,
    useAddSubAdminMutation,
    useDeleteSubAdminMutation,
    useChangeAdminStatusMutation,
    useUpdateUserModulePermissionsMutation,
    useGetRolePermissionsQuery,
    useGetUserModulePermissionsQuery,
} = subAdminApi;

export default subAdminApi;
