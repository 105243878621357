const useAddressSetter = () => {
    const getAddressField = (address: Array<any>, type: string) => {
        return address?.find((component) => component.types.includes(type))?.long_name || '';
    };
    const getCountryName = (address: Array<any>, type: string) => {
        return address?.find((component) => component.types.includes(type))?.short_name || '';
    };

    const setAddressByCountry = (setFieldValue, address) => {
        const country = getCountryName(address.address_components, 'country');

        setFieldValue(
            'addressLine1',
            [
                getAddressField(address.address_components, 'street_number'),
                getAddressField(address.address_components, 'route'),
            ]
                .filter((txt) => !!txt)
                .join(' '),
        );

        setFieldValue(
            'addressLine2',
            [
                getAddressField(address.address_components, 'sublocality_level_2'),
                getAddressField(address.address_components, 'sublocality_level_1'),
                getAddressField(address.address_components, 'country'),
            ]
                .filter((txt) => !!txt)
                .join(','),
        );

        setFieldValue('postcode', getAddressField(address.address_components, 'postal_code'));

        setFieldValue(
            'businessAddress',
            [
                getAddressField(address.address_components, 'street_number'),
                getAddressField(address.address_components, 'route'),
            ]
                .filter((txt) => !!txt)
                .join(' '),
        );
        switch (country.toLowerCase()) {
            case 'gb':
                setFieldValue('city', getAddressField(address.address_components, 'postal_town'));
                break;
            default:
                setFieldValue('city', getAddressField(address.address_components, 'administrative_area_level_1'));
                break;
        }
        // else if (country === "United States") {
        //     setFieldValue("city", getAddressField(address.address_components, "administrative_area_level_1"));
        // }
    };

    return { setAddressByCountry };
};

export default useAddressSetter;
