import { jsonObject } from 'typedjson';
import { GetBusinessIndustryMetaResponseDataObject } from './GetBusinessIndustryResponseDataObject';
import { UserBase } from './UserBase';

export enum ClientStatus {
    AllClients = '',
    isActive = 'active',
    isNotActive = 'inActive',
    isPending = 'pending',
    isLost = 'lost',
}

@jsonObject()
export class GetTableResponseDataObject {
    data: UserBase[];
    meta: GetBusinessIndustryMetaResponseDataObject;
}
