import CloseIcon from '@mui/icons-material/Close';

import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDTypography from 'components/themed/MDTypography';
import './style.scss';
import React from 'react';

interface ISendReportModal {
    checkMyReport: any; // any should be removed
    handleCloseSendReportModal: any;
    values: any;
}

const SendReportModal: React.FC<ISendReportModal> = ({ checkMyReport, handleCloseSendReportModal, values }) => {
    return (
        <>
            <MDBox className="modalFormMainWrapper">
                <MDBox className="modalMainWrapper">
                    <MDBox className="modalScrollArea">
                        <MDTypography variant="h2" className="modal-heading-1">
                            Are you sure?
                        </MDTypography>
                        <MDTypography variant="h6" className="modal-heading-5">
                            Once a report is submitted it cannot be changed or withdrawn.
                        </MDTypography>
                        <MDBox className="modal-card-list">
                            <MDTypography variant="p" className="headng">
                                Before submitting a report, please check:
                            </MDTypography>
                            <ul>
                                <li className="list">
                                    Please add lead notes to increase likelihood of us accepting your report.
                                </li>
                                <li className="list">All details of the report are accurate and true</li>
                                <li className="list">
                                    The reason for rejection is not due to the lead not responding to calls or emails
                                </li>
                            </ul>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <MDButton
                    onClick={() => {
                        checkMyReport(values);
                    }}
                    // onClick={() => changeInvalidLeadReason1(reportTime)}

                    // onClick={(e) => archiveSpecificLead(leadId, status)}
                    type="submit"
                    // onClick={changeInvalidLeadReason1}
                    //  onClick={handleClosePopUp}
                    // value={'Yes, I have checked my report'}
                    id="w-node-_0bea9dcc-b36c-bc0d-2d8a-91c15aa9a1ab-9e7c9e88"
                    className="button w-100"
                >
                    Yes, I have checked my report
                </MDButton>

                <MDBox className="modalCloseWrapper">
                    <MDButton className="modalCloseBtn" onClick={handleCloseSendReportModal}>
                        <CloseIcon fontSize="large" />
                        {/* color="black" */}
                    </MDButton>
                </MDBox>
            </MDBox>
        </>
    );
};

export default SendReportModal;
