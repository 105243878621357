import { IXeroDetailsResponse } from 'contracts/requests/IXeroDetailsResponse';
import { TAG_GET_XERO_DETAILS, TAG_INDUSTRIES_LEAD } from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';
import { IUpdateContactPricesRequest } from 'contracts/requests/IUpdateContactPricesRequest';
import { ICommonResponse } from 'contracts/spotdif/ICommonResponse';

const advanceManagementApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getXeroDetails: builder.query<IXeroDetailsResponse, string>({
            providesTags: [TAG_GET_XERO_DETAILS],
            query: (userId) => {
                return {
                    url: `/adminSettings/xero/contact/${userId}`,
                    method: 'GET',
                };
            },
        }),

        createXeroId: builder.mutation<any, string>({
            invalidatesTags: [TAG_GET_XERO_DETAILS],
            query: (userId) => {
                return {
                    url: `/adminSettings/xero/contact/${userId}`,
                    method: 'POST',
                };
            },
        }),

        updateContactPrices: builder.mutation<ICommonResponse, IUpdateContactPricesRequest>({
            invalidatesTags: [TAG_INDUSTRIES_LEAD],
            query: (body) => {
                return {
                    url: `/adminSettings/contact-options/${body.businessId}`,
                    method: 'POST',
                    body,
                };
            },
        }),
    }),
});

export const { useLazyGetXeroDetailsQuery, useCreateXeroIdMutation, useUpdateContactPricesMutation } =
    advanceManagementApi;
