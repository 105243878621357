import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Tooltip } from '@mui/material';
import ExpiredLinks from 'assets/images/icons/report-accepted.png';
import validLinks from 'assets/images/icons/valid-leads.png';
import BasicModal from 'components/core/Modal';
import AlertPopUp from 'components/shared/AlertPopUp';
import MDBadge from 'components/themed/MDBadge';
import { FreeCreditUser } from 'contracts/spotdif/GetFreeCreditsLinkResponseData';
import { PromoLinkTableItem } from 'contracts/spotdif/PromoLinkTableItem';
import useToggle from 'hooks/useToggle';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useDeletePromoLinkMutation, useExpirePromolinkMutation } from 'redux/services/spotdif/promoLink';
import { copyUrl, stringFormatter, timeFormatter } from 'utils/text-helpers';
import SignedUpUserSection from './SignedUpUserSection';

interface TableProps {
    row: PromoLinkTableItem;
}

const PromoLinkResponsiveTable: React.FC<TableProps> = ({ row }) => {
    const [isUserPopupOpen, toggleUserPopup] = useToggle(false);
    const [selectedPromoUsers, setSelectedPromoUsers] = useState<FreeCreditUser[]>([]);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);

    const [promoLinkID, setPromoLinkID] = useState('');

    const [deletePromoLinkTrigger] = useDeletePromoLinkMutation();
    const [expirePromoLinkTrigger] = useExpirePromolinkMutation();

    const handleCloseDeletePopup = useCallback(() => {
        setShowDeletePopup(false);
        setPromoLinkID('');
    }, [setShowDeletePopup, setPromoLinkID]);

    const handleClosePopup = useCallback(() => {
        setShowPopUp(false);
        setPromoLinkID('');
    }, [setShowPopUp, setPromoLinkID]);

    const openUserPopup = useCallback(
        (users: FreeCreditUser[]) => {
            toggleUserPopup();
            setSelectedPromoUsers(users);
        },
        [toggleUserPopup, setSelectedPromoUsers],
    );

    const handleModalAction = useCallback(
        (action, successMsg) => async (id) => {
            try {
                await action(id);
                enqueueSnackbar(successMsg, { variant: 'success', key: successMsg.toLowerCase().replace(' ', '-') });
                handleClosePopup();
                handleCloseDeletePopup();
            } catch (error) {
                enqueueSnackbar('Something went wrong', { variant: 'error', key: 'error-promo' });
            }
        },
        [handleCloseDeletePopup, handleClosePopup],
    );

    const destroyedLink = handleModalAction(expirePromoLinkTrigger, 'Expired Successfully');
    const deletePromoLinkPermanently = handleModalAction(deletePromoLinkTrigger, 'Deleted Successfully');

    return (
        <>
            <table className="table-v2-responsive-row promo-link-responsive">
                {[
                    {
                        label: 'Status',
                        content: row.original.isDisabled ? (
                            <span className="status_icon">
                                <img src={ExpiredLinks} alt="expired" />
                            </span>
                        ) : (
                            <span className="status_icon">
                                <img src={validLinks} alt="valid" />
                            </span>
                        ),
                    },
                    {
                        label: 'Action',
                        content: (
                            <div className="table_col ml-1 pt-0 promo-link-action">
                                <div className="d-flex gap align-items-baseline">
                                    {row.original.isDisabled ? (
                                        <div style={{ cursor: 'not-allowed' }} className="expire-link">
                                            <Tooltip title="Already Expired" placement="top">
                                                <ErrorOutlineIcon />
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div
                                            onClick={(e) => {
                                                setShowPopUp(true);
                                                setPromoLinkID(row.original._id);
                                            }}
                                            className="expire-link"
                                        >
                                            <Tooltip title="Expire this link" placement="top">
                                                <HighlightOffIcon />
                                            </Tooltip>
                                        </div>
                                    )}

                                    <div
                                        onClick={(e) => {
                                            setShowDeletePopup(true);
                                            setPromoLinkID(row.original._id);
                                        }}
                                        className="trash-icon-red"
                                    >
                                        <Tooltip title="Delete" placement="top">
                                            <DeleteIcon />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        ),
                    },
                    {
                        label: 'Name',
                        content: stringFormatter(10, row.original.name),
                    },
                    {
                        label: 'Promo Link',
                        content: (
                            <div>
                                <span
                                    className="copy-url"
                                    onClick={() =>
                                        copyUrl(`${process.env.REACT_APP_API_SERVER}/sign-up?code=${row.original.code}`)
                                    }
                                >
                                    {`.../sign-up?code=${row.original.code}`} <ContentCopyOutlinedIcon />{' '}
                                </span>
                            </div>
                        ),
                    },
                    {
                        label: 'Account Manager',
                        content: stringFormatter(10, row.original.accountManager),
                    },
                    {
                        label: 'Free Leads',
                        content: row.original.freeCredits,
                    },
                    {
                        label: 'Top Up Leads',
                        content: row.original.topUpAmount,
                    },
                    {
                        label: 'Max Use Amount',
                        content: row.original.maxUseCounts,
                    },
                    {
                        label: 'First Card Bonus',
                        content: row.original.giveCreditOnAddCard ? 'Enabled' : 'Disabled',
                    },
                    {
                        label: 'First Card Credits',
                        content: row.original.firstCardBonusCredit ?? '-',
                    },
                    {
                        label: 'Discount',
                        content: row.original.discount ?? '-',
                    },
                    {
                        label: 'Users',
                        content: (
                            <p className="users-count-wrapper promo-link-users-popup">
                                {row.original.users?.length || '-'}
                                {row.original.users?.length > 0 && (
                                    <MDBadge
                                        color="info"
                                        sx={{ cursor: 'pointer' }}
                                        ml={2}
                                        badgeContent="View Users"
                                        onClick={() => {
                                            openUserPopup(row.original.users);
                                        }}
                                        container
                                    />
                                )}
                            </p>
                        ),
                    },
                    {
                        label: 'Created At',
                        content: timeFormatter(row.original.createdAt, 'DD/MM/YYYY, h:mm:ss A'),
                    },
                ].map((rowItem, index) => (
                    <tr key={index} className="admin-table-row">
                        <td className="promo-data-label">{rowItem.label}</td>
                        <td>{rowItem.content ? rowItem.content : '-'}</td>
                    </tr>
                ))}
            </table>

            <SignedUpUserSection
                isUserPopupOpen={isUserPopupOpen}
                toggleUserPopup={toggleUserPopup}
                selectedPromoUsers={selectedPromoUsers}
            />

            <BasicModal open={showPopUp} handleClose={handleClosePopup}>
                <AlertPopUp
                    fn={(promoLinkID) => destroyedLink(promoLinkID)}
                    handleCloseModal={handleClosePopup}
                    heading={`Are you sure?`}
                    subheading={`Do you want to expire this Promo Link? It cannot be undone.`}
                    buttonText="Yes"
                    value={promoLinkID}
                />
            </BasicModal>

            <BasicModal open={showDeletePopup} handleClose={handleCloseDeletePopup}>
                <AlertPopUp
                    fn={(promoLinkID) => deletePromoLinkPermanently(promoLinkID)}
                    handleCloseModal={handleCloseDeletePopup}
                    heading={`Are you sure?`}
                    subheading={`Do you want to delete this Promo Link? It cannot be undone.`}
                    buttonText="Yes"
                    value={promoLinkID}
                />
            </BasicModal>
        </>
    );
};

export default PromoLinkResponsiveTable;
