import accountIcon from 'assets/images/icons/account.svg';
import resetPasswordIcon from 'assets/images/icons/reset.svg';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import NotificationManagement from 'components/notification-management';
import UpdatePassword from 'components/update-password';
import './style.scss';
import { EUserRole } from 'contracts/app-utils/EUserRole';
import useAuthentication from 'hooks/useAuthentication';
import React, { useState } from 'react';

import AdminProfile from './admin';

function AccountDashBoard() {
    const { user } = useAuthentication();
    const [ActiveTab, setActiveTab] = useState({
        profile: true,
        password: false,
        notification: false,
    });

    const tabChangeHandler = (currentTab) => {
        switch (currentTab) {
            case 'profile':
                setActiveTab({
                    profile: true,
                    password: false,
                    notification: false,
                });
                break;
            case 'password':
                setActiveTab({
                    profile: false,
                    password: true,
                    notification: false,
                });
                break;
            case 'notification':
                setActiveTab({
                    profile: false,
                    password: false,
                    notification: true,
                });
                break;
            default:
                break;
        }
    };

    const CurrentComponent = () => {
        if (ActiveTab.profile) {
            return <AdminProfile />;
        } else if (ActiveTab.password) {
            return <UpdatePassword />;
        } else if (user.role !== EUserRole.ACCOUNT_MANAGER && ActiveTab.notification) {
            return <NotificationManagement />;
        }
    };

    return (
        <div className="layout-middle">
            <AuthorisedUsage module={MODULE.PROFILE} permission={PERMISSIONS.READ}>
                <div
                    data-current="Tab 1"
                    data-easing="ease"
                    data-duration-in="300"
                    data-duration-out="100"
                    className="app_tabs-wrap w-tabs"
                >
                    <div className="app_tabs-nav w-tab-menu">
                        <button
                            className={
                                ActiveTab.profile
                                    ? 'app_tabs-link w-inline-block w-tab-link w--current'
                                    : 'app_tabs-link w-inline-block w-tab-link'
                            }
                            onClick={() => tabChangeHandler('profile')}
                            data-w-tab="Tab 1"
                        >
                            <div className="app_tab-icon">
                                <img loading="lazy" src={accountIcon} alt="" className="icon-1x1-xsmall" />
                            </div>
                            <div>Account</div>
                        </button>

                        {user.role !== EUserRole.ACCOUNT_MANAGER && (
                            <button
                                onClick={() => tabChangeHandler('notification')}
                                data-w-tab="Tab 2"
                                className={
                                    ActiveTab.notification
                                        ? 'app_tabs-link w-inline-block w-tab-link w--current'
                                        : 'app_tabs-link w-inline-block w-tab-link'
                                }
                            >
                                <div className="app_tab-icon">
                                    <img loading="lazy" src={resetPasswordIcon} alt="" className="icon-1x1-small" />
                                </div>
                                <div>Notifications</div>
                            </button>
                        )}

                        <button
                            onClick={() => tabChangeHandler('password')}
                            data-w-tab="Tab 3"
                            className={
                                ActiveTab.password
                                    ? 'app_tabs-link w-inline-block w-tab-link w--current'
                                    : 'app_tabs-link w-inline-block w-tab-link'
                            }
                        >
                            <div className="app_tab-icon">
                                <img loading="lazy" src={resetPasswordIcon} alt="" className="icon-1x1-xsmall" />
                            </div>
                            <div>Reset Password</div>
                        </button>
                    </div>
                    <CurrentComponent />
                </div>
            </AuthorisedUsage>
        </div>
    );
}

export default AccountDashBoard;
