import { Autocomplete } from '@mui/material';
import { ExpandedState } from '@tanstack/react-table';
import classNames from 'classnames';
import SendReportModal from 'components/admin-leads/SendReportModal';
import LeadSaleReport from 'components/client-leads-table/LeadSaleReport';
import BasicModal from 'components/core/Modal';
import LeadInfoModal from 'components/LeadInfoModal';
import BadgeItem from 'components/leads-table/BadgeItem';
import { BadgeNature } from 'components/leads-table/BadgeV2';

import InfoItem from 'components/leads-table/InfoItem';
import DateRenderer from 'components/shared/DateTimeRenderer/DateRenderer';
import MDInput from 'components/themed/MDInput';
import { LeadsBidData, LeadStatuses } from 'contracts/spotdif/LeadsBidData';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { useReportLeadMutation } from 'redux/services/spotdif/leads';
import { expandDataCopy } from 'utils/data-format-utils';

interface ExpandedRowProps {
    leadData: LeadsBidData;
    setExpanded: (expand: ExpandedState) => void;
    adminLeadsData: any;
}

export const contactStatusOptions = [
    'Made a Sale (Please add sale value to notes)',
    'Appointment Booked',
    'Gone Elsewhere',
    'Will Call Us Back',
    'Not Interested (Add notes)',
    'Contact Guarantee',
    'No Contact',
    'Non-Homeowner',
    'Straight to Voicemail',
    'Hung Up',
    'Duplicate',
    'Government Grant',
    'Didn\'t Make Enquiry (Add notes)',
    'Wrong Number',
];

export const reportReasonOptions = [
    'Out of Area (Please contact us to check your delivery postcodes)',
    'Out of Hours',
    'Non-Homeowner',
    'Fake Name',
    'Fake Email',
    'Fake Number',
    'Duplicate',
    'Contact Guarantee',
];

export interface CustomHTMLTextAreaElement extends HTMLTextAreaElement {
    timeoutId: number;
}

const ExpandedRow: React.FC<ExpandedRowProps> = ({ leadData, setExpanded, adminLeadsData }) => {
    const [reportLead] = useReportLeadMutation();
    const [errors, setErrors] = useState(false);

    const [showLeadModal, setShowLeadModal] = useState<boolean>(false);

    // @todo - make forms formik and also try to split form into component
    const [contactStatus, setContactStatus] = useState(leadData?.contactStatus);
    const [clientNotes, setClientNotes] = useState(leadData?.clientNotes);
    const [selectedLeadId, setSelectedLeadId] = useState('');
    const [reportTime, setReportTime] = useState(leadData?.invalidLeadReason ?? '');

    const [showSendReportModal, setShowSendReportModal] = useState(false);

    const notesDebounceRef = useRef<number>(null);

    const handleCloseLeadModal = useCallback(() => {
        setShowLeadModal(false);
    }, []);

    const handleArchive = (e, status, clientNotes, leadId, contactStatus) => {
        if (status !== 'Reported') {
            const newStatus = () => {
                if (status === 'Valid') {
                    return 'Archived';
                    // return 'Archived Leads';
                } else {
                    return 'Valid';
                }
            };

            reportLead({ status: newStatus(), clientNotes, leadId, contactStatus })
                .unwrap()
                .then((payload) => {
                    enqueueSnackbar(`Lead ${newStatus() === 'Archived' ? 'Archived' : 'Unarchived'} Successfully`, {
                        variant: 'success',
                        key: 'user-auth',
                    });
                    setExpanded({});
                })
                .catch(({ data }) => {
                    enqueueSnackbar(data.error.message, { variant: 'error', key: 'user-auth' });
                });

            // setArchive(newStatus());

            // mutate({ leadId: leadData?._id, data: { status: newStatus(), ClientNotes } });
        }
    };

    const onClickSendReportModal = useCallback(
        (leadId) => {
            if (clientNotes.trim().length > 0) {
                setSelectedLeadId(leadId);
                setShowSendReportModal(true);
                setErrors(false);
            } else {
                setErrors(true);
            }
        },
        [clientNotes],
    );

    const handleCloseSendReportModal = useCallback(() => {
        setShowSendReportModal(false);
    }, []);

    const changeInvalidLeadReason = async (values) => {
        await reportLead({
            invalidLeadReason: values,
            clientNotes: clientNotes,
            leadId: selectedLeadId,
            contactStatus,
        })
            .unwrap()
            .then((payload) => {
                enqueueSnackbar('Lead Reported Successfully', { variant: 'success', key: 'user-auth' });
                setExpanded({});
            })
            .catch(({ data }) => {
                enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', {
                    variant: 'error',
                    key: 'user-auth',
                });
            });

        handleCloseSendReportModal();
    };
    const saveNotesInServer = useCallback((notes: string, contactStatus: string) => {
        setTimeout(() => {
            reportLead({ clientNotes: notes, leadId: leadData?._id, contactStatus })
                .unwrap()
                .then((payload) => {
                    enqueueSnackbar('Lead notes saved successfully', { variant: 'success', key: 'user-auth' });
                    // setExpanded({});
                })
                .catch(({ data }) => {
                    enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', {
                        variant: 'error',
                        key: 'user-auth',
                    });
                });
        }, 100);
    }, [leadData?._id, reportLead]);

    const isLeadFixed = useMemo<boolean>(
        () => {
            return (leadData.isHydrated === false)
                ? true
                : (
                    leadData?.status === 'Report Accepted' ||
                    leadData?.status === 'Report Rejected' ||
                    leadData?.status === 'Reported'
                );
        },
        [leadData?.status, leadData.isHydrated],
    );

    const handleNotesChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const { value } = event.target;
            setClientNotes(value);
            setSelectedLeadId(leadData._id);

            if (notesDebounceRef.current) {
                clearTimeout(notesDebounceRef.current);
            }
            notesDebounceRef.current = window.setTimeout(() => saveNotesInServer(value, contactStatus), 1000);
        },
        [leadData._id, saveNotesInServer, contactStatus],
    );

    const handleStatusChange = useCallback(
        (event: React.SyntheticEvent, contactStatus: string) => {
            setContactStatus(contactStatus);
            setSelectedLeadId(leadData?._id);

            if (notesDebounceRef.current) {
                clearTimeout(notesDebounceRef.current);
            }
            notesDebounceRef.current = window.setTimeout(() => saveNotesInServer(clientNotes, contactStatus), 1000);
        },
        [leadData?._id, clientNotes, saveNotesInServer],
    );

    return (
        <>
            <div className={`expanded-row`}>
                <td colSpan={12}>
                    <div className={`expanded-row-block ${leadData.isHydrated ? 'is-hydrated' : 'is-not-hydrated'}`}>
                        <div className="block-left">
                            <h2 className="expanded-heading">More Details</h2>
                            <div className="info">
                                <div className="info-right">
                                    <BadgeItem
                                        item={{
                                            nature: BadgeNature.normal,
                                            className: `${leadData?.status?.split(' ').join('-')}-v2`,
                                            label: 'Status',
                                            value: leadData?.status,
                                        }}
                                    />

                                    <InfoItem item={{
                                        label: 'Reported At',
                                        value: leadData.reportedAt ? <DateRenderer date={leadData.reportedAt} /> : '-',
                                    }} />

                                    <InfoItem item={{
                                        label: 'Report Accepted At',
                                        value: leadData.reportAcceptedAt ?
                                            <DateRenderer date={leadData.reportAcceptedAt} /> : '-',
                                    }} />

                                    {expandDataCopy(leadData, adminLeadsData)
                                        ?.slice(0, Math.ceil(expandDataCopy(leadData, adminLeadsData).length / 2))
                                        ?.map(({ label, value }) => {
                                            return <InfoItem item={{ label: label, value: value ?? '-' }} />;
                                        })}
                                </div>
                                <div className="info-left">
                                    {expandDataCopy(leadData, adminLeadsData)
                                        ?.slice(Math.ceil(expandDataCopy(leadData, adminLeadsData).length / 2))
                                        ?.map(({ label, value }) => {
                                            return <InfoItem item={{ label: label, value: value ?? '-' }} />;
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className="block-middle">
                            <h2 className="expanded-heading">Add Notes</h2>
                            <div className="action-block">
                                <div>
                                    <p>Notes</p>
                                    <textarea
                                        rows={8}
                                        style={{
                                            cursor: isLeadFixed ? 'not-allowed' : 'pointer',
                                        }}
                                        disabled={isLeadFixed}
                                        placeholder="Enter notes here"
                                        name="Notes"
                                        value={clientNotes}
                                        onChange={handleNotesChange}
                                        id="clientsNotes"
                                        className="w-100"
                                    />
                                    {errors && <div className="errors">Notes are Required</div>}
                                </div>

                                <div className="contract">
                                    <p>Contact Status</p>
                                    <Autocomplete
                                        className="autocomplete-field"
                                        disableClearable
                                        defaultValue={leadData?.contactStatus ? leadData?.contactStatus : ''}
                                        options={contactStatusOptions}
                                        onChange={handleStatusChange}
                                        renderInput={(params) => (
                                            <MDInput {...params} fullWidth placeholder="Select a contact status" />
                                        )}
                                        style={{
                                            cursor: isLeadFixed ? 'not-allowed' : 'pointer',
                                        }}
                                        disabled={isLeadFixed}
                                    />
                                </div>

                                <LeadSaleReport
                                    disabled={isLeadFixed}
                                    leadId={leadData._id}
                                    soldAt={leadData.soldAt}
                                />

                                <div>
                                    <input
                                        type="button"
                                        className={classNames('max-width-full w-100 button button-gray')}
                                        disabled={isLeadFixed}
                                        onClick={(e) =>
                                            handleArchive(e, leadData?.status, clientNotes, leadData._id, contactStatus)
                                        }
                                        style={{
                                            cursor:
                                                isLeadFixed
                                                    ? // leadDetail?.status === 'Valid'
                                                    'not-allowed'
                                                    : 'pointer',
                                        }}
                                        value={
                                            leadData?.status !== 'Report Accepted' &&
                                            leadData?.status !== 'Report Rejected'
                                                ? leadData?.status === 'Archived'
                                                    ? 'Unarchive this lead'
                                                    : 'Archive this lead'
                                                : 'Cannot update'
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="block-right">
                            <div>
                                <div className="block-right-header">
                                    <h2 className="expanded-heading">Report Lead</h2>
                                    <span className="ques-icon" onClick={() => setShowLeadModal(true)}>
                                        {' '}
                                        ?{' '}
                                    </span>
                                </div>
                                <BadgeItem
                                    item={{
                                        nature: BadgeNature.inverted,
                                        className: `${leadData?.status?.split(' ').join('-')}`,
                                        label: 'Current status',
                                        value: leadData?.isHydrated ? leadData?.status : LeadStatuses.tentative,
                                    }}
                                />

                                <div className="report-reason">
                                    <p>Reason for report (please add comments in notes)</p>

                                    <Autocomplete
                                        className="autocomplete-field"
                                        disableClearable
                                        defaultValue={leadData?.invalidLeadReason ? leadData?.invalidLeadReason : ''}
                                        options={reportReasonOptions}
                                        onChange={(event, value) => setReportTime(value)}
                                        renderInput={(params) => (
                                            <MDInput {...params} fullWidth placeholder="Select a reason for report" />
                                        )}
                                        style={{
                                            cursor: isLeadFixed
                                                ? // leadDetail?.status === 'Valid'
                                                'not-allowed'
                                                : 'pointer',
                                        }}
                                        disabled={isLeadFixed}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="action-box">
                                    <div
                                        className={classNames('w-100', {
                                            'disabled-wrapper': leadData?.status !== 'Reported',
                                        })}
                                    >
                                        <input
                                            disabled={
                                                isLeadFixed
                                            }
                                            style={{
                                                cursor:
                                                    isLeadFixed ||
                                                    reportTime === ''
                                                        ? // leadDetail?.status === 'Valid'
                                                        'not-allowed'
                                                        : 'pointer',
                                            }}
                                            onClick={() => onClickSendReportModal(leadData._id)}
                                            value={'Send report'}
                                            type="button"
                                            className={classNames('max-width-full w-100 button', {
                                                disabled:
                                                    isLeadFixed ||
                                                    reportTime === '',
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </div>
            <BasicModal open={showLeadModal} handleClose={() => setShowLeadModal(false)}>
                <LeadInfoModal handleCloseLeadModal={handleCloseLeadModal} />
            </BasicModal>
            <BasicModal open={showSendReportModal} handleClose={handleCloseSendReportModal}>
                <SendReportModal
                    handleCloseSendReportModal={handleCloseSendReportModal}
                    checkMyReport={(values) => changeInvalidLeadReason(values)}
                    values={reportTime}
                />
            </BasicModal>
        </>
    );
};

export default ExpandedRow;
