import MDTypography from 'components/themed/MDTypography';
import React, { ReactNode } from 'react';
import './style.scss';

interface OfferBannerProps {
    icon: ReactNode; // In this case, we allow any ReactNode as the icon
    title: string;
    buttonText: string;
    onButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void; // Change here
}

const OfferBanner: React.FC<OfferBannerProps> = ({ icon, title, buttonText, onButtonClick }) => {
    return (
        <div className="offer-wrapper">
            <div className="offer-left-wrapper">
                <>{icon}</>
                <MDTypography className="offer-text" dangerouslySetInnerHTML={{ __html: title }}></MDTypography>
            </div>
            <button className="offer-btn" onClick={onButtonClick} type="button">
                {buttonText}
            </button>
        </div>
    );
};

export default OfferBanner;
