import AdminAccounts from 'components/AdminAccounts';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import { BaseLayout } from 'components/layout/baseLayout';
import { BrandingLayout } from 'components/layout/brandingLayout';

import NotificationProvider from 'context/AppNotificationContext';
import { UserBase } from 'contracts/spotdif/UserBase';
import useAuthentication from 'hooks/useAuthentication';
import LegacyClient from 'legacyPages/Client';

// -- moved componenets
import { SnackbarProvider } from 'notistack';

import NotFound from 'pages/404Page';
import AdminDashboard from 'pages/AdminDashboard';
import Leads from 'pages/AdminLeads';
import AdvanceManagement from 'pages/AdvanceManagement';
import Certified from 'pages/Certified';
import { ChatInbox } from 'pages/ChatInbox';
import Client from 'pages/Client';
import ClientLeads from 'pages/ClientLeads';
import ClientPartnershipOffers from 'pages/ClientPartnershipOffers';
import ClientCertifiedSupplier from 'pages/ClientPartnershipOffers/ClientCertifiedSupplier';
import ForgotPassword from 'pages/ForgotPassword';
import ImpersonateLogin from 'pages/ImpersonateLogin';
import IndustriesLeads from 'pages/IndustriesLeads';
import Partners from 'pages/Partners';
import PlanPackage from 'pages/PlanPackages';
import Postcodes from 'pages/Postcodes';
import PromoLink from 'pages/PromoLink';
import ReportedLeads from 'pages/ReportedLeads';
import ResetPassword from 'pages/ResetPassword';

// --renamed components
import SignIn from 'pages/Signin';
import SignUp from 'pages/Signup';
import SiteConfigs from 'pages/SiteConfigs';
import SubAdmin from 'pages/SubAdmin';
import UserDashboard from 'pages/UserDashboard';
import React from 'react';
import { Helmet } from 'react-helmet';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { GuestRoute } from 'routing/GuestRoute';
import { ADMIN_ONLY, CLIENTS_ONLY, ModuleProtectedRoute, SPECIAL_ADMINS_ONLY } from 'routing/ModuleProtectedRoute';
import UserAccount from '../pages/UserAccount';
import UserOnboarding from '../pages/UserOnboarding';
import RolePermissionPage from 'pages/RolePermissions';

function getApplicationRoutes({ user }) {
    const checkIfUserShouldPay = (user: UserBase) => {
        return false;
    };

    return [
        {
            path: '/',
            element: <GuestRoute element={<BrandingLayout />} />,
            children: [
                {
                    index: true,
                    path: '',
                    element: <SignUp />,
                },

                {
                    path: 'sign-up',
                    element: <SignUp />,
                },
                {
                    path: 'login',
                    element: <SignIn />,
                },
                {
                    path: 'forget-password',
                    element: <ForgotPassword />,
                },
            ],
        },

        {
            path: '/on-boarding/*',
            element: (
                <ModuleProtectedRoute
                    element={<BaseLayout />}
                    permissions={[
                        {
                            module: MODULE.CLIENT_DASHBOARD,
                            permission: PERMISSIONS.READ,
                        },
                    ]}
                />
            ),
            children: [
                {
                    index: true,
                    path: '*',
                    element: <UserOnboarding />,
                },
            ],
        },
        {
            path: '/chat-inbox',
            element: (
                <ModuleProtectedRoute
                    element={<BaseLayout />}
                    permissions={[
                        {
                            module: MODULE.CHAT_INBOX,
                            permission: PERMISSIONS.READ,
                        },
                    ]}
                    mustOnboard={true}
                />
            ),
            children: [
                {
                    path: ':roomId?',
                    // index: true,
                    element: <ChatInbox />,
                },
                // {
                //     path: 'credit-and-billing',
                //     element: <RouteProtector element={<CreditAndBilling/>} checkProtection={checkIfUserShouldPay}/>,
                // },
            ],
        },

        {
            path: '/dashboard',
            element: (
                <ModuleProtectedRoute
                    element={<BaseLayout />}
                    permissions={[
                        {
                            module: MODULE.CLIENT_DASHBOARD,
                            permission: PERMISSIONS.READ,
                        },
                    ]}
                    mustOnboard={true}
                />
            ),
            children: [
                {
                    path: '*',
                    index: true,
                    element: <UserDashboard />,
                },
                // {
                //     path: 'credit-and-billing',
                //     element: <RouteProtector element={<CreditAndBilling/>} checkProtection={checkIfUserShouldPay}/>,
                // },
            ],
        },

        {
            path: '/leads-v2',
            element: (
                <ModuleProtectedRoute
                    element={<BaseLayout />}
                    // role={[EUserRole.USER, EUserRole.INVITED, EUserRole.NON_BILLABLE]}
                    permissions={[
                        CLIENTS_ONLY,
                        {
                            module: MODULE.CLIENT_LEADS,
                            permission: PERMISSIONS.READ,
                        },
                    ]}
                    mustOnboard={true}
                />
            ),
            children: [
                {
                    path: '/leads-v2',
                    index: true,
                    element: <ClientLeads />,
                },
            ],
        },

        {
            path: '/account/*',
            element: (
                <ModuleProtectedRoute
                    element={<BaseLayout />}
                    // role={[EUserRole.USER, EUserRole.INVITED, EUserRole.NON_BILLABLE]}
                    permissions={[
                        {
                            module: MODULE.CLIENT_DASHBOARD,
                            permission: PERMISSIONS.READ,
                        },
                    ]}
                    mustOnboard={true}
                />
            ),
            children: [
                {
                    path: '*',
                    index: true,
                    element: (
                        <ModuleProtectedRoute
                            permissions={[
                                {
                                    module: MODULE.PROFILE,
                                    permission: PERMISSIONS.READ,
                                },
                            ]}
                            element={<UserAccount />}
                        />
                    ),
                },
                {
                    path: 'reset-password',
                    // element: <ResetPassword />,
                    element: (
                        <ModuleProtectedRoute
                            permissions={[
                                {
                                    module: MODULE.PROFILE,
                                    permission: PERMISSIONS.UPDATE,
                                },
                            ]}
                            element={<ResetPassword />}
                        />
                    ),
                },
            ],
        },

        {
            path: '/plans',
            element: (
                <ModuleProtectedRoute
                    element={<BaseLayout />}
                    // role={[EUserRole.USER, EUserRole.INVITED, EUserRole.NON_BILLABLE]}
                    permissions={[
                        {
                            module: MODULE.CLIENT_DASHBOARD,
                            permission: PERMISSIONS.READ,
                        },
                    ]}
                    mustOnboard={true}
                />
            ),
            children: [
                {
                    path: 'packages',
                    index: true,
                    element: <PlanPackage />,
                },
            ],
        },

        {
            path: '/partners',
            element: (
                <ModuleProtectedRoute
                    element={<BaseLayout />}
                    // role={[EUserRole.USER, EUserRole.INVITED, EUserRole.NON_BILLABLE]}
                    permissions={[
                        {
                            module: MODULE.CLIENT_DASHBOARD,
                            permission: PERMISSIONS.READ,
                        },
                    ]}
                    mustOnboard={true}
                />
            ),
            children: [
                {
                    path: 'offers',
                    index: true,
                    element: <ClientPartnershipOffers />,
                },
            ],
        },

        {
            path: '/certified',
            element: (
                <ModuleProtectedRoute
                    element={<BaseLayout />}
                    // role={[EUserRole.USER, EUserRole.NON_BILLABLE]}
                    permissions={[
                        {
                            module: MODULE.CLIENT_SUPPLIER_BADGE,
                            permission: PERMISSIONS.READ,
                        },
                    ]}
                    mustOnboard={true}
                />
            ),
            children: [
                {
                    path: 'supplier',
                    index: true,
                    element: <ClientCertifiedSupplier />,
                },
            ],
        },

        {
            path: '/reset',
            element: (
                <ModuleProtectedRoute
                    element={<BaseLayout />}
                    // role={[EUserRole.USER, EUserRole.INVITED, EUserRole.NON_BILLABLE]}
                    permissions={[
                        {
                            module: MODULE.CLIENT_DASHBOARD,
                            permission: PERMISSIONS.READ,
                        },
                    ]}
                    mustOnboard={true}
                />
            ),
            children: [
                {
                    path: 'password',
                    index: true,
                    element: <ResetPassword />,
                },
            ],
        },

        {
            path: '/admin/*',
            element: <ModuleProtectedRoute element={<BaseLayout />} permissions={[ADMIN_ONLY]} />,
            children: [
                // {
                //     path: '*',
                //     element: <Navigate to="/admin/dashboard" />,
                // },
                // {
                //     index: true,
                //     path: 'dashboard',
                //     element: <AdminDashboard />,
                // },

                // {
                //     path: 'reset-password',
                //     element: <ResetPassword />,
                // },

                // {
                //     path: 'industries-leads-v2',
                //     element: <IndustriesLeads />,
                // },

                {
                    path: '*',
                    element: (
                        <ModuleProtectedRoute
                            element={<Navigate to="/admin/dashboard" />}
                            permissions={[ADMIN_ONLY, SPECIAL_ADMINS_ONLY]}
                        />
                    ),
                },
                {
                    index: true,
                    path: 'dashboard',
                    element: (
                        <ModuleProtectedRoute
                            element={<AdminDashboard />}
                            permissions={[ADMIN_ONLY, SPECIAL_ADMINS_ONLY]}
                        />
                    ),
                },
                {
                    path: 'reset-password',
                    element: <ModuleProtectedRoute element={<ResetPassword />} permissions={[ADMIN_ONLY]} />,
                },
                {
                    path: 'industries-leads-v2',
                    element: (
                        <ModuleProtectedRoute
                            element={<IndustriesLeads />}
                            permissions={[
                                {
                                    module: MODULE.BUSINESS_INDUSTRIES,
                                    permission: PERMISSIONS.READ,
                                },
                            ]}
                        />
                    ),
                },
            ],
        },
        {
            path: '/admin/*',
            element: <ModuleProtectedRoute element={<BaseLayout />} permissions={[ADMIN_ONLY]} />,
            children: [
                // {
                //     path: '*',
                //     element: <Navigate to="/admin/clients-v2" />,
                // },
                // {
                //     index: true,
                //     path: 'clients-v2',
                //     element: <Client />,
                // },

                // // {
                // //     path: "leads",
                // //     element: <Leads />,
                // // },
                // {
                //     path: 'clients566555222',
                //     element: <LegacyClient />, // should be fix i.e removing it will cause css issue in v2
                // },
                // {
                //     path: 'clients-v2',
                //     element: <Client />,
                // },

                // {
                //     path: 'account',
                //     element: <AdminAccounts />,
                // },

                // {
                //     path: 'leads-v2',
                //     element: <Leads />,
                // },
                // // {
                // //     path: "reported-leads",
                // //     element: <ReportedLeads />,
                // // },
                // {
                //     path: 'reported-leads-v2',
                //     element: <ReportedLeads />,
                // },

                // // {
                // //     path: "promo-links",
                // //     element: <PromoLink />,
                // // },
                // {
                //     path: 'promo-links-v2',
                //     element: <PromoLink />,
                // },
                // {
                //     path: 'partners',
                //     element: <Partners />,
                // },
                // {
                //     path: 'postcodes',
                //     element: <Postcodes />,
                // },
                // {
                //     path: 'site-configs',
                //     element: <SiteConfigs />,
                // },

                {
                    path: '*',
                    element: (
                        <ModuleProtectedRoute
                            element={<Navigate to="/admin/clients-v2" />}
                            permissions={[
                                {
                                    module: MODULE.CLIENTS,
                                    permission: PERMISSIONS.READ,
                                },
                            ]}
                        />
                    ),
                },
                {
                    index: true,
                    path: 'clients-v2',
                    element: (
                        <ModuleProtectedRoute
                            element={<Client />}
                            permissions={[
                                {
                                    module: MODULE.CLIENTS,
                                    permission: PERMISSIONS.READ,
                                },
                            ]}
                        />
                    ),
                },
                {
                    path: 'clients566555222',
                    element: (
                        <ModuleProtectedRoute
                            element={<LegacyClient />}
                            permissions={[
                                {
                                    module: MODULE.CLIENTS,
                                    permission: PERMISSIONS.READ,
                                },
                            ]}
                        />
                    ),
                },
                {
                    path: 'account',
                    element: (
                        <ModuleProtectedRoute
                            element={<AdminAccounts />}
                            permissions={[
                                {
                                    module: MODULE.PROFILE,
                                    permission: PERMISSIONS.READ,
                                },
                            ]}
                        />
                    ),
                },
                {
                    path: 'leads-v2',
                    element: (
                        <ModuleProtectedRoute
                            element={<Leads />}
                            permissions={[
                                {
                                    module: MODULE.LEADS,
                                    permission: PERMISSIONS.READ,
                                },
                            ]}
                        />
                    ),
                },
                {
                    path: 'reported-leads-v2',
                    element: (
                        <ModuleProtectedRoute
                            element={<ReportedLeads />}
                            permissions={[
                                {
                                    module: MODULE.LEADS,
                                    permission: PERMISSIONS.READ,
                                },
                            ]}
                        />
                    ),
                },
                {
                    path: 'promo-links-v2',
                    element: (
                        <ModuleProtectedRoute
                            element={<PromoLink />}
                            permissions={[
                                {
                                    module: MODULE.PROMO_LINKS,
                                    permission: PERMISSIONS.READ,
                                },
                            ]}
                        />
                    ),
                },
                {
                    path: 'partners',
                    element: (
                        <ModuleProtectedRoute
                            element={<Partners />}
                            permissions={[
                                ADMIN_ONLY,
                                {
                                    module: MODULE.PARTNERS,
                                    permission: PERMISSIONS.READ,
                                },
                            ]}
                        />
                    ),
                },
                {
                    path: 'postcodes',
                    element: (
                        <ModuleProtectedRoute
                            element={<Postcodes />}
                            permissions={[
                                ADMIN_ONLY,
                                {
                                    module: MODULE.POST_CODE_STATS,
                                    permission: PERMISSIONS.READ,
                                },
                            ]}
                        />
                    ),
                },
                {
                    path: 'site-configs',
                    element: (
                        <ModuleProtectedRoute
                            element={<SiteConfigs />}
                            permissions={[
                                {
                                    module: MODULE.SITE_CONFIGS,
                                    permission: PERMISSIONS.UPDATE,
                                },
                            ]}
                        />
                    ),
                },
                {
                    path: 'certified',
                    element: (
                        <ModuleProtectedRoute
                            element={<Certified />}
                            permissions={[
                                {
                                    module: MODULE.SUPPLIER_BADGE,
                                    permission: PERMISSIONS.READ,
                                },
                            ]}
                        />
                    ),
                },
            ],
        },
        {
            path: '/admin/*',
            element: (
                <ModuleProtectedRoute
                    element={<ModuleProtectedRoute element={<BaseLayout />} permissions={[ADMIN_ONLY]} />}
                    // role={[EUserRole.SUPER_ADMIN]}
                    permissions={[
                        {
                            module: MODULE.ADMINS,
                            permission: PERMISSIONS.UPDATE,
                        },
                    ]}
                />
            ),
            children: [
                // {
                //     path: "sub-admin",
                //     element: <SubAdmin />,
                // },
                // {
                //     path: 'sub-admin-v2',
                //     element: <SubAdmin />,
                // },

                {
                    path: 'sub-admin-v2',
                    element: (
                        <ModuleProtectedRoute
                            element={<SubAdmin />}
                            permissions={[
                                {
                                    module: MODULE.ADMINS,
                                    permission: PERMISSIONS.UPDATE,
                                },
                            ]}
                        />
                    ),
                },
                {
                    path: 'advance-management',
                    element: (
                        <ModuleProtectedRoute
                            element={<AdvanceManagement />}
                            permissions={[
                                {
                                    module: MODULE.INVOICE_MANAGEMENT,
                                    permission: PERMISSIONS.UPDATE,
                                },
                            ]}
                        />
                    ),
                },
                {
                    path: 'role-permissions',
                    element: (
                        <ModuleProtectedRoute
                            element={<RolePermissionPage />}
                            permissions={[
                                ADMIN_ONLY,
                                {
                                    module: MODULE.ROLE_PERMISSIONS,
                                    permission: PERMISSIONS.READ,
                                },
                            ]}
                        />
                    ),
                },
                // {
                //     path: "non_billable_users",
                //     element: <NonBillableUsers />,
                // },
            ],
        },
        {
            path: 'impersonate-login',
            element: <ImpersonateLogin />,
        },

        {
            path: '*',
            element: <NotFound />,
        },
    ];
}

const SpotDifApp = () => {
    const user = useAuthentication();
    const rootRouter = createBrowserRouter(getApplicationRoutes(user));
    return (
        <NotificationProvider>
            <Helmet defaultTitle="SpotDif Leads" titleTemplate="SpotDif Leads - %s" />
            <SnackbarProvider
                maxSnack={3}
                preventDuplicate={true}
                variant={'info'}
                autoHideDuration={3000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <RouterProvider router={rootRouter} />
            </SnackbarProvider>
        </NotificationProvider>
    );
};

export default SpotDifApp;
