import React from 'react';
import MDBox from 'components/themed/MDBox';
import MDTypography from 'components/themed/MDTypography';
import MDButton from 'components/themed/MDButton';
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';
// import { notifySuccess } from 'components/Messages';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useSnackbar } from 'notistack';

const copyUrl = (url) => {
    const formattedUrls = url.join(',\n');
    navigator.clipboard.writeText(formattedUrls).then(() => {
        // notifySuccess('Copied Successfully');
    });
};
const PostalCodeModal = ({ handleCloseModal, allPostalCodes }) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleCopyAllPostalCode = () => {
        const allCodes = [];
        Object.keys(allPostalCodes).forEach((item) => {
            allCodes.push(allPostalCodes[item].postalCode);
        });

        copyUrl(allCodes);
        enqueueSnackbar('Copied Successfully', { variant: 'success', key: 'copied-code' });
    };

    return (
        <MDBox class="modalFormMainWrapper" component="form" role="form">
            <MDBox class="modalMainWrapper postalModalWrapper">
                <div className="modalScrollArea">
                    <MDBox className="modal-heading-wrap">
                        <h6 className="modal-heading-2">Postcodes</h6>
                        <MDBox className="copy-all-btn" onClick={handleCopyAllPostalCode}>
                            Copy all Postcodes
                            <ContentCopyOutlinedIcon
                                // onClick={() => { copyUrl(newPostalCode.postalCode); enqueueSnackbar('Copied Successfully', { variant: 'success', key: 'copied-code' }) }}
                                className="copy-to-clipboard"
                            />
                        </MDBox>
                    </MDBox>

                    {Object.keys(allPostalCodes).map((items) => {
                        let newPostalCode = allPostalCodes[items];
                        return (
                            <div>
                                <MDTypography class="modalHeadingPostalCode">
                                    {newPostalCode.county}
                                    <ContentCopyOutlinedIcon
                                        onClick={() => {
                                            copyUrl(newPostalCode.postalCode);
                                            enqueueSnackbar('Copied Successfully', {
                                                variant: 'success',
                                                key: 'copied-code',
                                            });
                                        }}
                                        className="copy-to-clipboard"
                                    />
                                </MDTypography>

                                <div className="postalModalGrid">
                                    {newPostalCode.postalCode.map((postalCodes) => (
                                        <div className="modal_input-wrapNew postalModalContentWrapper">
                                            <MDTypography class="postalModalContentDetails postalModalUserName">
                                                {postalCodes}
                                            </MDTypography>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </MDBox>
            <MDBox class="modalCloseWrapper">
                <MDButton class="modalCloseBtn" onClick={handleCloseModal}>
                    <CloseIcon fontSize="large" />
                </MDButton>
            </MDBox>
        </MDBox>
    );
};

export default PostalCodeModal;
