import { useGetValidationConfigsQuery } from 'redux/services/spotdif/siteConfigs';

export default function useGlobalMinimumTopupLeads() {
    const { minimumLeadConfigAsPerAdmin, globalSettingEnabled } = useGetValidationConfigsQuery(undefined, {
        selectFromResult: ({ data }) => {
            return {
                minimumLeadConfigAsPerAdmin: Number(data?.value),
                globalSettingEnabled: data?.enabled,
            };
        },
    });
    return {
        minimumLeadConfigAsPerAdmin: globalSettingEnabled ? minimumLeadConfigAsPerAdmin : 10,
        globalSettingEnabled,
    };
}
