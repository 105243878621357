import DeleteIcon from '@mui/icons-material/Delete';

import { Switch, Tooltip } from '@mui/material';
import Icon from '@mui/material/Icon';

import './style.scss';
import smallCardLogo from 'assets/images/small-logos/small-mastercard.png';
import BasicModal from 'components/core/Modal';
import AlertPopUp from 'components/shared/AlertPopUp';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useDeleteCardMutation, useUpdateDefaultCardMutation } from 'redux/services/spotdif/creditAndBilling';

export interface IPaymentMethodProps {
    creditCardData: object;
    isLoading: boolean;
    cardData: [];
}

const PaymentMethod = ({ creditCardData: card, isLoading, cardData }) => {
    const [updateDefaultCardTrigger, { isLoading: isUpdatingDefaultCard }] = useUpdateDefaultCardMutation();
    const [showPopUp, setShowPopUp] = useState(false);
    const [cardId, setCardId] = useState('');

    const [deleteCardTrigger] = useDeleteCardMutation();

    const handleDeleteCard = (id) => {
        deleteCardTrigger(id)
            .unwrap()
            .then((res) => {
                enqueueSnackbar(res?.data?.message ?? 'Card Deleted Successfully', {
                    variant: 'success',
                    key: 'card-detail',
                });
            })
            .catch((error) => {
                enqueueSnackbar(error?.data?.error?.message ?? 'Error in Deleting the Card', {
                    variant: 'error',
                    key: 'card-detail',
                });
            });
    };

    const handleUpdateCardTrigger = useCallback((id) => {
        updateDefaultCardTrigger(id)
            .unwrap()
            .then((res) => {
                enqueueSnackbar(res?.message ?? 'Card Updated Successfully', {
                    variant: 'success',
                    key: 'cardDetailUpdate',
                });
            })
            .catch((error) => {
                enqueueSnackbar(error?.data?.error?.message ?? 'Something went wrong', {
                    variant: 'error',
                    key: 'cardDetailUpdate',
                });
            });
    }, []);

    const handleClosePopup = () => {
        setShowPopUp(false);
        setCardId('');
    };

    return (
        <>
            <div
                className={`${
                    card.status === 'success'
                        ? 'edit_card_container_list spotdif-payment-card'
                        : 'disabled-card spotdif-payment-card'
                }`}
            >
                <div key={card._id} className="edit_card_container">
                    <div className="edit_card_left">
                        <img src={smallCardLogo} loading="lazy" alt="" width={'40px'} />
                        <h6>
                            ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;
                            {card.cardNumber?.substr(-4)}
                        </h6>
                    </div>
                    <MDBox className="edit_card_right">
                        {isUpdatingDefaultCard ? (
                            <p className="loading-spinner-small"></p>
                        ) : (
                            <>
                                <Tooltip title={card.isDefault ? 'Default Card' : 'Set Default'} placement="top">
                                    <Switch
                                        defaultChecked={card.isDefault}
                                        onChange={() =>
                                            card.status === 'success' ? handleUpdateCardTrigger(card._id) : ''
                                        }
                                        disabled={card.status !== 'success'}
                                        className={`${card.status !== 'success'}`}
                                    />
                                </Tooltip>

                                {card.isDefault && card.status === 'success' ? (
                                    <Tooltip title="Default Card Can't be deleted" placement="top">
                                        <Icon>delete</Icon>
                                    </Tooltip>
                                ) : (
                                    <MDButton
                                        className={`delete-icon ${
                                            card.status === 'success'
                                                ? 'payment-delete-icon'
                                                : 'payment-delete-disbaled'
                                        }`}
                                        onClick={() => {
                                            setShowPopUp(true);
                                            setCardId(card._id);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </MDButton>
                                )}
                            </>
                        )}
                    </MDBox>
                </div>

                {card.status !== 'success' ? (
                    <p className="card-disbaled-text">Card is not yet verified, please wait</p>
                ) : (
                    ''
                )}
            </div>

            <BasicModal open={showPopUp} handleClose={handleClosePopup}>
                <AlertPopUp
                    fn={(cardId) => handleDeleteCard(cardId)}
                    handleCloseModal={handleClosePopup}
                    heading={`Are you sure?`}
                    subheading={`Do you want to delete this card ${card.cardNumber?.substr(-4)}? It cannot be undone.`}
                    buttonText="Yes"
                    value={cardId}
                />
            </BasicModal>
        </>
    );
};

export default PaymentMethod;
