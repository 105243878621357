import { Autocomplete } from '@mui/material';
import classNames from 'classnames';
import SendReportModal from 'components/admin-leads/SendReportModal';
import LeadSaleReport from 'components/client-leads-table/LeadSaleReport';
import BasicModal from 'components/core/Modal';
import LeadInfoModal from 'components/LeadInfoModal';
import BadgeItem from 'components/leads-table/BadgeItem';
import { BadgeNature } from 'components/leads-table/BadgeV2';

import InfoItem from 'components/leads-table/InfoItem';
import MDInput from 'components/themed/MDInput';

import { LeadsBidData } from 'contracts/spotdif/LeadsBidData';

import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useReportLeadMutation } from 'redux/services/spotdif/leads';
import { expandDataCopy } from 'utils/data-format-utils';
import { contactStatusOptions, CustomHTMLTextAreaElement, reportReasonOptions } from './ExpandedRow';
import { TableCardView } from './ResponsiveTable';

interface ExpandedRowProps {
    leadData: LeadsBidData;
    adminLeadsData: any;
    view: TableCardView;
}

const ExpandedResponsiveRow: React.FC<ExpandedRowProps> = ({ leadData, adminLeadsData, view }) => {
    const [reportLead] = useReportLeadMutation();

    const [showLeadModal, setShowLeadModal] = useState<boolean>(false);

    // @todo - make forms formik and also try to split form into component
    const [contactStatus, setContactStatus] = useState(leadData?.contactStatus);
    const [clientNotes, setClientNotes] = useState(leadData?.clientNotes);
    const [selectedLeadId, setSelectedLeadId] = useState('');
    const [reportTime, setReportTime] = useState(leadData?.invalidLeadReason ?? '');

    const [showSendReportModal, setShowSendReportModal] = useState(false);

    const notesDebounceRef = useRef<CustomHTMLTextAreaElement>(null);

    const isLeadFixed = useMemo<boolean>(() => (leadData?.status === 'Report Accepted' ||
        leadData?.status === 'Report Rejected' ||
        leadData?.status === 'Reported'), [leadData?.status]);

    const handleCloseLeadModal = useCallback(() => {
        setShowLeadModal(false);
    }, []);


    const handleArchive = (e, status, clientNotes, leadId, contactStatus) => {
        if (status !== 'Reported') {
            const newStatus = () => {
                if (status === 'Valid') {
                    return 'Archived';
                    // return 'Archived Leads';
                } else {
                    return 'Valid';
                }
            };

            reportLead({ status: newStatus(), clientNotes, leadId, contactStatus })
                .unwrap()
                .then((payload) => {
                    enqueueSnackbar(`Lead ${newStatus() === 'Archived' ? 'Archived' : 'Unarchived'} Successfully`, {
                        variant: 'success',
                        key: 'user-auth',
                    });
                    // setExpanded({});
                })
                .catch(({ data }) => {
                    enqueueSnackbar(data.error.message, { variant: 'error', key: 'user-auth' });
                });
        }
    };

    const onClickSendReportModal = useCallback((leadId) => {
        setSelectedLeadId(leadId);
        setShowSendReportModal(true); // can have bug
    }, []);

    const handleCloseSendReportModal = useCallback(() => {
        setShowSendReportModal(false);
    }, []);

    const changeInvalidLeadReason = async (values) => {
        await reportLead({
            invalidLeadReason: values,
            clientNotes: clientNotes,
            leadId: selectedLeadId,
            contactStatus,
        })
            .unwrap()
            .then((payload) => {
                enqueueSnackbar('Lead Reported Successfully', { variant: 'success', key: 'user-auth' });
            })
            .catch(({ data }) => {
                enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', {
                    variant: 'error',
                    key: 'user-auth',
                });
            });
        handleCloseSendReportModal();
    };

    const updateNotes = useCallback(() => {
        reportLead({ clientNotes, leadId: leadData?._id, contactStatus })
            .unwrap()
            .then((payload) => {
                enqueueSnackbar('Lead notes saved successfully', { variant: 'success', key: 'user-auth' });
                // setExpanded({});
            })
            .catch(({ data }) => {
                enqueueSnackbar(data?.error?.message ?? 'Something went wrong.', {
                    variant: 'error',
                    key: 'user-auth',
                });
            });
    }, [clientNotes, contactStatus, reportLead, selectedLeadId]);

    const handleNotesChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const { value } = event.target;
            setClientNotes(value);
            setSelectedLeadId(leadData._id);

            if (notesDebounceRef.current) {
                clearTimeout(notesDebounceRef.current.timeoutId);
                notesDebounceRef.current.timeoutId = window.setTimeout(updateNotes, 1000);
            }
        },
        [leadData._id, updateNotes],
    );

    return (
        <>
            <div className="expanded-row">
                <td colSpan={12}>
                    <div className="expanded-row-block">
                        <div
                            className={classNames('table-v2-responsive-more-info', {
                                'd-none': view !== TableCardView.MoreDetails,
                            })}
                        >
                            <div className="block-left">
                                <h2 className="expanded-heading">More Details</h2>
                                <div className="info">
                                    <div className="info-right">
                                        <BadgeItem
                                            item={{
                                                nature: BadgeNature.normal,
                                                className: `${leadData?.status?.split(' ').join('-')}-v2`,
                                                label: 'Status',
                                                value: leadData?.status,
                                            }}
                                        />

                                        {expandDataCopy(leadData, adminLeadsData)
                                            ?.slice(0, Math.ceil(expandDataCopy(leadData, adminLeadsData).length / 2))
                                            ?.map(({ label, value }) => {
                                                return <InfoItem item={{ label: label, value: value ?? '-' }} />;
                                            })}
                                    </div>
                                    <div className="info-left">
                                        {expandDataCopy(leadData, adminLeadsData)
                                            ?.slice(Math.ceil(expandDataCopy(leadData, adminLeadsData).length / 2))
                                            ?.map(({ label, value }) => {
                                                return <InfoItem item={{ label: label, value: value ?? '-' }} />;
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={classNames('table-v2-responsive-add-note', {
                                'd-none': view !== TableCardView.AddNotes,
                            })}
                        >
                            <div className="block-middle">
                                <h2 className="expanded-heading">Add Notes</h2>
                                <div className="action-block">

                                    <div>
                                        <p>Note</p>
                                        <textarea
                                            ref={notesDebounceRef}
                                            rows={8}
                                            style={{
                                                cursor: isLeadFixed
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                            }}
                                            disabled={isLeadFixed}
                                            placeholder="Enter notes here"
                                            name="Notes"
                                            value={clientNotes}
                                            onChange={handleNotesChange}
                                            id="clientsNotes"
                                            className="w-100"
                                        />
                                    </div>

                                    <div className="contract">
                                        <p>Contact Status</p>
                                        <Autocomplete
                                            className="autocomplete-field"
                                            disableClearable
                                            defaultValue={leadData?.contactStatus ? leadData?.contactStatus : ''}
                                            options={contactStatusOptions}
                                            onChange={(event, value) => {
                                                setContactStatus(value);
                                                if (notesDebounceRef.current) {
                                                    clearTimeout(notesDebounceRef.current.timeoutId);
                                                    notesDebounceRef.current.timeoutId = window.setTimeout(updateNotes, 1000);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <MDInput {...params} fullWidth placeholder="Select a contact status" />
                                            )}
                                            style={{
                                                cursor: isLeadFixed
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                            }}
                                            disabled={isLeadFixed}
                                        />
                                    </div>

                                    <LeadSaleReport leadId={leadData._id} soldAt={leadData.soldAt} />

                                    <div>
                                        <input
                                            type="button"
                                            className={classNames('max-width-full w-100 button button-gray')}
                                            disabled={isLeadFixed}
                                            onClick={(e) =>
                                                handleArchive(
                                                    e,
                                                    leadData?.status,
                                                    clientNotes,
                                                    leadData._id,
                                                    contactStatus,
                                                )
                                            }
                                            style={{
                                                cursor: isLeadFixed
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                            }}
                                            value={
                                                leadData?.status !== 'Report Accepted' &&
                                                leadData?.status !== 'Report Rejected'
                                                    ? leadData?.status === 'Archived'
                                                        ? 'Unarchive this lead'
                                                        : 'Archive this lead'
                                                    : 'Cannot update'
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={classNames('table-v2-responsive-report-lead', {
                                'd-none': view !== TableCardView.ReportLead,
                            })}
                        >
                            <div className="block-right">
                                <div>
                                    <div className="block-right-header">
                                        <h2 className="expanded-heading">Report Lead</h2>
                                        <span className="ques-icon" onClick={() => setShowLeadModal(true)}>
                                            {' '}
                                            ?{' '}
                                        </span>
                                    </div>
                                    <BadgeItem
                                        item={{
                                            nature: BadgeNature.inverted,
                                            className: `${leadData?.status?.split(' ').join('-')}`,
                                            label: 'Current status',
                                            value: leadData?.status,
                                        }}
                                    />

                                    <div className="report-reason">
                                        <p>Reason for report (please add comments in notes)</p>

                                        <Autocomplete
                                            className="autocomplete-field"
                                            disableClearable
                                            defaultValue={
                                                leadData?.invalidLeadReason ? leadData?.invalidLeadReason : ''
                                            }
                                            options={reportReasonOptions}
                                            onChange={(event, value) => setReportTime(value)}
                                            renderInput={(params) => (
                                                <MDInput
                                                    {...params}
                                                    fullWidth
                                                    placeholder="Select a reason for report"
                                                />
                                            )}
                                            style={{
                                                cursor:
                                                    leadData.status === 'Report Accepted' ||
                                                    leadData?.status === 'Report Rejected' ||
                                                    leadData?.status === 'Reported'
                                                        ? // leadDetail?.status === 'Valid'
                                                        'not-allowed'
                                                        : 'pointer',
                                            }}
                                            disabled={
                                                leadData?.status === 'Report Accepted' ||
                                                leadData?.status === 'Report Rejected' ||
                                                leadData?.status === 'Reported'
                                                    ? // leadDetail?.status === 'Valid'
                                                    true
                                                    : false
                                            }
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="action-box">
                                        <div
                                            className={classNames('w-100', {
                                                'disabled-wrapper': leadData?.status !== 'Reported',
                                            })}
                                        >
                                            <input
                                                disabled={
                                                    leadData?.status === 'Report Accepted' ||
                                                    leadData?.status === 'Report Rejected' ||
                                                    leadData?.status === 'Reported' ||
                                                    reportTime === ''
                                                        ? // leadDetail?.status === 'Valid'
                                                        true
                                                        : false
                                                }
                                                style={{
                                                    cursor:
                                                        leadData?.status === 'Report Accepted' ||
                                                        leadData?.status === 'Report Rejected' ||
                                                        leadData?.status === 'Reported' ||
                                                        reportTime === ''
                                                            ? // leadDetail?.status === 'Valid'
                                                            'not-allowed'
                                                            : 'pointer',
                                                }}
                                                onClick={() => onClickSendReportModal(leadData._id)}
                                                value={'Send report'}
                                                type="button"
                                                className={classNames('max-width-full w-100 button', {
                                                    disabled:
                                                        leadData?.status === 'Report Accepted' ||
                                                        leadData?.status === 'Report Rejected' ||
                                                        leadData?.status === 'Reported' ||
                                                        reportTime === '',
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </div>
            <BasicModal open={showLeadModal} handleClose={() => setShowLeadModal(false)}>
                <LeadInfoModal handleCloseLeadModal={handleCloseLeadModal} />
            </BasicModal>
            <BasicModal open={showSendReportModal} handleClose={handleCloseSendReportModal}>
                <SendReportModal
                    handleCloseSendReportModal={handleCloseSendReportModal}
                    checkMyReport={(values) => changeInvalidLeadReason(values)}
                    values={reportTime}
                />
            </BasicModal>
        </>
    );
};

export default ExpandedResponsiveRow;
