import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import { Tab, Tabs } from '@mui/material';
import classNames from 'classnames';
import ImageInput from 'components/industries-leads/ImageInput';
import BuyerQuestions from 'components/shared/BuyerQuestions';
import CurrencySymbol from 'components/shared/CurrencyFormat';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import ExpandedToolTip from 'components/shared/ExpandedToolTip';
import { FormikErrorsRenderer } from 'components/shared/Formik/FormikErrorsRenderer';
import PrimarySwitch from 'components/shared/PrimarySwitch';
import MDButton from 'components/themed/MDButton';
import { GetBusinessIndustryResponseDataObject } from 'contracts/spotdif/GetBusinessIndustryResponseDataObject';
import { ErrorMessage, FieldArray, Form, FormikProvider, useFormik } from 'formik';
import useAuthentication from 'hooks/useAuthentication';
import useGlobalMinimumTopupLeads from 'hooks/useGlobalMinimumTopupLeads';
import { useRegexPatterns } from 'hooks/useRegexPatterns';
import update from 'immutability-helper';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useBusinessIndustryUpdateV2Mutation } from 'redux/services/spotdif/industriesLeads';
import { avgConversionRateRegex, checkForNegativeValue, copyData, onlyNumbersWithTwoDecimal } from 'utils/text-helpers';
import * as Yup from 'yup';
import ColumnItem from './ColumnItem';
import ContactPrice from './ContactPrice';

interface ExpandedRowProps {
    industryData: GetBusinessIndustryResponseDataObject;
}

const ExpandedRow: React.FC<ExpandedRowProps> = ({ industryData }) => {
    console.log(industryData, ">>>>> industry data")

    const [businessIndustryUpdateV2, { isLoading: isSavingIndustry }] = useBusinessIndustryUpdateV2Mutation();
    const { user } = useAuthentication();
    const { minimumLeadConfigAsPerAdmin, globalSettingEnabled } = useGlobalMinimumTopupLeads();
    const [businessIndustryTab, setBusinessIndustryTab] = useState(0);
    const { imageUrlRegex } = useRegexPatterns();

    const validationSchema = Yup.object().shape({
        industry: Yup.string().required('Industry name is required').trim(),
        industryUrl: Yup.string()
            .url('Valid URL is required. Eg. https://spotdif.com/industry')
            .required('Industry URL is required')
            .trim(),
        centralIndustryId: Yup.string().nullable().trim(),
        leadCost: Yup.string()
            .matches(
                onlyNumbersWithTwoDecimal,
                'Enter a valid cost. (i.e., up to 5 digits before and 2 digits after the decimal point)',
            )
            .required('Lead cost is required'),
        columns: Yup.array().of(
            Yup.object().shape({
                originalName: Yup.string()
                    .required((context) => {
                        const {
                            groups: { number },
                        } = context.path.match(/\[(?<number>[0-9]+)\]/);
                        return `Column ${+number + 1} Original Name is required`;
                    })
                    .trim(),
                displayName: Yup.string()
                    .required((context) => {
                        const {
                            groups: { number },
                        } = context.path.match(/\[(?<number>[0-9]+)\]/);
                        return `Column ${+number + 1} Display Name is required`;
                    })
                    .trim(),
                originalNameActive: Yup.boolean().oneOf([false], 'Accept Change'),
                displayNameActive: Yup.boolean().oneOf([false], 'Accept Change'),
            }),
        ),
        avgConversionRate: Yup.string()
            .matches(avgConversionRateRegex, 'Conversion rate should be between 0 and 100')
            .required('Conversion rate is required'),
        minimumTopupLeads: Yup.string().matches(checkForNegativeValue, 'Negative value not allowed'),

        supplierBadges: Yup.array().of(
            Yup.object().shape({
                src: Yup.string()
                    .matches(
                        imageUrlRegex,
                        'Please enter a valid image URL. Supported formats .jpg, .webp, .gif, .svg or .png',
                    )
                    .required('URL is required'),
                altText: Yup.string().required('Alt text is required'),
            }),
        ),

        buyerQuestions: Yup.array().of(
            Yup.object().shape({
                title: Yup.string().nullable().trim(),
                columnName: Yup.string().nullable().trim(),
            }),
        ),
    });

    const defaultBuyerQuestions = Array.from({ length: 4 }, () => ({ title: '' }));

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...industryData,
            buyerQuestions:
                industryData.buyerQuestions.length < 4 ? defaultBuyerQuestions : industryData.buyerQuestions,
            minimumTopupLeads:
                industryData.minimumTopupLeads !== null && industryData.minimumTopupLeads > 0
                    ? industryData.minimumTopupLeads
                    : '',
            columns: industryData.columns
                .filter((column) => column.isVisible)
                .map((column) => ({ ...column, originalNameActive: false, displayNameActive: false })),
            invisibleColumns: industryData.columns
                .filter((column) => !column.isVisible)
                .map((column) => ({ ...column, originalNameActive: false, displayNameActive: false })),
        },
        validationSchema,
        onSubmit: async (values) => {
            businessIndustryUpdateV2({
                data: {
                    industry: values?.industry.trim(),
                    industryUrl: values?.industryUrl?.trim(),
                    centralIndustryId: values?.centralIndustryId?.trim(),
                    isActive: values.isActive,
                    leadCost: values?.leadCost,
                    buyerQuestions: values?.buyerQuestions,
                    avgConversionRate: Number(values?.avgConversionRate),
                    columns: [...values.columns, ...values.invisibleColumns].map((column) => {
                        const { originalNameActive, displayNameActive, ...newColumn } = column;
                        return newColumn;
                    }),
                    supplierBadges: values?.supplierBadges,
                    ...(values?.minimumTopupLeads !== null &&
                        Number(values?.minimumTopupLeads) > 0 && {
                            minimumTopupLeads: Number(values?.minimumTopupLeads),
                        }),
                },
                id: values._id,
            })
                .unwrap()
                .then((res) => {
                    enqueueSnackbar('Data updated successfully', { variant: 'success' });
                })
                .catch((err) => {
                    console.log('err', err);
                    enqueueSnackbar(err?.data?.error?.message, { variant: 'error' });
                });
        },
    });
    const handleAddColumn = () => {
        const columns = Array.isArray(formik.values.columns) ? formik.values.columns : [];
        if (formik.values.invisibleColumns.length > 0) {
            formik.setFieldValue('columns', [...columns, { ...formik.values.invisibleColumns[0], isVisible: true }]);
            formik.setFieldValue('invisibleColumns', [...formik.values.invisibleColumns.slice(1)]);
        } else {
            formik.setFieldValue('columns', [
                ...columns,
                {
                    displayName: '',
                    displayNameActive: false,
                    isVisible: true,
                    originalName: '',
                    originalNameActive: false,
                    index: columns.length,
                },
            ]);
        }
    };
    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragColumn = formik.values.columns[dragIndex];
            formik.setFieldValue(
                'columns',
                update(formik.values.columns, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragColumn],
                    ],
                }),
            );
        },
        [formik, formik.values.columns],
    );

    const formatJsonData = useCallback((columns) => {
        let jsonData = {};
        for (const item of columns) {
            if (item.isVisible) {
                jsonData = {
                    ...jsonData,
                    ...{ [`${item.originalName}`]: item.displayName },
                };
            }
        }
        return jsonData;
    }, []);

    const handleBusinessIndustryTabChange = (event: React.SyntheticEvent, newValue: number) => {
        return setBusinessIndustryTab(newValue);
    };

    const {values} = formik

    return (
        <div className="expanded-row expanded-industry-row">
            <ErrorBoundary identifier="industries-expanded-row" message="Something went wrong #sd-bi-re--0">
                <td colSpan={12}>
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <div className="expanded-row-block">
                                <ErrorBoundary
                                    identifier="industries-expanded-row"
                                    message="Something went wrong #sd-bi-re--1"
                                >
                                    <div className="upper-block">
                                        <div className="left-block">
                                            <div className="action-toggler-tile">
                                                <h2>Active</h2>
                                                <div className="toggler">
                                                    <PrimarySwitch
                                                        defaultChecked
                                                        name="isActive"
                                                        value={formik.values.isActive}
                                                        onChange={formik.handleChange}
                                                        checked={formik.values.isActive}
                                                    />
                                                </div>
                                            </div>
                                            <div className="industry-details-block">
                                                <h2 className="block-heading">Industry Details</h2>
                                                <div className="details-list">
                                                    <div className="name-row">
                                                        <h3>Industry Name</h3>
                                                        <div>
                                                            <input
                                                                onBlur={formik.handleBlur}
                                                                maxLength={80}
                                                                name="industry"
                                                                placeholder="Windows"
                                                                value={formik.values?.industry}
                                                                onChange={formik.handleChange}
                                                            />
                                                            <div className="error-v2">
                                                                <ErrorMessage name="industry" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="name-row">
                                                        <h3>Industry URL</h3>
                                                        <div>
                                                            <input
                                                                onBlur={formik.handleBlur}
                                                                maxLength={80}
                                                                name="industryUrl"
                                                                placeholder={`https://spotdif.com/${formik.values?.industry}`}
                                                                value={formik.values?.industryUrl}
                                                                onChange={formik.handleChange}
                                                            />
                                                            <div className="error-v2">
                                                                <ErrorMessage name="industryUrl" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="name-row">
                                                        <h3>Central Industry Id</h3>
                                                        <div>
                                                            <input
                                                                onBlur={formik.handleBlur}
                                                                maxLength={80}
                                                                name="centralIndustryId"
                                                                placeholder="6539fc6e8728d1dee6ec597a"
                                                                value={formik.values?.centralIndustryId}
                                                                onChange={formik.handleChange}
                                                            />
                                                            <div className="error-v2">
                                                                <ErrorMessage name="centralIndustryId" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="seprator" />
                                                    <div className="total-sum">
                                                        <h3>Default CPL</h3>
                                                        <h3>
                                                            <div className="cpl-input">
                                                                <CurrencySymbol
                                                                    currencyCode={industryData.associatedCurrency}
                                                                />
                                                                <input
                                                                    name="leadCost"
                                                                    onBlur={formik.handleBlur}
                                                                    size={
                                                                        String(formik.values?.leadCost).length - 1 <= 0
                                                                            ? 2
                                                                            : String(formik.values?.leadCost).length
                                                                    }
                                                                    value={formik.values?.leadCost}
                                                                    onChange={formik.handleChange}
                                                                    maxLength={8}
                                                                />
                                                            </div>
                                                            <div className="error-v2">
                                                                <ErrorMessage name="leadCost" />
                                                            </div>
                                                        </h3>
                                                    </div>
                                                    <div className="total-sum">
                                                        <h3>Average conversion rate</h3>
                                                        <h3>
                                                            <div className="cpl-input">
                                                                <input
                                                                    name="avgConversionRate"
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values?.avgConversionRate}
                                                                    onChange={formik.handleChange}
                                                                />
                                                                %
                                                            </div>
                                                            <div className="error-v2">
                                                                <ErrorMessage name="avgConversionRate" />
                                                            </div>
                                                        </h3>
                                                    </div>
                                                    <div className="total-sum">
                                                        <div className="d-flex">
                                                            <h3 className="mr-1">Minimum Top-Up leads </h3>
                                                            <ExpandedToolTip
                                                                placement="top"
                                                                title={
                                                                    <>
                                                                        Setting this to '0' will allow using
                                                                        Global/Default minimum top-up override.
                                                                        <br />
                                                                        Current {' - '}
                                                                        {globalSettingEnabled
                                                                            ? `Global setting is ${minimumLeadConfigAsPerAdmin}`
                                                                            : `Default value is ${user?.normalizedTopupAmount}`}
                                                                    </>
                                                                }
                                                            >
                                                                <ErrorIcon className="copy-content-icon" />
                                                            </ExpandedToolTip>
                                                        </div>

                                                        <h3>
                                                            <div className="cpl-input">
                                                                <input
                                                                    name="minimumTopupLeads"
                                                                    onBlur={formik.handleBlur}
                                                                    placeholder={
                                                                        globalSettingEnabled
                                                                            ? `Global setting is ${minimumLeadConfigAsPerAdmin}`
                                                                            : `Default value is ${user?.normalizedTopupAmount}`
                                                                    }
                                                                    value={formik.values?.minimumTopupLeads}
                                                                    onChange={formik.handleChange}
                                                                />
                                                            </div>
                                                            <div className="error-v2">
                                                                <ErrorMessage name="minimumTopupLeads" />
                                                            </div>
                                                        </h3>
                                                    </div>
                                                    <div className="json-data">
                                                        <ContentCopyOutlinedIcon
                                                            className="copy-content-icon"
                                                            onClick={() => {
                                                                copyData(
                                                                    JSON.stringify(
                                                                        formatJsonData(industryData.columns),
                                                                        null,
                                                                        2,
                                                                    ),
                                                                    'Copied Successfully.',
                                                                );
                                                            }}
                                                        />
                                                        <h3>JSON</h3>
                                                        <div className="json">
                                                            <pre>
                                                                {JSON.stringify(
                                                                    formatJsonData(industryData.columns),
                                                                    null,
                                                                    2,
                                                                )}
                                                            </pre>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right-block">
                                            <h2 className="block-heading">Display Options</h2>
                                            <ul className="column-lists">
                                                <li>
                                                    <div>
                                                        <div>
                                                            <p className="">Field Name</p>
                                                            <p className="">Column Name</p>
                                                        </div>
                                                        <i />
                                                    </div>
                                                </li>

                                                <FieldArray
                                                    name="columns"
                                                    render={(arrayHelpers) => (
                                                        <DndProvider backend={HTML5Backend}>
                                                            {formik.values.columns.map((column, index) => {
                                                                return (
                                                                    <React.Fragment key={column._id}>
                                                                        <ColumnItem
                                                                            mapIndex={index}
                                                                            index={column.index}
                                                                            displayName={column.displayName}
                                                                            originalName={column.originalName}
                                                                            isVisible={column.isVisible}
                                                                            arrayHelpers={arrayHelpers}
                                                                            // formik={formik}
                                                                            moveColumn={moveColumn}
                                                                        />
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </DndProvider>
                                                    )}
                                                />
                                            </ul>

                                            <MDButton
                                                sx={{
                                                    display: 'flex',
                                                    border: '1px solid Gray',
                                                    borderRadius: 30,
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                    alignItems: 'center',
                                                    color: '#000032',
                                                    cursor: 'pointer',
                                                    textTransform: 'capitalize',
                                                    userSelect: 'none',
                                                    '&:hover': {
                                                        backgroundColor: '#000032',
                                                        color: '#fff',
                                                    },
                                                    '&:focus:not(:hover)': {
                                                        backgroundColor: '#000032',
                                                        color: '#fff',
                                                    },
                                                }}
                                                onClick={() => handleAddColumn()}
                                            >
                                                Add column
                                            </MDButton>
                                        </div>
                                    </div>
                                </ErrorBoundary>

                                <ErrorBoundary
                                    identifier="industries-expanded-row--additional-settings"
                                    message="Something went wrong #sd-bi-re--3"
                                >
                                    <div className="industry-tabs w-100 mb-1">
                                        <Tabs value={businessIndustryTab} onChange={handleBusinessIndustryTabChange}>
                                            <Tab className="tab-text-value" value={0} label="Buyer Questions" />
                                            <Tab className="tab-text-value" value={1} label="Supplier Badges" />
                                            <Tab className="tab-text-value" value={2} label="Contact Prices" />
                                        </Tabs>
                                    </div>
                                    {businessIndustryTab === 0 ? (
                                        <BuyerQuestions />
                                    ) : businessIndustryTab === 2 ? (
                                        <>
                                            <ContactPrice contactOptions={industryData.contactOptions} businessId={values._id} />
                                        </>
                                    ) : (
                                        <FieldArray
                                            name="supplierBadges"
                                            render={(arrayHelpers) => (
                                                <div className="badges-wrapper">
                                                    {formik.values.supplierBadges.map((item, index) => (
                                                        <>
                                                            <div className="text-align-center bg-purple mt-2">
                                                                {item.type.toUpperCase()}
                                                            </div>
                                                            <div className="banner-block-wrapper">
                                                                <ImageInput
                                                                    imageUrl={item.src}
                                                                    isDisabled={item.type === 'post'}
                                                                    altText={item.src || 'supplier-image'}
                                                                    labelImage="Image URL"
                                                                    nameImage={`supplierBadges[${index}].src`}
                                                                    dataNameImage="Image URL"
                                                                    placeholderImage=""
                                                                    idImage={`supplierBadges[${index}].src`}
                                                                    valueImage={item.src}
                                                                    onChange={formik.handleChange}
                                                                    touchedImage={
                                                                        formik.getFieldMeta(
                                                                            `supplierBadges[${index}].src`,
                                                                        ).touched
                                                                    }
                                                                    errorsImage={
                                                                        formik.getFieldMeta(
                                                                            `supplierBadges[${index}].src`,
                                                                        ).error
                                                                    }
                                                                    labelAlt="Img Alt Text"
                                                                    nameAlt={`supplierBadges[${index}].altText`}
                                                                    dataNameAlt="Image Alt Text"
                                                                    placeholderAlt=""
                                                                    idAlt={`supplierBadges[${index}].altText`}
                                                                    valueAlt={item.altText}
                                                                    touchedAlt={
                                                                        formik.getFieldMeta(
                                                                            `supplierBadges[${index}].src`,
                                                                        ).touched
                                                                    }
                                                                    errorsAlt={
                                                                        formik.getFieldMeta(
                                                                            `supplierBadges[${index}].altText`,
                                                                        ).error
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                            )}
                                        />
                                    )}
                                </ErrorBoundary>

                                <ErrorBoundary
                                    identifier="industries-expanded-row"
                                    message="Something went wrong #sd-bi-re--2"
                                >
                                    <div className="lower-block">
                                        <div className="action-btn-block">
                                            <MDButton
                                                type="submit"
                                                isLoading={isSavingIndustry}
                                                disabled={!formik.isValid || isSavingIndustry}
                                                sx={{
                                                    marginRight: '10px',
                                                }}
                                                className={classNames('button button-primary', {})}
                                            >
                                                Save Changes
                                            </MDButton>
                                            <MDButton
                                                type="reset"
                                                isLoading={isSavingIndustry}
                                                className="button button-outline-gray"
                                                onClick={() => {
                                                    formik.resetForm();
                                                    const discardChange = new Event('discardChangeEvent');
                                                    window.dispatchEvent(discardChange);
                                                }}
                                            >
                                                Discard Changes
                                            </MDButton>
                                        </div>
                                        <div className="formik-errors mt-2">
                                            <FormikErrorsRenderer errors={formik.errors} />
                                        </div>
                                    </div>
                                </ErrorBoundary>
                            </div>
                        </Form>
                    </FormikProvider>
                </td>
            </ErrorBoundary>
        </div>
    );
};

export default ExpandedRow;
