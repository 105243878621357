import classNames from 'classnames';
import React from 'react';

import './index.scss';

interface IFeaturesProps {
    title: string;
    points: string[];
    imageUrl: string;
}

export const Features: React.FC<IFeaturesProps> = ({ title, points, imageUrl }) => {
    return (
        <div className="feature-card">
            <img src={imageUrl} alt="features-icon" />
            <h3 className="feature-card-title">{title}</h3>
            {points?.map((point, index) => (
                <p
                    className={classNames('feature-card-points', {})}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: point }}
                ></p>
            ))}
        </div>
    );
};
