import React, { useCallback, useMemo } from 'react';
import { IHeaderStepperProps } from 'contracts/requests/IHeaderStepperProps';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { NavLink, useLocation } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EastIcon from '@mui/icons-material/East';
import classNames from 'classnames';
import { getStepsForPath } from './getStepsForPath';
import useAuthentication from 'hooks/useAuthentication';

export default function OnboardingStepperHeader({ steps, onNext, onPrevious }: IHeaderStepperProps) {
    const { pathname } = useLocation();
    const { user, isNonBillableUser, isUser } = useAuthentication();

    const { currentStep, nextStep, previousStep } = useMemo(() => {
        return getStepsForPath(steps, pathname);
    }, [pathname, steps]);

    const handleNext = useCallback(() => {
        onNext(nextStep);
    }, [nextStep, onNext]);

    const handleBack = useCallback(() => {
        onPrevious(previousStep);
    }, [onPrevious, previousStep]);

    const isLastStep: boolean = useMemo(() => {
        return user.isFinalOnboardingStep(pathname);
    }, [pathname, nextStep]);

    const isFirstStep: boolean = useMemo(() => {
        return user.isFirstAllowedStep(pathname);
    }, [pathname, nextStep]);

    const shouldDisableLastStep = useMemo<boolean>(() => {
        if (isUser) {
            if (currentStep?.id === 4) {
                return !nextStep;
            }
        } else if (isNonBillableUser) {
            return !nextStep && !isNonBillableUser;
        }
    }, [currentStep]);

    return (
        <>
            <Box sx={{ width: '100%' }} className="header-stepper">
                <Box className={classNames('onboarding-steps', `onboarding-steps--${user.onboardingPercentage}`)}>
                    {steps.map((step) => (
                        <NavLink
                            key={step.id}
                            to={user.allowedOnboardingRoute.includes(step.path) ? step.path : user.onboardingRoute}
                            // to={ step.path }
                            className={({ isActive, isPending }) =>
                                classNames('step', {
                                    'step--active': currentStep?.id === step?.id,
                                    'step--pending': isPending,
                                    'step--default': true,
                                })
                            }
                            onClick={() => console.log(step)}
                        >
                            <span className="step__number">{step.id}. </span>
                            <span className="step__title">{step.title}</span>
                            <Icon fontSize="small" className="check-done">
                                <CheckIcon />
                            </Icon>
                        </NavLink>
                    ))}
                </Box>
                <>
                    <Box className={'onboarding-actions'} sx={{ display: 'flex', flexDirection: 'row' }}>
                        {/* disabled={!previousStep} */}
                        <Button
                            color="inherit"
                            className="prev-button"
                            disabled={!!isFirstStep}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            <Icon fontSize="small">
                                <KeyboardBackspaceIcon />
                            </Icon>{' '}
                            Back
                        </Button>
                        <Button disabled={shouldDisableLastStep} onClick={handleNext} className="next-button">
                            {/* {user.onboardingPercentage === 75 ? 'Finish' : 'Next'} */}
                            {isLastStep ? 'Finish' : 'Next'}
                            <Icon fontSize="small">
                                <EastIcon />
                            </Icon>
                        </Button>
                    </Box>
                </>
            </Box>
        </>
    );
}
