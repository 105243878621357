export enum ELeadsStatus {
    AllLeads = '',
    ArchivedLeads = 'Archived',
    ReportedLeads = 'Reported',
    ReportRejected = 'Report Rejected',
    ReportAccepted = 'Report Accepted',
    ValidLeads = 'Valid',
}

export const leadsAvailableStatuses = [
    {
        name: 'All Leads',
        value: ELeadsStatus.AllLeads,
    },
    {
        name: 'Archived Leads',
        value: ELeadsStatus.ArchivedLeads,
    },
    {
        name: 'Reported Leads',
        value: ELeadsStatus.ReportedLeads,
    },
    {
        name: 'Report Rejected',
        value: ELeadsStatus.ReportRejected,
    },
    {
        name: 'Report Accepted',
        value: ELeadsStatus.ReportAccepted,
    },
    {
        name: 'Valid Leads',
        value: ELeadsStatus.ValidLeads,
    },
];

export const reportedLeadsAvailableStatuses = [
    {
        name: 'Reported Leads',
        value: ELeadsStatus.ReportedLeads,
    },
    {
        name: 'Report Rejected',
        value: ELeadsStatus.ReportRejected,
    },
    {
        name: 'Report Accepted',
        value: ELeadsStatus.ReportAccepted,
    },
    {
        name: 'All Leads',
        value: ELeadsStatus.AllLeads,
    },
];
