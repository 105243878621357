import { createColumnHelper } from '@tanstack/react-table';
import reportedLeads from 'assets/images/icons/reported-leads.png';
import validLeads from 'assets/images/icons/valid-leads.png';
import classNames from 'classnames';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import HeaderCard from 'components/shared/Card/HeaderCard';
import FormattedCurrency from 'components/shared/FormattedCurrency';
import Pagination from 'components/shared/Pagination/Pagination';
import SharedDataTable from 'components/shared/SharedTable/SharedDataTable';
import SpinningComponent from 'components/shared/SpinningComponent';
import TableHeaderV2 from 'components/shared/TableHeaderV2';
import { IDashboardCommissionQuery } from 'contracts/requests/IDashboardCommission';
import { IDateRangeQuery } from 'contracts/requests/IDateRangeQuery';
import './index.scss';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
    useExportAccountManagersStatsMutation,
    useExportClientFinancialsMutation,
    useGetClientsFinancialDetailsQuery,
    useGetDashboardCommissionQuery,
    useGetDashboardStatsQuery,
} from 'redux/services/spotdif/accountManagerStats';
import { useGetAllIndustiesLeadsQuery } from 'redux/services/spotdif/industriesLeads';
import { useGetAccountManagerQuery } from 'redux/services/spotdif/promoLink';

const DashboardCommissionResponsiveHeader = {
    accountManager_fullName: 'Account Manager',
    clientsCount: 'Client Number',
    topUpSum: 'Top Up Total',
    activeClient: 'Number of Active Clients',
    comission: 'Commission',
};

const clientsFinancialDeatilsResponsiveHeader = {
    isCommissionedUser: 'Status',
    accountManager_fullName: 'Account Manager',
    businessDetails_businessName: 'Business Name',
    leadsCount: 'Number of Leads Received',
    credits: 'Remaining Credit',
};

const AccountManagerStatsDetail: React.FC = () => {
    const { data: accountManagerList } = useGetAccountManagerQuery();
    const { data: businessIndustries } = useGetAllIndustiesLeadsQuery();

    const [query, setQuery] = useState<IDashboardCommissionQuery>({
        search: '',
        perPage: 10,
        sortingOrder: 'desc',
        total: 0,
        pageCount: 0,
        page: 1,
        pageClient: 1,
        commissionStatus: '',
        industry: [],
        accountManagerId: [],
        sortBy: 'credits',
        timePeriod: {
            startDate: dayjs().startOf('month'),
            endDate: dayjs().endOf('month'),
        },
    });

    const serializedQuery = useMemo(() => {
        let timePeriod = {
            startDate: null,
            endDate: null,
        };

        if ('startDate' in query.timePeriod && 'endDate' in query.timePeriod) {
            timePeriod.startDate = query.timePeriod.startDate.format('YYYY-MM-DD');
            timePeriod.endDate = query.timePeriod.endDate.format('YYYY-MM-DD');
        }

        return {
            ...query,
            timePeriod: timePeriod,
        };
    }, [query]);

    const serializedQueryWithoutSortBy = useMemo(() => {
        const { sortBy, sortingOrder, ...rest } = serializedQuery;
        const defaultSortingOrder = sortingOrder !== undefined ? sortingOrder : 'asc';

        return {
            ...rest,
            sortingOrder: defaultSortingOrder,
        };
    }, [serializedQuery]);

    const { dashboardCommissionData, dashboardCommissionLoading } = useGetDashboardCommissionQuery(
        { ...serializedQueryWithoutSortBy, sortingOrder: 'desc' },
        {
            selectFromResult: ({ data, isLoading }) => {
                return {
                    dashboardCommissionData: data,
                    dashboardCommissionLoading: isLoading,
                };
            },
        },
    );

    const { data: dashboardStatsData } = useGetDashboardStatsQuery({
        ...serializedQueryWithoutSortBy,
        sortingOrder: 'desc',
    });
    const { data: clientsFinancialDeatils } = useGetClientsFinancialDetailsQuery({
        ...serializedQuery,
        page: query.pageClient,
    });

    const [exportAccountStats, { data: csvData }] = useExportAccountManagersStatsMutation();

    const [exportClientFinances, { data: csvDataFinances }] = useExportClientFinancialsMutation();

    const updateFilters = useCallback(
        (key: string, value: string | number | IDateRangeQuery) => {
            if (key === 'accountManagerId') {
                setQuery((currentQueryValues) => {
                    let newQueryValues = { ...currentQueryValues } as IDashboardCommissionQuery;

                    newQueryValues.page = 1;

                    newQueryValues.accountManagerId = value ? [value as string] : ([] as Array<string>);
                    return newQueryValues;
                });
                return;
            }
            if (key === 'industry') {
                setQuery((currentQueryValues) => {
                    let newQueryValues = { ...currentQueryValues } as IDashboardCommissionQuery;

                    newQueryValues.page = 1;
                    newQueryValues.industry = value ? [value as string] : ([] as Array<string>);
                    return newQueryValues;
                });
                return;
            } else {
                setQuery({ ...query, [key]: value, page: 1 });
            }
        },
        [setQuery, query],
    );

    const columnHelper = useMemo(() => createColumnHelper<any>(), []);

    const dashboardCommissionColumns = [
        {
            accessor: 'accountManager.fullName',
            header: <span>Account Manager</span>,
            cell: (info) => <span> {info.getValue() ?? '-'} </span>,
        },
        {
            accessor: 'clientsCount',
            header: <span>Client Number</span>,
            cell: (info) => <span> {info.getValue() ?? '-'} </span>,
        },
        {
            accessor: 'topUpSum',
            header: <span>Top Up Total</span>,
            cell: (info) => (
                <span>
                    <FormattedCurrency value={info.getValue().toFixed(2) ?? '-'} />
                </span>
            ),
        },
        {
            accessor: 'activeClient',
            header: <span>Number of Active Client</span>,
            cell: (info) => <span>{info.getValue() ?? '-'} </span>,
        },
        {
            accessor: 'comission',
            header: <span>Commission</span>,
            cell: (info) => (
                <span>
                    {' '}
                    <FormattedCurrency value={info.getValue().toFixed(2) ?? '-'} />
                </span>
            ),
        },
    ];

    const clientsFinancialDeatilsColumns = [
        {
            accessor: 'isCommissionedUser',
            header: <span>Status</span>,
            cell: (info) =>
                info.getValue() ? (
                    <img className="status-img" alt="status-commissioned" src={validLeads} />
                ) : (
                    <img className="status-img" alt="status-non-commissioned" src={reportedLeads} />
                ),
        },
        {
            accessor: 'accountManager.fullName',
            header: <span>Account Manager</span>,
            cell: (info) => <span> {info.getValue() ?? '-'} </span>,
        },
        {
            accessor: 'businessDetails.businessName',
            header: <span>Business Name</span>,
            cell: (info) => <span>{info.getValue() ?? '-'} </span>,
        },
        {
            accessor: 'leadsCount',
            header: <span>Number of Leads Received</span>,
            cell: (info) => <span> {info.getValue()?.toFixed(2) ?? '-'} </span>,
        },
        {
            accessor: 'credits',
            header: <span>Remaining Credit</span>,
            cell: (info) => <span> {info.getValue()?.toFixed(2) ?? '-'} </span>,
            manualSortBy: true, // Enable manual sorting
        },
    ];

    const handleExportLeads = useCallback(() => {
        exportAccountStats({
            ...serializedQueryWithoutSortBy,
            sortingOrder: 'desc',
        });
        exportClientFinances({ ...serializedQuery, page: query.pageClient })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [exportAccountStats, exportClientFinances, query]);

    const csvInstance: any = useRef();
    const csvInstanceFinace: any = useRef();

    useEffect(() => {
        if (csvData && csvInstance.current && csvInstance.current?.link) {
            setTimeout(() => {
                csvInstance.current.link.click();
            }, 0);
        }
    }, [csvData, csvInstance]);

    useEffect(() => {
        if (csvDataFinances && csvInstanceFinace.current && csvInstanceFinace.current?.link) {
            setTimeout(() => {
                csvInstanceFinace.current.link.click();
            }, 0);
        }
    }, [csvDataFinances, csvInstanceFinace]);

    const renderStatusHeader = useMemo(
        () => (
            <>
                <AuthorisedUsage module={MODULE.DASHBOARD_CSV_FILE} permission={PERMISSIONS.READ}>
                    {dashboardCommissionData && (
                        <div
                            className={classNames('table_export_leads show-button', {
                                table_export_leads_disabled: dashboardCommissionLoading,
                            })}
                        >
                            {/* Add OnClick */}
                            <button onClick={handleExportLeads} className="button is-small w-button export-csv">
                                Export
                            </button>
                            {/* for export account stats */}
                            {csvData && <CSVLink data={csvData} filename={'Leads.CSV'} ref={csvInstance} />}

                            {/* for export client finance */}
                            {csvDataFinances && (
                                <CSVLink
                                    data={csvDataFinances}
                                    filename={'CommisionLeads.CSV'}
                                    ref={csvInstanceFinace}
                                />
                            )}
                        </div>
                    )}
                </AuthorisedUsage>
            </>
        ),
        [csvData, csvInstance, handleExportLeads, csvDataFinances, csvInstanceFinace, dashboardCommissionData],
    );

    return (
        <>
            {
                <SpinningComponent loading={dashboardCommissionLoading}>
                    <div className="layout-middle table-v2 client-leads-v2">
                        <div className="table_header">
                            <AuthorisedUsage module={MODULE.DASHBOARD} permission={PERMISSIONS.READ}>
                                <div className="table_header-col d-flex w-100">
                                    {dashboardCommissionData && (
                                        <TableHeaderV2
                                            hideSearchAndPerPage={true}
                                            showCommissionStatus={true}
                                            updateFilters={updateFilters}
                                            accountManagerList={accountManagerList}
                                            businessIndustries={businessIndustries?.data}
                                            status={renderStatusHeader}
                                            filters={{
                                                perPage: 10,
                                                sortingOrder: 'desc',
                                                status: '',
                                                clientId: '',
                                                timePeriod: query.timePeriod,
                                            }}
                                            dateRange={true}
                                        />
                                    )}
                                </div>
                            </AuthorisedUsage>
                        </div>

                        <AuthorisedUsage module={MODULE.DASHBOARD} permission={PERMISSIONS.READ}>
                            <SharedDataTable
                                tableData={dashboardCommissionData?.data}
                                query={query}
                                setQuery={setQuery}
                                columns={dashboardCommissionColumns}
                                columnHelper={columnHelper}
                                responsiveTableHeader={DashboardCommissionResponsiveHeader}
                            />
                            {dashboardCommissionData?.meta && (
                                <Pagination
                                    totalCount={dashboardCommissionData?.meta?.total}
                                    currentPage={dashboardCommissionData?.meta?.page * 1}
                                    pageSize={dashboardCommissionData?.meta?.perPage}
                                    onPageChange={(page) => setQuery({ ...query, page })}
                                />
                            )}
                        </AuthorisedUsage>

                        <div className="table-card-block leads-header-align mt-2 mb-3">
                            <AuthorisedUsage module={MODULE.DASHBOARD} permission={PERMISSIONS.READ}>
                                <HeaderCard
                                    icon={reportedLeads}
                                    value={dashboardStatsData?.data?.comissionedClients ?? ''}
                                    custClass="reported-leads"
                                    heading="Number of Commission clients"
                                />

                                <HeaderCard
                                    icon={validLeads}
                                    value={dashboardStatsData?.data?.nonComissionedClients ?? ''}
                                    custClass="report-accepted"
                                    heading="Number of Non comission clients"
                                />

                                <HeaderCard
                                    icon={validLeads}
                                    value={dashboardStatsData?.data?.totalLeads ?? ''}
                                    custClass="report-accepted"
                                    heading="Total Leads"
                                />

                                <HeaderCard
                                    icon={validLeads}
                                    value={dashboardStatsData?.data?.remainingCredit ?? ''}
                                    custClass="report-accepted"
                                    heading="Total Remaining Credit"
                                />
                            </AuthorisedUsage>
                        </div>

                        <AuthorisedUsage module={MODULE.DASHBOARD} permission={PERMISSIONS.READ}>
                            <SharedDataTable
                                tableData={clientsFinancialDeatils?.data}
                                query={query}
                                setQuery={setQuery}
                                columns={clientsFinancialDeatilsColumns}
                                columnHelper={columnHelper}
                                responsiveTableHeader={clientsFinancialDeatilsResponsiveHeader}
                            />
                            {clientsFinancialDeatils?.meta && (
                                <Pagination
                                    totalCount={clientsFinancialDeatils?.meta?.total}
                                    currentPage={clientsFinancialDeatils?.meta?.page * 1}
                                    pageSize={clientsFinancialDeatils?.meta?.perPage}
                                    onPageChange={(page) => setQuery({ ...query, pageClient: page })}
                                />
                            )}
                        </AuthorisedUsage>
                    </div>
                </SpinningComponent>
            }
        </>
    );
};

export default AccountManagerStatsDetail;
