import useAuthentication from 'hooks/useAuthentication';
import { useEffect, useState } from 'react';

export function useUserHasPermission(module: string, permission: string) {
    const [hasPermission, setHasPermission] = useState(false);
    const { user } = useAuthentication();

    useEffect(() => {
        setHasPermission(!!user?.canAccess(module, permission));
    }, [module, permission, user]);

    return hasPermission;
}
