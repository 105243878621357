import classNames from 'classnames';
import MDBox from 'components/themed/MDBox';
import MDTypography from 'components/themed/MDTypography';
import './style.scss';

interface ILegendReportModal {
    title: string;
    info: string;
    type: string;
}

const index: React.FC<ILegendReportModal> = ({ title, info, type }) => {
    return (
        <MDBox className={classNames('reportLegendMainWrapper', { [`reportLegendWrapper--${type}`]: true })}>
            <MDBox className="reportLegendBefore"></MDBox>
            <MDBox className="reportLegendContent">
                <MDTypography className="reportLegendHeading">{title}</MDTypography>
                <MDTypography className="reportLegendInfo">{info}</MDTypography>
            </MDBox>
        </MDBox>
    );
};

export default index;
