import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';
import Switch from '@mui/material/Switch';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDInput from 'components/themed/MDInput';
import MDTypography from 'components/themed/MDTypography';
import { EUserRole } from 'contracts/app-utils/EUserRole';
import { UserBase } from 'contracts/spotdif/UserBase';
import { Form, FormikProvider, useFormik } from 'formik';
import './style.scss';
import { useSnackbar } from 'notistack';
import React from 'react';
import * as Yup from 'yup';
import { useRegexPatterns } from 'hooks/useRegexPatterns';

export interface IInviteUserProps {
    onModalClose: () => void;
    useSaveMutation: UseMutation<any>; // TODO: Find appropriate types instead of any
    modalHeader: string;
    modalButton: string;
    role?: string;
    adminData?: UserBase;
}

const InviteUser: React.FC<IInviteUserProps> = ({
    onModalClose,
    useSaveMutation,
    modalHeader = 'Add Here',
    modalButton = 'Save',
    role,
    adminData,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    // const [loading, setLoading] = useState('idle');
    const [inviteUser] = useSaveMutation();

    const { emailRegex } = useRegexPatterns();

    const initState = {
        firstName: adminData?.firstName ?? '',
        lastName: adminData?.lastName ?? '',
        email: adminData?.email ?? '',
        isAccountAdmin: adminData?.isAccountAdmin ?? false,
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email required *')
            .email('Must be a valid email')
            .matches(emailRegex, 'Enter a valid email'),
        firstName: Yup.string().min(3, 'minimum 3 characters').required('First Name is required').trim(),
        lastName:
            role === 'accountManager' || adminData?.role === 'accountManager'
                ? Yup.string().notRequired().trim()
                : Yup.string().min(3, 'minimum 3 characters').required('Last Name is required').trim(),
    });

    const formik = useFormik({
        initialValues: initState,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const formattedValues = {
                ...values,
                email: values.email.toLowerCase(),

                /* For SuperAccount Manager */
                ...(role === EUserRole.ADMIN && { isAccountAdmin: values.isAccountAdmin }),

                /* Both Below condition are added for update modal */
                ...(adminData && { _id: adminData?._id }) /* If AdminData, then send adminID */,
                ...(role &&
                    !adminData && {
                        role,
                    }) /* If role and NO AdminData (ie not used for update), then send role only */,
            };

            if (formattedValues.isAccountAdmin) {
                formattedValues.role = EUserRole.ACCOUNT_ADMIN;
            }
            // setLoading('pending');
            inviteUser(formattedValues)
                .unwrap()
                .then((res) => {
                    adminData && enqueueSnackbar('Updated Successfully', { variant: 'success', key: 'user-auth' });
                    !adminData && enqueueSnackbar('Invitation Sent', { variant: 'success', key: 'user-auth' });
                })
                .catch((err) => {
                    enqueueSnackbar(err?.data?.error?.message, { variant: 'error' });
                });
            onModalClose();
        },
    });

    return (
        <MDBox className="addUserModalWrapper" sx={{ width: 1 }}>
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} className="spotdif-login-form">
                    <MDBox className="modalFormMainWrapper">
                        <MDBox className="modalMainWrapper">
                            <MDTypography variant="h2" className="modal-heading-2">
                                {modalHeader}
                            </MDTypography>
                            <div className="form-groups">
                                <div className="form-input w-50">
                                    <MDInput
                                        type="text"
                                        label="First Name"
                                        placeholder="First Name"
                                        sx={{ width: 1 }}
                                        value={formik.values.firstName}
                                        id="firstName"
                                        autoComplete="firstName"
                                        onChange={formik.handleChange}
                                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                        helperText={formik.touched.firstName && formik.errors.firstName}
                                        fullWidth
                                    />
                                </div>
                                <div className="form-input w-50">
                                    <MDInput
                                        type="text"
                                        label="Last Name"
                                        placeholder="Last Name"
                                        sx={{ width: 1 }}
                                        value={formik.values.lastName}
                                        id="lastName"
                                        autoComplete="lastName"
                                        onChange={formik.handleChange}
                                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                        helperText={formik.touched.lastName && formik.errors.lastName}
                                        fullWidth
                                    />
                                </div>
                            </div>
                            <div className="full-width-field">
                                <MDInput
                                    type="email"
                                    sx={{ width: 1 }}
                                    label="Email address"
                                    placeholder="Email address"
                                    value={formik.values.email}
                                    id="email"
                                    autoComplete="email"
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    fullWidth
                                />
                            </div>
                            {(role === EUserRole.ADMIN || role === EUserRole.ACCOUNT_ADMIN) && (
                                <div className="form-input mb-15">
                                    Allow as Account Manager
                                    <Tooltip title={formik.values.isAccountAdmin ? 'True' : 'False'} placement="top">
                                        <Switch
                                            name="isAccountAdmin"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            defaultChecked={formik.values.isAccountAdmin ? true : false}
                                        />
                                    </Tooltip>
                                </div>
                            )}
                        </MDBox>
                        <MDButton
                            className="button modalBtn"
                            type="submit"
                            // isLoading={!!loading}
                            fullWidth
                        >
                            {modalButton}
                        </MDButton>

                        <MDBox className="modalCloseWrapper">
                            <MDButton className="modalCloseBtn" onClick={onModalClose}>
                                <CloseIcon fontSize="large" />
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </Form>
            </FormikProvider>
        </MDBox>
    );
};

export default InviteUser;
