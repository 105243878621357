import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import Icon from '@mui/material/Icon';
import { Box } from '@mui/system';
import addIcon from 'assets/images/basic-icons/addIcon.svg';
import BasicModal from 'components/core/Modal';
import InviteUser from 'components/user-profile/AddUserModal';
import DeleteUser from 'components/user-profile/DeleteUserModal';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import {
    useDeleteInvitedSubscriberMutation,
    useGetInvitedSubscriberQuery,
    useInvitedSubscriberMutation,
} from 'redux/services/spotdif/onBoarding';
import './style.scss';

const NotificationManagement: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [id, setId] = useState(null);

    const [deleteConfirmationData, setDeleteConfirmationData] = useState({
        name: '',
        email: '',
    });

    const { enqueueSnackbar } = useSnackbar();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseDeleteModal = () => setDeleteModal(false);

    const openDeleteModal = (e, id) => {
        setId(id);
        setDeleteModal(true);
    };

    const { data } = useGetInvitedSubscriberQuery();
    const [deleteInvitedSubscriber] = useDeleteInvitedSubscriberMutation();

    const handleUserDeleteBtn = async (val) => {
        val ? await deleteInvitedSubscriber(id) : setDeleteModal(false);

        enqueueSnackbar('Deleted Successfully', { variant: 'success' });
        setDeleteModal(false);
    };

    return (
        <>
            <div className="app_tab-layout">
                <div className="app_card">
                    <div className="w-form">
                        <div className="modal_wrap">
                            <div className="modal_title">
                                <h3 className="heading-style-card">Notifications</h3>
                            </div>
                            <div className="spotdif-card">
                                {data?.length > 0 && (
                                    <div className="team-table-heading">
                                        <div className="teamMemberCardContent">
                                            <p>First Name</p>
                                            <p>Last Name</p>
                                            <p>Email</p>
                                        </div>
                                        <div className="teamMemberCardContentMobile">
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    paddingRight: '16.5px',
                                                    borderRight: '0.5px solid #9FA1B3',
                                                }}
                                            >
                                                <p>First Name</p>
                                                &nbsp;
                                                <p>Last Name</p>
                                            </Box>
                                            <p
                                                style={{
                                                    paddingLeft: '16.5px',
                                                }}
                                            >
                                                Email
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {data?.map((val, index) => {
                                    return (
                                        <div className="teamMemberCard" key={index}>
                                            <div className="teamMemberCardContent">
                                                {val?.firstName?.length > 10 ? (
                                                    <Tooltip title={val?.firstName} placement="top">
                                                        <p>{val?.firstName}</p>
                                                    </Tooltip>
                                                ) : (
                                                    <p>{val?.firstName}</p>
                                                )}
                                                {val?.lastName?.length > 10 ? (
                                                    <Tooltip title={val?.lastName} placement="top">
                                                        <p>{val?.lastName}</p>
                                                    </Tooltip>
                                                ) : (
                                                    <p>{val?.lastName}</p>
                                                )}
                                                {val?.email?.length > 10 ? (
                                                    <Tooltip title={val?.email} placement="top">
                                                        <p>{val?.email}</p>
                                                    </Tooltip>
                                                ) : (
                                                    <p>{val?.email}</p>
                                                )}
                                            </div>
                                            <div className="teamMemberCardContentMobile">
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        paddingRight: '16.5px',
                                                        borderRight: '0.5px solid #9FA1B3',
                                                    }}
                                                >
                                                    {val?.firstName?.length > 10 ? (
                                                        <Tooltip title={val?.firstName} placement="top">
                                                            <p>{val?.firstName}</p>
                                                        </Tooltip>
                                                    ) : (
                                                        <p>{val?.firstName}</p>
                                                    )}
                                                    {val?.lastName?.length > 10 ? (
                                                        <Tooltip title={val?.lastName} placement="top">
                                                            <p>{val?.lastName}</p>
                                                        </Tooltip>
                                                    ) : (
                                                        <p>{val?.lastName}</p>
                                                    )}
                                                </Box>
                                                {val?.email?.length > 10 ? (
                                                    <Tooltip title={val?.email} placement="top">
                                                        <p className="notification-details-left-padding">
                                                            {val?.email}
                                                        </p>
                                                    </Tooltip>
                                                ) : (
                                                    <p className="notification-details-left-padding">{val?.email}</p>
                                                )}
                                            </div>
                                            <div className="delete-icon" onClick={(e) => openDeleteModal(e, val._id)}>
                                                <Icon fontSize="medium">
                                                    <DeleteIcon />
                                                </Icon>
                                            </div>
                                        </div>
                                    );
                                })}

                                {data?.length <= 0 && <p style={{ textAlign: 'center' }}>No Record Found</p>}
                            </div>
                            <div className="buttons_component">
                                <div className="addTeamMembersBtn" onClick={() => handleOpen()}>
                                    Add a new member
                                    <img src={addIcon} className="addTeamMemberIcon" alt="add icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <BasicModal open={open} handleClose={handleClose}>
                <InviteUser
                    useSaveMutation={useInvitedSubscriberMutation}
                    onModalClose={handleClose}
                    modalHeader="Add a new member"
                    modalButton="Add Member"
                />
            </BasicModal>

            <BasicModal open={deleteModal} handleClose={() => setDeleteModal(false)}>
                <DeleteUser
                    handleUserDeleteBtn={handleUserDeleteBtn}
                    handleCloseDeleteModal={handleCloseDeleteModal}
                    userData={deleteConfirmationData}
                />
            </BasicModal>
        </>
    );
};

export default NotificationManagement;
