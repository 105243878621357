import React, { useContext } from 'react';
import { Tooltip } from '@mui/material';
import { SessionHandlerContext } from 'context/RfytSessionContext';
import dayjs from 'dayjs';
import CrossIcon from 'assets/svg/crossIcon.svg';

const ProcessingPaymentSection: React.FC = () => {
    const { sessionId, isChecking, waitingSince, setSession } = useContext(SessionHandlerContext);
    return (
        <>
            {isChecking && sessionId !== undefined ? (
                <>
                    <div className="text-align-center">
                        <p className="text-gray-100">
                            <p className="font-bold">Please wait, while we process your payment</p>
                            <p>{`For reference your transaction ID is - ${sessionId}`}</p>
                        </p>
                        <p className="loading-spinner-big"></p>

                        {dayjs().diff(waitingSince, 'seconds') > 40 && (
                            <Tooltip
                                title={
                                    dayjs().diff(waitingSince, 'seconds') > 40
                                        ? 'Payment is taking longer than usual'
                                        : 'Please Wait we are Processing the Payments'
                                }
                                placement="top"
                            >
                                <p className="text-gray-100 cursor-pointer" onClick={() => setSession(null)}>
                                    Your payment is taking longer time than usual, please <strong>click here</strong> to
                                    continue, while we process in background.
                                    <img
                                        src={CrossIcon}
                                        alt="cross"
                                        className="cross-image"
                                        onClick={() => setSession(null)}
                                    />
                                </p>
                            </Tooltip>
                        )}
                    </div>
                </>
            ) : null}
        </>
    );
};

export default ProcessingPaymentSection;
