import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import { IconButton, InputAdornment } from '@mui/material';
import { ReactComponent as EditIcon } from 'assets/images/edit-fill.svg';
import MDInput from 'components/themed/MDInput';
import { GetBusinessIndustryResponseDataObject } from 'contracts/spotdif/GetBusinessIndustryResponseDataObject';

import { ErrorMessage, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

const columnTypes = {
    column: 'businessColumn',
};

interface DragItem {
    index: number;
    type: string;
}

interface IColumnItemProps {
    index: string | number;
    displayName: string;
    originalName: string;
    isVisible: boolean;
    mapIndex: number;
    arrayHelpers: any; //@todo serach or define its types
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}

const ColumnItem: React.FC<IColumnItemProps> = ({ index, mapIndex, moveColumn, arrayHelpers }) => {
    const ref = useRef<HTMLLIElement>(null);

    const { values, setFieldValue, initialValues, handleBlur } =
        useFormikContext<Partial<GetBusinessIndustryResponseDataObject>>();

    const [, drop] = useDrop({
        accept: columnTypes.column,
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = mapIndex;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // if (Math.abs(hoverClientY) > Math.abs(hoverMiddleY)) {
            //   return;
            // }

            // Time to actually perform the action
            moveColumn(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: columnTypes.column,
        item: {
            index: mapIndex,
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const [editColumn, setEditColumn] = useState({
        fieldColumnValue: values.columns[mapIndex]?.originalName,
        columnNameValue: values.columns[mapIndex]?.displayName,
    });

    useEffect(() => {
        setEditColumn({
            ...editColumn,
            columnNameValue: values.columns[mapIndex]?.displayName,
            fieldColumnValue: values.columns[mapIndex]?.originalName,
        });
        window.addEventListener('discardChangeEvent', (e) => {
            setEditColumn({
                ...editColumn,
                columnNameValue: initialValues.columns[mapIndex]?.displayName,
                fieldColumnValue: initialValues.columns[mapIndex]?.originalName,
            });
        });
    }, [index, mapIndex, values.columns, initialValues.columns]);

    const deleteColumn = (index) => {
        if (initialValues.columns.find((item) => item.index === values.columns[index].index)) {
            setFieldValue('invisibleColumns', [
                ...values.invisibleColumns,
                {
                    ...values.columns[index],
                    isVisible: false,
                    originalNameActive: false,
                    displayNameActive: false,
                },
            ]); //
        }

        arrayHelpers.remove(index);
    };

    const toggleColumnFormik = (key, value) => {
        setFieldValue(key, value);
    };

    const handleInputChange = useCallback(
        (key, value) => {
            setEditColumn({
                ...editColumn,
                [key]: value,
            });
        },
        [editColumn],
    );

    const inputSubmit = useCallback(
        (key, formikKey, name) => {
            // toggleColumn(key);
            setFieldValue(formikKey, editColumn?.[`${key}Value`]);
            if (!editColumn?.[`${key}Value`]) {
                return;
            }
            toggleColumnFormik(`${formikKey}Active`, false);
        },
        [editColumn, toggleColumnFormik],
    );

    return (
        <>
            <li ref={ref}>
                <h4>Column {Number(mapIndex) + 1}</h4>
                <div className="input-block">
                    <div>
                        <MDInput
                            value={editColumn?.fieldColumnValue}
                            name={`columns[${mapIndex}].originalName`}
                            onChange={(e) => handleInputChange('fieldColumnValue', e.target.value)}
                            onBlur={handleBlur}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '0',
                                },
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                    border: '0.5px solid #79798F',
                                    borderRadius: '26px',
                                },
                            }}
                            inputProps={{ readOnly: !values.columns[mapIndex].originalNameActive }}
                            varient="standard"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle edit state" edge="end">
                                            {!values.columns[mapIndex].originalNameActive ? (
                                                <EditIcon
                                                    onClick={() =>
                                                        toggleColumnFormik(
                                                            `columns[${mapIndex}].originalNameActive`,
                                                            true,
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <div style={{ display: 'flex', gap: 2 }}>
                                                    <div>
                                                        <CheckIcon
                                                            color="success"
                                                            onClick={(e) =>
                                                                inputSubmit(
                                                                    'fieldColumn',
                                                                    `columns[${mapIndex}].originalName`,
                                                                    'originalName',
                                                                )
                                                            }
                                                        />{' '}
                                                    </div>
                                                    <div>
                                                        <CloseIcon
                                                            color="error"
                                                            onClick={(e) => {
                                                                setEditColumn({
                                                                    ...editColumn,
                                                                    fieldColumnValue:
                                                                        values.columns[mapIndex]?.originalName ?? '',
                                                                });
                                                                setFieldValue(
                                                                    `columns[${mapIndex}].originalName`,
                                                                    values.columns?.[mapIndex]?.originalName ?? '',
                                                                );
                                                                toggleColumnFormik(
                                                                    `columns[${mapIndex}].originalNameActive`,
                                                                    false,
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div className="error-v2">
                            <ErrorMessage name={`columns[${mapIndex}].originalName`} />
                        </div>
                        <div className="error-v2">
                            <ErrorMessage name={`columns[${mapIndex}].originalNameActive`} />
                        </div>
                    </div>
                    <div>
                        <MDInput
                            value={editColumn?.columnNameValue}
                            onChange={(e) => handleInputChange('columnNameValue', e.target.value)}
                            onBlur={handleBlur}
                            name={`columns[${mapIndex}].displayName`}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '0',
                                },
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                    border: '0.5px solid #79798F',
                                    borderRadius: '26px',
                                },
                            }}
                            varient="standard"
                            inputProps={{ readOnly: !values.columns[mapIndex].displayNameActive }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle edit state" edge="end">
                                            {!values.columns[mapIndex].displayNameActive ? (
                                                <EditIcon
                                                    onClick={() =>
                                                        toggleColumnFormik(
                                                            `columns[${mapIndex}].displayNameActive`,
                                                            true,
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <div style={{ display: 'flex', gap: 2 }}>
                                                    <CheckIcon
                                                        color="success"
                                                        onClick={(e) =>
                                                            inputSubmit(
                                                                'columnName',
                                                                `columns[${mapIndex}].displayName`,
                                                                'displayName',
                                                            )
                                                        }
                                                    />{' '}
                                                    <CloseIcon
                                                        color="error"
                                                        onClick={(e) => {
                                                            setEditColumn({
                                                                ...editColumn,
                                                                columnNameValue:
                                                                    values.columns?.[mapIndex]?.displayName ?? '',
                                                            });
                                                            setFieldValue(
                                                                `columns[${mapIndex}].displayName`,
                                                                values?.columns?.[mapIndex]?.displayName ?? '',
                                                            );
                                                            toggleColumnFormik(
                                                                `columns[${mapIndex}].displayNameActive`,
                                                                false,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div className="error-v2">
                            <ErrorMessage name={`columns[${mapIndex}].displayName`} />
                        </div>
                        <div className="error-v2">
                            <ErrorMessage name={`columns[${mapIndex}].displayNameActive`} />
                        </div>
                    </div>
                </div>
                <i className="hover-animation" onClick={() => deleteColumn(mapIndex)}>
                    <DeleteIcon />
                </i>
            </li>
        </>
    );
};

export default ColumnItem;
