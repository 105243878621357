import { Autocomplete } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete.svg';
import { ReactComponent as DragIndicatorIcon } from 'assets/images/icons/drag.svg';
import MDBox from 'components/themed/MDBox';
import MDInput from 'components/themed/MDInput';

import update from 'immutability-helper';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const SingleColumn = ({
                          id,
                          currentVisible,
                          setCurrentColumn,
                          handleSelectOption,
                          handleDeleteColumn,
                          column,
                          value,
                          defaultValue,
                          index,
                          options,
                          setColPref,
                      }) => {
    const ref = useRef(null);
    const restructureDataForResult = (data) => {
        let result = new Map();
        for (let i = 0; i < data.length; i++) {
            result.set(i, data[i].index);
        }
        return result;
    };

    const reorder = (selectedItem, index) => {
        const mapData = restructureDataForResult(currentVisible);
        const dragRecord = currentVisible[selectedItem.index];
        const result = update(currentVisible, {
            $splice: [
                [selectedItem.index, 1],
                [index, 0, dragRecord],
            ],
        });

        const updatedColumn = result.map((item, index) => {
            return {
                originalName: item.originalName,
                isVisible: item.isVisible,
                index: mapData.get(index),
                displayName: item.displayName,
            };
        });

        const finalResult = result.map((item) => {
            if (!item.isVisible) {
                return item;
            } else {
                const column = updatedColumn.find((i) => i.originalName === item.originalName);
                return column;
            }
        });
        return finalResult;
    };

    const [, drop] = useDrop({
        accept: 'card',
        drop: (item) => {
            const result = reorder(item, index);

            setCurrentColumn(result);
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => {
            return {
                id,
                index,
                value: column,
            };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <MDBox
            ref={ref}
            sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                gap: 1,
                mb: 1,
            }}
        >
            <MDBox sx={{ width: '5%' }}>
                <DragIndicatorIcon />
            </MDBox>
            <MDBox sx={{ width: '80%' }}>
                <Autocomplete
                    disableClearable
                    sx={{ width: '100%' }}
                    value={value}
                    defaultValue={defaultValue}
                    options={options}
                    getOptionLabel={(option) => {
                        if (option?.originalName === 'daily') {
                            return 'Daily Lead Cap';
                        } else if (option?.originalName === 'createdAt') {
                            return 'Signup Date';
                        } else {
                            return option?.displayName;
                        }
                    }}
                    onChange={(event, value) => {
                        handleSelectOption(event, value, column);
                    }}
                    renderInput={(params) => <MDInput {...params} varient="standard" sx={{ width: '100%' }} />}
                />
            </MDBox>
            <MDBox className="delete-light-icon" sx={{ width: '10%' }} onClick={(e) => handleDeleteColumn(e, column)}>
                <DeleteIcon />
            </MDBox>
        </MDBox>
    );
};

export default SingleColumn;
