export const PERMISSIONS = {
    CREATE: 'create',
    READ: 'read',
    DELETE: 'delete',
    UPDATE: 'update',
    MANAGE: 'manage',
    DISPLAY: 'all',
};

export const MODULE = {
    CLIENT_DASHBOARD: 'clientDashboard',

    CLIENT_LEADS: 'clientLeads',

    CLIENT_CREDIT_AND_BILLING: 'clientCreditAndBilling',

    CLIENT_SUPPLIER_BADGE: 'clientSupplierBadge',

    SUPPLIER_BADGE: 'supplierBadge',

    DASHBOARD: 'dashboard',
    DASHBOARD_CSV_FILE: 'dashboardCSVFile',

    CLIENTS: 'clients',
    CLIENT_CSV_FILE: 'clientsCSVFile',

    LEADS: 'leads',
    LEADS_CSV: 'leadsCSVFile',

    REPORTED_LEADS: 'reportedLeads',
    REPORTED_LEADS_CSV_FILE: 'reportedLeadsCSVFile',

    BUSINESS_INDUSTRIES: 'businessIndustries',

    PROFILE: 'profile',

    PROMO_LINKS: 'promoLinks',

    ADMINS: 'admins',

    PARTNERS: 'partners',

    POST_CODE_STATS: 'postCodeStats',
    ROLE_PERMISSIONS: 'rolePermissions',
    SITE_CONFIGS: 'siteConfigs',

    ACCOUNT_MANAGERS: 'accountManagers',
    SUBSCRIBERS: 'subscribers',
    NON_BILLABLE: 'nonBillables',

    CARD_DETAILS: 'cardDetails',
    TEAM_MEMBERS: 'teamMembers',
    TRANSACTIONS: 'transactions',
    INVOICES: 'invoices',
    ADJUST_LEADS: 'adjustLeads',
    FREE_CREDITS_CONFIGS: 'freeCreditsConfigs',
    VALIDATION_CONFIGS: 'validationConfigs',
    INVOICE_MANAGEMENT: 'invoiceManagement',
    CHAT_INBOX: 'chatInbox',
};

export type AvailablePermissions = keyof typeof PERMISSIONS;
export type AvailableModules = keyof typeof MODULE;
