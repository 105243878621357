import { Tab, Tabs, Tooltip } from '@mui/material';
import classNames from 'classnames';
import Modal from 'components/shared/Modal';
import MDBox from 'components/themed/MDBox';
import { GetAccountManagerDropdownDataObject } from 'contracts/spotdif/AccountManager';
import { GetBusinessIndustryDropdownDataObject } from 'contracts/spotdif/BusinessIndustry';
import { GetFreeCreditsLinkResponseData } from 'contracts/spotdif/GetFreeCreditsLinkResponseData';
import GenerateLink from 'pages/PromoLink/GenerateLink';
import React, { useCallback, useState } from 'react';
import GeneratePromolinkContent from './PromoLinkContent';

interface IGeneeratePromoLinkContentModal {
    closePopup: () => void;
    onSave: Function;
    accountManagerList: Array<GetAccountManagerDropdownDataObject>;
    businessIndustries?: Array<GetBusinessIndustryDropdownDataObject>;
    promoLinkData?: GetFreeCreditsLinkResponseData;
    isEditMode?: boolean;
}

const GeneratePromoLinkContentModal: React.FC<IGeneeratePromoLinkContentModal> = ({
                                                                                      closePopup,
                                                                                      onSave,
                                                                                      accountManagerList,
                                                                                      businessIndustries,
                                                                                      promoLinkData,
                                                                                      isEditMode = false,
                                                                                  }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [promoLinkContentId, setPromoLinkContentId] = useState('');

    const handleTabChange = useCallback(
        (event: React.SyntheticEvent, newTab: number) => {
            setActiveTab(newTab);
        },
        [activeTab],
    );
    return (
        <Modal open={true} handleClose={closePopup}>
            <MDBox className="modalFormMainWrapper">
                <MDBox className="modalMainWrapper promo-link-responsive-modal promo-content-scroller min-width-800">
                    <div className="promolink-tabs w-100 mb-1">
                        <Tabs className="promo-link-tab-wrapper mb-1" value={activeTab} onChange={handleTabChange}>
                            <Tab className="tab-text-value" label="Generate Link" />
                            <Tooltip
                                title={
                                    !promoLinkContentId && !isEditMode && `You must first create the promotional link`
                                }
                                placement="top"
                            >
                                <Tab
                                    className={classNames('tab-text-value', {
                                        'tab-text-value-disabled': !isEditMode && !promoLinkContentId,
                                    })}
                                    value={1}
                                    label="Generate Content"
                                    disabled={!isEditMode && !promoLinkContentId}
                                />
                            </Tooltip>
                        </Tabs>
                        {activeTab === 0 && (
                            <GenerateLink
                                onSave={onSave}
                                accountManagerList={accountManagerList}
                                businessIndustries={businessIndustries}
                                setActiveTab={setActiveTab}
                                setPromoLinkContentId={setPromoLinkContentId}
                                promoLinkContentId={promoLinkContentId}
                                promoLinkData={promoLinkData && promoLinkData}
                                isEditMode={isEditMode}
                            />
                        )}

                        {activeTab === 1 && (
                            <GeneratePromolinkContent
                                closePopup={closePopup}
                                promoLinkId={promoLinkContentId}
                                promoLinkData={promoLinkData && promoLinkData}
                                isEditMode={isEditMode}
                            />
                        )}
                    </div>
                </MDBox>
            </MDBox>
        </Modal>
    );
};

export default GeneratePromoLinkContentModal;
