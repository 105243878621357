/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import { BoxProps } from '@mui/material';

// Custom styles for MDBox
import MDBoxRoot from 'components/themed/MDBox/MDBoxRoot';
import { FC, forwardRef } from 'react';

// declaring props types for MDBox
interface Props extends BoxProps {
    variant?: 'contained' | 'gradient';
    bgColor?: string;
    color?: string;
    opacity?: number;
    borderRadius?: string;
    shadow?: string;
    coloredShadow?: string;

    [key: string]: any;
}

const MDBox: FC<Props> = forwardRef(
    ({ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow, ...rest }, ref) => (
        <MDBoxRoot
            {...rest}
            ref={ref}
            ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow }}
        />
    ),
);

// Declaring default props for MDBox
MDBox.defaultProps = {
    variant: 'contained',
    bgColor: 'transparent',
    color: 'dark',
    opacity: 1,
    borderRadius: 'none',
    shadow: 'none',
    coloredShadow: 'none',
};

export default MDBox;
