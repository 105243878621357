import { jsonMember, jsonObject } from "typedjson";

@jsonObject()
export class ContactOptionsObject{
    @jsonMember(String)
    _id: string;

    @jsonMember(String)
    optionSlug: string;

    @jsonMember(String)
    optionName: string;

    @jsonMember(Number)
    optionPrice: number;

    @jsonMember(Boolean)
    isActive: boolean
}